import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.find.js";
import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: /*@__PURE__*/_normalizeClass(['pointer-events-none text-xs font-semibold text-neutral-500'])
};
const _hoisted_2 = {
  class: "font-sans"
};
const _hoisted_3 = {
  class: "flex flex-1 flex-col"
};
const _hoisted_4 = {
  class: "relative flex flex-1 flex-col gap-y-7"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PSLeftSidebarUserMenu = _resolveComponent("PSLeftSidebarUserMenu");
  const _component_PanelLeftCloseIcon = _resolveComponent("PanelLeftCloseIcon");
  const _component_PanelLeftOpenIcon = _resolveComponent("PanelLeftOpenIcon");
  const _component_PSButton = _resolveComponent("PSButton");
  const _component_SearchIcon = _resolveComponent("SearchIcon");
  const _component_OutputDisplay = _resolveComponent("OutputDisplay");
  return _openBlock(), _createElementBlock("div", {
    id: "left-sidebar",
    class: _normalizeClass(_ctx.$style.leftSidebar),
    style: _normalizeStyle($options.cssProps)
  }, [_createElementVNode("div", {
    class: _normalizeClass(_ctx.$style.topMenuWrapper)
  }, [_createVNode(_component_PSLeftSidebarUserMenu, {
    class: _normalizeClass(_ctx.$style.userMenu)
  }, null, 8, ["class"]), _createVNode(_component_PSButton, {
    class: _normalizeClass(_ctx.$style.buttonToggleSidebar),
    title: "Toggle sidebar",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('update:modelValue', !$props.modelValue)),
    shortcode: "m"
  }, {
    default: _withCtx(() => [_cache[5] || (_cache[5] = _createElementVNode("span", {
      class: "sr-only"
    }, "Toggle sidebar", -1)), $props.modelValue ? (_openBlock(), _createBlock(_component_PanelLeftCloseIcon, {
      key: 0,
      size: "20",
      strokeWidth: "1.5"
    })) : (_openBlock(), _createBlock(_component_PanelLeftOpenIcon, {
      key: 1,
      size: "20",
      strokeWidth: "1.5"
    }))]),
    _: 1
  }, 8, ["class"])], 2), _createVNode(_component_PSButton, {
    ref: "commandPaletteButton",
    onClick: _cache[1] || (_cache[1] = $event => _ctx.$emit('toggleCommandPalette')),
    class: _normalizeClass([_ctx.$style.commandPaletteButton, "hidden sm:flex"])
  }, {
    default: _withCtx(() => [_createVNode(_component_SearchIcon, {
      size: "24",
      "stroke-width": "1.75",
      "aria-hidden": "true"
    }), _cache[7] || (_cache[7] = _createElementVNode("span", null, " Search ", -1)), _createElementVNode("span", _hoisted_1, [_createElementVNode("kbd", _hoisted_2, _toDisplayString(_ctx.$metaKey), 1), _cache[6] || (_cache[6] = _createElementVNode("kbd", {
      class: "font-sans"
    }, "K", -1))])]),
    _: 1
  }, 8, ["class"]), _createElementVNode("nav", _hoisted_3, [_createElementVNode("div", _hoisted_4, [(_openBlock(), _createBlock(_resolveDynamicComponent('style'), null, {
    default: _withCtx(() => [_createTextVNode(" body:not(:has(#draggable-wrapper)) ." + _toDisplayString(_ctx.$style.leftSidebar) + " [data-entry-id=\"" + _toDisplayString(_ctx.$route.params.id) + "\"] { --ps-output-display-entry-display-background-color: var(--ps-base-primary-color); --ps-output-display-entry-display-background-color-hover: var(--ps-base-primary-color); --ps-output-display-entry-display-text-color: var(--ps-base-background-color); --ps-output-display-entry-display-text-color-hover: var(--ps-base-background-color); --ps-entry-display-settings-button-bg-color: var(--ps-base-primary-color); --ps-entry-display-settings-button-bg-color-hover: var(--ps-base-secondary-color); .ps_sense_button { --ps-button-bg-color:transparent!important; --ps-button-bg-color-hover: transparent!important; } } body:not(:has(#draggable-wrapper)) ." + _toDisplayString(_ctx.$style.leftSidebar) + " [data-entry-id=\"" + _toDisplayString(_ctx.$route.params.id) + "\"] .name_display { --ps-name-display-color: var(--ps-base-background-color)!important; } ", 1)]),
    _: 1
  })), _createVNode(_component_OutputDisplay, {
    id: $props.outputId,
    modelValue: $data.originsEntry.output,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.originsEntry.output = $event),
    entry: $data.originsEntry,
    display: {
      status: false,
      procrastination: false,
      links: false,
      description: false,
      schedule: false,
      output: true,
      output_count: true,
      input: false,
      columns: false,
      leftover: false,
      settings: true,
      senses: true,
      routine: false,
      time_trackings: false,
      custom_fields: false
    },
    overrideEntryPermissions: {
      name: false,
      description: false,
      status: false
    },
    sorting: $data.originsEntry.output.sorting,
    position: "left",
    disableViewportChecking: false,
    entriesDisabled: true,
    onOnEntryClick: _cache[3] || (_cache[3] = $event => _ctx.$emit('onEntryClick')),
    overrideEntryDisplayOutput: entry => entry.anchors.find(anchor => anchor.placement == 'left-sidebar')?.settings?.display?.output,
    overrideToggleEntryDisplayOutput: entry => {
      let anchor = entry.anchors.find(anchor => anchor.placement == 'left-sidebar');
      if (anchor) {
        if (!anchor.settings) anchor.settings = {};
        if (!anchor.settings.display) anchor.settings.display = {};
        anchor.settings.display = {
          ...anchor.settings.display,
          output: anchor.settings.display?.output ? !anchor.settings.display.output : true
        };
        if (!entry.temp) _ctx.$store.dispatch('push', {
          event: 'entry_update',
          params: {
            entry: entry
          },
          entry: entry,
          undo: true
        });
      }
    },
    editable: false,
    size: "sm",
    onCreated: _cache[4] || (_cache[4] = entry => {
      _ctx.$document.activeElement?.blur();
      _ctx.$nextTick(() => {
        this.$router.push({
          name: 'entry',
          params: {
            id: entry.id
          }
        });
      });
    }),
    overrideOnDrop: $options.handleAnchorSorting,
    class: _normalizeClass(_ctx.$style.outputDisplay)
  }, null, 8, ["id", "modelValue", "entry", "sorting", "overrideEntryDisplayOutput", "overrideToggleEntryDisplayOutput", "overrideOnDrop", "class"])])])], 6);
}