import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.find.js";
import "core-js/modules/es.iterator.map.js";
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["onClick"];
const _hoisted_2 = ["onMousedown"];
const _hoisted_3 = {
  "data-handle": "",
  class: "cursor-move"
};
const _hoisted_4 = {
  key: 0
};
const _hoisted_5 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_EllipsisIcon = _resolveComponent("EllipsisIcon");
  const _component_GripVerticalIcon = _resolveComponent("GripVerticalIcon");
  const _component_EyeIcon = _resolveComponent("EyeIcon");
  const _component_PSButton = _resolveComponent("PSButton");
  const _component_draggable = _resolveComponent("draggable");
  const _component_EyeClosedIcon = _resolveComponent("EyeClosedIcon");
  const _component_PopoverHelper = _resolveComponent("PopoverHelper");
  return _openBlock(), _createBlock(_component_draggable, {
    class: _normalizeClass(_ctx.$style.outputTableHeaderRow),
    modelValue: $options.tableColumns,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.$emit('update:modelValue', $event)),
    group: "statuses",
    handle: "[data-handle]",
    direction: "vertical",
    onChange: $options.updatePositions,
    "item-key": "field",
    delay: 150,
    delayOnTouchOnly: true
  }, {
    item: _withCtx(({
      element: column
    }) => [_createElementVNode("div", {
      onClick: $event => $options.handleSortingByColumn(column.field),
      "data-handle": "",
      class: _normalizeClass(_ctx.$style.tableColumn),
      style: _normalizeStyle({
        '--ps-table-column-width': column.width + 'px'
      }),
      ref: "columnRef"
    }, [column.field.startsWith('custom_field_') ? (_openBlock(), _createElementBlock("span", {
      key: 0,
      class: _normalizeClass(_ctx.$style.columnLabel)
    }, _toDisplayString($props.entry.custom_fields.find(f => f.id == column.field.replace("custom_field_", ""))?.name), 3)) : (_openBlock(), _createElementBlock("span", {
      key: 1,
      class: _normalizeClass(_ctx.$style.columnLabel)
    }, _toDisplayString(column.field.replace(/_/g, " ")), 3)), _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.resizeHandle),
      onMousedown: _withModifiers($event => $options.startResizing($event, column), ["stop", "prevent"])
    }, null, 42, _hoisted_2)], 14, _hoisted_1)]),
    footer: _withCtx(() => [_createVNode(_component_PopoverHelper, {
      teleport: true,
      class: _normalizeClass(_ctx.$style.tableColumn)
    }, {
      button: _withCtx(() => [_createVNode(_component_EllipsisIcon, {
        size: "20",
        strokeWidth: "1.5"
      })]),
      default: _withCtx(() => [_createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.popoverContent)
      }, [_createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.popoverHeader)
      }, "Visible Columns", 2), _createVNode(_component_draggable, {
        modelValue: $options.tableColumns,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.$emit('update:modelValue', $event)),
        handle: "[data-handle]",
        onChange: $options.updatePositions,
        "item-key": "field",
        delay: 150,
        delayOnTouchOnly: true
      }, {
        item: _withCtx(({
          element: column
        }) => [_createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.popoverItem)
        }, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_GripVerticalIcon, {
          size: "20"
        })]), _createElementVNode("div", null, [column.field.startsWith('custom_field_') ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString($props.entry.custom_fields.find(f => f.id == column.field.replace("custom_field_", ""))?.name), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(column.field.replace(/_/g, " ").replace(/\b\w/g, char => char.toUpperCase())), 1))]), _createElementVNode("div", null, [_createVNode(_component_PSButton, {
          onClick: $event => $options.tableColumns = $options.tableColumns.filter(c => c.field !== column.field),
          title: "Hide Column"
        }, {
          default: _withCtx(() => [_createVNode(_component_EyeIcon, {
            size: "20"
          })]),
          _: 2
        }, 1032, ["onClick"])])], 2)]),
        _: 1
      }, 8, ["modelValue", "onChange"]), _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.popoverHeader)
      }, "Hidden Columns", 2), _createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.availableColumns.filter(column => !$options.tableColumns.map(c => c.field).includes(column.field)), column => {
        return _openBlock(), _createElementBlock("div", {
          key: column.field,
          class: _normalizeClass(_ctx.$style.popoverItem)
        }, [_createElementVNode("div", null, _toDisplayString(column.label), 1), _createElementVNode("div", null, [_createVNode(_component_PSButton, {
          onClick: $event => $options.tableColumns = [...$options.tableColumns, {
            field: column.field,
            width: 100
          }],
          title: "Show Column"
        }, {
          default: _withCtx(() => [_createVNode(_component_EyeClosedIcon, {
            size: "20"
          })]),
          _: 2
        }, 1032, ["onClick"])])], 2);
      }), 128))])], 2)]),
      _: 1
    }, 8, ["class"])]),
    _: 1
  }, 8, ["class", "modelValue", "onChange"]);
}