import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ZapIcon = _resolveComponent("ZapIcon");
  const _component_PSButton = _resolveComponent("PSButton");
  const _component_PSList = _resolveComponent("PSList");
  return $options.entries.length ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass(_ctx.$style.quickTransformInputOutput)
  }, [_createElementVNode("span", {
    class: _normalizeClass(_ctx.$style.quickTransformInputOutputLabel)
  }, [_createVNode(_component_ZapIcon, {
    size: "16",
    class: _normalizeClass(_ctx.$style.quickTransformInputOutputIcon)
  }, null, 8, ["class"]), _cache[1] || (_cache[1] = _createTextVNode(" Convert into "))], 2), _createVNode(_component_PSList, {
    class: _normalizeClass(_ctx.$style.quickTransformInputOutputList)
  }, {
    default: _withCtx(() => [_createVNode(_component_PSButton, {
      onClick: _cache[0] || (_cache[0] = $event => $options.apply('todo-list')),
      class: _normalizeClass(_ctx.$style.quickTransformInputOutputButton)
    }, {
      default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode(" Todo List ")])),
      _: 1
    }, 8, ["class"])]),
    _: 1
  }, 8, ["class"])], 2)) : _createCommentVNode("", true);
}