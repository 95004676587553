import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.for-each.js";
import Scrollparent from "scrollparent";
export default {
  props: {
    items: Array,
    itemHeight: {
      type: Number,
      default: 0
    },
    itemClass: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      intersectionObserver: null,
      resizeObserver: null,
      visibleItems: [],
      updateTimeout: null,
      visibleItemsDelayed: [],
      cachedSizes: [],
      rootMargin: null
    };
  },
  watch: {
    items: {
      handler: function (n, o) {
        if (!o) return;
        n.forEach((entry, index) => {
          if (entry.id != o[index]?.id) {
            if (this.$refs && this.$refs.item && this.$refs.item[index]) {
              entry = this.$refs?.item[index];
              if (entry) {
                const boundary = entry.$refs.boundaries;
                if (boundary) {
                  boundary.style.minHeight = null;
                  this.intersectionObserver.unobserve(boundary);
                  this.resizeObserver.unobserve(boundary);
                }
              }
            }
          }
        });
        this.$nextTick(() => {
          n.forEach((entry, index) => {
            if (entry.id != o[index]?.id) {
              if (this.$refs && this.$refs.item && this.$refs.item[index]) {
                entry = this.$refs?.item[index];
                if (entry) {
                  const boundary = entry.$refs.boundaries;
                  if (boundary) {
                    // boundary.style.minHeight = `${this.itemHeight}px`;
                    this.intersectionObserver.observe(boundary);
                    this.resizeObserver.observe(boundary);
                  }
                }
              }
            }
          });
        });
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    if (this.$Cypress) this.rootMargin = "999999px";else this.rootMargin = window.innerWidth * 0.8 + "px";

    // Initialize an array with the same length as items, filled with `false`
    this.visibleItems = Array(this.items.length).fill(false);
    this.itemsCached = this.items;
    var scrollParent = Scrollparent(this.$el.parentElement);
    // console.log(this.$parent.$el, scrollParent, scrollParent.dataset);
    if (scrollParent.dataset.skipScrollparent) {
      scrollParent = Scrollparent(scrollParent);
    }
    const options = {
      root: scrollParent !== document.body ? scrollParent : null,
      rootMargin: this.rootMargin,
      threshold: 0
      // threshold: [0, 0.25, 0.5, 0.75, 1], // Multiple thresholds to detect partial visibility
    };

    // Create a standard IntersectionObserver callback
    const handleIntersect = entries => {
      entries.forEach(entry => {
        if (!entry.target || !document.body.contains(entry.target)) return; // fixing entries going "invisible" when their above entry unmounts
        const index = entry.target.dataset.index;
        let isVisible = entry.isIntersecting;
        if (entry.rootBounds && entry.rootBounds.width == 0 && entry.rootBounds.height == 0) {
          // @note as of 2025-03-10 this is a workaround for a bug in the IntersectionObserver
          // where the rootBounds are 0 when the entry is in the viewport
          // and the entry is not visible
          isVisible = true;
          // isVisible = isElementInViewport(entry.target);
        }

        // Only update visibleItems if the state has changed
        if (this.visibleItems[index] !== isVisible) {
          this.visibleItems[index] = isVisible; // Directly update the reactive array
        }
      });
    };
    this.intersectionObserver = new IntersectionObserver(handleIntersect, options);

    // Helper method to check if element is in viewport
    const isElementInViewport = el => {
      const rect = el.getBoundingClientRect();
      return rect.top >= -parseInt(this.rootMargin) && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) + parseInt(this.rootMargin) && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
    };

    // Force an initial check of all items
    this.$nextTick(() => {
      this.$refs.item?.forEach(entry => {
        const boundary = entry.$refs.boundaries;
        if (boundary) {
          boundary.style.minHeight = `${this.itemHeight}px`;
          this.intersectionObserver.observe(boundary);
          // Trigger an initial intersection check
          handleIntersect([{
            target: boundary,
            isIntersecting: isElementInViewport(boundary)
          }]);
        }
      });
    });
    const handleResizes = entries => {
      // eslint-disable-next-line no-unused-vars
      entries.forEach(entry => {
        setTimeout(() => {
          entry.target.style.minHeight = null; // This is needed when the browser window is resized so the entries are not "too high"
        }, 100);
      });
    };

    // Create a ResizeObserver to handle resizing of items
    this.resizeObserver = new ResizeObserver(handleResizes);

    // Observe the boundaries of each item
    this.$refs.item?.forEach(entry => {
      const boundary = entry.$refs.boundaries;
      if (boundary) {
        boundary.style.minHeight = `${this.itemHeight}px`;
        this.intersectionObserver.observe(boundary);
        this.resizeObserver.observe(boundary);
      }
    });
  },
  beforeUnmount() {
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
    }
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }
};