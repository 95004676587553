import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, renderSlot as _renderSlot, Transition as _Transition, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "flex flex-col gap-4 divide-y divide-neutral-200"
};
const _hoisted_2 = {
  class: "flex justify-between gap-4 pt-4"
};
const _hoisted_3 = {
  key: 1,
  class: "container relative mx-auto flex w-full max-w-5xl flex-col gap-4 divide-y divide-neutral-100 md:flex-row md:divide-x md:divide-y-0 dark:divide-neutral-900"
};
const _hoisted_4 = {
  class: "flex min-w-32 flex-wrap gap-2 md:flex-col"
};
const _hoisted_5 = {
  class: "flex flex-col gap-4 pt-4 md:pl-4 md:pt-0"
};
const _hoisted_6 = {
  key: 0,
  class: "grid gap-4 md:grid-cols-2 lg:grid-cols-3"
};
const _hoisted_7 = {
  class: "group flex h-full cursor-pointer flex-col gap-2 rounded-xl border border-neutral-100 p-5 hover:border-neutral-200 hover:bg-neutral-50 dark:border-neutral-900 dark:hover:border-neutral-800 dark:hover:bg-neutral-950"
};
const _hoisted_8 = {
  class: "grid gap-4 md:grid-cols-2 lg:grid-cols-3"
};
const _hoisted_9 = {
  class: "group flex h-full cursor-pointer flex-col gap-2 rounded-xl border border-neutral-100 p-5 hover:border-neutral-200 hover:bg-neutral-50 dark:border-neutral-900 dark:hover:border-neutral-800 dark:hover:bg-neutral-950"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TemplateDisplay = _resolveComponent("TemplateDisplay");
  const _component_ButtonComponent = _resolveComponent("ButtonComponent");
  const _component_TransitionGroupHelper = _resolveComponent("TransitionGroupHelper");
  return _openBlock(), _createBlock(_Transition, {
    onEnter: $options.animationEnter,
    onLeave: $options.animationLeave
  }, {
    default: _withCtx(() => [$data.selectedTemplate ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_TemplateDisplay, {
      modelValue: $data.selectedTemplate,
      showPreview: true
    }, null, 8, ["modelValue"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_ButtonComponent, {
      onClick: _cache[0] || (_cache[0] = $event => $data.selectedTemplate = null),
      color: "secondary"
    }, {
      default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode(" Close ")])),
      _: 1
    }), _createVNode(_component_ButtonComponent, {
      onClick: _cache[1] || (_cache[1] = $event => (_ctx.applyTemplateToEntry($data.selectedTemplate, $options.entry), _ctx.$emit('templateSelected', $data.selectedTemplate), $data.selectedTemplate = null)),
      color: "primary"
    }, {
      default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode(" Use ")])),
      _: 1
    })])])) : (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_cache[4] || (_cache[4] = _createElementVNode("h5", {
      class: "mb-2 basis-full font-semibold text-neutral-900 md:basis-0 dark:text-neutral-300"
    }, " Filter ", -1)), _createVNode(_component_TransitionGroupHelper, {
      enter: {
        // easing: 'easeOutExpo',
        translateY: [50, 0],
        opacity: [0, 1]
      }
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tagsWithTemplates, tag => {
        return _openBlock(), _createElementBlock("div", {
          key: tag,
          class: "font-mono"
        }, [_createVNode(_component_ButtonComponent, {
          size: "xs",
          color: _ctx.tagSelected == tag ? 'primary' : 'secondary',
          onClick: $event => _ctx.tagSelected == tag ? _ctx.tagSelected = null : _ctx.tagSelected = tag,
          class: "!font-normal text-neutral-500"
        }, {
          default: _withCtx(() => [_createTextVNode(" #" + _toDisplayString(tag
          /*.replace("-", "-")
           .toUpperCase()
           .replace(/\b\w/g, (c) => c.toUpperCase()) */), 1)]),
          _: 2
        }, 1032, ["color", "onClick"])]);
      }), 128))]),
      _: 1
    })]), _createElementVNode("div", _hoisted_5, [!$props.hideCustomTemplates ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_TransitionGroupHelper, {
      enter: {
        // easing: 'easeOutExpo',
        translateY: [50, 0],
        opacity: [0, 1]
      }
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customTemplates, item => {
        return _openBlock(), _createElementBlock("div", {
          key: item.id
        }, [_createElementVNode("div", _hoisted_7, [_createVNode(_component_TemplateDisplay, {
          modelValue: item,
          showPreview: false,
          onClick: $event => $options.handleTemplateClick(item),
          size: "sm"
        }, {
          top: _withCtx(() => [_renderSlot(_ctx.$slots, "template_top", {
            template: item
          })]),
          _: 2
        }, 1032, ["modelValue", "onClick"])])]);
      }), 128))]),
      _: 3
    })])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_8, [_createVNode(_component_TransitionGroupHelper, {
      enter: {
        // easing: 'easeOutExpo',
        translateY: [50, 0],
        opacity: [0, 1]
      }
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.templatesFiltered.filter(t => t.global), item => {
        return _openBlock(), _createElementBlock("div", {
          key: item.id
        }, [_createElementVNode("div", _hoisted_9, [_createVNode(_component_TemplateDisplay, {
          modelValue: item,
          showPreview: false,
          onClick: $event => $options.handleTemplateClick(item),
          size: "sm"
        }, {
          top: _withCtx(() => [_renderSlot(_ctx.$slots, "template_top", {
            template: item
          })]),
          _: 2
        }, 1032, ["modelValue", "onClick"])])]);
      }), 128))]),
      _: 3
    })])])]))]),
    _: 3
  }, 8, ["onEnter", "onLeave"]);
}