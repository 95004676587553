import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  role: "button",
  class: "w-full"
};
const _hoisted_2 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_FlagIcon = _resolveComponent("FlagIcon");
  const _component_ButtonComponent = _resolveComponent("ButtonComponent");
  const _component_PSVerticalListItem = _resolveComponent("PSVerticalListItem");
  const _component_PSVerticalList = _resolveComponent("PSVerticalList");
  const _component_PSOverlay = _resolveComponent("PSOverlay");
  return _openBlock(), _createBlock(_component_PSOverlay, null, {
    button: _withCtx(({
      open
    }) => [_createElementVNode("button", _hoisted_1, [_renderSlot(_ctx.$slots, "button", {
      open: open
    }, () => [_cache[0] || (_cache[0] = _createElementVNode("span", {
      class: "sr-only"
    }, "Set priority", -1)), _createVNode(_component_ButtonComponent, {
      title: "Set priority",
      variant: _ctx.variant,
      size: _ctx.size,
      color: $options.prioColor,
      colorWeight: _ctx.colorWeight
    }, {
      default: _withCtx(() => [_createVNode(_component_FlagIcon, {
        size: "20"
      }), _ctx.showLabel && _ctx.variant != 'round' ? (_openBlock(), _createElementBlock("span", _hoisted_2, " Priority " + _toDisplayString($options.priority?.level), 1)) : _createCommentVNode("", true)]),
      _: 1
    }, 8, ["variant", "size", "color", "colorWeight"])])])]),
    title: _withCtx(() => _cache[1] || (_cache[1] = [_createElementVNode("span", null, "Priority", -1)])),
    default: _withCtx(({
      close
    }) => [_createVNode(_component_PSVerticalList, {
      class: _normalizeClass(_ctx.$style.PrioritySettingsVerticalList)
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.priorities, (priority, index) => {
        return _openBlock(), _createBlock(_component_PSVerticalListItem, {
          key: index,
          active: $data.level == priority,
          onClick: $event => ($data.level = priority, close())
        }, {
          before: _withCtx(() => [_createVNode(_component_FlagIcon, {
            size: "16",
            color: _ctx.$colors[priority == 1 ? 'red' : priority == 2 ? 'orange' : priority == 3 ? 'yellow' : 'neutral'][500]
          }, null, 8, ["color"])]),
          default: _withCtx(() => [_createTextVNode(" Priority " + _toDisplayString(priority), 1)]),
          _: 2
        }, 1032, ["active", "onClick"]);
      }), 128)), _createVNode(_component_PSVerticalListItem, {
        active: $data.level == null,
        onClick: $event => ($data.level = null, close())
      }, {
        icon: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode("   ")])),
        default: _withCtx(() => [_cache[3] || (_cache[3] = _createTextVNode(" No Priority "))]),
        _: 2
      }, 1032, ["active", "onClick"])]),
      _: 2
    }, 1032, ["class"])]),
    _: 3
  });
}