import { renderSlot as _renderSlot, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createBlock(_Transition, {
    "enter-active-class": _ctx.$style.enterActiveClass,
    "enter-from-class": _ctx.$style.enterFromClass,
    "enter-to-class": _ctx.$style.enterToClass,
    "leave-active-class": _ctx.$style.leaveActiveClass,
    "leave-from-class": _ctx.$style.leaveFromClass,
    "leave-to-class": _ctx.$style.leaveToClass
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
    _: 3
  }, 8, ["enter-active-class", "enter-from-class", "enter-to-class", "leave-active-class", "leave-from-class", "leave-to-class"]);
}