import "core-js/modules/es.array.push.js";
export default {
  computed: {
    space() {
      return this.$store.getters.space;
    }
  },
  mounted() {
    this.versionCheck();
  },
  watch: {
    "$store.getters.timestamp": function () {
      this.$onIdle(() => {
        this.versionCheck();
      });
    }
  },
  methods: {
    versionCheck() {
      if (this.$isElectron || this.$isCapacitor) {
        console.log("Skipping version check in Electron/Capacitor");
        return;
      }
      this.$store.dispatch("versionCheck").then(() => {
        if (this.$store.getters.version != this.$version) {
          console.log("Version check failed", this.$store.getters.version, this.$version);
          if (this.parseVersion(this.$version) < this.parseVersion(this.$store.getters.version)) {
            this.resetApp();
          } else {
            console.log("the version is newer than the current version");
          }
        }
      });
    },
    parseVersion(version) {
      // Match pattern like "0.13.2-RC5" or "1.2.3" or "1.2.3-RC1"
      const match = version.match(/^(\d+)\.(\d+)\.(\d+)(?:-RC(\d+))?$/i);
      if (!match) {
        throw new Error(`Invalid version format: ${version}`);
      }
      const [, major, minor, patch, rc] = match;

      // Pad each number to 2 digits
      return major.padStart(2, "0") + minor.padStart(2, "0") + patch.padStart(2, "0") + (rc ? rc.padStart(2, "0") : "00");
    },
    resetApp() {
      this.$router.push({
        name: "logout"
      });
      window.location.reload();
    }
  }
};