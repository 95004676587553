import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "uppercase"
};
const _hoisted_2 = {
  class: "sm:w-[188px]"
};
const _hoisted_3 = {
  style: {
    "color": "var(--color-medium-gray)"
  }
};
const _hoisted_4 = {
  style: {
    "color": "var(--color-medium-gray)"
  }
};
const _hoisted_5 = {
  key: 0
};
const _hoisted_6 = {
  class: "justfy-between flex gap-4 px-4 py-3"
};
const _hoisted_7 = {
  style: {
    "display": "flex",
    "gap": "0.5rem"
  }
};
const _hoisted_8 = {
  style: {
    "font-weight": "bold"
  }
};
const _hoisted_9 = {
  style: {
    "display": "flex",
    "gap": "0.5rem"
  }
};
const _hoisted_10 = {
  style: {
    "font-weight": "bold",
    "min-width": "5rem",
    "display": "inline-block",
    "text-align": "center",
    "text-transform": "uppercase"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ClockIcon = _resolveComponent("ClockIcon");
  const _component_ButtonComponent = _resolveComponent("ButtonComponent");
  const _component_ScheduleTimeAndDurationSettings = _resolveComponent("ScheduleTimeAndDurationSettings");
  const _component_PSListItem = _resolveComponent("PSListItem");
  const _component_TrashIcon = _resolveComponent("TrashIcon");
  const _component_SaveIcon = _resolveComponent("SaveIcon");
  const _component_XIcon = _resolveComponent("XIcon");
  const _component_PSList = _resolveComponent("PSList");
  const _component_PSOverlay = _resolveComponent("PSOverlay");
  return _openBlock(), _createBlock(_component_PSOverlay, {
    id: "schedule_time_picker",
    teleport: $props.teleport,
    class: "relative"
  }, {
    button: _withCtx(() => [_createVNode(_component_ButtonComponent, {
      variant: "none",
      ref: "reference",
      class: _normalizeClass([_ctx.$style.scheduleTimePickerButton, "group"]),
      title: "Set schedule time"
    }, {
      default: _withCtx(() => [_createVNode(_component_ClockIcon, {
        size: "14"
      }), _createElementVNode("span", _hoisted_1, _toDisplayString($options.displayText), 1)]),
      _: 1
    }, 8, ["class"])]),
    default: _withCtx(({
      close
    }) => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_ScheduleTimeAndDurationSettings, {
      modelValue: $options.schedule,
      entry: $props.entry,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = value => $options.schedule = value),
      class: _normalizeClass(_ctx.$style.timeAndDurationSettings)
    }, null, 8, ["modelValue", "entry", "class"]), _createVNode(_component_PSList, null, {
      default: _withCtx(() => [_createVNode(_component_PSListItem, {
        onClick: $event => ($options.time = _ctx.interpretTime({
          time: _ctx.$moment().utc().format('HH:mm:00')
        }), close()),
        class: "flex w-full cursor-pointer items-center justify-between gap-x-2 px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-950"
      }, {
        after: _withCtx(() => [_createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.interpretTime({
          time: _ctx.$moment().format("HH:mm")
        })), 1)]),
        default: _withCtx(() => [_cache[6] || (_cache[6] = _createElementVNode("span", {
          style: {
            "font-weight": "bold"
          }
        }, " Now ", -1))]),
        _: 2
      }, 1032, ["onClick"]), _createVNode(_component_PSListItem, {
        onClick: $event => ($options.time = _ctx.interpretTime({
          time: _ctx.$moment().utc().add(1, 'hour').format('HH:mm:00')
        }), close()),
        class: "flex w-full cursor-pointer items-center justify-between gap-x-2 px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-950"
      }, {
        after: _withCtx(() => [_createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.interpretTime({
          time: _ctx.$moment().add(1, "hour").format("HH:mm")
        })), 1)]),
        default: _withCtx(() => [_cache[7] || (_cache[7] = _createElementVNode("span", {
          style: {
            "font-weight": "bold"
          }
        }, " In 1 Hour ", -1))]),
        _: 2
      }, 1032, ["onClick"]), _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.utilitiesRow)
      }, [_createVNode(_component_PSListItem, {
        onClick: $event => ($options.time = null, close()),
        class: _normalizeClass(_ctx.$style.buttonDelete)
      }, {
        default: _withCtx(() => [_createVNode(_component_TrashIcon, {
          size: "18"
        })]),
        _: 2
      }, 1032, ["onClick", "class"]), _createVNode(_component_PSListItem, {
        onClick: $event => close(),
        class: _normalizeClass(_ctx.$style.buttonSave)
      }, {
        default: _withCtx(() => [_createVNode(_component_SaveIcon, {
          size: "18"
        })]),
        _: 2
      }, 1032, ["onClick", "class"])], 2), 3 > 4 && typeof $options.time == 'object' ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_ctx.value?.op ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        style: _normalizeStyle([{
          color: _ctx.value.op == 'now' || _ctx.value.op.slice(0, 4) == 'next' && (typeof _ctx.value.x == 'undefined' || _ctx.value.x >= 0) || _ctx.value.op.slice(0, 4) == 'last' && (typeof _ctx.value.x == 'undefined' || _ctx.value.x <= 0) ? 'var(--color-dark-gray)' : 'var(--color-medium-gray)'
        }, {
          "font-size": "82%",
          "font-weight": "bold",
          "text-transform": "uppercase"
        }])
      }, " in ", 4)) : _createCommentVNode("", true), _createElementVNode("span", null, [_createElementVNode("span", _hoisted_7, [_createVNode(_component_ButtonComponent, {
        onClick: _cache[1] || (_cache[1] = _withModifiers($event => $options.modifyRelativeDate(-1, _ctx.value), ["prevent"]))
      }, {
        default: _withCtx(() => _cache[8] || (_cache[8] = [_createTextVNode(" − ")])),
        _: 1
      }), _createElementVNode("span", _hoisted_8, _toDisplayString($options.relativeTimespan(_ctx.value) ? Math.abs($options.relativeTimespan(_ctx.value)[0]) : null), 1), _createVNode(_component_ButtonComponent, {
        onClick: _cache[2] || (_cache[2] = _withModifiers($event => $options.modifyRelativeDate(1, _ctx.value), ["prevent"]))
      }, {
        default: _withCtx(() => _cache[9] || (_cache[9] = [_createTextVNode(" + ")])),
        _: 1
      })])]), _createElementVNode("span", null, [_createElementVNode("span", _hoisted_9, [_createVNode(_component_ButtonComponent, {
        onClick: _cache[3] || (_cache[3] = _withModifiers($event => $options.modifyRelativeTimespan(-1, _ctx.value), ["prevent"]))
      }, {
        default: _withCtx(() => _cache[10] || (_cache[10] = [_createTextVNode(" − ")])),
        _: 1
      }), _createElementVNode("span", _hoisted_10, _toDisplayString($options.relativeTimespan(_ctx.value) ? $options.relativeTimespan(_ctx.value)[1] : null), 1), _createVNode(_component_ButtonComponent, {
        onClick: _cache[4] || (_cache[4] = _withModifiers($event => $options.modifyRelativeTimespan(1, _ctx.value), ["prevent"]))
      }, {
        default: _withCtx(() => _cache[11] || (_cache[11] = [_createTextVNode(" + ")])),
        _: 1
      })])]), _ctx.value?.op ? (_openBlock(), _createElementBlock("span", {
        key: 1,
        style: _normalizeStyle([{
          color: _ctx.value.op.slice(0, 4) == 'next' && (typeof _ctx.value.x == 'undefined' || _ctx.value.x < 0) || _ctx.value.op.slice(0, 4) == 'last' && (typeof _ctx.value.x == 'undefined' || _ctx.value.x > 0) ? 'var(--color-dark-gray)' : 'var(--color-medium-gray)'
        }, {
          "font-size": "82%",
          "font-weight": "bold",
          "text-transform": "uppercase"
        }])
      }, " ago ", 4)) : _createCommentVNode("", true)]), _createVNode(_component_PSListItem, {
        onClick: _cache[5] || (_cache[5] = $event => [_ctx.value = $props.entry ? {
          op: 'null'
        } : {
          op: 'unset'
        }]),
        class: "flex w-full cursor-pointer items-center gap-x-2 px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-950"
      }, {
        default: _withCtx(() => [_createVNode(_component_XIcon, {
          size: "16"
        }), _cache[12] || (_cache[12] = _createTextVNode(" Unset "))]),
        _: 1
      })])) : _createCommentVNode("", true)]),
      _: 2
    }, 1024)])]),
    _: 1
  }, 8, ["teleport"]);
}