import { addonBlueprint } from "@/addonBlueprint";
import moment from "moment";

export const Statusing = {
  inject: {
    overrideStatuses: {
      default: null,
    },
  },
  methods: {

    setStatus(object, status) {

      // console.log("setStatus", object, status);
      if (!this.statusIsDone(object.status?.id) && this.statusIsDone(status)) {
        object.completed_at = moment.utc().format("YYYY-MM-DD HH:mm:ss");
        if (typeof object.completed_count === 'undefined')
          object.completed_count = 0;
        object.completed_count++;
      }


      if (status) {
        if (typeof status === 'string') {
          status = this.getStatusById(status);
          object.status = status;
        }
        else {
          object.status = status;
        }
      } else {
        object.status = null;
      }


      // Handling Procrastination
      if (object.procrastination) {
        if (object.status === null || this.statusIsDone(object.status?.id)) {
          const procrastinationId = object.procrastination.id;
          delete object.procrastination;
          if (!object.temp)
            this.$store.dispatch("push", {
              event: "procrastination_delete",
              params: { id: procrastinationId },
              entry: object,
              undo: true,
            });
        }
      }

      // Handling automatic time tracking
      if (this.statusIsDone(object.status?.id) && object.time_trackings?.count > 0 && this.$store.getters.time_trackings.find(tt => tt.entry_id == object.id && tt.end_at === null)) {

        // Will close active time tracking
        this.stopTimeTracking(object);

        // const time_track = this.$store.getters.time_trackings.find(tt => tt.entry_id == object.id && tt.end_at === null)
        // time_track.end_at = moment.utc().format("YYYY-MM-DD HH:mm:ss");


      } else if (this.statusIsDone(object.status?.id) && object.schedule && object.schedule.duration) {

        // Will create a new time tracking with the duration of the schedule

        const time_track = addonBlueprint("time_track", object, this);
        let start = null;
        let end = null;
        if (object.schedule.date && object.schedule.time) {
          start = moment.utc(object.schedule.date + " " + object.schedule.time)
          end = start.clone().add(object.schedule.duration, "seconds");
        } else {
          end = moment.utc();
          start = end.clone().subtract(object.schedule.duration, "seconds");
        }



        time_track.start_at = start.format("YYYY-MM-DD HH:mm:ss");
        time_track.end_at = end.format("YYYY-MM-DD HH:mm:ss");

        // console.log(start.format("YYYY-MM-DD HH:mm:ss") + " - " + end.format("YYYY-MM-DD HH:mm:ss"));

        this.$store.getters.time_trackings.push(time_track);
        this.$store.getters.user.time_trackings.push(time_track);

        this.$store.dispatch("push", {
          event: "time_tracking_create",
          params: { time_tracking: time_track },
          entry: object,
          undo: true,
        });

        object.time_trackings.count++;
        object.time_trackings.total_time = parseInt(object.time_trackings.total_time || 0) +
          this.$moment(time_track.end_at).diff(this.$moment(time_track.start_at), 'seconds');
      }

      if (this.statusIsDone(object.status?.id) && object.routine) {
        this.handleRoutineCheck(object);
      }

      // Handling recurrence
      if (this.statusIsDone(object.status?.id) && object.schedule?.recurrence) {
        /**
         * If the object is done and has a recurrence, we need to set the next date
         * but only if there is a possible next open status available
         */
        const nextStatus = this.getNextStatusInType('open', this.getAvailableStatuses(object));
        if (nextStatus) {
          this.setStatus(object, nextStatus);
          object.schedule.date = this.interpretDate({ date: object.schedule.recurrence }, object.schedule.recurrence.mode == "absolute"
            ? moment.utc()
            : moment.utc(object.schedule.date));
        }
      }
    },
    getStatusById(id, statuses = null) {
      if (this.overrideStatuses?.length && this.overrideStatuses.find((s) => s.id == id))
        return this.overrideStatuses.find((s) => s.id == id)


      if (statuses !== null && statuses?.find((s) => s.id == id)) {
        return statuses.find((s) => s.id == id)
      }
      return this.$store.getters.statuses.find((s) => s.id == id);
    },
    getStatusColorById(id, statuses = null) {
      return this.getStatusById(id, statuses)?.color;
    },
    getNextStatusInType(type, statuses = null) {
      if (statuses === null)
        statuses = this.$store.getters.space?.statuses;
      return statuses.find(s => s.type == type)
    },
    statusIsDone(status) {
      if (typeof status === 'string') {
        status = this.$store.getters.statuses.find((s) => s.id === status);
      }

      if (typeof status != 'undefined' && status !== null && typeof status.type != 'undefined')
        return status.type == "done" || status.type == "closed";

      return false;
    },
    getOwnAvailableStatuses(entry, all = false) {
      if (this.overrideStatuses?.length)
        return this.overrideStatuses;


      var statuses = [];
      if (entry?.links.length && all) {

        statuses = [...statuses, ...this.entry.links
          .map((id) => this.$store.getters.entries.find((e) => e.id == id))
          .filter((e) => e).flatMap((e) => e.statuses).filter(s => s)]
      }

      statuses = [...statuses, ...entry ? entry.statuses : []
        //.map((status) => this.$store.getters.space.statuses.find((e) => e.id == status.id))
        .filter((e) => e)];

      if (statuses.length == 0) {
        statuses = this.$store.getters.statuses.filter((s) => all || !s.entry_id);
      }

      return statuses
    },
    getAvailableStatuses(entry) {
      var statuses = [];

      if (entry?.links?.length) {
        statuses = entry.links
          .map((link) => this.$store.getters.entries.find((e) => e.id == link.id))
          .filter((e) => e).flatMap((e) => e.statuses).filter(s => s);
      }

      return statuses.length
        ? statuses
        : this.$store.getters.statuses.filter((s) => !s.entry_id);
    }
  },
};
