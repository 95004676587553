import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ScheduleDatePicker = _resolveComponent("ScheduleDatePicker");
  const _component_ScheduleTimePicker = _resolveComponent("ScheduleTimePicker");
  const _component_ScheduleSettings = _resolveComponent("ScheduleSettings");
  return _openBlock(), _createElementBlock("section", {
    class: _normalizeClass(_ctx.$style.scheduleDisplay)
  }, [$props.entry && $props.entry.schedule ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    onClick: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"])),
    class: _normalizeClass({
      [_ctx.$style.scheduleDisplayInner]: true,
      [_ctx.$style.scheduleDisplayInnerDisabled]: $props.disabled
    })
  }, [_createVNode(_component_ScheduleDatePicker, {
    key: "date",
    ref: "date",
    entry: $props.entry,
    modelValue: $options.schedule.date,
    teleport: true,
    onUpdate: $options.updateDate,
    "onUpdate:recurrence": _cache[0] || (_cache[0] = value => {
      // @note checking if there are differences between the recurrence value and the schedule.recurrence
      if (JSON.stringify(value) != JSON.stringify($options.schedule.recurrence)) {
        // @note if there are differences, update the schedule
        $options.schedule = {
          ...$options.schedule,
          recurrence: value
        };
      }
    }),
    onClick: _cache[1] || (_cache[1] = _withModifiers(e => _ctx.$emit('click', e) // @note for input display to recognize click
    , ["stop"])),
    class: _normalizeClass(_ctx.$style.datePicker)
  }, null, 8, ["entry", "modelValue", "onUpdate", "class"]), _createVNode(_component_ScheduleTimePicker, {
    key: "time",
    ref: "time",
    entry: $props.entry,
    otherentries: $props.otherentries,
    modelValue: $options.schedule.time,
    teleport: true,
    "onUpdate:time": $options.updateTime,
    "onUpdate:duration": _cache[2] || (_cache[2] = value => {
      $options.schedule = {
        ...$options.schedule,
        duration: value
      };
    }),
    onClick: _cache[3] || (_cache[3] = _withModifiers(e => _ctx.$emit('click', e) // @note for input display to recognize click
    , ["stop"])),
    class: _normalizeClass(_ctx.$style.timePicker)
  }, null, 8, ["entry", "otherentries", "modelValue", "onUpdate:time", "class"])], 2)) : (_openBlock(), _createBlock(_component_ScheduleSettings, {
    key: 1,
    modelValue: $props.entry.schedule,
    entry: $props.entry
  }, null, 8, ["modelValue", "entry"]))], 2);
}