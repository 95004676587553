import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.find.js";
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, normalizeStyle as _normalizeStyle, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  role: "button"
};
const _hoisted_2 = {
  class: "whitespace-nowrap"
};
const _hoisted_3 = {
  class: "capitalize"
};
const _hoisted_4 = {
  class: "flex min-w-48 flex-col items-stretch gap-y-2 p-2"
};
const _hoisted_5 = ["onClick"];
const _hoisted_6 = {
  key: 0
};
const _hoisted_7 = {
  key: 1,
  class: "absolute -right-2.5 -top-2.5 flex h-5 w-5 items-center justify-center rounded-full border border-neutral-300 bg-neutral-100 text-xs font-bold text-black dark:border-neutral-700 dark:bg-neutral-900 dark:text-white"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_RepeatIcon = _resolveComponent("RepeatIcon");
  const _component_PSButton = _resolveComponent("PSButton");
  const _component_SectionHeader = _resolveComponent("SectionHeader");
  const _component_CalendarComponent = _resolveComponent("CalendarComponent");
  const _component_LabelHelper = _resolveComponent("LabelHelper");
  const _component_SelectMenu = _resolveComponent("SelectMenu");
  const _component_TextPicker = _resolveComponent("TextPicker");
  const _component_ColorPicker = _resolveComponent("ColorPicker");
  const _component_ToggleComponent = _resolveComponent("ToggleComponent");
  const _component_TrashIcon = _resolveComponent("TrashIcon");
  const _component_SaveIcon = _resolveComponent("SaveIcon");
  const _component_PopoverHelper = _resolveComponent("PopoverHelper");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_PopoverHelper, {
    teleport: true
  }, {
    button: _withCtx(() => [_createElementVNode("button", _hoisted_1, [_renderSlot(_ctx.$slots, "button_routine_configure", {
      routine: $options.routine
    }, () => [_createVNode(_component_PSButton, {
      class: _normalizeClass(_ctx.$style.button),
      title: "Configure Routine",
      "data-table-column-target": ""
    }, {
      default: _withCtx(() => [$options.routine ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_createVNode(_component_RepeatIcon, {
        size: "16"
      }), _createElementVNode("span", _hoisted_2, [_createTextVNode(_toDisplayString($options.routine.target) + "x ", 1), _createElementVNode("span", _hoisted_3, _toDisplayString($options.routine.frequency), 1)])], 64)) : _createCommentVNode("", true)]),
      _: 1
    }, 8, ["class"])])])]),
    default: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_createVNode(_component_SectionHeader, {
      url: "https://help.pensive.io/routines"
    }, {
      title: _withCtx(() => [_createVNode(_component_RepeatIcon, {
        size: "16"
      }), _cache[11] || (_cache[11] = _createTextVNode(" Routine "))]),
      _: 1
    }), $options.routine ? (_openBlock(), _createBlock(_component_CalendarComponent, {
      key: 0,
      mode: $options.routine.frequency == 'weekly' ? 'weeks' : $options.routine.frequency == 'monthly' ? 'months' : 'days',
      class: _normalizeClass({
        [_ctx.$style.loadingData]: !$data.dataReady
      }),
      style: _normalizeStyle({
        '--ps-routine-display-positive-color': _ctx.$colors[$options.routine.settings?.colors?.positive || 'neutral'][500],
        '--ps-routine-display-positive-color-hover': _ctx.$colors[$options.routine.settings?.colors?.positive || 'neutral'][600],
        '--ps-routine-display-negative-color': _ctx.$colors[$options.routine.settings?.colors?.negative || 'neutral'][500],
        '--ps-routine-display-negative-color-hover': _ctx.$colors[$options.routine.settings?.colors?.negative || 'neutral'][600]
      }),
      onMounted: _cache[0] || (_cache[0] = () => $options.pullDataBySpan()),
      onCursor: _cache[1] || (_cache[1] = date => $options.pullDataBySpan(date))
    }, {
      default: _withCtx(({
        date,
        span
      } = _ctx.slotProps) => [_createElementVNode("button", {
        onClick: () => $options.handleSpanClick(date),
        class: _normalizeClass([{
          [`border-[var(--ps-routine-display-positive-color)]`]: _ctx.routineLogs.filter(l => l.routine_id === $options.routine.id).find(l => l.span_at == $options.getSpanForDate($options.routine.frequency, date))?.completed === 1,
          [`border-[var(--ps-routine-display-negative-color)]`]: _ctx.routineLogs.filter(l => l.routine_id === $options.routine.id).find(l => l.span_at == $options.getSpanForDate($options.routine.frequency, date))?.completed === 0,
          'border-neural-500': _ctx.routineLogs.filter(l => l.routine_id === $options.routine.id).find(l => l.span_at == $options.getSpanForDate($options.routine.frequency, date))?.completed === null,
          'cursor-normal pointer-events-none opacity-50': _ctx.$moment(date).isAfter(_ctx.$moment(), 'day')
        }, "absolute inset-0 rounded-full border-2"])
      }, [_createTextVNode(_toDisplayString(_ctx.$moment(date).format("DD")) + " ", 1), span ? (_openBlock(), _createElementBlock("span", _hoisted_6, " - " + _toDisplayString(_ctx.$moment(date).add(span, "days").format("DD")), 1)) : _createCommentVNode("", true), $options.routine.value && _ctx.isCurrentSlot(date) ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString($options.routine.value), 1)) : _createCommentVNode("", true)], 10, _hoisted_5)]),
      _: 1
    }, 8, ["mode", "class", "style"])) : _createCommentVNode("", true), _createVNode(_component_LabelHelper, null, {
      default: _withCtx(() => _cache[12] || (_cache[12] = [_createTextVNode(" Frequency ")])),
      _: 1
    }), _createVNode(_component_SelectMenu, {
      modelValue: $options.formTarget.frequency,
      "onUpdate:modelValue": [_cache[2] || (_cache[2] = $event => $options.formTarget.frequency = $event), _cache[3] || (_cache[3] = val => $options.formTarget = {
        ...$options.formTarget,
        ...{
          frequency: val
        }
      })],
      options: $data.options
    }, null, 8, ["modelValue", "options"]), _createVNode(_component_LabelHelper, null, {
      default: _withCtx(() => _cache[13] || (_cache[13] = [_createTextVNode(" Target ")])),
      _: 1
    }), _createVNode(_component_TextPicker, {
      modelValue: $options.target,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => $options.target = $event),
      class: _normalizeClass(_ctx.$style.inputTarget)
    }, null, 8, ["modelValue", "class"]), _createVNode(_component_ColorPicker, {
      label: "Positive Color",
      modelValue: $options.formTarget.settings?.colors?.positive,
      onUpdate: _cache[5] || (_cache[5] = color => {
        $options.formTarget = {
          ...$options.formTarget,
          ...{
            settings: {
              ...$options.formTarget.settings,
              ...{
                colors: {
                  ...$options.formTarget.settings.colors,
                  ...{
                    positive: color
                  }
                }
              }
            }
          }
        };
      }),
      class: _normalizeClass(_ctx.$style.inputTarget)
    }, null, 8, ["modelValue", "class"]), _createVNode(_component_ColorPicker, {
      label: "Negative Color",
      modelValue: $options.formTarget.settings?.colors?.negative,
      onUpdate: _cache[6] || (_cache[6] = color => {
        $options.formTarget = {
          ...$options.formTarget,
          ...{
            settings: {
              ...$options.formTarget.settings,
              ...{
                colors: {
                  ...$options.formTarget.settings.colors,
                  ...{
                    negative: color
                  }
                }
              }
            }
          }
        };
      }),
      class: _normalizeClass(_ctx.$style.inputTarget)
    }, null, 8, ["modelValue", "class"]), _createVNode(_component_ToggleComponent, {
      modelValue: $options.formTarget.auto_fail,
      "onUpdate:modelValue": [_cache[7] || (_cache[7] = $event => $options.formTarget.auto_fail = $event), _cache[8] || (_cache[8] = auto_fail => $options.formTarget = {
        ...$options.formTarget,
        ...{
          auto_fail
        }
      })],
      label: "Auto Fail"
    }, null, 8, ["modelValue"]), _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.utilitiesRow)
    }, [$props.modelValue ? (_openBlock(), _createBlock(_component_PSButton, {
      key: 0,
      onClick: _cache[9] || (_cache[9] = $event => $options.routine = null),
      title: "Delete Routine",
      class: _normalizeClass(_ctx.$style.buttonDelete)
    }, {
      default: _withCtx(() => [_createVNode(_component_TrashIcon, {
        size: "18"
      })]),
      _: 1
    }, 8, ["class"])) : _createCommentVNode("", true), !$props.modelValue ? (_openBlock(), _createBlock(_component_PSButton, {
      key: 1,
      onClick: _cache[10] || (_cache[10] = $event => $options.routine = $data.draftRoutine),
      title: "Create Routine",
      class: _normalizeClass(_ctx.$style.buttonSave)
    }, {
      default: _withCtx(() => [_createVNode(_component_SaveIcon, {
        size: "18"
      })]),
      _: 1
    }, 8, ["class"])) : _createCommentVNode("", true)], 2)])]),
    _: 3
  })]);
}