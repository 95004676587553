import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.for-each.js";
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  class: "flex flex-1 items-center justify-between"
};
const _hoisted_2 = {
  class: "flex flex-1 items-center justify-between"
};
const _hoisted_3 = {
  class: "w-96 max-w-full p-2"
};
const _hoisted_4 = {
  class: "flex flex-1 items-center justify-between"
};
const _hoisted_5 = {
  class: "flex flex-1 items-center justify-between"
};
const _hoisted_6 = {
  class: "flex flex-1 items-center justify-between"
};
const _hoisted_7 = {
  key: 2,
  class: "whitespace-nowrap"
};
const _hoisted_8 = {
  key: 3,
  class: "whitespace-nowrap"
};
const _hoisted_9 = {
  class: "flex basis-full flex-col gap-1 rounded-lg bg-gray-50 p-2 dark:bg-neutral-950"
};
const _hoisted_10 = {
  key: 0,
  class: "flow-root rounded-md"
};
const _hoisted_11 = {
  class: "flow-root rounded-md"
};
const _hoisted_12 = {
  class: "block select-text font-mono text-xs text-gray-500"
};
const _hoisted_13 = {
  class: "flow-root rounded-md"
};
const _hoisted_14 = {
  class: "block select-text font-mono text-xs text-gray-500"
};
const _hoisted_15 = {
  class: "flex flex-col gap-2 pt-2"
};
const _hoisted_16 = {
  class: "pointer-events-none flex flex-col gap-2 border-t border-neutral-200 pt-2 dark:border-neutral-700"
};
const _hoisted_17 = {
  key: 0
};
const _hoisted_18 = {
  class: "flex flex-wrap justify-between gap-2 border-t border-neutral-200 pt-2 dark:border-neutral-700"
};
const _hoisted_19 = {
  class: "flex gap-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ClockIcon = _resolveComponent("ClockIcon");
  const _component_PSListItem = _resolveComponent("PSListItem");
  const _component_MenuItem = _resolveComponent("MenuItem");
  const _component_ScheduleSettings = _resolveComponent("ScheduleSettings");
  const _component_CircleIcon = _resolveComponent("CircleIcon");
  const _component_CircleDashedIcon = _resolveComponent("CircleDashedIcon");
  const _component_ChevronRightIcon = _resolveComponent("ChevronRightIcon");
  const _component_ButtonComponent = _resolveComponent("ButtonComponent");
  const _component_StatusSettings = _resolveComponent("StatusSettings");
  const _component_Link2Icon = _resolveComponent("Link2Icon");
  const _component_LinksSettings = _resolveComponent("LinksSettings");
  const _component_PSOverlay = _resolveComponent("PSOverlay");
  const _component_PSVerticalListSeparator = _resolveComponent("PSVerticalListSeparator");
  const _component_FlagIcon = _resolveComponent("FlagIcon");
  const _component_PrioritySettings = _resolveComponent("PrioritySettings");
  const _component_SwatchBookIcon = _resolveComponent("SwatchBookIcon");
  const _component_ColorSettings = _resolveComponent("ColorSettings");
  const _component_TextCursorInputIcon = _resolveComponent("TextCursorInputIcon");
  const _component_SendTo = _resolveComponent("SendTo");
  const _component_PopoverHelper = _resolveComponent("PopoverHelper");
  const _component_PuzzleIcon = _resolveComponent("PuzzleIcon");
  const _component_TemplateSettings = _resolveComponent("TemplateSettings");
  const _component_SidebarIcon = _resolveComponent("SidebarIcon");
  const _component_HeartIcon = _resolveComponent("HeartIcon");
  const _component_AnchorSettings = _resolveComponent("AnchorSettings");
  const _component_ImageIcon = _resolveComponent("ImageIcon");
  const _component_CoverSettings = _resolveComponent("CoverSettings");
  const _component_BracketsIcon = _resolveComponent("BracketsIcon");
  const _component_SectionHeader = _resolveComponent("SectionHeader");
  const _component_CustomFieldsSettings = _resolveComponent("CustomFieldsSettings");
  const _component_ModalHelper = _resolveComponent("ModalHelper");
  const _component_CircleDotDashedIcon = _resolveComponent("CircleDotDashedIcon");
  const _component_StatusesSettings = _resolveComponent("StatusesSettings");
  const _component_CopyPlusIcon = _resolveComponent("CopyPlusIcon");
  const _component_CopyMinusIcon = _resolveComponent("CopyMinusIcon");
  const _component_FilesIcon = _resolveComponent("FilesIcon");
  const _component_TrashIcon = _resolveComponent("TrashIcon");
  const _component_PSList = _resolveComponent("PSList");
  const _component_MenuItems = _resolveComponent("MenuItems");
  const _component_Menu = _resolveComponent("Menu");
  const _component_CompletedAtComponent = _resolveComponent("CompletedAtComponent");
  const _component_EntryDisplay = _resolveComponent("EntryDisplay");
  const _component_PSButton = _resolveComponent("PSButton");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_Menu, {
    as: "div"
  }, {
    default: _withCtx(() => [_createVNode(_component_MenuItems, {
      ref: "items",
      static: "",
      class: _normalizeClass([_ctx.$style.content, "focus-visible:outline-none"])
    }, {
      default: _withCtx(() => [_createVNode(_component_PSList, {
        class: _normalizeClass(_ctx.$style.ContextMenuVerticalList)
      }, {
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "default"), _createVNode(_component_ScheduleSettings, {
          modelValue: $options.entry.schedule,
          entry: $options.entry,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.$emit('close'))
        }, {
          button_date: _withCtx(({
            date
          }) => [_createTextVNode(_toDisplayString(date), 1)]),
          button_open: _withCtx(() => [_createVNode(_component_MenuItem, null, {
            default: _withCtx(({
              active
            }) => [_createVNode(_component_PSListItem, {
              active: active,
              class: "w-full rounded-md"
            }, {
              before: _withCtx(() => [_createVNode(_component_ClockIcon, {
                size: "16",
                class: _normalizeClass({
                  'text-indigo-500': active
                })
              }, null, 8, ["class"])]),
              default: _withCtx(() => [_cache[23] || (_cache[23] = _createElementVNode("span", {
                class: "whitespace-nowrap"
              }, " Schedule ", -1))]),
              _: 2
            }, 1032, ["active"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["modelValue", "entry"]), _createVNode(_component_StatusSettings, {
          modelValue: $options.status,
          entry: $options.entry,
          statuses: _ctx.getAvailableStatuses(this.entry),
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.$emit('close')),
          class: "w-full"
        }, {
          button: _withCtx(() => [_createVNode(_component_MenuItem, null, {
            default: _withCtx(({
              active
            }) => [_createVNode(_component_ButtonComponent, {
              variant: "menu",
              color: "white",
              size: "sm",
              active: active,
              class: "group/button w-full rounded-md"
            }, {
              default: _withCtx(() => [$options.status ? (_openBlock(), _createBlock(_component_CircleIcon, {
                key: 0,
                size: "16",
                strokeWidth: "2.5",
                class: _normalizeClass(_ctx.$style.statusIcon),
                style: _normalizeStyle({
                  '--ps-status-color': _ctx.$colors[$options.status.color || 'neutral'][500]
                })
              }, null, 8, ["class", "style"])) : (_openBlock(), _createBlock(_component_CircleDashedIcon, {
                key: 1,
                size: "16"
              })), _createElementVNode("div", _hoisted_1, [_cache[24] || (_cache[24] = _createElementVNode("span", null, "Status", -1)), _createVNode(_component_ChevronRightIcon, {
                size: "12"
              })])]),
              _: 2
            }, 1032, ["active"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["modelValue", "entry", "statuses"]), _createVNode(_component_PSOverlay, {
          placement: "right",
          openOnHover: false
        }, {
          button: _withCtx(({
            open
          }) => [_createVNode(_component_PSListItem, {
            active: open,
            style: {
              "width": "100%"
            }
          }, {
            before: _withCtx(() => [_createVNode(_component_Link2Icon, {
              size: "16"
            })]),
            after: _withCtx(() => [_createVNode(_component_ChevronRightIcon, {
              size: "12"
            })]),
            default: _withCtx(() => [_cache[25] || (_cache[25] = _createTextVNode(" Links "))]),
            _: 2
          }, 1032, ["active"])]),
          title: _withCtx(() => _cache[26] || (_cache[26] = [_createElementVNode("span", null, "Links", -1)])),
          default: _withCtx(() => [_createVNode(_component_LinksSettings, {
            modelValue: $options.entry.links,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $options.entry.links = $event),
            entry: $options.entry,
            class: "flex-col !items-stretch gap-y-1",
            size: "sm",
            color: "white",
            style: {
              '--ps-list-flex-direction': 'row'
            }
          }, null, 8, ["modelValue", "entry"])]),
          _: 1
        }), _createVNode(_component_PSVerticalListSeparator), _createVNode(_component_PrioritySettings, {
          modelValue: $options.entry.priority,
          entry: $options.entry,
          placement: "right",
          openOnHover: true,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.$emit('close'))
        }, {
          button: _withCtx(({
            open
          }) => [_createVNode(_component_PSListItem, {
            active: open
          }, {
            before: _withCtx(() => [_createVNode(_component_FlagIcon, {
              size: "16",
              color: _ctx.$colors[$options.entry.priority?.level == 1 ? 'red' : $options.entry.priority?.level == 2 ? 'orange' : $options.entry.priority?.level == 3 ? 'yellow' : 'neutral'][500]
            }, null, 8, ["color"])]),
            after: _withCtx(() => [_createVNode(_component_ChevronRightIcon, {
              size: "12"
            })]),
            default: _withCtx(() => [_createTextVNode(" Priority " + _toDisplayString($options.entry.priority?.level) + " ", 1)]),
            _: 2
          }, 1032, ["active"])]),
          _: 1
        }, 8, ["modelValue", "entry"]), _createVNode(_component_ColorSettings, {
          modelValue: $options.entry.color,
          entry: $options.entry,
          placement: "right",
          openOnHover: true,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.$emit('close'))
        }, {
          button: _withCtx(({
            open
          }) => [_createVNode(_component_PSListItem, {
            active: open
          }, {
            before: _withCtx(() => [_createVNode(_component_SwatchBookIcon, {
              size: "16",
              color: _ctx.$colors[$options.entry.color || 'neutral'][500]
            }, null, 8, ["color"])]),
            after: _withCtx(() => [_createVNode(_component_ChevronRightIcon, {
              size: "12"
            })]),
            default: _withCtx(() => [_cache[27] || (_cache[27] = _createTextVNode(" Color "))]),
            _: 2
          }, 1032, ["active"])]),
          _: 1
        }, 8, ["modelValue", "entry"]), _createVNode(_component_PSVerticalListSeparator), _createVNode(_component_PopoverHelper, {
          placement: "bottom",
          teleport: true
        }, {
          button: _withCtx(() => [_createVNode(_component_MenuItem, null, {
            default: _withCtx(({
              active
            }) => [_createVNode(_component_ButtonComponent, {
              variant: "menu",
              color: "white",
              size: "sm",
              active: active,
              class: "w-full rounded-md"
            }, {
              default: _withCtx(() => [_createVNode(_component_TextCursorInputIcon, {
                size: "16",
                class: _normalizeClass({
                  'text-neutral-500': active
                })
              }, null, 8, ["class"]), _createElementVNode("div", _hoisted_2, [_cache[28] || (_cache[28] = _createElementVNode("span", null, " Send to ", -1)), _createVNode(_component_ChevronRightIcon, {
                size: "12"
              })])]),
              _: 2
            }, 1032, ["active"])]),
            _: 1
          })]),
          default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createVNode(_component_SendTo, {
            entry: $options.entry,
            onClose: _cache[5] || (_cache[5] = $event => _ctx.$emit('close'))
          }, null, 8, ["entry"])])]),
          _: 1
        }), _createVNode(_component_TemplateSettings, {
          onClose: _cache[6] || (_cache[6] = $event => this.$emit('close')),
          entry: $options.entry,
          size: "sm",
          class: "w-full"
        }, {
          button: _withCtx(() => [_createVNode(_component_MenuItem, null, {
            default: _withCtx(({
              active
            }) => [_createVNode(_component_ButtonComponent, {
              variant: "menu",
              color: "white",
              size: "sm",
              active: active,
              class: "w-full rounded-md"
            }, {
              default: _withCtx(() => [_createVNode(_component_PuzzleIcon, {
                size: "16",
                class: _normalizeClass({
                  'text-purple-500': active
                })
              }, null, 8, ["class"]), _createElementVNode("div", _hoisted_4, [_cache[29] || (_cache[29] = _createElementVNode("span", null, "Templates", -1)), _createVNode(_component_ChevronRightIcon, {
                size: "12"
              })])]),
              _: 2
            }, 1032, ["active"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["entry"]), _createVNode(_component_PSVerticalListSeparator), _createVNode(_component_AnchorSettings, {
          modelValue: $options.entry.anchors,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => $options.entry.anchors = $event),
          entry: $options.entry,
          class: "flex flex-col !gap-0"
        }, {
          button_create_left: _withCtx(() => [_createVNode(_component_MenuItem, null, {
            default: _withCtx(({
              active
            }) => [_createVNode(_component_ButtonComponent, {
              variant: "menu",
              size: "sm",
              color: active ? 'neutral' : 'white',
              active: active,
              class: "w-full rounded-md"
            }, {
              default: _withCtx(() => [_createVNode(_component_SidebarIcon, {
                size: "16",
                class: _normalizeClass({
                  'text-indigo-500': active
                })
              }, null, 8, ["class"]), _cache[30] || (_cache[30] = _createElementVNode("span", {
                class: "whitespace-nowrap"
              }, " Add to Left Sidebar ", -1))]),
              _: 2
            }, 1032, ["color", "active"])]),
            _: 1
          })]),
          button_delete_left: _withCtx(() => [_createVNode(_component_MenuItem, null, {
            default: _withCtx(({
              active
            }) => [_createVNode(_component_ButtonComponent, {
              variant: "menu",
              size: "sm",
              color: active ? 'neutral' : 'white',
              active: active,
              class: "w-full rounded-md"
            }, {
              default: _withCtx(() => [_createVNode(_component_SidebarIcon, {
                size: "16",
                class: _normalizeClass({
                  'text-indigo-500': active
                })
              }, null, 8, ["class"]), _cache[31] || (_cache[31] = _createElementVNode("span", {
                class: "whitespace-nowrap"
              }, " Remove from Left Sidebar ", -1))]),
              _: 2
            }, 1032, ["color", "active"])]),
            _: 1
          })]),
          button_create_right: _withCtx(() => [_createVNode(_component_MenuItem, null, {
            default: _withCtx(({
              active
            }) => [_createVNode(_component_ButtonComponent, {
              variant: "menu",
              size: "sm",
              color: active ? 'neutral' : 'white',
              active: active,
              class: "w-full rounded-md"
            }, {
              default: _withCtx(() => [_createVNode(_component_SidebarIcon, {
                size: "16",
                class: _normalizeClass({
                  'text-indigo-500': active,
                  'scale-x-[-1] transform': true
                })
              }, null, 8, ["class"]), _cache[32] || (_cache[32] = _createElementVNode("span", {
                class: "whitespace-nowrap"
              }, " Add to Right Sidebar ", -1))]),
              _: 2
            }, 1032, ["color", "active"])]),
            _: 1
          })]),
          button_delete_right: _withCtx(() => [_createVNode(_component_MenuItem, null, {
            default: _withCtx(({
              active
            }) => [_createVNode(_component_ButtonComponent, {
              variant: "menu",
              size: "sm",
              color: active ? 'neutral' : 'white',
              active: active,
              class: "w-full rounded-md"
            }, {
              default: _withCtx(() => [_createVNode(_component_SidebarIcon, {
                size: "16",
                class: _normalizeClass({
                  'text-indigo-500': active
                })
              }, null, 8, ["class"]), _cache[33] || (_cache[33] = _createElementVNode("span", {
                class: "whitespace-nowrap"
              }, " Remove from Right Sidebar ", -1))]),
              _: 2
            }, 1032, ["color", "active"])]),
            _: 1
          })]),
          button_create_main: _withCtx(() => [_createVNode(_component_MenuItem, null, {
            default: _withCtx(({
              active
            }) => [_createVNode(_component_ButtonComponent, {
              variant: "menu",
              size: "sm",
              color: active ? 'neutral' : 'white',
              active: active,
              class: "w-full rounded-md"
            }, {
              default: _withCtx(() => [_createVNode(_component_HeartIcon, {
                size: "16",
                class: _normalizeClass({
                  'text-indigo-500': active
                })
              }, null, 8, ["class"]), _cache[34] || (_cache[34] = _createElementVNode("span", {
                class: "whitespace-nowrap"
              }, "Add to Favorites", -1))]),
              _: 2
            }, 1032, ["color", "active"])]),
            _: 1
          })]),
          button_delete_main: _withCtx(() => [_createVNode(_component_MenuItem, null, {
            default: _withCtx(({
              active
            }) => [_createVNode(_component_ButtonComponent, {
              variant: "menu",
              size: "sm",
              color: active ? 'neutral' : 'white',
              active: active,
              class: "w-full rounded-md"
            }, {
              default: _withCtx(() => [_createVNode(_component_HeartIcon, {
                size: "16",
                class: _normalizeClass({
                  'text-indigo-500': active
                })
              }, null, 8, ["class"]), _cache[35] || (_cache[35] = _createElementVNode("span", {
                class: "whitespace-nowrap"
              }, "Remove from Favorites", -1))]),
              _: 2
            }, 1032, ["color", "active"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["modelValue", "entry"]), _createVNode(_component_CoverSettings, {
          modelValue: $options.entry.cover,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => $options.entry.cover = $event),
          key: "cover",
          color: "secondary",
          entry: $options.entry
        }, {
          button: _withCtx(() => [_createVNode(_component_MenuItem, null, {
            default: _withCtx(({
              active
            }) => [_createVNode(_component_ButtonComponent, {
              variant: "menu",
              color: "white",
              size: "sm",
              active: active,
              class: "w-full rounded-md"
            }, {
              default: _withCtx(() => [_createVNode(_component_ImageIcon, {
                size: "16",
                class: _normalizeClass({
                  'text-neutral-500': active
                })
              }, null, 8, ["class"]), _cache[36] || (_cache[36] = _createElementVNode("span", {
                class: "whitespace-nowrap"
              }, "Cover image", -1))]),
              _: 2
            }, 1032, ["active"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["modelValue", "entry"]), _createVNode(_component_PSVerticalListSeparator), _createVNode(_component_MenuItem, null, {
          default: _withCtx(({
            active
          }) => [_createVNode(_component_ButtonComponent, {
            onClick: _cache[9] || (_cache[9] = $event => $data.showCustomFieldsModal = !$data.showCustomFieldsModal),
            variant: "menu",
            color: "white",
            size: "sm",
            active: active,
            class: "w-full rounded-md"
          }, {
            default: _withCtx(() => [_createVNode(_component_BracketsIcon, {
              size: "16",
              class: _normalizeClass({
                'text-neutral-500': active
              })
            }, null, 8, ["class"]), _createElementVNode("div", _hoisted_5, [_cache[37] || (_cache[37] = _createElementVNode("span", null, " Custom Fields ", -1)), _createVNode(_component_ChevronRightIcon, {
              size: "12"
            })])]),
            _: 2
          }, 1032, ["active"])]),
          _: 1
        }), _createVNode(_component_ModalHelper, {
          show: $data.showCustomFieldsModal,
          onClose: _cache[11] || (_cache[11] = $event => $data.showCustomFieldsModal = false)
        }, {
          default: _withCtx(() => [_createVNode(_component_SectionHeader, {
            url: "https://help.pensive.io/custom-fields-values",
            class: "my-5"
          }, {
            title: _withCtx(() => [_createVNode(_component_BracketsIcon, {
              size: "16"
            }), _cache[38] || (_cache[38] = _createTextVNode(" Custom Fields "))]),
            _: 1
          }), _createVNode(_component_CustomFieldsSettings, {
            modelValue: $options.entry.custom_fields,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => $options.entry.custom_fields = $event),
            entry: $options.entry,
            position: "dialog",
            size: "sm",
            color: "secondary"
          }, null, 8, ["modelValue", "entry"])]),
          _: 1
        }, 8, ["show"]), _createVNode(_component_MenuItem, null, {
          default: _withCtx(({
            active
          }) => [_createVNode(_component_ButtonComponent, {
            onClick: _cache[12] || (_cache[12] = $event => $data.showCustomStatusesModal = !$data.showCustomStatusesModal),
            variant: "menu",
            color: "white",
            size: "sm",
            active: active,
            class: "w-full rounded-md"
          }, {
            default: _withCtx(() => [_createVNode(_component_CircleDotDashedIcon, {
              size: "16",
              class: _normalizeClass({
                'text-neutral-500': active
              })
            }, null, 8, ["class"]), _createElementVNode("div", _hoisted_6, [_cache[39] || (_cache[39] = _createElementVNode("span", null, " Custom Statuses ", -1)), _createVNode(_component_ChevronRightIcon, {
              size: "12"
            })])]),
            _: 2
          }, 1032, ["active"])]),
          _: 1
        }), _createVNode(_component_ModalHelper, {
          show: $data.showCustomStatusesModal,
          onClose: _cache[14] || (_cache[14] = $event => $data.showCustomStatusesModal = false)
        }, {
          default: _withCtx(() => [_createVNode(_component_SectionHeader, {
            url: "https://help.pensive.io/statuses",
            class: "my-5"
          }, {
            title: _withCtx(() => [_createVNode(_component_CircleDotDashedIcon, {
              size: "16"
            }), _cache[40] || (_cache[40] = _createTextVNode(" Custom Statuses "))]),
            _: 1
          }), _createVNode(_component_StatusesSettings, {
            entry: $options.entry,
            modelValue: $options.entry.statuses,
            "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => $options.entry.statuses = $event),
            position: "dialog",
            size: "sm",
            color: "secondary"
          }, null, 8, ["entry", "modelValue"])]),
          _: 1
        }, 8, ["show"]), _createVNode(_component_PSVerticalListSeparator), _createVNode(_component_MenuItem, {
          as: "div"
        }, {
          default: _withCtx(({
            active
          }) => [_createVNode(_component_ButtonComponent, {
            variant: "menu",
            color: "white",
            size: "sm",
            active: active,
            onClick: _cache[15] || (_cache[15] = $event => (_ctx.$store.dispatch('selected', $options.entry), _ctx.$emit('close'))),
            class: "w-full rounded-md"
          }, {
            default: _withCtx(() => [_ctx.$store.getters.selected.findIndex(e => e.id == $options.entry.id) === -1 ? (_openBlock(), _createBlock(_component_CopyPlusIcon, {
              key: 0,
              size: "16",
              class: _normalizeClass({
                'text-amber-500': active
              })
            }, null, 8, ["class"])) : (_openBlock(), _createBlock(_component_CopyMinusIcon, {
              key: 1,
              size: "16",
              class: _normalizeClass({
                'text-amber-500': active
              })
            }, null, 8, ["class"])), _ctx.$store.getters.selected.findIndex(e => e.id == $options.entry.id) === -1 ? (_openBlock(), _createElementBlock("span", _hoisted_7, "Select Entry")) : (_openBlock(), _createElementBlock("span", _hoisted_8, "Unselect Entry"))]),
            _: 2
          }, 1032, ["active"])]),
          _: 1
        }), _createVNode(_component_MenuItem, {
          as: "div"
        }, {
          default: _withCtx(({
            active
          }) => [_createVNode(_component_ButtonComponent, {
            variant: "menu",
            color: "white",
            size: "sm",
            active: active,
            onClick: _cache[16] || (_cache[16] = $event => _ctx.duplicateEntry($options.entry)),
            class: "w-full rounded-md"
          }, {
            default: _withCtx(() => [_createVNode(_component_FilesIcon, {
              size: 16,
              class: _normalizeClass({
                'text-blue-500': active
              })
            }, null, 8, ["class"]), _cache[41] || (_cache[41] = _createElementVNode("span", {
              class: "whitespace-nowrap"
            }, "Duplicate Entry", -1))]),
            _: 2
          }, 1032, ["active"])]),
          _: 1
        }), _createVNode(_component_MenuItem, {
          as: "div"
        }, {
          default: _withCtx(({
            active
          }) => [_createVNode(_component_ButtonComponent, {
            variant: "menu",
            color: "white",
            size: "sm",
            active: active,
            onClick: _cache[17] || (_cache[17] = $event => _ctx.attemptDeleteEntry($options.entry)),
            class: "w-full rounded-md"
          }, {
            default: _withCtx(() => [_createVNode(_component_TrashIcon, {
              size: 16,
              class: _normalizeClass({
                'text-red-500': active
              })
            }, null, 8, ["class"]), _cache[42] || (_cache[42] = _createElementVNode("span", {
              class: "whitespace-nowrap"
            }, "Delete Entry", -1))]),
            _: 2
          }, 1032, ["active"])]),
          _: 1
        })]),
        _: 3
      }, 8, ["class"])]),
      _: 3
    }, 8, ["class"])]),
    _: 3
  }), _createElementVNode("div", _hoisted_9, [$options.entry.completed_at ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_cache[43] || (_cache[43] = _createElementVNode("span", {
    class: "flex items-center"
  }, [_createElementVNode("span", {
    class: "text-xs font-medium text-gray-900 dark:text-neutral-100"
  }, " Completed ")], -1)), _createVNode(_component_CompletedAtComponent, {
    modelValue: $options.entry,
    "onUpdate:modelValue": _cache[18] || (_cache[18] = $event => $options.entry = $event),
    class: "font-mono text-xs"
  }, null, 8, ["modelValue"])])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_11, [_cache[44] || (_cache[44] = _createElementVNode("span", {
    class: "flex items-center"
  }, [_createElementVNode("span", {
    class: "text-xs font-medium text-gray-900 dark:text-neutral-100"
  }, " Updated ")], -1)), _createElementVNode("span", _hoisted_12, _toDisplayString($data.moment.utc($options.entry.updated_at).local().format("YYYY-MM-DD HH:mm:ss")), 1)]), _createElementVNode("div", _hoisted_13, [_cache[45] || (_cache[45] = _createElementVNode("span", {
    class: "flex items-center"
  }, [_createElementVNode("span", {
    class: "text-xs font-medium text-gray-900 dark:text-neutral-100"
  }, " Created ")], -1)), _createElementVNode("span", _hoisted_14, _toDisplayString($data.moment.utc($options.entry.created_at).local().format("YYYY-MM-DD HH:mm:ss")), 1)])]), _createVNode(_component_ModalHelper, {
    show: _ctx.showDeleteModal,
    onClose: _cache[22] || (_cache[22] = $event => _ctx.showDeleteModal = false)
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_15, [_cache[49] || (_cache[49] = _createElementVNode("p", null, " Do you want to delete this entry and all entries that are exclusively linked to it? ", -1)), _createElementVNode("div", _hoisted_16, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getExclusiveLinkedEntries($options.entry).slice(0, 3), entry => {
      return _openBlock(), _createBlock(_component_EntryDisplay, {
        key: entry.id,
        modelValue: entry,
        disabled: true,
        display: {
          output: false,
          settings: false
        }
      }, null, 8, ["modelValue"]);
    }), 128)), _ctx.getExclusiveLinkedEntries($options.entry).length > 3 ? (_openBlock(), _createElementBlock("span", _hoisted_17, " and " + _toDisplayString(_ctx.getExclusiveLinkedEntries($options.entry).length - 3) + " more ", 1)) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_18, [_createElementVNode("div", null, [_createVNode(_component_PSButton, {
      onClick: _cache[19] || (_cache[19] = $event => _ctx.showDeleteModal = false),
      class: _normalizeClass(_ctx.$style.deleteModalButtonCancel)
    }, {
      default: _withCtx(() => _cache[46] || (_cache[46] = [_createTextVNode(" Cancel ")])),
      _: 1
    }, 8, ["class"])]), _createElementVNode("div", _hoisted_19, [_createVNode(_component_PSButton, {
      onClick: _cache[20] || (_cache[20] = $event => (_ctx.deleteEntry($options.entry), _ctx.showDeleteModal = false)),
      class: _normalizeClass(_ctx.$style.deleteModalButtonDeleteOnlyThisEntry)
    }, {
      default: _withCtx(() => [_createVNode(_component_TrashIcon, {
        size: "16"
      }), _cache[47] || (_cache[47] = _createTextVNode(" Delete only this entry "))]),
      _: 1
    }, 8, ["class"]), _createVNode(_component_PSButton, {
      onClick: _cache[21] || (_cache[21] = $event => (_ctx.getExclusiveLinkedEntries($options.entry).forEach(e => _ctx.deleteEntry(e)), _ctx.deleteEntry($options.entry), _ctx.showDeleteModal = false)),
      class: _normalizeClass(_ctx.$style.deleteModalButtonDeleteAll)
    }, {
      default: _withCtx(() => [_createVNode(_component_TrashIcon, {
        size: "16"
      }), _cache[48] || (_cache[48] = _createTextVNode(" Delete all "))]),
      _: 1
    }, 8, ["class"])])])])]),
    _: 1
  }, 8, ["show"])]);
}