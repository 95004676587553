export default {
  // inheritAttrs: false,
  props: {
    modelValue: String
  },
  watch: {
    time() {
      this.setInternalTimeAsLocal();
    }
  },
  mounted() {
    this.setInternalTimeAsLocal();
  },
  computed: {
    time: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      }
    },
    times() {
      return Array.from({
        length: 24
      }, (_, hour) => {
        return ["00", "15", "30", "45"].map(minute => {
          return `${String(hour).padStart(2, "0")}:${minute}`;
        });
      }).flat();
    }
  },
  methods: {
    onOverlayOpen() {
      this.handleOnFocus();
      setTimeout(() => {
        const utcTime = this.$moment.utc(this.$moment().format("YYYY-MM-DD") + " " + (this.time ? this.time : this.$moment().format("HH:mm:ss")));
        const scrollToTime = utcTime.minute(Math.floor(utcTime.minute() / 15) * 15).format("HH:mm");
        if (this.$refs && this.$refs[scrollToTime] && this.$refs[scrollToTime][0] && this.$refs[scrollToTime][0].$el) {
          this.$refs[scrollToTime][0].$el.scrollIntoView({
            behavior: "instant",
            block: "start"
          });
        }
      }, 10);
    },
    toUTC(time) {
      if (!this.isValidTime(time)) {
        return null;
      }
      return this.$moment(this.$moment.utc().format("YYYY-MM-DD") + " " + time).utc().format("HH:mm:ss");
    },
    setInternalTimeAsLocal() {
      if (this.time === null || this.time === undefined) {
        this.internalTimeAsLocal = "";
        return;
      }
      this.internalTimeAsLocal = this.$moment.utc(this.$moment.utc().format("YYYY-MM-DD") + " " + this.time).local().format("HH:mm");
    },
    isValidTime(time) {
      return /^([01]\d|2[0-3])(:[0-5]\d(:[0-5]\d)?)?$/.test(time);
    },
    handleOnFocus() {
      // setTimeout(() => {
      if (window.matchMedia("(pointer: coarse)").matches) {
        if (this.$refs.mobile_input) {
          // if (expand) expand();
          // this.$refs.mobile_input.focus();
          // this.$refs.mobile_input.setSelectionRange(
          //   0,
          //   this.$refs.mobile_input.value.length,
          // );
        }
      } else {
        if (this.$refs.input) {
          // this.$refs.input.select();
          this.$refs.input.setSelectionRange(0, this.$refs.input.value.length);
        }
      }
      // }, 0);
    },
    handleOnBlur(close) {
      setTimeout(() => {
        close();
      }, 10);
      if (this.internalTimeAsLocal === "") {
        if (this.time !== null) {
          this.time = null;
        }
        return;
      }
      if (!this.isValidTime(this.internalTimeAsLocal)) {
        this.setInternalTimeAsLocal();
        return;
      }
      if (this.time != this.toUTC(this.internalTimeAsLocal)) {
        this.time = this.toUTC(this.internalTimeAsLocal);
        this.setInternalTimeAsLocal();
      }
    }
  },
  data() {
    return {
      internalTimeAsLocal: ""
    };
  }
};