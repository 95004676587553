import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.map.js";
// import EntryComponent from "../entries/EntryComponent_Deprecated.vue";
import { Applicators } from "../mixins/Applicators";
import { Scheduling } from "../mixins/Scheduling";
import { Schema } from "../mixins/Schema";
import { Statusing } from "../mixins/Statusing";
export default {
  mixins: [Schema, Applicators, Statusing, Scheduling],
  data() {
    return {
      showDraggableWrapper: false,
      draggedEntriesInterpreted: [],
      draggableWrapperPosition: {
        x: 0,
        y: 0
      }
    };
  },
  watch: {
    "$store.getters.dragged.length": function (n, o) {
      if (n > 0 && o == 0) {
        // if (navigator.userAgent.includes("Mobile")) {
        //   this.updateCursorPosition({
        //     type: "touchmove",
        //   });
        // }

        this.interpretEntries();
        setTimeout(() => {
          this.showDraggableWrapper = true;
        }, 10);
      }
      if (n == 0) {
        this.showDraggableWrapper = false;
        this.draggedEntriesInterpreted = [];
      }
    },
    "$refs.draggableWrapper": function () {
      console.log("draggableWrapper", this.$refs.draggableWrapper);
    },
    "$store.getters.schema": function () {
      if (this.$store.getters.dragged.length) {
        if (this.interprationTimeout) clearTimeout(this.interprationTimeout);
        this.interprationTimeout = setTimeout(() => {
          this.interpretEntries();
        }, 50);
      }
    }
  },
  computed: {
    // dragged() {
    //   return this.$store.getters.dragged
    //     ? JSON.parse(JSON.stringify(this.$store.getters.dragged))?.map(
    //         (entry) =>
    //           this.$store.getters.schema &&
    //           entry.id != this.$store.getters.hovered.id
    //             ? this.applyInput(
    //                 this.inputSchema(this.$store.getters.schema, entry),
    //                 entry,
    //               )
    //             : entry,
    //       )
    //     : [];
    // },
  },
  mounted() {
    document.addEventListener("mousemove", this.updateCursorPosition);
    document.addEventListener("touchmove", this.updateCursorPosition, {
      passive: true
    });
    document.addEventListener("touchstart", this.updateCursorPosition, {
      passive: true
    });
  },
  beforeUnmount() {
    document.removeEventListener("mousemove", this.updateCursorPosition);
    document.removeEventListener("touchmove", this.updateCursorPosition, {
      passive: true
    });
    document.removeEventListener("touchstart", this.updateCursorPosition, {
      passive: true
    });
  },
  methods: {
    interpretEntries() {
      this.draggedEntriesInterpreted = this.$store.getters.dragged ? JSON.parse(JSON.stringify(this.$store.getters.dragged))?.map(entry => this.$store.getters.schema && entry.id != this.$store.getters.hovered.id ? this.applyInput(this.inputSchema(this.$store.getters.schema, entry), entry) : entry) : [];
    },
    updateCursorPosition(e) {
      // Prevent mouse events if this is actually a touch event
      if (e.sourceCapabilities?.firesTouchEvents && e.type == "mousemove") {
        return;
      }
      const draggableWrapperWidth = this.$refs.draggableWrapper?.clientWidth ?? 256;
      const draggableWrapperHeight = this.$refs.draggableWrapper?.clientHeight ?? Math.max(this.$store.getters.selected.length, 1) * 32 + 16;

      // Check if it's a touch event by checking touches property
      const isTouchEvent = e.touches && e.touches.length > 0;
      if (isTouchEvent) {
        this.draggableWrapperPosition.x = this.$cursorPosition.x - draggableWrapperWidth / 2;
        this.draggableWrapperPosition.y = this.$cursorPosition.y - draggableWrapperHeight - 10;
        if (this.draggableWrapperPosition.y < draggableWrapperHeight - 10) this.draggableWrapperPosition.y += draggableWrapperHeight + 20;
      } else {
        this.draggableWrapperPosition.x = this.$cursorPosition.x + 10;
        this.draggableWrapperPosition.y = this.$cursorPosition.y + 10;
      }
      if (this.draggableWrapperPosition.x < 10) this.draggableWrapperPosition.x = 10;
      if (this.draggableWrapperPosition.y < 10) this.draggableWrapperPosition.y = 10;
      if (this.draggableWrapperPosition.x + draggableWrapperWidth > window.innerWidth - 10) this.draggableWrapperPosition.x = window.innerWidth - draggableWrapperWidth - 10;
      if (this.draggableWrapperPosition.y + draggableWrapperHeight > window.innerHeight + window.scrollY - 10) this.draggableWrapperPosition.y = window.innerHeight + window.scrollY - draggableWrapperHeight - 10;

      // console.log("draggableWrapperPosition", this.draggableWrapperPosition);
    }
  }
};