import { resolveComponent as _resolveComponent, createVNode as _createVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, withModifiers as _withModifiers, normalizeClass as _normalizeClass, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "flex min-h-full items-end justify-center sm:items-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PSModalBackdrop = _resolveComponent("PSModalBackdrop");
  const _component_PSModalBackdropTransition = _resolveComponent("PSModalBackdropTransition");
  const _component_PSModalDragHandle = _resolveComponent("PSModalDragHandle");
  const _component_XIcon = _resolveComponent("XIcon");
  const _component_PSButton = _resolveComponent("PSButton");
  const _component_PSModalTitle = _resolveComponent("PSModalTitle");
  const _component_PSModalTransition = _resolveComponent("PSModalTransition");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_PSModalBackdropTransition, null, {
    default: _withCtx(() => [$options.isRenderBackdrop && $data.isOpen ? (_openBlock(), _createBlock(_Teleport, {
      key: 0,
      to: "body"
    }, [_createVNode(_component_PSModalBackdrop, {
      onClick: _cache[0] || (_cache[0] = $event => $data.isOpen = false)
    })])) : _createCommentVNode("", true)]),
    _: 1
  }), _createVNode(_component_PSModalTransition, null, {
    default: _withCtx(() => [$data.isOpen ? (_openBlock(), _createBlock(_Teleport, {
      key: 0,
      to: "body"
    }, [_createElementVNode("div", {
      ref: "root",
      class: _normalizeClass(_ctx.$style.ModalRoot)
    }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
      ref: "modal",
      class: _normalizeClass([_ctx.$style.ModalSlot, {
        'transition-transform duration-300 ease-out': !$data.isTouching,
        [_ctx.$style.isDragging]: $data.isTouching
      }]),
      style: _normalizeStyle({
        '--ps-modal-transition-y': `${$data.modalTransition}px`
      })
    }, [_createVNode(_component_PSModalDragHandle), _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.Header)
    }, [_createVNode(_component_PSButton, {
      onClick: _cache[1] || (_cache[1] = _withModifiers($event => $data.isOpen = false, ["stop"])),
      class: _normalizeClass(_ctx.$style.CloseButton)
    }, {
      default: _withCtx(() => [_createVNode(_component_XIcon, {
        size: "20"
      })]),
      _: 1
    }, 8, ["class"]), _createVNode(_component_PSModalTitle, null, {
      default: _withCtx(() => [_renderSlot(_ctx.$slots, "title")]),
      _: 3
    })], 2), _createElementVNode("div", {
      ref: "content",
      class: _normalizeClass([_ctx.$style.ModalContent, {
        [_ctx.$style.isExpanded]: $data.isExpanded,
        [_ctx.$style.isCollapsed]: !$data.isExpanded
      }])
    }, [_renderSlot(_ctx.$slots, "default", {
      close: $options.close,
      expand: $options.expand
    })], 2)], 6)])], 2)])) : _createCommentVNode("", true)]),
    _: 3
  })]);
}