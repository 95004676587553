import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue";
const _hoisted_1 = {
  class: "flex space-x-3"
};
const _hoisted_2 = {
  class: "min-w-0 flex-1"
};
const _hoisted_3 = {
  class: "text-sm font-semibold text-gray-900 dark:text-neutral-300"
};
const _hoisted_4 = {
  class: "text-sm text-neutral-500"
};
const _hoisted_5 = {
  href: "#",
  class: "hover:underline"
};
const _hoisted_6 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TimerIcon = _resolveComponent("TimerIcon");
  const _component_PSButton = _resolveComponent("PSButton");
  const _component_EntryDisplay = _resolveComponent("EntryDisplay");
  const _component_DateRenderComponent = _resolveComponent("DateRenderComponent");
  const _component_CalendarComponent = _resolveComponent("CalendarComponent");
  const _component_PopoverHelper = _resolveComponent("PopoverHelper");
  const _component_OutputDisplay = _resolveComponent("OutputDisplay");
  return _openBlock(), _createBlock(_component_PopoverHelper, {
    teleport: true,
    class: "flex items-center"
  }, {
    button: _withCtx(() => [_createVNode(_component_PSButton, {
      onClick: _cache[0] || (_cache[0] = $event => $data.date = _ctx.$moment.utc().format('YYYY-MM-DD')),
      class: _normalizeClass([{
        'animate-pulse !bg-neutral-900 !text-white hover:!bg-neutral-800 dark:!bg-neutral-100 dark:!text-neutral-900 dark:hover:!bg-neutral-200': _ctx.activelyTimeTrackedEntry && !_ctx.$store.getters.focus,
        '': !_ctx.activelyTimeTrackedEntry || _ctx.$store.getters.focus
      }, "rounded-full"]),
      title: "Time trackings today"
    }, {
      default: _withCtx(() => [_cache[3] || (_cache[3] = _createElementVNode("span", {
        class: "sr-only"
      }, "Time Trackings", -1)), _createVNode(_component_TimerIcon, {
        size: "24",
        "aria-hidden": "true",
        "stroke-width": "2"
      })]),
      _: 1
    }, 8, ["class"])]),
    default: _withCtx(({
      close
    }) => [_ctx.activelyTimeTrackedEntry ? (_openBlock(), _createBlock(_component_EntryDisplay, {
      key: 0,
      modelValue: _ctx.activelyTimeTrackedEntry,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.activelyTimeTrackedEntry = $event),
      display: {
        senses: false,
        name: true,
        status: true,
        links: true,
        description: false,
        schedule: true,
        output: false,
        input: false,
        columns: false,
        leftover: false,
        time_trackings: true,
        routine: false
      },
      disableViewportChecking: true,
      position: "contextmenu",
      class: "border-b p-5"
    }, null, 8, ["modelValue"])) : _createCommentVNode("", true), _createVNode(_component_OutputDisplay, {
      modelValue: $options.outputComputed,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $options.outputComputed = $event),
      filter: $options.outputFilter,
      display: {
        senses: false,
        name: true,
        status: false,
        links: false,
        description: false,
        schedule: false,
        output: false,
        input: false,
        columns: false,
        leftover: false,
        time_trackings: false,
        routine: false,
        custom_fields: false,
        settings: false,
        time_trackings_today: true
      },
      sorting: [{
        field: 'updated_at',
        dir: -1
      }],
      position: "contextmenu",
      onOnEntryClick: () => {
        close();
      },
      editable: false,
      class: "min-w-96 p-2"
    }, {
      "output-before": _withCtx(({
        entries
      }) => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("p", _hoisted_3, " Total Duration: " + _toDisplayString(_ctx.getTotalDurationOfEntries(entries, $data.date)), 1), _createElementVNode("p", _hoisted_4, [_createVNode(_component_PopoverHelper, {
        teleport: true
      }, {
        button: _withCtx(() => [_createElementVNode("a", _hoisted_5, _toDisplayString(_ctx.$moment.utc($data.date).format("DD.MM.YYYY")), 1)]),
        default: _withCtx(({
          close
        }) => [_createVNode(_component_CalendarComponent, {
          class: "mb-1 mt-2"
        }, {
          default: _withCtx(({
            date: _date
          }) => [_createElementVNode("button", {
            role: "button",
            onClick: $event => ($data.date = _date, close()),
            class: _normalizeClass([{
              'bg-gray-700 text-white hover:bg-gray-800': _date == $data.date,
              'bg-white hover:bg-gray-50': _date != $data.date
            }, "rounded-full px-3 py-1.5 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"])
          }, [_createVNode(_component_DateRenderComponent, {
            date: _ctx.$moment.utc(_date),
            format: 'DD',
            class: _normalizeClass({
              'text-gray-500': _date != $data.date
            })
          }, null, 8, ["date", "class"])], 10, _hoisted_6)]),
          _: 2
        }, 1024)]),
        _: 2
      }, 1024)])])])]),
      _: 2
    }, 1032, ["modelValue", "filter", "onOnEntryClick"])]),
    _: 1
  });
}