import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.for-each.js";
import "core-js/modules/es.iterator.map.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "flex items-center gap-x-4"
};
const _hoisted_2 = {
  class: "flex flex-1 items-center justify-center gap-4"
};
const _hoisted_3 = {
  class: "md:flex md:flex-1 md:justify-end"
};
const _hoisted_4 = {
  class: "flex flex-col gap-2 pt-2"
};
const _hoisted_5 = {
  class: "pt-1.5"
};
const _hoisted_6 = {
  key: 0,
  class: ""
};
const _hoisted_7 = {
  class: "pointer-events-none flex flex-col gap-2"
};
const _hoisted_8 = {
  key: 0,
  class: "px-2 text-xs"
};
const _hoisted_9 = {
  class: "flex flex-wrap justify-between gap-2 border-t border-neutral-200 pt-2 dark:border-neutral-700"
};
const _hoisted_10 = {
  class: "flex gap-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_XIcon = _resolveComponent("XIcon");
  const _component_PSButton = _resolveComponent("PSButton");
  const _component_CircleDashedIcon = _resolveComponent("CircleDashedIcon");
  const _component_StatusSettings = _resolveComponent("StatusSettings");
  const _component_CalendarIcon = _resolveComponent("CalendarIcon");
  const _component_DatePicker = _resolveComponent("DatePicker");
  const _component_Link2Icon = _resolveComponent("Link2Icon");
  const _component_LinksBuilder = _resolveComponent("LinksBuilder");
  const _component_TrashIcon = _resolveComponent("TrashIcon");
  const _component_EntryDisplay = _resolveComponent("EntryDisplay");
  const _component_ToggleComponent = _resolveComponent("ToggleComponent");
  const _component_ModalHelper = _resolveComponent("ModalHelper");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.$style.BulKSelectMenu, "flex items-center gap-x-4 gap-y-4"])
  }, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_PSButton, {
    onClick: _cache[0] || (_cache[0] = $event => _ctx.$store.dispatch('selected', null)),
    class: _normalizeClass(_ctx.$style.buttonUnselect),
    title: "Unselect all entries"
  }, {
    default: _withCtx(() => [_createElementVNode("span", {
      class: _normalizeClass(_ctx.$style.buttonUnselectText)
    }, [_createTextVNode(_toDisplayString(_ctx.$store.getters.selected.length) + " " + _toDisplayString(_ctx.$store.getters.selected.length == 1 ? "Entry" : "Entries") + " ", 1), _cache[8] || (_cache[8] = _createElementVNode("span", {
      class: "hidden sm:inline-block"
    }, " selected ", -1))], 2), _createVNode(_component_XIcon, {
      size: "16",
      "stroke-width": "1.5"
    })]),
    _: 1
  }, 8, ["class"])]), _cache[13] || (_cache[13] = _createElementVNode("div", {
    class: "h-4 w-px bg-neutral-700 dark:bg-neutral-300",
    "aria-hidden": "true"
  }, null, -1)), _createElementVNode("div", _hoisted_2, [_createVNode(_component_StatusSettings, {
    modelValue: $data.bulkStatus,
    "onUpdate:modelValue": [_cache[1] || (_cache[1] = $event => $data.bulkStatus = $event), _cache[2] || (_cache[2] = status => {
      $options.selectedEntries.forEach(entry => {
        _ctx.setStatus(entry, status);
        _ctx.$store.dispatch('push', {
          event: 'entry_update',
          params: {
            entry: entry
          },
          entry: entry
        });
      }), _ctx.$store.dispatch('selected', null), $data.bulkStatus = null;
    })],
    full: true,
    statuses: $options.availableStatuses,
    size: $props.size,
    color: "secondary"
  }, {
    button: _withCtx(() => [_createVNode(_component_PSButton, {
      title: "Apply Status",
      class: _normalizeClass(_ctx.$style.buttonRound)
    }, {
      default: _withCtx(() => [_createVNode(_component_CircleDashedIcon, {
        size: "16",
        "stroke-width": "1.5"
      })]),
      _: 1
    }, 8, ["class"])]),
    _: 1
  }, 8, ["modelValue", "statuses", "size"]), _createVNode(_component_DatePicker, {
    ref: "date",
    showRecurrence: false,
    autoSetOnClick: false,
    "onUpdate:modelValue": $options.updateDate,
    teleport: true,
    class: "!border-none !p-0"
  }, {
    button: _withCtx(() => [_createVNode(_component_PSButton, {
      title: "Apply Schedule Date",
      class: _normalizeClass(_ctx.$style.buttonRound)
    }, {
      default: _withCtx(() => [_createVNode(_component_CalendarIcon, {
        size: "16",
        "stroke-width": "1.5"
      })]),
      _: 1
    }, 8, ["class"])]),
    _: 1
  }, 8, ["onUpdate:modelValue"]), _createVNode(_component_LinksBuilder, {
    modelValue: $data.bulkModel.links,
    "onUpdate:modelValue": [_cache[3] || (_cache[3] = $event => $data.bulkModel.links = $event), _cache[4] || (_cache[4] = links => {
      $options.selectedEntries.forEach(e => {
        e.links = [...e.links, ...links.map(link => ({
          id: link,
          position: link.length,
          settings: {},
          created_at: _ctx.$moment.utc().format('YYYY-MM-DD HH:mm:ss'),
          updated_at: _ctx.$moment.utc().format('YYYY-MM-DD HH:mm:ss'),
          deleted_at: null
        }))];
        _ctx.$store.dispatch('push', {
          event: 'entry_update',
          params: {
            entry: e
          },
          entry: e
        });
      });
      _ctx.$store.dispatch('selected', null);
      $data.bulkModel.links = [];
    })]
  }, {
    button: _withCtx(() => [_createVNode(_component_PSButton, {
      title: "Apply Links",
      class: _normalizeClass(_ctx.$style.buttonRound)
    }, {
      default: _withCtx(() => [_createVNode(_component_Link2Icon, {
        size: "16",
        "stroke-width": "1.5"
      })]),
      _: 1
    }, 8, ["class"])]),
    _: 1
  }, 8, ["modelValue"]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_PSButton, {
    onClick: $options.initiazeDeletionOfEntries,
    title: "Delete selected entries",
    class: _normalizeClass(_ctx.$style.buttonRound),
    style: {
      "--ps-button-color": "var(--ps-color-white)",
      "--ps-button-color-hover": "var(--ps-color-white)",
      "--ps-button-bg-color": "var(--ps-base-delete-color)",
      "--ps-button-bg-color-hover": "var(--ps-base-delete-color)",
      "--ps-button-border-color-hover": "var(--ps-base-delete-color)"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_TrashIcon, {
      size: "16",
      "stroke-width": "1.5"
    })]),
    _: 1
  }, 8, ["onClick", "class"])])]), _createVNode(_component_ModalHelper, {
    show: _ctx.showDeleteModal,
    onClose: _cache[7] || (_cache[7] = $event => _ctx.showDeleteModal = false)
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_cache[11] || (_cache[11] = _createElementVNode("p", null, "Do you really want to delete the selected entries?", -1)), _cache[12] || (_cache[12] = _createElementVNode("div", {
      class: "grid grid-cols-2 gap-2 text-xs"
    }, [_createElementVNode("div", null, "Entry"), _createElementVNode("div", null, "Include exclusively linked entries")], -1)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.selectedEntries, entry => {
      return _openBlock(), _createElementBlock("div", {
        key: entry.id,
        class: "grid grid-cols-2 gap-2 rounded-md ring-1 ring-neutral-200 dark:ring-neutral-700"
      }, [(_openBlock(), _createBlock(_component_EntryDisplay, {
        key: entry.id,
        modelValue: entry,
        disabled: true,
        display: {
          output: false,
          status: false,
          settings: false,
          description: false,
          schedule: false,
          time_trackings: false,
          routine: false
        }
      }, null, 8, ["modelValue"])), _createElementVNode("div", _hoisted_5, [_ctx.getExclusiveLinkedEntries(entry).length ? (_openBlock(), _createBlock(_component_ToggleComponent, {
        key: 0,
        modelValue: $data.includeExclusiveLinkedEntries[entry.id],
        "onUpdate:modelValue": value => $data.includeExclusiveLinkedEntries[entry.id] = value,
        style: {
          "--ps-toggle-color": "var(--ps-color-red-500)"
        }
      }, null, 8, ["modelValue", "onUpdate:modelValue"])) : _createCommentVNode("", true)]), $data.includeExclusiveLinkedEntries[entry.id] ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getExclusiveLinkedEntries(entry).slice(0, 3), entry => {
        return _openBlock(), _createBlock(_component_EntryDisplay, {
          key: entry.id,
          modelValue: entry,
          disabled: true,
          display: {
            output: false,
            status: false,
            settings: false,
            description: false,
            schedule: false,
            time_trackings: false,
            routine: false
          }
        }, null, 8, ["modelValue"]);
      }), 128)), _ctx.getExclusiveLinkedEntries(entry).length > 3 ? (_openBlock(), _createElementBlock("span", _hoisted_8, " ... and " + _toDisplayString(_ctx.getExclusiveLinkedEntries(entry).length - 3) + " more ", 1)) : _createCommentVNode("", true)])])) : _createCommentVNode("", true)]);
    }), 128)), _createElementVNode("div", _hoisted_9, [_createElementVNode("div", null, [_createVNode(_component_PSButton, {
      onClick: _cache[5] || (_cache[5] = $event => _ctx.showDeleteModal = false),
      class: _normalizeClass(_ctx.$style.deleteModalButtonCancel)
    }, {
      default: _withCtx(() => _cache[9] || (_cache[9] = [_createTextVNode(" Cancel ")])),
      _: 1
    }, 8, ["class"])]), _createElementVNode("div", _hoisted_10, [_createVNode(_component_PSButton, {
      onClick: _cache[6] || (_cache[6] = $event => ($options.entriesMarkedForDeletion.forEach(e => _ctx.deleteEntry(e)), _ctx.showDeleteModal = false, _ctx.$store.dispatch('selected', null))),
      class: _normalizeClass(_ctx.$style.deleteModalButtonDeleteSelected)
    }, {
      default: _withCtx(() => [_createVNode(_component_TrashIcon, {
        size: "16"
      }), _cache[10] || (_cache[10] = _createTextVNode(" Delete selected entries "))]),
      _: 1
    }, 8, ["class"])])])])]),
    _: 1
  }, 8, ["show"])], 2);
}