// import { addonBlueprint } from "@/addonBlueprint";

export default {
  props: {
    output: Object,
    entry: Object,
    computedDisplay: Object
  },
  mounted() {
    this.initResizeObserver();
  },
  beforeUnmount() {
    this.cleanupResizeObserver();
  },
  methods: {
    initResizeObserver() {
      this.resizeObserver = new ResizeObserver(entries => {
        window.requestAnimationFrame(() => {
          for (let entry of entries) {
            const newHeight = entry.contentRect.height;
            if (this.$refs.outer) {
              this.$refs.outer.style.height = `${newHeight}px`;
            }
          }
        });
      });
      if (this.$refs.inner) {
        this.resizeObserver.observe(this.$refs.inner);
      }
    },
    cleanupResizeObserver() {
      if (this.resizeObserver) {
        this.resizeObserver.disconnect();
      }
    }
  }
};