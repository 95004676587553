import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.find.js";
import "core-js/modules/es.iterator.for-each.js";
import "core-js/modules/es.iterator.map.js";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import momentPlugin from "@fullcalendar/moment";
import { Routing } from "@/components/mixins/Routing";
import { Scheduling } from "@/components/mixins/Scheduling";
import { Statusing } from "@/components/mixins/Statusing";

// import colors from "tailwindcss/colors";
import { Schema } from "@/components/mixins/Schema";
import { Applicators } from "@/components/mixins/Applicators";
import { defineAsyncComponent } from "vue";
export default {
  components: {
    FullCalendar,
    // make the <FullCalendar> tag available
    CalendarEntryDisplay: defineAsyncComponent(() => import("./CalendarEntryDisplay.vue"))
  },
  mixins: [Scheduling, Statusing, Schema, Applicators, Routing],
  props: {
    dateSelected: String,
    entry: Object,
    entries: Array,
    mode: {
      type: String,
      default: "month"
    },
    groupIndex: Number,
    size: String,
    renderType: String,
    overrideEntryRenderType: String,
    schema: Object,
    color: {
      type: String,
      default: "neutral"
    },
    position: String,
    editable: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    mode() {
      this.changeView(this.mode);
      this.$nextTick(() => {
        this.retrieveDateSpanFromCalendar();
        this.$onIdle(() => {
          this.renderEvents();
        });
      });
    },
    filters: {
      handler(n) {
        console.log("filters updated", JSON.stringify(n));
      },
      deep: true
    },
    entries: {
      handler(n) {
        if (JSON.stringify(n) !== JSON.stringify(this.entriesCache)) {
          const calendarApi = this.$refs.fullCalendar?.getApi();
          const changedEntries = n.filter(entry => {
            const cacheEntry = this.entriesCache.find(cacheEntry => cacheEntry.id === entry.id);
            return !cacheEntry || JSON.stringify(entry) !== JSON.stringify(cacheEntry);
          });
          if (changedEntries.length !== 0) {
            this.$onIdle(() => {
              // setTimeout(() => {
              if (calendarApi) {
                changedEntries.forEach(entry => {
                  const event = calendarApi.getEventById(entry.id);
                  if (event) {
                    event.remove();
                  }
                  calendarApi.addEvent(this.mapEntryToEvent(entry));
                });
              }
              // }, 200);
            });
          }
          const deletedEntries = this.entriesCache.filter(entry => !n.map(e => e.id).includes(entry.id));
          if (deletedEntries.length !== 0) {
            this.$onIdle(() => {
              if (calendarApi) {
                deletedEntries.forEach(entry => {
                  const event = calendarApi.getEventById(entry.id);
                  if (event) {
                    event.remove();
                  }
                });
              }
            });
          }
          this.entriesCache = JSON.parse(JSON.stringify(n));
        }
      },
      deep: true
    },
    showInput(val) {
      if (val) {
        this.showInputOverlay = false;
        this.$nextTick(() => {
          setTimeout(() => {
            this.showInputOverlay = true;
          }, 100);
        });
      } else {
        this.showInputOverlay = false;
      }
    },
    isLazyMounted(n) {
      if (n) {
        this.$nextTick(() => {
          this.retrieveDateSpanFromCalendar();
          this.renderEvents();
          this.resizeObserver = new ResizeObserver(() => {
            const windowWidth = window.innerWidth;
            if (windowWidth < 768 && this.mode === "week") {
              this.$refs.fullCalendar.getApi().changeView("timeGridThreeDay");
            } else {
              this.$refs.fullCalendar.getApi().changeView(this.getViewForFC(this.mode));
            }
            this.$refs.fullCalendar.getApi().updateSize();
          });
          this.resizeObserver.observe(this.$el);
        });
      }
    },
    input() {
      this.setInputSchema();
    }
  },
  mounted() {
    this.entriesCache = JSON.parse(JSON.stringify(this.entries));

    // new ThirdPartyDraggable(document, {
    //   itemSelector: ["center"].includes(this.position)
    //     ? "[data-entry-id][data-position]:is([data-position='center'])"
    //     : "[data-entry-id][data-position]:is([data-position='modal'])",
    //   mirrorSelector: "#draggable-wrapper",
    //   eventData: function (eventEl) {
    //     return {
    //       create: false,
    //       id: eventEl.getAttribute("data-entry-id"),
    //     };
    //   },
    // });
    this.isLazyMounted = true;
  },
  beforeUnmount() {
    this.resizeObserver.disconnect();
  },
  data() {
    return {
      entriesCache: [],
      currentStart: this.$moment(),
      calendarActiveStart: null,
      calendarActiveEnd: null,
      showInput: false,
      showInputOverlay: false,
      inputPosition: {
        left: 0,
        top: 0
      },
      inputSchemaDated: [],
      clickedDate: null,
      clickedTime: null,
      slotDuration: "00:15:00",
      resizeObserver: null,
      isLazyMounted: false
    };
  },
  methods: {
    renderEvents() {
      this.entriesCache.forEach(entry => {
        this.$refs.fullCalendar.getApi().addEvent(this.mapEntryToEvent(entry));
      });
    },
    retrieveDateSpanFromCalendar() {
      const calendarApi = this.$refs.fullCalendar.getApi();
      const {
        activeStart,
        activeEnd
      } = calendarApi.view;
      this.calendarActiveStart = activeStart;
      this.calendarActiveEnd = activeEnd;
    },
    getSlotSize(event) {
      if (!event.start || !event.end) return 1;
      const start = this.$moment.utc(event.start);
      const end = this.$moment.utc(event.end);
      const duration = end.diff(start, "minutes");
      return duration / parseInt(this.slotDuration.split(":")[1]);
    },
    getTimeDifferenceInMinutes(date) {
      const currentTime = this.$moment();
      const argTime = this.$moment(date);

      // Set both times to the same date (today)
      const currentTimeToday = this.$moment().set({
        hour: currentTime.hour(),
        minute: currentTime.minute(),
        second: 0,
        millisecond: 0
      });
      const argTimeToday = this.$moment().set({
        hour: argTime.hour(),
        minute: argTime.minute(),
        second: 0,
        millisecond: 0
      });

      // Calculate the difference in minutes
      return argTimeToday.diff(currentTimeToday, "minutes");
    },
    getViewForFC(view) {
      switch (view) {
        case "day":
          return "timeGridDay";
        case "week":
          return "timeGridWeek";
        case "month":
        default:
          return "dayGridMonth";
      }
    },
    changeView(view) {
      this.$refs.fullCalendar.getApi().changeView(this.getViewForFC(view));
    },
    getEntryByEvent(event) {
      return this.$store.getters.entries.find(entry => entry.id == event.id);
    },
    handleDatesSet(info) {
      // This event gets triggered whenever the view is changed.
      this.currentStart = this.$moment(info.view.currentStart);
    },
    animationEnterInputPanel(el, done) {
      this.$anime({
        targets: el,
        opacity: [0, 1],
        translateY: [20, 0],
        complete: done
      });
    },
    mapEntryToEvent(entry) {
      const start = entry.schedule?.date + (entry.schedule?.time ? "T" + entry.schedule?.time + "Z" : "");
      const end = entry.schedule?.date + (entry.schedule?.time ? "T" + this.$moment.utc(entry.schedule?.date + (entry.schedule?.time ? " " + entry.schedule?.time : "")).add(entry.schedule?.duration && entry.schedule.duration > 900 ? entry.schedule.duration : 900, "seconds").format("HH:mm:ss") + "Z" : start);
      return {
        id: entry.id,
        title: entry.name + start + " - " + end,
        start: start,
        end: end,
        eventStartEditable: false,
        eventDurationEditable: true,
        backgroundColor: entry.color ? entry.color : "neutral"
      };
    },
    setInputSchema() {
      if (this.entry.input) {
        this.inputSchemaDated = {
          ...this.entry.input.schema
        };
      } else {
        this.inputSchemaDated = {};
      }
      if (!this.inputSchemaDated.schedule) {
        this.inputSchemaDated.schedule = {
          date: null,
          time: null
        };
      }
      this.inputSchemaDated.schedule.date = this.clickedDate;
      this.inputSchemaDated.schedule.time = this.clickedTime;
    }
  },
  computed: {
    calendarOptionsComputed() {
      const isSmallScreen = window.innerWidth < 768; // You can adjust this breakpoint as needed

      return {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, momentPlugin],
        // droppable: true,
        // eslint-disable-next-line no-unused-vars
        dayCellDidMount: info => {
          // info.el.classList.add("bg-indigo-500");
          // console.log("huh?", info);
          // info.el.innerHTML = '<div class="h-full w-full" ></div>';
          // info.el.addEventListener("mouseover", (e) => {
          //   // console.log(info);
          //   console.log("WHAT");
          //   this.onMouseEnter(e, {
          //     id: this.entry?.id + "_" + info.date,
          //     schema: {
          //       ...this.entry.input.schema,
          //       ...{
          //         schedule: {
          //           date: this.$moment(info.date).format("YYYY-MM-DD"),
          //         },
          //       },
          //     },
          //   });
          // });
        },
        // dayCellWillUnmount(info) {
        //   // info.el.removeEventListener("mouseover"); // todo add this.
        //   info.el.innerHTML = "";
        // },
        initialView: isSmallScreen && this.mode === "week" ? "timeGridThreeDay" : this.getViewForFC(this.mode),
        events: [] /*JSON.parse(
                   JSON.stringify(this.entries.map(this.mapEntryToEvent)),
                   )*/,

        // eventTimeFormat: {
        //   // like '14:30:00'
        //   hour: "2-digit",
        //   minute: "2-digit",
        //   second: "2-digit",
        //   meridiem: false,
        // },
        // aspectRatio: 1,
        height: "60dvh",
        dragScroll: false,
        nowIndicator: true,
        datesSet: this.handleDatesSet,
        editable: true,
        eventStartEditable: false,
        eventDurationEditable: true,
        // eventBackgroundColor: "#e5e5e5",
        eventBorderColor: "transparent",
        slotDuration: this.slotDuration,
        dayMaxEventRows: 3,
        dayPopoverFormat: "MMM D.",
        scrollTime: this.$moment().add(-0.5, "hours").format("HH:mm:ss"),
        handleWindowResize: false,
        eventClick: info => {
          this.navigateTo(this.getEntryByEvent(info.event));
        },
        eventResize: eventResizeInfo => {
          // console.log(eventResizeInfo.event);
          eventResizeInfo.revert();
          const entry = this.entries.find(entry => entry.id == eventResizeInfo.event.id);
          let schema = {};
          if (this.entry.input) {
            schema = JSON.parse(JSON.stringify(this.entry.input.schema));
          }
          const start = this.$moment.utc(eventResizeInfo.event.start);
          const end = this.$moment.utc(eventResizeInfo.event.end);
          const durationInSeconds = end.diff(start, "seconds");
          // console.log(durationInSeconds);

          schema.schedule = {
            ...schema.schedule,
            ...{
              date: start.format("YYYY-MM-DD"),
              time: start.format("HH:mm:ss"),
              duration: durationInSeconds
            }
          };
          this.applyInput(this.inputSchema(schema, entry), entry);
          if (!entry.temp) this.$store.dispatch("push", {
            event: "entry_update",
            params: {
              entry: entry
            },
            entry: entry
          });
        },
        headerToolbar: false /*{
                             left: "prev,today,next",
                             center: "title",
                             right: "timeGridWeek,timeGridDay,dayGridMonth", // user can switch between the two
                             }*/,

        // fixedMirrorParent: document.body,
        firstDay: 1,
        // Define custom view for 3 days in week mode
        views: {
          timeGridThreeDay: {
            type: "timeGrid",
            duration: {
              days: 3
            },
            buttonText: "3 days"
          }
        },
        visibleRange: function (currentDate) {
          // if (this.$isMobile && this.mode === "week") {
          // Show only 3 days for smaller screens in week mode
          const start = this.$moment(currentDate).startOf("week");
          const end = this.$moment(start).add(2, "days");
          return {
            start,
            end
          };
          // } else {
          // Default behavior for other screens or modes
          // return null;
          // }
        },
        dateClick: info => {
          this.clickedDate = this.$moment.utc(info.date).local().format("YYYY-MM-DD");
          this.clickedTime = info.allDay ? null : this.$moment(info.date).utc().format("HH:mm:ss");
          this.showInput = true;
          this.setInputSchema();
          if (info.jsEvent.type == "touchend") {
            this.inputPosition = {
              left: info.jsEvent.changedTouches[0].clientX + "px",
              top: info.jsEvent.changedTouches[0].clientY + "px"
            };
          } else {
            this.inputPosition = {
              left: info.jsEvent.clientX + "px",
              top: info.jsEvent.clientY + "px"
            };
          }
          this.$nextTick(() => {
            if (this.$refs.input_display) {
              /**
               * This is used to prevent the input
               * form from overlapping on the right side
               */
              if (window.innerWidth < parseInt(this.inputPosition.left) + this.$refs.input_display.$el.offsetWidth) {
                this.inputPosition.left = window.innerWidth - this.$refs.input_display.$el.offsetWidth - 20;
              }
              /**
               * This is used to prevent the input
               * form from overlapping on the bottom side
               */

              if (window.innerHeight < parseInt(this.inputPosition.top) + this.$refs.input_display.$el.offsetHeight) {
                this.inputPosition.top = window.innerHeight - this.$refs.input_display.$el.offsetHeight - window.innerHeight / 10;
              }
              this.inputPosition.left = Math.max(5, parseInt(this.inputPosition.left)) + "px";
              this.inputPosition.top = Math.max(5, parseInt(this.inputPosition.top)) + "px";
            }
          });
        }
      };
    },
    input: {
      get() {
        return this.entry.input;
      }
    }
    // eventsComputed() {
    //   return this.entriesCache.map((entry) => {
    //     const start =
    //       entry.schedule?.date +
    //       (entry.schedule?.time ? "T" + entry.schedule?.time + "Z" : "");
    //     const end =
    //       entry.schedule?.date +
    //       (entry.schedule?.time
    //         ? "T" +
    //           this.$moment
    //             .utc(
    //               entry.schedule?.date +
    //                 (entry.schedule?.time ? " " + entry.schedule?.time : ""),
    //             )
    //             .add(
    //               entry.schedule?.duration && entry.schedule.duration > 900
    //                 ? entry.schedule.duration
    //                 : 900,
    //               "seconds",
    //             )
    //             .format("HH:mm:ss") +
    //           "Z"
    //         : start);

    //     return {
    //       id: entry.id,
    //       title: entry.name + start + " - " + end,
    //       start: start,
    //       end: end,
    //       eventStartEditable: false,
    //       eventDurationEditable: true,
    //       backgroundColor: entry.color ? entry.color : "neutral",
    //     };
    //   });
    // },
  }
};