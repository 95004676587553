import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.find.js";
import { TimeTrackingsMixin } from "./mixins/TimeTrackingsMixin";
import { Tracking } from "../mixins/Tracking";
export default {
  mixins: [TimeTrackingsMixin, Tracking],
  props: {
    modelValue: Object,
    entry: Object
  },
  data() {
    return {
      dataReady: false
    };
  },
  computed: {
    time_trackings: {
      get() {
        return this.$store.getters.time_trackings.filter(tt => tt.entry_id == this.entry.id);
      },
      set(time_trackings) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.time_trackings = time_trackings;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: {
            entry: this.entry
          },
          entry: this.entry
        });
      }
    },
    user() {
      return this.$store.state.user;
    },
    userHasActiveTimeTracking() {
      return this.user.time_trackings.find(tt => tt.user_id == this.user.id && !tt.end_at);
    },
    userHasInEntryActiveTimeTracking() {
      if (!this.user) return null;
      return this.$store.getters.time_trackings.find(tt => tt.user_id == this.user.id && !tt.end_at);
    },
    totalTrackedTime() {
      const totalSeconds = this.entry.time_trackings.total_time;

      // Calculate hours, minutes, and seconds manually
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor(totalSeconds % 3600 / 60);
      const seconds = totalSeconds % 60;

      // Format with leading zeros for minutes and seconds
      return `${hours}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    }
  },
  mounted() {
    this.pullData();
  },
  watch: {
    "entry.id": function () {
      this.pullData();
    }
  },
  methods: {
    pullData() {
      this.dataReady = false;
      this.$store.dispatch("pull", {
        filters: [{
          key: ["time_trackings", "entry_id"],
          op: "eq",
          val: this.entry.id
        }],
        data: "time_trackings",
        cb: () => this.dataReady = true
      });
    }
  }
};