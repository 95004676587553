import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, Teleport as _Teleport } from "vue";
const _hoisted_1 = {
  class: "flex w-full flex-wrap justify-between gap-1"
};
const _hoisted_2 = {
  class: "flex min-w-64 max-w-md flex-col gap-4 p-4 sm:min-w-96"
};
const _hoisted_3 = {
  class: "flex items-center gap-2"
};
const _hoisted_4 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SenseIndicatorComponent = _resolveComponent("SenseIndicatorComponent");
  const _component_PSButton = _resolveComponent("PSButton");
  const _component_ColorPicker = _resolveComponent("ColorPicker");
  const _component_TextInput = _resolveComponent("TextInput");
  const _component_XIcon = _resolveComponent("XIcon");
  const _component_SenseBuilder = _resolveComponent("SenseBuilder");
  const _component_PSOverlay = _resolveComponent("PSOverlay");
  const _component_SettingsIcon = _resolveComponent("SettingsIcon");
  const _component_TitleHelper = _resolveComponent("TitleHelper");
  const _component_SensesSettings = _resolveComponent("SensesSettings");
  const _component_ModalHelper = _resolveComponent("ModalHelper");
  return _openBlock(), _createElementBlock("div", {
    ref: "senses_display",
    class: _normalizeClass([_ctx.$style.sensesDisplay, "senses_display"])
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
    class: _normalizeClass(_ctx.$style.senses)
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.senses, (sense, index) => {
    return _openBlock(), _createBlock(_component_PSOverlay, {
      key: index,
      class: "flex justify-center"
    }, {
      button: _withCtx(() => [_createVNode(_component_PSButton, {
        class: _normalizeClass(['ps_sense_button', _ctx.$style.senseButton, {
          [_ctx.$style.showName]: $props.internalSettings.display.label
        }]),
        style: _normalizeStyle({
          '--ps-button-bg-color': _ctx.$colors[sense.color][100],
          '--ps-button-bg-color-hover': _ctx.$colors[sense.color][200],
          '--ps-button-bg-color-dark': _ctx.$colors[sense.color][950],
          '--ps-button-bg-color-dark-hover': _ctx.$colors[sense.color][900]
        })
      }, {
        default: _withCtx(() => [_createVNode(_component_SenseIndicatorComponent, {
          modelValue: $options.senses[index],
          "onUpdate:modelValue": $event => $options.senses[index] = $event,
          entry: $props.entry,
          hideOnOk: false && $props.position == 'left',
          position: $props.position,
          class: "h-3 w-3"
        }, null, 8, ["modelValue", "onUpdate:modelValue", "entry", "hideOnOk", "position"]), $props.internalSettings.display.label ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(_ctx.$style.senseName)
        }, _toDisplayString(sense.name), 3)) : _createCommentVNode("", true)]),
        _: 2
      }, 1032, ["class", "style"])]),
      default: _withCtx(({
        close
      }) => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_ColorPicker, {
        modelValue: sense.color,
        "onUpdate:modelValue": $event => sense.color = $event,
        onUpdate: color => {
          sense.color = color;
          _ctx.$store.dispatch('push', {
            event: 'sense_update',
            params: {
              sense: sense
            },
            entry: $props.entry
          });
        },
        hideLabel: true
      }, null, 8, ["modelValue", "onUpdate:modelValue", "onUpdate"]), _createVNode(_component_TextInput, {
        modelValue: $options.senses[index].name,
        "onUpdate:modelValue": [$event => $options.senses[index].name = $event, $event => _ctx.$store.dispatch('push', {
          event: 'sense_update',
          params: {
            sense: sense
          },
          entry: $props.entry
        })],
        blurOnEnter: true,
        class: _normalizeClass(_ctx.$style.textInput)
      }, null, 8, ["modelValue", "onUpdate:modelValue", "class"]), _createVNode(_component_PSButton, {
        onClick: close,
        class: "ml-auto"
      }, {
        default: _withCtx(() => [_createVNode(_component_XIcon, {
          size: "20"
        })]),
        _: 2
      }, 1032, ["onClick"])]), _createVNode(_component_SenseBuilder, {
        modelValue: $options.senses[index],
        "onUpdate:modelValue": [$event => $options.senses[index] = $event, sense => {
          _ctx.$console.log(sense);
          _ctx.$store.dispatch('push', {
            event: 'sense_update',
            params: {
              sense: sense
            },
            entry: $props.entry
          });
        }],
        entry: $props.entry,
        "onDelete:modelValue": sense => {
          _ctx.$console.log('onDelete', sense);
          $options.senses = $options.senses.filter(s => s.id !== sense.id);
          _ctx.$store.dispatch('push', {
            event: 'sense_delete',
            params: {
              id: sense.id
            },
            entry: $props.entry
          });
        }
      }, null, 8, ["modelValue", "onUpdate:modelValue", "entry", "onDelete:modelValue"])])]),
      _: 2
    }, 1024);
  }), 128)), !$options.senses.length ? (_openBlock(), _createBlock(_component_PSButton, {
    key: 0,
    class: _normalizeClass(_ctx.$style.emptyButton),
    title: "Configure Senses"
  }, null, 8, ["class"])) : _createCommentVNode("", true)], 2), $props.internalSettings.display.settings ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_PSButton, {
    onClick: _cache[0] || (_cache[0] = $event => $data.showSenseModal = true),
    title: "Configure Senses",
    "data-table-column-target": "",
    class: _normalizeClass([_ctx.$style.sensesSettingsButton])
  }, {
    default: _withCtx(() => [_createVNode(_component_SettingsIcon, {
      size: "16",
      strokeWidth: "1.5"
    })]),
    _: 1
  }, 8, ["class"]), $data.showSenseModal ? (_openBlock(), _createBlock(_Teleport, {
    key: 0,
    to: "body"
  }, [_createVNode(_component_ModalHelper, {
    show: $data.showSenseModal,
    onClose: _cache[1] || (_cache[1] = $event => $data.showSenseModal = false)
  }, {
    default: _withCtx(() => [_createVNode(_component_TitleHelper, null, {
      default: _withCtx(() => [_createTextVNode(" Configure Senses for " + _toDisplayString($props.entry.name), 1)]),
      _: 1
    }), _createVNode(_component_SensesSettings, {
      modelValue: $props.entry.senses,
      entry: $props.entry
    }, null, 8, ["modelValue", "entry"])]),
    _: 1
  }, 8, ["show"])])) : _createCommentVNode("", true)])) : _createCommentVNode("", true)])], 2);
}