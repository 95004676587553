import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.find.js";
import "core-js/modules/es.iterator.map.js";
import { Coloring } from "../../mixins/Coloring";
import { Addon } from "@/components/mixins/Addon";
export default {
  mixins: [Coloring, Addon],
  data() {
    return {
      input: "",
      searchQuery: ""
    };
  },
  computed: {
    entries() {
      return this.links?.map(id => this.$store.getters.entries.find(e => e.id == id)).filter(e => e);
    },
    links: {
      get() {
        return this.modelValue;
      },
      set(links) {
        this.$emit("update:modelValue", links);
      }
    }
  },
  methods: {
    onSearchSelect(entry) {
      if (entry) {
        this.links = [...this.links, entry.id];
        this.$emit("add:linkId", entry.id);
        this.$emit("link", entry);
      }
      this.showSearch = false;
      this.$emit("close");
    }
  }
};