import "core-js/modules/es.array.push.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import Placeholder from "@tiptap/extension-placeholder";
import StarterKit from "@tiptap/starter-kit";
import { Markdown } from "tiptap-markdown";
import { Link } from "@tiptap/extension-link";
import { Editor, EditorContent } from "@tiptap/vue-3";
Markdown.configure({
  html: true,
  // Allow HTML input/output
  tightLists: true,
  // No <p> inside <li> in markdown output
  tightListClass: "tight",
  // Add class to <ul> allowing you to remove <p> margins when tight
  bulletListMarker: "-",
  // <li> prefix in markdown output
  linkify: true,
  // Create links from "https://..." text
  breaks: true,
  // New lines (\n) in markdown input are converted to <br>
  transformPastedText: false,
  // Allow to paste markdown text in the editor
  transformCopiedText: false // Copied text is transformed to markdown
});
export default {
  components: {
    EditorContent
  },
  props: {
    modelValue: {
      type: String,
      default: ""
    },
    editable: {
      type: Boolean,
      default: true
    },
    placeholder: String
  },
  emits: ["update:modelValue"],
  data() {
    return {
      editor: null
    };
  },
  watch: {
    modelValue(value) {
      // // HTML
      // const isSame = this.editor.getHTML() === value;

      // // JSON
      // // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      // if (isSame) {
      //   return;
      // }

      this.editor.commands.setContent(value);
    },
    editable(value) {
      this.editor.setEditable(value);
    }
  },
  mounted() {
    // console.log("Mounting TextEditor", this.modelValue);
    this.editor = new Editor({
      editable: this.editable,
      extensions: [StarterKit.configure({
        orderedList: false,
        // Disable ordered lists (1. 2. 3.)
        bulletList: false,
        // Disable bullet lists (• - *)
        blockquote: false,
        // Disable blockquotes (> text)
        codeBlock: false,
        // Disable code blocks (``` ```)
        horizontalRule: false,
        // Disable horizontal rules (---)
        heading: false,
        // Disable headings (# ## ###)
        strike: false,
        // Disable strikethrough (~~text~~)
        code: false // Disable inline code (`text`)
      }), Link, Markdown, Placeholder.configure({
        placeholder: this.placeholder,
        emptyEditorClass: "is-editor-empty",
        showOnlyWhenEditable: false
      })],
      content: this.modelValue,
      onBlur: () => {
        // if (
        //   this.modelValue?.length &&
        //   this.editor.storage.markdown.getMarkdown() == this.modelValue
        // )
        //   return;
        // console.log(this.editor.get());
        // HTML
        this.$emit("update:modelValue", this.editor.getHTML());
        // this.$emit(
        //   "update:markdown",
        //   this.editor.storage.markdown.getMarkdown(),
        // );

        // JSON
        // this.$emit('update:modelValue', this.editor.getJSON())
      },
      onCreate: ({
        editor
      }) => {
        editor.view.dom.addEventListener("click", event => {
          const link = event.target.closest("a");
          if (link && link.href) {
            event.preventDefault();
            event.stopPropagation();
            const href = link.href;
            const currentHost = window.location.host;
            const finalHost = currentHost.includes("localhost") ? "app.pensive.io" : currentHost;
            const linkHost = new URL(href).host;
            if (linkHost === finalHost) {
              const path = new URL(href).pathname;
              this.$router.push(path);
            } else {
              window.open(href, "_blank");
            }
          }
        });
      }
    });
  },
  beforeUnmount() {
    const content = this.$refs.editor.innerHTML;
    this.$refs.editor.innerHTML = content;
    if (this.editor) this.editor.destroy();
  }
};