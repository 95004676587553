import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_OutputDisplay = _resolveComponent("OutputDisplay");
  const _component_MenuIcon = _resolveComponent("MenuIcon");
  const _component_PSButton = _resolveComponent("PSButton");
  const _component_PSOverlay = _resolveComponent("PSOverlay");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_OutputDisplay, {
    modelValue: $data.anchorizedEntry.output,
    entry: $data.anchorizedEntry,
    sorting: $data.anchorizedEntry.output.sorting,
    editable: false,
    showHeader: false,
    overrideOnDrop: $options.handleAnchorSorting,
    class: _normalizeClass([_ctx.$style.anchorizedOutputDisplay, "hidden sm:block"]),
    disableViewportChecking: true
  }, {
    "group-before": _withCtx(({}) => [_renderSlot(_ctx.$slots, "default")]),
    _: 3
  }, 8, ["modelValue", "entry", "sorting", "overrideOnDrop", "class"]), _createVNode(_component_PSOverlay, {
    class: "text-sm sm:hidden",
    placement: "top"
  }, {
    button: _withCtx(() => [_createVNode(_component_PSButton, {
      class: _normalizeClass(_ctx.$style.buttonRound)
    }, {
      default: _withCtx(() => [_createVNode(_component_MenuIcon, {
        size: "24",
        "stroke-width": "2"
      })]),
      _: 1
    }, 8, ["class"])]),
    default: _withCtx(({
      close
    }) => [_createVNode(_component_OutputDisplay, {
      modelValue: {
        ...$data.anchorizedEntry.output,
        ...{
          type: 'list'
        }
      },
      display: {
        output: false,
        status: false,
        procrastination: false,
        description: false,
        routine: false,
        time_trackings: false,
        links: false,
        schedule: false,
        settings: false
      },
      entry: $data.anchorizedEntry,
      sorting: $data.anchorizedEntry.output.sorting,
      editable: false,
      showHeader: false,
      overrideOnDrop: $options.handleAnchorSorting,
      class: _normalizeClass(_ctx.$style.anchorizedOutputDisplayInPopover),
      disableViewportChecking: true,
      skipLazyMount: true,
      onOnEntryClick: close
    }, {
      "group-before": _withCtx(({}) => [_renderSlot(_ctx.$slots, "default")]),
      _: 2
    }, 1032, ["modelValue", "entry", "sorting", "overrideOnDrop", "class", "onOnEntryClick"])]),
    _: 3
  })]);
}