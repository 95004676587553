import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "flex flex-col gap-5"
};
const _hoisted_2 = {
  key: 0,
  class: "flex gap-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_XIcon = _resolveComponent("XIcon");
  const _component_PSButton = _resolveComponent("PSButton");
  const _component_SaveIcon = _resolveComponent("SaveIcon");
  const _component_SortingBuilder = _resolveComponent("SortingBuilder");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_SortingBuilder, {
    modelValue: $data.value,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.value = $event)
  }, {
    after: _withCtx(() => [$data.value.length == $options.validatedValue.length ? (_openBlock(), _createElementBlock("div", _hoisted_2, [JSON.stringify($data.value) != JSON.stringify($props.modelValue) ? (_openBlock(), _createBlock(_component_PSButton, {
      key: 0,
      onClick: _cache[0] || (_cache[0] = _withModifiers($event => {
        $data.value = JSON.parse(JSON.stringify($props.modelValue));
        _ctx.$emit('cancel:modelValue');
      }, ["stop"])),
      class: _normalizeClass(_ctx.$style.buttonCancel)
    }, {
      default: _withCtx(() => [_createVNode(_component_XIcon, {
        size: "16",
        "stroke-width": "1.5"
      }), _cache[3] || (_cache[3] = _createTextVNode(" Cancel "))]),
      _: 1
    }, 8, ["class"])) : _createCommentVNode("", true), JSON.stringify($data.value) != JSON.stringify($props.modelValue) ? (_openBlock(), _createBlock(_component_PSButton, {
      key: 1,
      onClick: _cache[1] || (_cache[1] = _withModifiers($event => _ctx.$emit('update:modelValue', $data.value), ["stop"])),
      class: _normalizeClass(_ctx.$style.buttonSave)
    }, {
      default: _withCtx(() => [_createVNode(_component_SaveIcon, {
        size: "16",
        "stroke-width": "1.5"
      }), _cache[4] || (_cache[4] = _createTextVNode(" Save "))]),
      _: 1
    }, 8, ["class"])) : _createCommentVNode("", true)])) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["modelValue"])]);
}