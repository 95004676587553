import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.find.js";
import "core-js/modules/es.iterator.flat-map.js";
import "core-js/modules/es.iterator.for-each.js";
import "core-js/modules/es.iterator.map.js";
import { Statusing } from "../mixins/Statusing";
import { Scheduling } from "../mixins/Scheduling";
import { addonBlueprint } from "@/addonBlueprint";
import { Routining } from "../mixins/Routining";
import { Managing } from "../mixins/Managing";
export default {
  mixins: [Statusing, Scheduling, Routining, Managing],
  props: {
    size: {
      type: String,
      default: "md"
    }
  },
  data() {
    return {
      navigator: navigator,
      bulkStatus: null,
      bulkModel: {
        status: null,
        links: [],
        schedule: {
          date: "",
          time: null
        }
      },
      includeExclusiveLinkedEntries: {}
    };
  },
  computed: {
    entry() {
      if (this.$route.params.id2) {
        return this.entries.find(e => e.id == this.$route.params.id2);
      }
      return this.entries.find(e => e.id == this.$route.params.id);
    },
    entries() {
      return this.$store.getters.entries.filter(e => e.deleted_at === null);
    },
    selectedEntries() {
      return this.$store.getters.selected.map(id => this.entries.find(entry => entry.id == id)).filter(e => e !== undefined);
    },
    availableStatuses() {
      // console.log(
      //   "this.getOwnAvailableStatuses(this.entry)",
      //   this.getOwnAvailableStatuses(this.entry),
      // );
      // console.log(
      //   "this.getAvailableStatuses(this.entry)",
      //   this.getAvailableStatuses(this.entry),
      // );

      // const statuses = [];
      // this.selectedEntries.forEach((e) => {
      //   statuses.push(...this.getOwnAvailableStatuses(e));
      // });
      // console.log("statuses", statuses);
      return this.$merge(this.getOwnAvailableStatuses(this.entry), JSON.stringify(this.getOwnAvailableStatuses(this.entry)) != JSON.stringify(this.getAvailableStatuses(this.entry)) ? this.getAvailableStatuses(this.entry) : []);
    },
    /**
     * Returns all entries that are marked for deletion.
     * This includes the selected entries and the entries that are exclusively linked to the selected entries.
     */
    entriesMarkedForDeletion() {
      return [...Object.keys(this.includeExclusiveLinkedEntries).filter(id => this.includeExclusiveLinkedEntries[id]).flatMap(id => this.getExclusiveLinkedEntries(this.selectedEntries.find(e => e.id == id))), ...this.selectedEntries];
    }
  },
  methods: {
    updateDate(val) {
      this.$store.getters.selected.map(id => this.$store.getters.entries.find(entry => entry.id == id)).forEach(e => {
        if (!e.schedule) {
          e.schedule = addonBlueprint("schedule", e);
        }
        this.setEntrySchedule(e, {
          date: this.interpretDate({
            date: val
          }),
          time: e.schedule?.time
        });
        !e.temp ? this.$store.dispatch("push", {
          event: "entry_update",
          params: {
            entry: e
          },
          entry: e
        }) : null;
      });
      this.$store.dispatch("selected", null);
    },
    initiazeDeletionOfEntries() {
      this.includeExclusiveLinkedEntries = {};
      // this.selectedEntries.forEach((e) => this.attemptDeleteEntry(e));
      this.showDeleteModal = true;
    }
    // updateTime(val) {
    //   if (this.entry.schedule === null) return;

    //   if (
    //     (val === null || (val && val.op && val.op === "null")) &&
    //     this.entry.schedule?.date === null
    //   ) {
    //     this.deleteAddon();
    //     return;
    //   }

    //   // TODO need to move this part over to Addon Mixin updateAddon()
    //   this.setEntrySchedule(this.entry, {
    //     date: this.entry.schedule?.date,
    //     time: this.interpretTime({ time: val }),
    //   });

    //   if (
    //     this.entry.schedule.time === null &&
    //     this.entry.schedule.date === null
    //   ) {
    //     this.deleteAddon();
    //   } else {
    //     !this.entry.temp
    //       ? this.$store.dispatch("push", {
    //           event: "entry_update",
    //           params: { entry: this.entry },
    //           entry: this.entry,
    //         })
    //       : null;
    //   }
    // },
    // deleteEntry(entry = null) {
    //   if (entry === null) entry = this.entry;
    //   entry.deleted_at = this.$moment.utc().format("YYYY-MM-DD HH:mm:ss");
    //   this.$store.getters.entries
    //     .filter((e) => e.links.includes(entry.id))
    //     .forEach((e) => {
    //       e.links.splice(
    //         e.links.findIndex((l) => l == entry.id),
    //         1,
    //       );
    //     });
    //   if (!entry.temp)
    //     this.$store.dispatch("push", {
    //       event: "entry_delete",
    //       params: { id: entry.id },
    //       entry: entry,
    //     });
    //   if (this.$route.params.id == entry.id) this.$router.back();
    // },
  }
};