export default {
  props: {
    visible: Boolean
  },
  // data() {
  //   return {
  //     resizeObserver: null,
  //   };
  // },
  watch: {
    visible: {
      handler: function (n) {
        if (n === false) this.$refs.boundaries.style.minHeight = this.$refs.boundaries.getBoundingClientRect().height + "px";else this.$refs.boundaries.style.minHeight = null;
      },
      immetiate: true
    }
  }
  // mounted() {
  //   this.$refs.boundaries.style.transition = "height 200ms ease-out";

  //   this.resizeObserver = new ResizeObserver((entries) => {
  //     for (const entry of entries) {
  //       console.log(entry);
  //       if (this.visible) {
  //         this.$refs.boundaries.style.height = `${entry.contentRect.height}px`;
  //       }
  //     }
  //   });

  //   this.resizeObserver.observe(this.$refs.content);
  // },
  // beforeUnmount() {
  //   if (this.resizeObserver) {
  //     this.resizeObserver.disconnect();
  //   }
  // },
};