import { withModifiers as _withModifiers, withKeys as _withKeys, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["value", "onBlur"];
const _hoisted_2 = ["value", "onFocus"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PSListItem = _resolveComponent("PSListItem");
  const _component_PSList = _resolveComponent("PSList");
  const _component_PSOverlay = _resolveComponent("PSOverlay");
  return _openBlock(), _createBlock(_component_PSOverlay, {
    maxHeight: "200px",
    onOnOverlayOpen: _cache[5] || (_cache[5] = () => {
      $options.onOverlayOpen();
    }),
    renderBackdrop: false
  }, {
    button: _withCtx(({
      close
    }) => [_createElementVNode("input", {
      ref: "input",
      value: $data.internalTimeAsLocal,
      onInput: _cache[0] || (_cache[0] = $event => $data.internalTimeAsLocal = $event.target.value),
      type: "text",
      onFocus: _cache[1] || (_cache[1] = (...args) => $options.handleOnFocus && $options.handleOnFocus(...args)),
      onBlur: $event => $options.handleOnBlur(close),
      onKeydown: _cache[2] || (_cache[2] = _withKeys(_withModifiers($event => _ctx.$refs.input.blur(), ["stop", "prevent"]), ["enter"])),
      placeholder: "Select time",
      style: {
        "width": "100%"
      }
    }, null, 40, _hoisted_1)]),
    default: _withCtx(({
      close,
      expand
    }) => [_createVNode(_component_PSList, null, {
      default: _withCtx(() => [_createVNode(_component_PSListItem, {
        class: _normalizeClass([_ctx.$style.mobileInputWrapper, "ps_overlay_mobile_only"])
      }, {
        default: _withCtx(() => [_createElementVNode("input", {
          ref: "mobile_input",
          value: $data.internalTimeAsLocal,
          onInput: _cache[3] || (_cache[3] = $event => $data.internalTimeAsLocal = $event.target.value),
          type: "text",
          onFocus: $event => $options.handleOnFocus(expand),
          onKeydown: _cache[4] || (_cache[4] = _withKeys(_withModifiers($event => _ctx.$refs.mobile_input.blur(), ["stop", "prevent"]), ["enter"])),
          placeholder: "Select time",
          class: _normalizeClass(_ctx.$style.mobileInput)
        }, null, 42, _hoisted_2)]),
        _: 2
      }, 1032, ["class"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.times, (_time, key) => {
        return _openBlock(), _createBlock(_component_PSListItem, {
          key: key,
          ref_for: true,
          ref: _time,
          onClick: _withModifiers($event => ($options.time = $options.toUTC(_time), close()), ["prevent"])
        }, {
          default: _withCtx(() => [_renderSlot(_ctx.$slots, "timeSuggestion", {
            timeLocal: _time
          }, () => [_createTextVNode(_toDisplayString(_time), 1)])]),
          _: 2
        }, 1032, ["onClick"]);
      }), 128))]),
      _: 2
    }, 1024)]),
    _: 3
  });
}