import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_OutputDisplay = _resolveComponent("OutputDisplay");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.searchResults)
  }, [_createVNode(_component_OutputDisplay, {
    experimentalHighlightIndex: $data.searchFocusIndex,
    modelValue: $options.outputComputed,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.outputComputed = $event),
    entry: $props.entry,
    ref: "output",
    display: $props.displayFields,
    overrideEntryPermissions: {
      name: false,
      status: false,
      links: false,
      description: false,
      schedule: false,
      output: false,
      input: false,
      senses: false,
      settings: false,
      routine: false,
      time_trackings: false,
      custom_fields: false
    },
    editable: false,
    showHeader: false,
    sorting: _ctx.searchSorting,
    limit: 25,
    position: "search",
    onOnEntryClick: _cache[1] || (_cache[1] = entry => {
      _ctx.$emit('onEntrySelect', entry);
    }),
    disableRouting: true,
    filter: $props.filterSearchResults
  }, {
    "entry-top-right": _withCtx(({
      index
    }) => [_createElementVNode("span", {
      class: _normalizeClass(['ml-3 flex-none text-xs font-semibold', $data.searchFocusIndex != index && 'opacity-0'])
    }, _cache[2] || (_cache[2] = [_createElementVNode("kbd", {
      class: "font-sans"
    }, "Enter", -1)]), 2)]),
    _: 1
  }, 8, ["experimentalHighlightIndex", "modelValue", "entry", "display", "sorting", "filter"])], 2);
}