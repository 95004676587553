import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Link2Icon = _resolveComponent("Link2Icon");
  const _component_PSButton = _resolveComponent("PSButton");
  const _component_InputWithLabel = _resolveComponent("InputWithLabel");
  const _component_SearchResults = _resolveComponent("SearchResults");
  const _component_PSList = _resolveComponent("PSList");
  const _component_PSOverlay = _resolveComponent("PSOverlay");
  return _openBlock(), _createBlock(_component_PSOverlay, null, {
    button: _withCtx(() => [_renderSlot(_ctx.$slots, "button", {}, () => [_createVNode(_component_PSButton, {
      title: "Add link",
      class: _normalizeClass(_ctx.$style.button),
      "data-table-column-target": ""
    }, {
      default: _withCtx(() => [_createVNode(_component_Link2Icon, {
        size: "20"
      })]),
      _: 1
    }, 8, ["class"])])]),
    title: _withCtx(() => [_renderSlot(_ctx.$slots, "title", {}, () => [_cache[2] || (_cache[2] = _createElementVNode("span", null, "Add Link", -1))])]),
    default: _withCtx(({
      close
    }) => [_createVNode(_component_PSList, null, {
      default: _withCtx(() => [_createVNode(_component_InputWithLabel, {
        ref: "input",
        modelValue: $data.searchQuery,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.searchQuery = $event),
        placeholder: "Search...",
        onMounted: _cache[1] || (_cache[1] = $event => ($data.searchQuery = '', _ctx.$refs.input.focus())),
        class: "mb-2 px-2"
      }, null, 8, ["modelValue"]), _createVNode(_component_SearchResults, {
        query: $data.searchQuery,
        filtersOnEmpty: [{
          key: 'anchor',
          op: 'is set'
        }],
        filterSearchResults: e => !$options.links.includes(e.id),
        onOnEntrySelect: entry => {
          $options.onSearchSelect(entry);
          close();
        },
        class: _normalizeClass(_ctx.$style.searchResults)
      }, null, 8, ["query", "filterSearchResults", "onOnEntrySelect", "class"])]),
      _: 2
    }, 1024)]),
    _: 3
  });
}