import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.for-each.js";
import { Filtering } from "@/components/mixins/Filtering";
export default {
  name: "SpacePage",
  mixins: [Filtering],
  data() {
    return {
      statuses: this.$store.getters.space?.statuses
    };
  },
  mounted() {
    // this.pullTrashedEntries();
  },
  watch: {
    space: {
      handler() {
        this.statuses = this.$store.getters.space.statuses;
      },
      deep: true
    },
    trashedEntries: {
      handler() {
        console.log("entries changed");
      },
      deep: true
    }
  },
  computed: {
    space() {
      return this.$store.getters.space;
    },
    page() {
      return this.$route.params.page || "settings";
    }
    // trashedEntries() {
    //   return this.filterEntries(
    //     [
    //       {
    //         key: "deleted_at",
    //         op: "is set",
    //       },
    //     ],
    //     this.$store.getters.entries,
    //   );
    // },
  },
  methods: {
    // pullTrashedEntries() {
    //   this.$store.dispatch("pull", {
    //     filters: [
    //       {
    //         key: "deleted_at",
    //         op: "is set",
    //       },
    //     ],
    //   });
    // },
    emptyTrash() {
      this.$refs.trashOutputDisplay.entries.forEach(entry => {
        this.$store.dispatch("push", {
          event: "entry_delete",
          params: {
            id: entry.id
          },
          entry: entry
        });
        this.$store.dispatch("forceDeleteEntry", entry);
      });
    }
  }
};