import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.find.js";
import "core-js/modules/es.iterator.some.js";
export default {
  watch: {
    "draggedEntries.length": function (n) {
      if (n > 0) {
        window.addEventListener("touchmove", this.onTouchMove);
      } else {
        window.removeEventListener("touchmove", this.onTouchMove);
      }
    }
  },
  computed: {
    anchors() {
      return this.$store.getters.entries?.filter(e => e.anchors.length && e.anchors.some(a => a.placement == "left-sidebar")).sort((a, b) => {
        if (a.anchors.find(a => a.placement == "left-sidebar").position >
        // @note need to check if these are correct
        b.anchors.find(a => a.placement == "left-sidebar").position) return 1;
        if (a.anchors.find(a => a.placement == "left-sidebar").position < b.anchors.find(a => a.placement == "left-sidebar").position) return -1;
        return 0;
      });
    },
    draggedEntries() {
      return this.$store.getters.dragged;
    }
  }
};