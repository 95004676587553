import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.for-each.js";
import "core-js/modules/es.iterator.map.js";
import { statusBlueprint } from "@/addonBlueprint";
import draggable from "vuedraggable";
import { Addon } from "../mixins/Addon";
export default {
  components: {
    draggable
  },
  mixins: [Addon],
  computed: {
    space() {
      return this.$store.getters.space;
    },
    statuses: {
      get() {
        return this.modelValue;
      },
      set(statuses) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.statuses = statuses;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: {
            entry: this.entry
          },
          entry: this.entry
        });
      }
    }
  },
  data() {
    return {
      newStatus: statusBlueprint(this.entry, this)
    };
  },
  methods: {
    updatePositions: function () {
      this.statuses = this.statuses.map((s, i) => {
        s.position = i;
        return s;
      });
    },
    addStatus() {
      // Set position of new status
      this.newStatus.position = this.statuses.length;
      this.statuses = [...this.statuses, this.newStatus];

      //this.space.statuses.push(this.newStatus);
      this.newStatus = statusBlueprint(this.entry, this);
    },
    updateStatus(status) {
      this.$store.dispatch("push", {
        event: "status_update",
        params: {
          status: status
        },
        entry: this.entry
      });
      this.space.statuses = this.space.statuses.map(s => {
        if (s.id === status.id) {
          return status;
        }
        return s;
      });
    },
    deleteStatus(status) {
      this.$store.dispatch("push", {
        event: "status_delete",
        params: {
          id: status.id
        },
        entry: this.entry
      });

      // Find all entries with that status and remove
      this.$store.getters.entries.filter(e => e.status?.id === status.id).forEach(e => {
        e.status = null;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: {
            entry: e
          },
          entry: e
        });
      });

      // Remove status from global space statuses list
      // this.space.statuses = this.space.statuses.filter(
      //   (s) => s.id !== status.id,
      // );
      // Remove status from entry statuses list for modelValue:update
      this.statuses = this.statuses.filter(s => s.id !== status.id);
      this.$nextTick(() => {
        this.updatePositions();
      });
    }
  }
};