import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.for-each.js";
import "core-js/modules/es.iterator.map.js";
import "core-js/modules/es.iterator.some.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Link2Icon = _resolveComponent("Link2Icon");
  const _component_PlusIcon = _resolveComponent("PlusIcon");
  const _component_PSListItem = _resolveComponent("PSListItem");
  const _component_LinksBuilder = _resolveComponent("LinksBuilder");
  return _openBlock(), _createBlock(_component_LinksBuilder, {
    modelValue: $options.links.map(link => link.id),
    "onUpdate:modelValue": _cache[0] || (_cache[0] = val => {
      // add new links
      val.forEach(id => {
        if (!$options.links.some(link => link.id === id)) {
          $options.links.push({
            id: id,
            settings: {},
            created_at: _ctx.$moment.utc().format('YYYY-MM-DD HH:mm:ss'),
            updated_at: _ctx.$moment.utc().format('YYYY-MM-DD HH:mm:ss'),
            deleted_at: null
          });
        }
      });
      // remove links
      $options.links = $options.links.filter(link => val.includes(link.id));
      $options.links.forEach((link, index) => {
        link.position = index;
      });
    }),
    entry: _ctx.entry,
    variant: _ctx.variant,
    size: _ctx.size,
    color: _ctx.color,
    colorWeight: _ctx.colorWeight
  }, {
    button: _withCtx(() => [_createVNode(_component_PSListItem, {
      style: {
        "width": "100%"
      }
    }, {
      before: _withCtx(() => [_createVNode(_component_Link2Icon, {
        size: "16"
      })]),
      after: _withCtx(() => [_createVNode(_component_PlusIcon, {
        size: "16"
      })]),
      default: _withCtx(() => [_cache[1] || (_cache[1] = _createTextVNode(" Add Link "))]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue", "entry", "variant", "size", "color", "colorWeight"]);
}