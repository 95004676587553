import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.find.js";
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment } from "vue";
const _hoisted_1 = ["onMousedown"];
const _hoisted_2 = {
  key: 2,
  class: "status_display_tooltip pointer-events-none absolute bottom-0 left-0 z-10 w-max translate-y-full truncate rounded-md bg-white px-2 py-1 text-sm opacity-0 drop-shadow-md group-hover/status:block group-hover/status:opacity-100 group-hover/status:transition-opacity group-hover/status:delay-300 dark:bg-black dark:text-neutral-300 dark:ring-1 dark:ring-neutral-700"
};
const _hoisted_3 = {
  class: "flex flex-wrap items-center justify-center gap-2"
};
const _hoisted_4 = ["aria-label"];
const _hoisted_5 = {
  class: ""
};
const _hoisted_6 = {
  key: 1,
  class: "flex items-center gap-2"
};
const _hoisted_7 = ["aria-label"];
const _hoisted_8 = {
  key: 0,
  class: "mt-2 flex flex-wrap items-center justify-center gap-1 text-xs text-neutral-500"
};
const _hoisted_9 = {
  key: 0,
  class: "ml-auto pl-2 text-xs text-neutral-500"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_StatusIndicator = _resolveComponent("StatusIndicator");
  const _component_ChevronRightIcon = _resolveComponent("ChevronRightIcon");
  const _component_MouseIcon = _resolveComponent("MouseIcon");
  const _component_InputWithLabel = _resolveComponent("InputWithLabel");
  const _component_PSList = _resolveComponent("PSList");
  const _component_CheckIcon = _resolveComponent("CheckIcon");
  const _component_PSListItem = _resolveComponent("PSListItem");
  const _component_XIcon = _resolveComponent("XIcon");
  const _component_PSOverlay = _resolveComponent("PSOverlay");
  const _directive_touch = _resolveDirective("touch");
  return _openBlock(), _createBlock(_component_PSOverlay, {
    disabled: $props.disabled,
    class: _normalizeClass([_ctx.$style.statusDisplay, "status_display"]),
    style: _normalizeStyle({
      '--ps-status-color': _ctx.$colors[$options.status?.color || 'neutral'][500],
      '--ps-light-status-color': _ctx.$colors[$options.status?.color || 'neutral'][50],
      '--ps-dark-status-color': _ctx.$colors[$options.status?.color || 'neutral'][950]
    }),
    placement: "bottom-start",
    offset: 6
  }, {
    button: _withCtx(({
      triggerOpen
    }) => [_createElementVNode("div", {
      class: _normalizeClass([_ctx.$style.statusDisplayButton])
    }, [_renderSlot(_ctx.$slots, "button", {}, () => [_withDirectives((_openBlock(), _createElementBlock("div", {
      onMousedown: e => $options.onMouseDown(e, triggerOpen),
      onMouseup: _cache[0] || (_cache[0] = (...args) => $options.onMouseUp && $options.onMouseUp(...args)),
      onClick: _cache[1] || (_cache[1] = e => $options.quickStatusSwapEnabled ? [e.stopPropagation(), _ctx.$emit('click', e)] : null),
      onContextmenu: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop", "prevent"])),
      class: "group/status relative flex items-center",
      "data-table-column-target": ""
    }, [$data.statusOverride || $options.status ? (_openBlock(), _createBlock(_component_StatusIndicator, {
      key: 0,
      status: $data.statusOverride || $options.status,
      size: $props.size,
      class: _normalizeClass({
        [_ctx.$style.statusIndicator]: true
      })
    }, null, 8, ["status", "size", "class"])) : _createCommentVNode("", true), $props.internalSettings.display.label ? (_openBlock(), _createElementBlock("span", {
      key: 1,
      class: _normalizeClass(_ctx.$style.statusLabel)
    }, _toDisplayString($data.statusOverride?.name || $options.status?.name || "No status"), 3)) : _createCommentVNode("", true), $props.position != 'calendar' && !$data.statusOverride ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [$options.status ? (_openBlock(), _createElementBlock("span", {
      key: 0,
      "aria-label": $options.status?.name || 'No status',
      class: _normalizeClass(_ctx.$style.tooltipDot)
    }, null, 10, _hoisted_4)) : _createCommentVNode("", true), _createElementVNode("span", _hoisted_5, _toDisplayString($options.status?.name || "No status"), 1), $options.quickStatusSwapEnabled && !$props.disabled ? (_openBlock(), _createElementBlock("span", _hoisted_6, [_createVNode(_component_ChevronRightIcon, {
      class: "inline h-3 w-3"
    }), _createElementVNode("span", {
      "aria-label": $props.statuses.find(s => s.id != $options.status?.id).name || 'No status',
      class: _normalizeClass([_ctx.$style.tooltipDot]),
      style: _normalizeStyle({
        '--ps-status-color': _ctx.$colors[$props.statuses.find(s => s.id != $options.status?.id).color][500]
      })
    }, null, 14, _hoisted_7), _createTextVNode(" " + _toDisplayString($props.statuses.find(s => s.id != $options.status?.id).name), 1)])) : _createCommentVNode("", true)]), $options.quickStatusSwapEnabled && !$props.disabled ? (_openBlock(), _createElementBlock("span", _hoisted_8, [_cache[4] || (_cache[4] = _createTextVNode(" Hold ")), _createVNode(_component_MouseIcon, {
      size: "16",
      strokeWidth: "1.5"
    }), _cache[5] || (_cache[5] = _createTextVNode(" for more options "))])) : _createCommentVNode("", true)])) : _createCommentVNode("", true)], 40, _hoisted_1)), [[_directive_touch, e => {
      if ($options.quickStatusSwapEnabled && !['mouseup', 'mousedown'].includes(e.type)) {
        triggerOpen();
      }
    }, "hold", {
      stop: true,
      prevent: true
    }], [_directive_touch, void 0, "press", {
      stop: true
    }], [_directive_touch, void 0, "release", {
      stop: true
    }]])])], 2)]),
    default: _withCtx(({
      close
    }) => [_createVNode(_component_PSList, {
      class: _normalizeClass(_ctx.$style.statusList)
    }, {
      default: _withCtx(() => [$props.statuses.length > 3 ? (_openBlock(), _createBlock(_component_InputWithLabel, {
        key: 0,
        ref: "search",
        modelValue: $data.searchStatusQuery,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $data.searchStatusQuery = $event),
        placeholder: "Search...",
        onMounted: $options.onMountSearch,
        class: _normalizeClass(_ctx.$style.searchInput)
      }, null, 8, ["modelValue", "onMounted", "class"])) : _createCommentVNode("", true)]),
      _: 1
    }, 8, ["class"]), _createVNode(_component_PSList, {
      class: _normalizeClass(_ctx.$style.statusList)
    }, {
      default: _withCtx(() => [$options.status && $props.statuses?.findIndex(s => s.id == $options.status?.id) == -1 ? (_openBlock(), _createBlock(_component_PSListItem, {
        key: 0,
        active: true,
        disabled: true
      }, {
        before: _withCtx(() => [_createVNode(_component_StatusIndicator, {
          status: _ctx.option
        }, null, 8, ["status"])]),
        after: _withCtx(() => [_createVNode(_component_CheckIcon, {
          size: "20",
          strokeWidth: "1.5"
        })]),
        default: _withCtx(() => [_createTextVNode(" " + _toDisplayString(_ctx.option.name) + " ", 1)]),
        _: 1
      })) : _createCommentVNode("", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.statuses.filter(s => s.name.toLowerCase().includes($data.searchStatusQuery.toLowerCase())), option => {
        return _openBlock(), _createBlock(_component_PSListItem, {
          key: option.id,
          active: $options.status?.id == option.id,
          onClick: $event => ($options.status = option, close())
        }, {
          before: _withCtx(() => [_createVNode(_component_StatusIndicator, {
            status: option
          }, null, 8, ["status"])]),
          after: _withCtx(() => [$options.status?.id == option.id ? (_openBlock(), _createBlock(_component_CheckIcon, {
            key: 0,
            size: "16"
          })) : _createCommentVNode("", true)]),
          default: _withCtx(() => [_createTextVNode(" " + _toDisplayString(option.name) + " ", 1), option.entry_id ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.$store.getters.entries.find(e => e.id == option.entry_id)?.name), 1)) : _createCommentVNode("", true)]),
          _: 2
        }, 1032, ["active", "onClick"]);
      }), 128)), $options.status || !$props.entry ? (_openBlock(), _createBlock(_component_PSListItem, {
        key: 1,
        onClick: $event => ($options.status = null, close()),
        active: !$options.status
      }, {
        before: _withCtx(() => [_createVNode(_component_XIcon, {
          size: "16"
        })]),
        default: _withCtx(() => [_cache[6] || (_cache[6] = _createTextVNode(" No status "))]),
        _: 2
      }, 1032, ["onClick", "active"])) : _createCommentVNode("", true)]),
      _: 2
    }, 1032, ["class"])]),
    _: 3
  }, 8, ["disabled", "class", "style"]);
}