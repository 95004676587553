import { Addon } from "../mixins/Addon";
export default {
  mixins: [Addon],
  props: {
    options: Array,
    render: {
      type: Function,
      default: function (v) {
        return typeof v == "object" ? v?.join(" · ").replace("_", " ") : v?.replace("_", " ");
      }
    }
  }
};