import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TransitionGroupHelper = _resolveComponent("TransitionGroupHelper");
  const _directive_touch = _resolveDirective("touch");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.entryDisplayTable)
  }, [_createVNode(_component_TransitionGroupHelper, null, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList([...$options.tableColumns, {
      field: 'settings'
    }], column => {
      return _withDirectives((_openBlock(), _createElementBlock("div", {
        key: column.field,
        ref_for: true,
        ref: column.field,
        role: "button",
        onClick: e => {
          if (e.pointerType == 'touch') return;
          if (_ctx.$refs[column.field]?.[0]?.querySelector('[data-table-column-target]')) {
            e.stopPropagation();
            _ctx.$refs[column.field]?.[0]?.querySelector('[data-table-column-target]')?.click();
            if (column.field != 'status') {
              _ctx.$refs[column.field]?.[0]?.querySelector('[data-table-column-target]')?.focus();
            }
          }
        },
        class: _normalizeClass([_ctx.$style.tableColumn, _ctx.$style[column.field.startsWith('custom_field_') ? 'customField' : column.field + 'Field']]),
        style: _normalizeStyle({
          '--ps-table-column-width': column.width + 'px'
        })
      }, [column.field.startsWith('custom_field_') ? _renderSlot(_ctx.$slots, column.field, {
        key: 0
      }) : _renderSlot(_ctx.$slots, column.field, {
        key: 1
      })], 14, _hoisted_1)), [[_directive_touch, e => {
        if (_ctx.$store.getters.dragged.length) return;
        if (_ctx.$refs[column.field]?.[0]?.querySelector('[data-table-column-target]')) {
          e.preventDefault();
          _ctx.$refs[column.field]?.[0]?.querySelector('[data-table-column-target]')?.click();
          console.log(column.field);
          if (column.field != 'status') {
            _ctx.$refs[column.field]?.[0]?.querySelector('[data-table-column-target]')?.focus();
          }
        }
      }]]);
    }), 128))]),
    _: 3
  })], 2);
}