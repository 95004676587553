import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, renderSlot as _renderSlot } from "vue";
const _hoisted_1 = {
  class: "flex justify-between"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SelectMenu = _resolveComponent("SelectMenu");
  const _component_TrashIcon = _resolveComponent("TrashIcon");
  const _component_PSButton = _resolveComponent("PSButton");
  const _component_PlusIcon = _resolveComponent("PlusIcon");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.sortingBuilder)
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.value, (s, k) => {
    return _openBlock(), _createElementBlock("fieldset", {
      key: k,
      class: _normalizeClass(_ctx.$style.sortingRow)
    }, [_createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.sortingRow_left)
    }, [_createVNode(_component_SelectMenu, {
      modelValue: s.field,
      "onUpdate:modelValue": $event => s.field = $event,
      options: $data.fields,
      openWhenEmpty: true
    }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]), _createVNode(_component_SelectMenu, {
      modelValue: s.dir,
      "onUpdate:modelValue": $event => s.dir = $event,
      options: [{
        title: 'Ascending',
        value: 1
      }, {
        title: 'Descending',
        value: -1
      }],
      openWhenEmpty: true
    }, null, 8, ["modelValue", "onUpdate:modelValue"])], 2), _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.sortingRow_right)
    }, [_createVNode(_component_PSButton, {
      onClick: $event => [_ctx.value.splice(k, 1)],
      class: _normalizeClass(_ctx.$style.buttonRemove)
    }, {
      default: _withCtx(() => [_createVNode(_component_TrashIcon, {
        size: "16",
        strokeWidth: "1.5"
      })]),
      _: 2
    }, 1032, ["onClick", "class"])], 2)], 2);
  }), 128)), _createElementVNode("fieldset", _hoisted_1, [_createVNode(_component_PSButton, {
    onClick: _withModifiers($options.handleRowAdd, ["stop"]),
    class: _normalizeClass(_ctx.$style.buttonAdd)
  }, {
    default: _withCtx(() => [_createVNode(_component_PlusIcon, {
      size: "16",
      "stroke-width": "1.5"
    }), _cache[0] || (_cache[0] = _createTextVNode(" Add "))]),
    _: 1
  }, 8, ["onClick", "class"]), _renderSlot(_ctx.$slots, "after")])], 2);
}