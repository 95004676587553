import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue";
const _hoisted_1 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ClockIcon = _resolveComponent("ClockIcon");
  const _component_PSButton = _resolveComponent("PSButton");
  const _component_SectionHeader = _resolveComponent("SectionHeader");
  const _component_PSListItem = _resolveComponent("PSListItem");
  const _component_TrashIcon = _resolveComponent("TrashIcon");
  const _component_CalendarComponent = _resolveComponent("CalendarComponent");
  const _component_PSOverlay = _resolveComponent("PSOverlay");
  return _ctx.entry ? (_openBlock(), _createBlock(_component_PSOverlay, {
    key: 0,
    onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"]))
  }, {
    button: _withCtx(() => [_renderSlot(_ctx.$slots, "button_open", {}, () => [_createVNode(_component_PSButton, {
      title: "Set Schedule",
      class: _normalizeClass([_ctx.$style.button]),
      "data-table-column-target": ""
    }, {
      default: _withCtx(() => [_createVNode(_component_ClockIcon, {
        size: "16",
        "stroke-width": "1.5"
      }), _cache[4] || (_cache[4] = _createElementVNode("span", null, " Schedule ", -1))]),
      _: 1
    }, 8, ["class"])])]),
    title: _withCtx(() => [_createVNode(_component_SectionHeader, {
      url: "https://help.pensive.io/schedules"
    }, {
      title: _withCtx(() => [_createVNode(_component_ClockIcon, {
        size: "16"
      }), _cache[5] || (_cache[5] = _createTextVNode(" Schedule "))]),
      _: 1
    })]),
    default: _withCtx(({
      close
    }) => [_createElementVNode("div", null, [_createVNode(_component_PSListItem, {
      onClick: $event => ($options.createSchedule(_ctx.$moment().format('YYYY-MM-DD')), close()),
      class: "flex w-full cursor-pointer items-center justify-between gap-x-2 px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-950"
    }, {
      after: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(_ctx.$formattedDateOp(_ctx.$moment().format("YYYY-MM-DD"), false)), 1)]),
      default: _withCtx(() => [_cache[6] || (_cache[6] = _createElementVNode("span", {
        style: {
          "font-weight": "bold"
        }
      }, " Today ", -1))]),
      _: 2
    }, 1032, ["onClick"]), _createVNode(_component_PSListItem, {
      onClick: _cache[0] || (_cache[0] = $event => $options.createSchedule(_ctx.$moment().add(1, 'days').format('YYYY-MM-DD'))),
      class: "flex w-full cursor-pointer items-center justify-between gap-x-2 px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-950"
    }, {
      after: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(_ctx.$formattedDateOp(_ctx.$moment().add(1, "days").format("YYYY-MM-DD"), false)), 1)]),
      default: _withCtx(() => [_cache[7] || (_cache[7] = _createElementVNode("span", {
        style: {
          "font-weight": "bold"
        }
      }, " Tomorrow ", -1))]),
      _: 1
    }), _createVNode(_component_PSListItem, {
      onClick: _cache[1] || (_cache[1] = $event => $options.createSchedule(_ctx.$moment().add(-1, 'days').format('YYYY-MM-DD'))),
      class: "flex w-full cursor-pointer items-center justify-between gap-x-2 px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-950"
    }, {
      after: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(_ctx.$formattedDateOp(_ctx.$moment().add(-1, "days").format("YYYY-MM-DD"), false)), 1)]),
      default: _withCtx(() => [_cache[8] || (_cache[8] = _createElementVNode("span", {
        style: {
          "font-weight": "bold"
        }
      }, " Yesterday ", -1))]),
      _: 1
    }), $options.schedule?.date ? (_openBlock(), _createBlock(_component_PSListItem, {
      key: 0,
      onClick: _cache[2] || (_cache[2] = $event => $options.schedule.date = {
        op: 'null'
      }),
      class: "flex w-full cursor-pointer items-center justify-between gap-x-2 px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-950"
    }, {
      after: _withCtx(() => [_createVNode(_component_TrashIcon, {
        size: "16"
      })]),
      default: _withCtx(() => [_cache[9] || (_cache[9] = _createElementVNode("span", {
        style: {
          "font-weight": "bold"
        }
      }, " Unset ", -1))]),
      _: 1
    })) : _createCommentVNode("", true), _createVNode(_component_CalendarComponent, null, {
      default: _withCtx(({
        date: _date
      } = _ctx.slotProps) => [_createElementVNode("button", {
        onClick: _withModifiers($event => $options.createSchedule(_date), ["prevent"]),
        class: _normalizeClass({
          'font-bold text-black dark:text-white': _date == _ctx.$moment().format('YYYY-MM-DD'),
          'h-8 w-8 rounded-full bg-neutral-900 font-bold text-white': _date == _ctx.entry?.schedule?.date
        })
      }, _toDisplayString(_ctx.$moment(_date).format("DD")), 11, _hoisted_1)]),
      _: 1
    })])]),
    _: 3
  })) : _createCommentVNode("", true);
}