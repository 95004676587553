import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue";
const _hoisted_1 = {
  class: "mt-6 grid grid-cols-2 gap-4"
};
const _hoisted_2 = {
  class: "h-5 w-5",
  xmlns: "http://www.w3.org/2000/svg",
  x: "0px",
  y: "0px",
  width: "100",
  height: "100",
  viewBox: "0 0 30 30",
  style: {
    "fill": "#ffffff"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", null, [_cache[7] || (_cache[7] = _createStaticVNode("<div class=\"relative mt-10\"><div class=\"absolute inset-0 flex items-center\" aria-hidden=\"true\"><div class=\"w-full border-t border-neutral-200 dark:border-neutral-700\"></div></div><div class=\"relative flex justify-center text-sm font-medium leading-6\"><span class=\"bg-white px-6 text-neutral-900 dark:bg-neutral-950 dark:text-neutral-400\">Or continue with</span></div></div>", 1)), _createElementVNode("div", _hoisted_1, [_createElementVNode("button", {
    key: "google",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.loginWithProvider('google')),
    class: "flex w-full items-center justify-center gap-3 rounded-md bg-[#DB4437] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#24292F]"
  }, [(_openBlock(), _createElementBlock("svg", _hoisted_2, _cache[3] || (_cache[3] = [_createElementVNode("path", {
    d: "M 15.003906 3 C 8.3749062 3 3 8.373 3 15 C 3 21.627 8.3749062 27 15.003906 27 C 25.013906 27 27.269078 17.707 26.330078 13 L 25 13 L 22.732422 13 L 15 13 L 15 17 L 22.738281 17 C 21.848702 20.448251 18.725955 23 15 23 C 10.582 23 7 19.418 7 15 C 7 10.582 10.582 7 15 7 C 17.009 7 18.839141 7.74575 20.244141 8.96875 L 23.085938 6.1289062 C 20.951937 4.1849063 18.116906 3 15.003906 3 z"
  }, null, -1)]))), _cache[4] || (_cache[4] = _createElementVNode("span", {
    class: "text-sm font-semibold leading-6"
  }, "Google", -1))]), _createElementVNode("button", {
    key: "twitter",
    onClick: _cache[1] || (_cache[1] = $event => _ctx.loginWithProvider('twitter')),
    class: "flex w-full items-center justify-center gap-3 rounded-md bg-[#1D9BF0] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#1D9BF0]"
  }, _cache[5] || (_cache[5] = [_createElementVNode("svg", {
    class: "h-5 w-5",
    "aria-hidden": "true",
    fill: "currentColor",
    viewBox: "0 0 20 20"
  }, [_createElementVNode("path", {
    d: "M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84"
  })], -1), _createElementVNode("span", {
    class: "text-sm font-semibold leading-6"
  }, "Twitter", -1)])), _createElementVNode("button", {
    key: "github",
    onClick: _cache[2] || (_cache[2] = $event => _ctx.loginWithProvider('github')),
    class: "flex w-full items-center justify-center gap-3 rounded-md bg-[#24292F] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#24292F]"
  }, _cache[6] || (_cache[6] = [_createElementVNode("svg", {
    class: "h-5 w-5",
    "aria-hidden": "true",
    fill: "currentColor",
    viewBox: "0 0 20 20"
  }, [_createElementVNode("path", {
    "fill-rule": "evenodd",
    d: "M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z",
    "clip-rule": "evenodd"
  })], -1), _createElementVNode("span", {
    class: "text-sm font-semibold leading-6"
  }, "GitHub", -1)]))])]);
}