import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.map.js";
import moment from "moment";
import { Coloring } from "../mixins/Coloring";
// import CalendarComponent from "../helpers/CalendarComponent.vue";
import { getDateSpan } from "@/datesHelper";
import { Routining } from "../mixins/Routining";
import { addonBlueprint } from "@/addonBlueprint";
export default {
  props: {
    modelValue: Object,
    entry: Object,
    disabled: Boolean,
    internalSettings: {
      type: Object,
      default: () => ({
        display: {
          label: false,
          settings: false
        }
      })
    }
  },
  watch: {
    "routine.id": function () {
      this.pullData();
    }
  },
  data() {
    return {
      // isInitialized: false,
      open: false,
      getDateSpan: getDateSpan,
      dataReady: false
    };
  },
  mounted() {
    // console.log("mounted: routine", this.routine, this.entry.name);
    this.pullData();
  },
  mixins: [Coloring, Routining],
  computed: {
    routine: {
      get: function () {
        return this.modelValue;
      },
      set(routine) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.routine = routine;
        if (routine) {
          this.$store.dispatch("push", {
            event: "routine_update",
            params: {
              routine: routine
            },
            entry: this.entry
            // undo: 1, // @note @todo as i do not have a way to undo in other states (like routine_logs) we will skip it for now
          });
        }
      }
    },
    slots() {
      switch (this.routine.frequency) {
        case "daily":
          return Array.from({
            length: 7
          }, (_, i) => {
            const span_at = getDateSpan(this.routine.frequency, moment().add(-i, "days"));
            return {
              date: moment().add(-i, "days").format("YYYY-MM-DD"),
              log: this.getLogBySpan({
                entry: this.entry,
                span_at
              }),
              fn: () => {
                this.logASpan({
                  entry: this.entry,
                  span_at: span_at,
                  frequency: this.routine.frequency
                });
                if (!this.entry.temp) {
                  this.$store.dispatch("push", {
                    event: "routine_update",
                    params: {
                      routine: this.routine
                    },
                    entry: this.entry,
                    undo: 1 // @note @todo as i do not have a way to undo in other states (like routine_logs) we will skip it for now
                  });
                }
              }
            };
          }).reverse();
        case "weekly":
          return Array.from({
            length: 4
          }, (_, i) => {
            const span_at = getDateSpan(this.routine.frequency, moment() /*.add(-1, "day")*/.add(-i, "week"));
            return {
              date: moment().add(-i, "weeks").startOf("week").format("YYYY-MM-DD") + " - " + moment().add(-i, "weeks").endOf("week").format("YYYY-MM-DD"),
              log: this.getLogBySpan({
                entry: this.entry,
                span_at
              }),
              fn: () => {
                this.logASpan({
                  entry: this.entry,
                  span_at: span_at,
                  frequency: this.routine.frequency
                });
                if (!this.entry.temp) {
                  this.$store.dispatch("push", {
                    event: "routine_update",
                    params: {
                      routine: this.routine
                    },
                    entry: this.entry,
                    undo: 1 // @note @todo as i do not have a way to undo in other states (like routine_logs) we will skip it for now
                  });
                }
              }
            };
          }).reverse();
        case "monthly":
          return Array.from({
            length: 3
          }, (_, i) => {
            const span_at = getDateSpan(this.routine.frequency, moment().add(-i, "month"));
            return {
              date: moment().add(-i, "month").startOf("month").format("YYYY-MM-DD") + " - " + moment().add(-i, "month").endOf("month").format("YYYY-MM-DD"),
              log: this.getLogBySpan({
                entry: this.entry,
                span_at
              }),
              fn: () => {
                this.logASpan({
                  entry: this.entry,
                  span_at: span_at,
                  frequency: this.routine.frequency
                });
                if (!this.entry.temp) {
                  this.$store.dispatch("push", {
                    event: "routine_update",
                    params: {
                      routine: this.routine
                    },
                    entry: this.entry,
                    undo: 1 // @note @todo as i do not have a way to undo in other states (like routine_logs) we will skip it for now
                  });
                }
              }
            };
          }).reverse();
      }
      return [];
    }
  },
  methods: {
    pullData() {
      if (this.entry.routine) {
        this.dataReady = false;
        this.$store.dispatch("pull", {
          filters: [{
            key: ["routine", "id"],
            op: "eq",
            val: this.entry.routine.id
          }, {
            key: ["routine_logs", "span_at"],
            op: "any",
            val: this.slots.map(slot => slot.date)
          }],
          sorting: [{
            field: ["routine_log", "span_at"],
            dir: -1
          }],
          data: "routine_logs",
          cb: () => this.dataReady = true,
          source: {
            file: "RoutineDisplay",
            entry_name: this.entry.name
          }
        });
      }
    },
    createRoutine() {
      this.routine = addonBlueprint("routine", this.entry, this);
    },
    getTitle(date) {
      let dates = date.split(" - ");
      switch (this.routine.frequency) {
        case "daily":
          return this.$moment(date).format("DD");
        case "weekly":
          return "W " + this.$moment(dates[1]).format("W");
        case "monthly":
          return this.$moment(dates[1]).format("MMM");
      }
    }
  }
};