import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.map.js";
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  ref: "root"
};
const _hoisted_2 = {
  class: "flex flex-col gap-y-2 py-1"
};
const _hoisted_3 = {
  class: "min-w-max bg-white p-2 dark:bg-black"
};
const _hoisted_4 = {
  class: "min-w-max bg-white p-2 dark:bg-black"
};
const _hoisted_5 = ["data-skip-scrollparent"];
const _hoisted_6 = ["onClick"];
const _hoisted_7 = {
  class: "p-2"
};
const _hoisted_8 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_OutputSettings = _resolveComponent("OutputSettings");
  const _component_PlusIcon = _resolveComponent("PlusIcon");
  const _component_ButtonComponent = _resolveComponent("ButtonComponent");
  const _component_InputDisplay = _resolveComponent("InputDisplay");
  const _component_PopoverHelper = _resolveComponent("PopoverHelper");
  const _component_CalendarHelper = _resolveComponent("CalendarHelper");
  const _component_OutputGroupHeader = _resolveComponent("OutputGroupHeader");
  const _component_OutputTableHeaderRow = _resolveComponent("OutputTableHeaderRow");
  const _component_EntryDisplay = _resolveComponent("EntryDisplay");
  const _component_OutputDisplay = _resolveComponent("OutputDisplay", true);
  const _component_IntersectionList = _resolveComponent("IntersectionList");
  const _component_DropZone = _resolveComponent("DropZone");
  const _component_ListPlusIcon = _resolveComponent("ListPlusIcon");
  return $options.output ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    ref: "boundaries",
    class: _normalizeClass([{
      [_ctx.$style.outputDisplay]: true
    }, "ps_output_display"]),
    style: _normalizeStyle($options.cssProps)
  }, [_createElementVNode("div", _hoisted_1, [!$data.isLazyMounted ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    "data-initializing": "",
    class: _normalizeClass(["ps_skeleton_loader", _ctx.$style.skeletonLoaderWrapper])
  }, [_createElementVNode("div", {
    class: _normalizeClass([_ctx.$style.skeletonLoader, _ctx.$style.skeletonLoader_OutputGroupHeader])
  }, null, 2), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
    class: _normalizeClass([_ctx.$style.skeletonLoader, _ctx.$style.skeletonLoader_EntryWrapper])
  }, null, 2), _createElementVNode("div", {
    class: _normalizeClass([_ctx.$style.skeletonLoader, _ctx.$style.skeletonLoader_EntryWrapper])
  }, null, 2), _createElementVNode("div", {
    class: _normalizeClass([_ctx.$style.skeletonLoader, _ctx.$style.skeletonLoader_EntryWrapper])
  }, null, 2)])], 2)) : (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: "flex flex-col gap-2 font-normal",
    onClick: _cache[16] || (_cache[16] = _withModifiers(() => {}, ["stop"])),
    role: "list"
  }, [_renderSlot(_ctx.$slots, "output-before", {
    entries: $options.entries
  }), ['calendar', 'calendar_year', 'calendar_month', 'calendar_week', 'calendar_day'].includes($options.renderType) ? (_openBlock(), _createBlock(_component_CalendarHelper, {
    key: 0,
    mode: $options.output.type.split('_')[1],
    entry: $props.entry,
    entries: $options.entries,
    dateSelected: $props.dateSelected,
    "onUpdate:dateSelected": _cache[3] || (_cache[3] = $event => _ctx.$emit('update:dateSelected', $event)),
    onMode: _cache[4] || (_cache[4] = mode => {
      $options.output = {
        ...$options.output,
        ...{
          type: 'calendar_' + mode
        }
      };
    }),
    schema: $options.input?.schema,
    color: _ctx.getColor($props.entry),
    position: $props.position
  }, {
    header: _withCtx(() => [$props.editable ? (_openBlock(), _createBlock(_component_OutputSettings, {
      key: 0,
      modelValue: $options.output,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.output = $event),
      entry: $props.entry,
      render_menu: false,
      class: "flex-nowrap",
      permissions: {
        type: true,
        group: false,
        filter: true,
        sort: false
      }
    }, null, 8, ["modelValue", "entry"])) : _createCommentVNode("", true)]),
    "month-day": _withCtx(({
      date
    }) => [_createVNode(_component_PopoverHelper, {
      class: "absolute bottom-2 left-2"
    }, {
      button: _withCtx(() => [_createVNode(_component_ButtonComponent, {
        variant: "round",
        size: "xs",
        class: "!bg-neutral-100 !text-neutral-700 dark:!bg-neutral-900 dark:!text-neutral-100"
      }, {
        default: _withCtx(() => [_createVNode(_component_PlusIcon, {
          size: "20"
        })]),
        _: 1
      })]),
      default: _withCtx(({
        close
      }) => [_createElementVNode("div", _hoisted_3, [$props.entry.input ? (_openBlock(), _createBlock(_component_InputDisplay, {
        key: 0,
        modelValue: $options.input,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $options.input = $event),
        schema: {
          ...$options.input?.schema,
          ...{
            schedule: {
              date: date.format('YYYY-MM-DD')
            }
          }
        },
        display: $props.display,
        entry: $props.entry,
        position: $props.position,
        editable: $props.position != 'left-sidebar' && $props.position != 'right-sidebar',
        onCreated: entry => {
          close();
          _ctx.$emit('created', entry);
        },
        disableRouting: $props.disableRouting
      }, null, 8, ["modelValue", "schema", "display", "entry", "position", "editable", "onCreated", "disableRouting"])) : _createCommentVNode("", true)])]),
      _: 2
    }, 1024)]),
    "day-slot": _withCtx(({
      date,
      time
    }) => [_createVNode(_component_PopoverHelper, {
      class: "absolute inset-0"
    }, {
      button: _withCtx(() => _cache[18] || (_cache[18] = [_createElementVNode("div", {
        class: "hover:_bg-neutral-50 dark:_bg-neutral-900 h-full w-full"
      }, null, -1)])),
      default: _withCtx(({
        close
      }) => [_createElementVNode("div", _hoisted_4, [$props.entry.input ? (_openBlock(), _createBlock(_component_InputDisplay, {
        key: 0,
        modelValue: $options.input,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $options.input = $event),
        schema: {
          ...$options.input?.schema,
          ...{
            schedule: {
              date: date.format('YYYY-MM-DD'),
              time: time
            }
          }
        },
        display: $props.display,
        entry: $props.entry,
        position: $props.position,
        editable: true,
        onCreated: entry => {
          close();
          _ctx.$emit('created', entry);
        },
        disableRouting: $props.disableRouting
      }, null, 8, ["modelValue", "schema", "display", "entry", "position", "onCreated", "disableRouting"])) : _createCommentVNode("", true)])]),
      _: 2
    }, 1024)]),
    _: 1
  }, 8, ["mode", "entry", "entries", "dateSelected", "schema", "color", "position"])) : (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: _normalizeClass([[_ctx.$style['outputDisplay_' + $options.renderType]], "relative"]),
    "data-skip-scrollparent": $options.renderType == 'board',
    style: {
      "scrollbar-gutter": "stable"
    }
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.groups /*.slice(
                                                                                          renderType == 'board' && offset ? offset : 0,
                                                                                          renderType == 'board' && limit
                                                                                          ? (offset ? offset : 0) + limit
                                                                                          : undefined,
                                                                                          )*/, (group, groupIndex) => {
    return _openBlock(), _createBlock(_component_DropZone, {
      key: groupIndex,
      class: _normalizeClass([{
        [_ctx.$style['groupWrapper']]: true,
        [_ctx.$style['groupWrapper_' + $options.renderType]]: true,
        [_ctx.$style.groupWrapperWithSettings]: $data.groupIsHovered == groupIndex || $data.groupHasActivePopover == groupIndex
      }, "ps_group_wrapper"]),
      onMouseenter: $event => $data.groupIsHovered = groupIndex,
      onMouseleave: _cache[15] || (_cache[15] = $event => $data.groupIsHovered = -1),
      onOnDrop: schema => $options.onDrop(schema, group),
      orientation: $options.renderType == 'gallery' && $data.maxGridColumns > 1 || $options.renderType == 'tiles' ? 'horizontal' : 'vertical',
      dropSchema: group.schema,
      onDrop: () => {
        // $console.log(
        //   'old @drop handler in DropZone in OutputDisplay',
        // );
        /*onDrop(group.schema)*/
      },
      onDragover: _withModifiers($event => null, ["prevent"]),
      onDragenter: _withModifiers($event => null, ["prevent"]),
      "data-grid-columns": $data.maxGridColumns
    }, {
      default: _withCtx(() => [_renderSlot(_ctx.$slots, "group-before", {
        group: group
      }), $props.showHeader /*&&
                            !['search', 'left', 'right-sidebar'].includes(position)*/ ? (_openBlock(), _createBlock(_component_OutputGroupHeader, {
        key: 0,
        modelValue: $options.output,
        "onUpdate:modelValue": [_cache[7] || (_cache[7] = $event => $options.output = $event), _cache[8] || (_cache[8] = _output => {
          $options.output = _output;
        })],
        entry: $props.entry,
        group: group,
        editable: $props.editable && $options.groups.length == 1,
        disabled: $props.disabled,
        groupEntries: $options.entries.filter(group.filter),
        class: _normalizeClass(_ctx.$style.outputGroupHeader),
        renderType: $options.renderType
      }, {
        default: _withCtx(() => [$props.editable && $options.groups.length == 1 ? (_openBlock(), _createBlock(_component_OutputSettings, {
          key: 0,
          modelValue: $options.output,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => $options.output = $event),
          entry: $props.entry,
          render_menu: false,
          "onOn:popoverOpen": $event => $data.groupHasActivePopover = groupIndex,
          "onOn:popoverClose": _cache[6] || (_cache[6] = $event => $data.groupHasActivePopover = -1),
          class: _normalizeClass(_ctx.$style.outputSettings),
          "data-tour": "output_settings"
        }, null, 8, ["modelValue", "entry", "onOn:popoverOpen", "class"])) : _createCommentVNode("", true)]),
        _: 2
      }, 1032, ["modelValue", "entry", "group", "editable", "disabled", "groupEntries", "class", "renderType"])) : _createCommentVNode("", true), $options.renderType == 'table' ? (_openBlock(), _createBlock(_component_OutputTableHeaderRow, {
        key: 1,
        modelValue: $options.output.settings.table.columns,
        "onUpdate:modelValue": [_cache[9] || (_cache[9] = $event => $options.output.settings.table.columns = $event), _cache[10] || (_cache[10] = columns => {
          $options.output = {
            ...$options.output,
            ...{
              settings: {
                table: {
                  columns
                }
              }
            }
          };
        })],
        entry: $props.entry
      }, null, 8, ["modelValue", "entry"])) : _createCommentVNode("", true), _createVNode(_component_IntersectionList, {
        items: $options.entries.filter(group.filter),
        itemHeight: 32,
        itemClass: [_ctx.$style.entryWrapper, _ctx.$style['entryWrapper_' + $options.renderType]]
      }, {
        item: _withCtx(({
          item: entry,
          index
        }) => [_createElementVNode("div", {
          ref_for: true,
          ref: 'entry_' + index,
          onClick: event => !event.shiftKey && _ctx.$emit('onEntryClick', entry, event),
          class: _normalizeClass({
            [`${_ctx.$style.entryDisplay_highlighted}`]: $props.experimentalHighlightIndex == index
          })
        }, [_createVNode(_component_EntryDisplay, {
          modelValue: entry,
          position: $props.position,
          disabled: $props.entriesDisabled,
          permissions: $options.entryPermissions,
          renderType: $props.overrideEntryRenderType ? $props.overrideEntryRenderType : $options.renderType,
          output: $options.output,
          display: $props.display,
          disableViewportChecking: $props.disableViewportChecking,
          entryIndex: index,
          dropIndex: index,
          containingOutputGroup: entry?.id + '_' + groupIndex,
          size: $props.size,
          class: _normalizeClass({
            [`${_ctx.$style.entryDisplay}`]: true,
            [`${_ctx.$style.entryDisplay_withInput}`]: entry.input && !_ctx.$store.getters.dragged.map(e => e.id).includes(entry.id)
          })
        }, null, 8, ["modelValue", "position", "disabled", "permissions", "renderType", "output", "display", "disableViewportChecking", "entryIndex", "dropIndex", "containingOutputGroup", "size", "class"])], 10, _hoisted_6), $props.display.output && entry.output && $options.isDisplayOutputDefault(entry) ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.$style.outputDisplay_wrapper)
        }, [_createVNode(_component_OutputDisplay, {
          modelValue: entry.output,
          entry: entry,
          showHeader: $props.showHeader,
          sorting: entry.output.sorting,
          display: $options.subOutputDisplay,
          position: $props.position,
          overrideEntryRenderType: entry.output.type ? entry.output.type : $options.renderType /* note: this is for board > opening child entries showing links with basis-full */,

          overrideEntryPermissions: $props.overrideEntryPermissions,
          editable: false,
          size: $props.size
        }, null, 8, ["modelValue", "entry", "showHeader", "sorting", "display", "position", "overrideEntryRenderType", "overrideEntryPermissions", "size"])], 2)) : _createCommentVNode("", true)]),
        _: 2
      }, 1032, ["items", "itemClass"]), _renderSlot(_ctx.$slots, "before-input", {
        entries: $options.entries,
        pages: $options.totalPages
      }), $props.showInput && $props.position != 'search' && $props.entry.input && group.schema && $options.renderType != 'tiles' ? (_openBlock(), _createElementBlock("div", {
        key: 2,
        class: _normalizeClass([_ctx.$style.entryWrapper, _ctx.$style.inputWrapper])
      }, [_createVNode(_component_InputDisplay, {
        modelValue: $options.input,
        "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => $options.input = $event),
        entry: $props.entry,
        output: $options.output,
        schema: /*$merge(input?.schema, */group.schema /*)*/,
        display: $props.display,
        position: $props.position,
        renderType: $options.renderType,
        color: $props.entry.color,
        editable: $props.position != 'left' && $props.editable,
        disabled: /*disabled*/false,
        class: _normalizeClass({
          '_shadow-lg _rounded-md _p-2': $options.renderType == 'gallery'
          // 'px-2 py-1': position == 'left',
        }),
        onCreated: _cache[12] || (_cache[12] = entry => _ctx.$emit('created', entry))
      }, null, 8, ["modelValue", "entry", "output", "schema", "display", "position", "renderType", "color", "editable", "class"])], 2)) : $options.renderType == 'tiles' && $props.showInput && $props.entry.input ? (_openBlock(), _createElementBlock("div", {
        key: 3,
        class: _normalizeClass(_ctx.$style.tilesInputWrapper),
        style: {
          '--ps-entry-display-tiles-bg-color': 'var(--ps-base-primary-color)'
        }
      }, [_createVNode(_component_PopoverHelper, {
        teleport: true
      }, {
        button: _withCtx(() => [_createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.tilesInputButton)
        }, [_createVNode(_component_PlusIcon, {
          size: "20"
        })], 2)]),
        default: _withCtx(({
          close
        }) => [_createElementVNode("div", _hoisted_7, [_createVNode(_component_InputDisplay, {
          modelValue: $options.input,
          "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => $options.input = $event),
          entry: $props.entry,
          output: $options.output,
          schema: /*$merge(input?.schema, */group.schema /*)*/,
          display: $props.display,
          position: $props.position,
          renderType: "list",
          color: $props.entry.color,
          editable: $props.position != 'left' && $props.editable,
          disabled: /*disabled*/false,
          focusOnMount: true,
          class: "w-48 md:w-96",
          onCreated: _cache[14] || (_cache[14] = entry => _ctx.$emit('created', entry)),
          onStopEditing: $event => close()
        }, null, 8, ["modelValue", "entry", "output", "schema", "display", "position", "color", "editable", "onStopEditing"])])]),
        _: 2
      }, 1024)], 2)) : _createCommentVNode("", true)]),
      _: 2
    }, 1032, ["class", "onMouseenter", "onOnDrop", "orientation", "dropSchema", "data-grid-columns"]);
  }), 128))], 10, _hoisted_5)), $options.entries.length == 0 ? _renderSlot(_ctx.$slots, "output-empty", {
    key: 2
  }) : _createCommentVNode("", true), _renderSlot(_ctx.$slots, "output-after", {
    entries: $options.entries,
    pages: $options.totalPages
  })]))], 512)], 6)) : (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(_component_ButtonComponent, {
    onClick: _cache[17] || (_cache[17] = $event => $options.output = $data.addonBlueprint('output', $props.entry, this)),
    class: "w-full"
  }, {
    default: _withCtx(() => [_createVNode(_component_ListPlusIcon, {
      size: "20"
    }), _cache[19] || (_cache[19] = _createTextVNode()), _cache[20] || (_cache[20] = _createElementVNode("span", null, " Add Output", -1))]),
    _: 1
  })]));
}