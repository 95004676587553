import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.find.js";
import "core-js/modules/es.iterator.flat-map.js";
import "core-js/modules/es.iterator.for-each.js";
import "core-js/modules/es.iterator.map.js";
import { Schema } from "@/components/mixins/Schema";
import { Statusing } from "@/components/mixins/Statusing";
import { Scheduling } from "@/components/mixins/Scheduling";
import { Focusing } from "@/components/mixins/Focusing";
import { Grouping } from "@/components/mixins/Grouping";
import { Sorting } from "@/components/mixins/Sorting";
export default {
  mixins: [Schema, Statusing, Scheduling, Focusing, Grouping, Sorting],
  data() {
    return {
      mouseDown: false,
      interval: null,
      timeout: null,
      showInputModal: false,
      activeInput: null
    };
  },
  watch: {
    "$route.query.focusInput": function () {
      if (typeof this.$route.query.focusInput !== "undefined") {
        this.activeInput = this.inputs[0];
        this.showInputModal = true;
        const query = {
          ...this.$route.query
        };
        delete query.focusInput;
        this.$router.replace({
          query
        }, null, () => {});
      }
    }
  },
  computed: {
    entry() {
      return this.entries.find(e => e.id == this.$route.params.id);
    },
    rootEntry() {
      if (this.$route.params.id) {
        return this.$store.getters.entries.find(e => e.id == this.$route.params.id);
      }
      return {
        links: [],
        input: {
          schema: {}
        }
      };
    },
    linkedEntries() {
      return this.rootEntry?.links.map(link => this.entries.find(e => e.id == link.id)).filter(e => e);
    },
    entriesWithInput() {
      const entries = [];
      if (this.rootEntry?.input) {
        entries.push(this.rootEntry);
      }
      this.linkedEntries?.forEach(e => {
        if (e?.input) {
          entries.push(e);
        }
      });
      return entries;
    },
    inputs() {
      return this.entriesWithInput.flatMap(e => this.getGroups(e.output?.grouping, e).map(g => {
        return {
          entry: e,
          schema: g.schema
        };
      })).filter(i => i.schema);
    },
    entries() {
      return this.$store.getters.entries.filter(e => e.deleted_at === null);
    }
  },
  mounted() {
    window.addEventListener("keydown", this.onKeyDown);
    window.addEventListener("keyup", this.onKeyUp);
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
    window.removeEventListener("keyup", this.onKeyUp);
  },
  methods: {
    onKeyDown(event) {
      if (this.$refs.button && event.key == "+" && !event.repeat && event.target.tagName !== "INPUT" && event.target.isContentEditable !== true && !this.isElementObscured(this.$refs.button)) {
        this.$refs.button.dispatchEvent(new MouseEvent("mousedown", {
          bubbles: true
        }));
      }
    },
    onKeyUp(event) {
      if (this.$refs.button && event.key == "+" && !event.repeat && event.target.tagName !== "INPUT" && event.target.isContentEditable !== true && !this.isElementObscured(this.$refs.button)) {
        this.$refs.button.dispatchEvent(new MouseEvent("mouseup", {
          bubbles: true
        }));
      }
    },
    isElementObscured(element) {
      const rect = element.getBoundingClientRect();
      const centerX = rect.left + rect.width / 2;
      const centerY = rect.top + rect.height / 2;
      const topElement = document.elementFromPoint(centerX, centerY);
      return topElement !== element && !element.contains(topElement);
    },
    onMouseDown(e) {
      e.stopPropagation();
      if (e.type != "touchstart") e.preventDefault();
      // if (e.button === 0) {
      this.mouseDown = true;
      this.timeout = setTimeout(() => {
        if (this.mouseDown) {
          this.mouseDown = false;
          this.$refs.reference.click();
          this.timeout = null;
        }
      }, 500);
      // }
    },
    onMouseUp(e) {
      e.stopPropagation();
      e.preventDefault();
      // if (e.button === 0) {
      this.mouseDown = false;
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.activeInput = this.inputs[0];
        this.showInputModal = true;
      } else {
        clearTimeout(this.timeout);
      }
      this.timeout = null;
      // }
    },
    onTouchMove(e) {
      if (e.touches && e.touches.length > 0) {
        e.preventDefault();
        e.stopPropagation();
        const touch = e.touches[0];
        const el = document.elementFromPoint(touch.clientX, touch.clientY);
        if (el) {
          const button = el.closest("#left_sidebar_toggle_mobile");
          if (button) {
            this.$emit("toggleSidebarMobile");
          }
        }
      }
    },
    inputSchemaWithId(schema, name) {
      const base = {
        temp: true,
        id: this.$nanoid(),
        space_id: this.space?.id || this.$store.getters.user?.current_space_id,
        status: null,
        name: name || "Unnamed",
        statuses: [],
        custom_fields: [],
        custom_values: [],
        time_trackings: [],
        senses: [],
        links: [],
        created_at: this.$moment().utc().format("YYYY-MM-DD HH:mm:ss"),
        updated_at: this.$moment().utc().format("YYYY-MM-DD HH:mm:ss"),
        completed_at: null,
        deleted_at: null
      };
      return {
        ...base,
        ...this.inputSchema(schema, base)
      };
    }
  }
};