import { registerPlugin, WebPlugin } from '@capacitor/core';

class EchoWeb extends WebPlugin {
    // Implement your Echo plugin methods here
    // For example, if your plugin has an echo method:
    async echo(options) {
        // Web implementation
        return options;
    }
}
const Echo = registerPlugin('Echo', { web: new EchoWeb() });

export default Echo;