import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.find.js";
import "core-js/modules/es.iterator.for-each.js";
import "core-js/modules/es.iterator.map.js";
import { addonBlueprint } from "@/addonBlueprint";
import { Schema } from "../mixins/Schema";
import { Filtering } from "../mixins/Filtering";
import { Grouping } from "../mixins/Grouping";
import { Sorting } from "../mixins/Sorting";
export default {
  provide() {
    return {
      DndIsInReversedOrder: this.reverse
    };
  },
  mixins: [Schema, Filtering, Grouping, Sorting],
  props: {
    anchor: {
      type: String,
      required: true
    },
    reverse: Boolean
  },
  data() {
    return {
      anchorizedEntry: {
        temp: true,
        name: "Favorites",
        output: {
          type: "tiles",
          grouping: [],
          filters: [{
            key: ["anchors", "placement"],
            op: "eq",
            val: this.anchor
          }],
          sorting: [{
            field: ["anchor", "position"],
            dir: 1,
            placement: this.anchor
          }]
        },
        input: {
          schema: {
            anchors: [{
              placement: this.anchor,
              position: {
                op: "append"
              }
            }]
          }
        },
        links: []
      }
    };
  },
  computed: {
    anchoredEntries() {
      return this.filterEntries(this.anchorizedEntry.output.filters).sort(this.sortEntries(this.anchorizedEntry.output.sorting));
    }
  },
  methods: {
    getName(anchor) {
      return anchor.replace("-", " ").replace(/\b\w/g, char => char.toUpperCase());
    },
    handleAnchorSorting(schema) {
      let dragged = this.$store.getters.dragged;
      let newIndex = schema.dropIndex;
      let anchor_sorting = this.anchoredEntries.map(e => e.id);
      dragged.forEach(entry => {
        const oldIndex = anchor_sorting.indexOf(entry.id);
        if (oldIndex > -1) {
          anchor_sorting.splice(oldIndex, 1);
          if (newIndex > oldIndex) {
            newIndex = newIndex - 1;
          }
        }
        anchor_sorting.splice(newIndex, 0, entry.id);
      });
      setTimeout(() => {
        let position = 0;
        anchor_sorting.forEach(id => {
          let index = this.$store.getters.entries.findIndex(e => e.id == id);
          let entry = this.$store.getters.entries.find(e => e.id == id);

          // Create a copy of the existing anchors array
          const anchors = [...(entry.anchors || [])];

          // Find or create the anchor for this placement
          let anchorIndex = anchors.findIndex(a => a.placement === this.anchor);
          if (anchorIndex >= 0) {
            // Update existing anchor position while preserving other properties
            anchors[anchorIndex] = {
              ...anchors[anchorIndex],
              position
            };
          } else {
            // Create new anchor
            anchors.push({
              ...addonBlueprint("anchor", entry, this),
              placement: this.anchor,
              position
            });
          }

          // Update entry with modified anchors
          entry = {
            ...entry,
            anchors
          };
          this.$store.getters.entries[index] = entry;

          // Dispatch update event
          this.$store.dispatch("push", {
            event: anchorIndex >= 0 ? "anchor_update" : "anchor_create",
            params: {
              anchor: anchors[anchorIndex >= 0 ? anchorIndex : anchors.length - 1]
            },
            entry: entry,
            undo: true
          });
          position++;
        });
      }, 0);
    }
  }
};