import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = {
  class: "flex flex-col gap-2"
};
const _hoisted_2 = {
  class: "flex items-center justify-center gap-2"
};
const _hoisted_3 = {
  class: "text-sm"
};
const _hoisted_4 = {
  class: "flex min-w-64 flex-col items-stretch gap-2 pr-2"
};
const _hoisted_5 = {
  class: "text-xs text-gray-500"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ChevronLeftIcon = _resolveComponent("ChevronLeftIcon");
  const _component_ChevronRightIcon = _resolveComponent("ChevronRightIcon");
  const _component_EntryDisplay = _resolveComponent("EntryDisplay");
  const _component_RoutineChartEntry = _resolveComponent("RoutineChartEntry");
  const _component_ConstructionArea = _resolveComponent("ConstructionArea");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("button", {
    onClick: _cache[0] || (_cache[0] = () => $data.date = $data.date.clone().subtract(1, 'month'))
  }, [_createVNode(_component_ChevronLeftIcon, {
    class: "h-4 w-4"
  })]), _createElementVNode("span", _hoisted_3, _toDisplayString($data.date.format("YYYY-MM")), 1), _createElementVNode("button", {
    onClick: _cache[1] || (_cache[1] = () => $data.date = $data.date.clone().add(1, 'month'))
  }, [_createVNode(_component_ChevronRightIcon, {
    class: "h-4 w-4"
  })])]), _createElementVNode("div", {
    class: _normalizeClass(_ctx.$style.routineChart),
    style: _normalizeStyle({
      '--days-in-month': $options.daysInMonth
    })
  }, [_createElementVNode("div", _hoisted_4, [_cache[2] || (_cache[2] = _createElementVNode("div", {
    class: "h-[32px]"
  }, " ", -1)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.entries, entry => {
    return _openBlock(), _createElementBlock("div", {
      key: entry.id,
      class: _normalizeClass([_ctx.$style.entryWrapper, "flex h-[32px] items-center justify-center"])
    }, [_createVNode(_component_EntryDisplay, {
      modelValue: entry,
      display: {
        output: false,
        status: false,
        senses: false,
        procrastination: false,
        links: false,
        description: false,
        schedule: false,
        time_trackings: false,
        routine: false
      },
      permissions: {
        name: false,
        description: false,
        routine: false
      },
      class: _normalizeClass([_ctx.$style.entryDisplay, "flex-1"]),
      style: {
        "--ps-name-display-line-clamp": "1"
      }
    }, null, 8, ["modelValue", "class"]), _createElementVNode("div", _hoisted_5, _toDisplayString($options.getCompletedDays(entry)) + "/" + _toDisplayString($options.getDaysForFrequency(entry)) + " (" + _toDisplayString($options.getCompletionPercentage(entry)) + "%) ", 1)], 2);
  }), 128))]), _createElementVNode("div", {
    class: _normalizeClass(_ctx.$style.daysInMonth),
    ref: "daysInMonth"
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.daysInMonth, day => {
    return _openBlock(), _createElementBlock("div", {
      key: day,
      class: "flex h-[32px] items-center justify-center"
    }, [_createElementVNode("span", {
      class: _normalizeClass({
        'font-black': $data.date.clone().date(day).isSame(_ctx.$moment(), 'day')
      })
    }, _toDisplayString(day), 3)]);
  }), 128)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.entries, entry => {
    return _openBlock(), _createBlock(_component_RoutineChartEntry, {
      key: entry.id,
      entry: entry,
      date: $data.date
    }, null, 8, ["entry", "date"]);
  }), 128))], 2)], 6), _createVNode(_component_ConstructionArea, {
    class: "mt-5"
  })]);
}