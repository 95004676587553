import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock, renderSlot as _renderSlot } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TrashIcon = _resolveComponent("TrashIcon");
  const _component_PSButton = _resolveComponent("PSButton");
  const _component_PSListItem = _resolveComponent("PSListItem");
  const _component_LinksPopover = _resolveComponent("LinksPopover");
  const _component_PSList = _resolveComponent("PSList");
  return _openBlock(), _createBlock(_component_PSList, {
    class: _normalizeClass(_ctx.$style.List)
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.entries, (entry, index) => {
      return _openBlock(), _createBlock(_component_PSListItem, {
        key: entry.id,
        class: _normalizeClass(_ctx.$style.ListItem)
      }, {
        after: _withCtx(() => [_createVNode(_component_PSButton, {
          onClick: _withModifiers($event => _ctx.$emit('update:modelValue', $options.links = $options.links.filter((e, i) => i != index)), ["stop", "prevent"]),
          title: 'Unlink ' + _ctx.clearMarkdown(entry.name),
          class: _normalizeClass(_ctx.$style.UnlinkButton)
        }, {
          default: _withCtx(() => [_createVNode(_component_TrashIcon, {
            size: "16"
          })]),
          _: 2
        }, 1032, ["onClick", "title", "class"])]),
        default: _withCtx(() => [_createTextVNode(_toDisplayString(entry.name ? _ctx.clearMarkdown(entry.name).length > 20 ? _ctx.clearMarkdown(entry.name).slice(0, 17) + "..." : _ctx.clearMarkdown(entry.name) : "Unnamed") + " ", 1)]),
        _: 2
      }, 1032, ["class"]);
    }), 128)), _createVNode(_component_LinksPopover, {
      modelValue: $options.links,
      "onAdd:linkId": _cache[0] || (_cache[0] = id => {
        $options.links = [...$options.links, id];
      })
    }, {
      button: _withCtx(() => [_renderSlot(_ctx.$slots, "button")]),
      _: 3
    }, 8, ["modelValue"])]),
    _: 3
  }, 8, ["class"]);
}