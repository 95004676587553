export default {
  emits: ["close"],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: 0.4
    }
  },
  watch: {
    show: function () {
      this.modalTransition = window.innerHeight * this.height;
      this.isExpanded = false;
      this.scrollTop = 0;
      this.initialScrollTop = 0;
      if (this.$refs.wrapper) {
        this.$refs.wrapper.$el.scrollTop = 0;
      }
    },
    isExpanded: function (newVal) {
      // If the modal is collapsed, scroll to the top
      if (!newVal) {
        this.$nextTick(() => {
          this.scrollTop = 0;
          this.initialScrollTop = 0;
          if (this.$refs.wrapper) {
            this.$refs.wrapper.$el.scrollTop = 0;
          }
        });
      }
    },
    scrollTop: function (newVal) {
      if (newVal > 0) {
        this.temporarilyDisableDrag = true;
      }
    },
    isTouching: function (newVal) {
      if (!newVal) {
        this.temporarilyDisableDrag = false;
        this.initialScrollTop = 0;
      }
    },
    deltaY: function (newVal) {
      this.deltaYDebounced = newVal;
      if (this.deltaYTimeout) clearTimeout(this.deltaYTimeout);
      this.deltaYTimeout = setTimeout(() => {
        this.deltaYDebounced = null;
      }, 20);
    }
  },
  data() {
    return {
      // Touch tracking
      deltaY: 0,
      deltaYTimeout: null,
      deltaYDebounced: 0,
      isTouching: false,
      touchStartY: 0,
      initialScrollTop: 0,
      scrollTop: 0,
      initialHeight: 0,
      modalTransition: window.innerHeight * this.height,
      // @note with 90dvh height and 40% transition, the modal will be half the screen
      isExpanded: false,
      temporarilyDisableDrag: false
    };
  },
  methods: {
    handleTouchStart(e) {
      // console.log("handleTouchStart");
      this.isTouching = true;
      this.touchStartY = e.touches[0].clientY;
      this.initialScrollTop = this.$refs.wrapper.$el.scrollTop;
      this.scrollTop = this.initialScrollTop;
      this.initialTransition = this.modalTransition;
    },
    handleTouchMove(e) {
      // console.log("handleTouchMove", this.isTouching);
      if (!this.isTouching) return;
      const currentY = e.touches[0].clientY;
      this.deltaY = currentY - this.touchStartY;
      this.scrollTop = this.$refs.wrapper.$el.scrollTop;

      // const minTransition = window.innerHeight * 0.3;
      // const normalHeight = window.innerHeight * 0.6;
      // const minHeight = window.innerHeight * this.height;

      // Only handle gestures if we're at the top or pulling up
      if (!this.temporarilyDisableDrag && this.initialScrollTop <= 0 && this.scrollTop <= 0) {
        this.modalTransition = Math.max(this.initialTransition + this.deltaY, 0);
        if (this.deltaY > 0) {
          // Prevent default behavior of the touchmove event when pulling down to close the modal
          e.preventDefault();
        }
      }
    },
    handleTouchEnd() {
      // console.log("handleTouchEnd");
      this.isTouching = false;
      const maxHeight = 0;
      const normalHeight = window.innerHeight * this.height;
      const threshold = window.innerHeight * 0.25;
      if (this.deltaYDebounced !== null && this.scrollTop <= 0 && this.initialScrollTop <= 0) {
        if (this.deltaYDebounced <= 0) {
          if (!this.isExpanded) {
            this.modalTransition = maxHeight;
            this.isExpanded = true;
          }
        } else {
          if (this.modalTransition < normalHeight) {
            this.modalTransition = normalHeight;
            this.isExpanded = false;
          } else {
            // If pulled down far enough, close
            this.$emit("close");
          }
        }
      } else {
        if (this.modalTransition > window.innerHeight * 0.7) {
          // If pulled down far enough, close
          this.$emit("close");
        } else {
          if (this.modalTransition < threshold) {
            this.modalTransition = maxHeight;
            this.isExpanded = true;
          } else {
            this.modalTransition = normalHeight;
            this.isExpanded = false;
          }
        }
        if (!this.isExpanded) {
          this.scrollTop = 0;
          this.initialScrollTop = 0;
          this.$refs.wrapper.$el.scrollTop = 0;
        }
      }
    }
  }
};