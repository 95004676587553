import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.for-each.js";
import "core-js/modules/es.iterator.map.js";
import { Filtering } from "../mixins/Filtering.js";
import { Grouping } from "../mixins/Grouping.js";
import { Sorting } from "../mixins/Sorting.js";
import { Scheduling } from "../mixins/Scheduling.js";
import { Schema } from "../mixins/Schema.js";
import { Applicators } from "../mixins/Applicators.js";
import { Statusing } from "../mixins/Statusing";
import { Coloring } from "../mixins/Coloring";
import { addonBlueprint } from "@/addonBlueprint";
import moment from "moment";
import { Lazying } from "../mixins/Lazying.js";
import { Routining } from "../mixins/Routining.js";
export default {
  inject: {
    overrideEntries: {
      default: null
    }
  },
  provide() {
    return {
      output_entry_origin: this.entry,
      toggleDisplayOutput: this.toggleDisplayOutputDefault,
      isDisplayOutput: this.isDisplayOutputDefault
    };
  },
  mixins: [Filtering, Sorting, Scheduling, Schema, Applicators, Grouping, Statusing,
  // DragAndDrop,
  Coloring, Routining, Lazying
  // Viewporting,
  ],
  props: {
    modelValue: Object,
    entry: {
      type: Object,
      default: () => ({})
    },
    disabled: Boolean,
    display: {
      type: Object,
      default: () => ({})
    },
    schema: Object,
    sorting: Array,
    showInput: {
      type: Boolean,
      default: true
    },
    inputs: Array,
    filter: {
      type: Function,
      default: () => true
    },
    position: String,
    editable: {
      type: Boolean,
      default: true
    },
    experimentalHighlightIndex: Number,
    disableRouting: Boolean,
    delay: {
      type: Number,
      default: 0
    },
    size: {
      type: String,
      default: "md"
    },
    overrideEntryRenderType: String,
    dateSelected: {
      type: String,
      default: () => moment().format("YYYY-MM-DD")
    },
    limit: {
      type: Number,
      default: 0
    },
    offset: {
      type: Number,
      default: 0
    },
    transitionSection: Object,
    showHeader: {
      type: Boolean,
      default: true
    },
    disableViewportChecking: Boolean,
    overrideOnDrop: null,
    // Permissions for entries in this output
    overrideEntryPermissions: {
      type: Object,
      default: () => ({})
    },
    entriesDisabled: Boolean,
    overrideEntryDisplayOutput: Function,
    overrideToggleEntryDisplayOutput: Function,
    skipLazyMount: Boolean
  },
  watch: {
    "$store.getters.timestamp": function () {
      this.$onIdle(() => {
        this.pullData();
      });
    },
    // "$store.getters.entriesJson": {
    //   handler: function () {
    //     if (
    //       JSON.stringify(this.entries) != JSON.stringify(this.computedEntries)
    //     ) {
    //       // console.log(this.entry.name, "entries changed");
    //       this.$nextTick(() => {
    //         // setTimeout(() => {
    //         this.entries = this.computedEntries;
    //         // }, 2000);
    //       });
    //     }
    //   },
    //   immediate: true,
    // },
    output: {
      handler: function () {
        if (JSON.stringify(this.output) != JSON.stringify(this.cachedOutput)) {
          // this.entries = this.computedEntries;

          this.internalLimit = this.limit;
          if (this.outputChangedTimeOut) clearTimeout(this.outputChangedTimeOut);
          this.outputChangedTimeOut = setTimeout(() => {
            this.pullData();
          }, 250);
          // this.cacheEntries();
          this.cachedOutput = {
            ...this.output
          };
        }
      },
      deep: true
    },
    groups: function (n, o) {
      if (o.length > n.length) {
        this.transitionDelay = "1000ms";
      } else {
        this.transitionDelay = null;
      }
      // setTimeout(() => {
      this.groupsCache = n;
      // }, 500);
    }
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      groupIsHovered: -1,
      groupHasActivePopover: -1,
      groupsCache: [],
      transition: "",
      addonBlueprint: addonBlueprint,
      entriesCache: [],
      outputChangedTimeOut: null,
      internalLimit: this.limit,
      cachedGroupEntryLengths: [],
      cachedOutput: null,
      maxGridColumns: 4,
      observeElement: null,
      entriesObserver: null,
      isLazyMounted: this.skipLazyMount ? true : false,
      transitionDelay: null,
      resizeObserver: null,
      isTemporarilyEntryDisplayOutput: [],
      skipUndo: false
    };
  },
  mounted: function () {
    // console.log("mounting output of " + this.entry?.name, this.position);
    // setTimeout(() => {

    // console.log(this.entry.name, "mounted");
    this.$onIdle(() => {
      this.isLazyMounted = true;
    });
    // }, 1000);

    // setInterval(() => {
    //   this.isLazyMounted = !this.isLazyMounted;
    // }, 1000);

    // this.cacheEntries();
    this.$emit("mounted");
    this.cachedOutput = {
      ...this.output
    };
    this.groupsCache = this.groups;
    this.updateGridColumns();
    this.resizeObserver = new ResizeObserver(this.updateGridColumns);
    if (this.$refs.root) {
      this.resizeObserver.observe(this.$refs.root);
    }
  },
  beforeUnmount() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
  computed: {
    output: {
      get: function () {
        return this.modelValue;
      },
      set(output) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.output = output;
        if (this.entry.temp) return;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: {
            entry: this.entry
          },
          entry: this.entry,
          undo: this.skipUndo
        });
      }
    },
    groups() {
      return this.getGroups(this.output?.grouping, this.entry);
    },
    entries() {
      if (this.overrideEntries !== null) return this.overrideEntries;
      return this.filterEntries(this.output.filters).filter(this.filter).sort(this.sortEntries(this.sorting)).sort((a, b) => {
        if (this.output.custom_sorting) {
          const indexA = this.output.custom_sorting.indexOf(a.id);
          const indexB = this.output.custom_sorting.indexOf(b.id);

          // If neither item is in custom_sorting, maintain their relative order
          if (indexA === -1 && indexB === -1) return 0;

          // If only A is not in custom_sorting, move it to the end
          if (indexA === -1) return 1;

          // If only B is not in custom_sorting, move it to the end
          if (indexB === -1) return -1;

          // Both items are in custom_sorting, sort by their position
          return indexA - indexB;
        }
        return 0;
      }).slice(this.offset ? this.offset : 0, this.limit ? (this.offset ? this.offset : 0) + this.limit : undefined);
    },
    input: {
      get: function () {
        return this.entry?.input;
      },
      set(input) {
        this.$emit("update:modelValue", this.output, input);
      }
    },
    renderType() {
      switch (this.position) {
        // @todo is this even needed?
        case "builder":
          return "row";
        case "right-sidebar":
          if (this.output.type == "board") return "list";
          return this?.output?.type || "list";
        case "center":
        case "modal":
        case "focus":
        case "template":
          return this?.output?.type || "list";
        default:
          return this?.output?.type || "list";
        // return "list";
      }

      // if (this.position == "builder") return "row";
      // if (
      //   this.position == "center" ||
      //   this.position == "right-sidebar" ||
      //   this.position == "modal" ||
      //   this.position == "focus" ||
      //   this.position == "template"
      // )
      //   return this?.output?.type || "list";
      // return "list";
    },
    token() {
      return this.$store.getters.token;
    },
    applyableInputsForAll() {
      var inputs = [];
      this.entries.forEach(entry => {
        inputs = [...inputs, ...this.applyableInputs(entry).filter(input => !inputs.map(i => i.id).includes(input.id))];
      });
      return inputs;
    },
    totalPages() {
      switch (this.renderType) {
        case "gallery":
          return this.entries.length;
        case "board":
          return this.groups.length;
        case "list":
        default:
          return 1;
      }
    },
    cssProps() {
      return {
        "--ps-output-display-grid-columns": this.maxGridColumns,
        "--ps-output-display-computed-width": this.$refs.root ? `${this.$refs.root.offsetWidth}px` : "auto"
      };
    },
    entryPermissions() {
      return {
        name: !["calendar", "table", "board", "gallery"].includes(this.renderType) && window.matchMedia("(pointer: fine)").matches && this.windowWidth >= 768,
        description: false,
        status: true,
        schedule: !["calendar"].includes(this.renderType),
        routine: !["calendar"].includes(this.renderType),
        senses: !["calendar"].includes(this.renderType),
        ...this.overrideEntryPermissions
      };
    },
    subOutputDisplay() {
      return this.display;
    }
  },
  methods: {
    isDisplayOutputDefault(entry) {
      if (this.overrideEntryDisplayOutput) return this.overrideEntryDisplayOutput(entry);
      return this.isTemporarilyEntryDisplayOutput.includes(entry.id);
    },
    toggleDisplayOutputDefault(entry) {
      if (this.overrideToggleEntryDisplayOutput) {
        this.overrideToggleEntryDisplayOutput(entry);
      } else {
        if (this.isTemporarilyEntryDisplayOutput.includes(entry.id)) {
          this.isTemporarilyEntryDisplayOutput = this.isTemporarilyEntryDisplayOutput.filter(id => id != entry.id);
        } else {
          this.isTemporarilyEntryDisplayOutput.push(entry.id);
        }
      }
    },
    onDrop(schema, group) {
      if (this.overrideOnDrop) {
        this.overrideOnDrop(schema, group);
        return;
      }
      let dragged = this.$store.getters.dragged;
      let custom_sorting = this.entries.filter(group.filter).map(e => e.id);
      let newIndex = schema.dropIndex;
      dragged.forEach(entry => {
        const oldIndex = custom_sorting.indexOf(entry.id);
        // console.log("oldIndex", oldIndex);
        if (oldIndex > -1) {
          custom_sorting.splice(oldIndex, 1);
          if (newIndex > oldIndex) {
            newIndex = newIndex - 1;
          }
        }
        custom_sorting.splice(newIndex, 0, entry.id);
      });
      this.skipUndo = true;
      this.output = {
        ...this.output,
        ...{
          custom_sorting: custom_sorting
        }
      };
      this.skipUndo = false;
    },
    updateGridColumns() {
      if (!this.$refs.root) return;
      const width = this.$refs.root.offsetWidth;
      // Update the CSS custom property when width changes
      this.$refs.root.style.setProperty("--ps-output-display-computed-width", `${width}px`);
      if (width < 480) {
        this.maxGridColumns = 1;
      } else if (width < 720) {
        this.maxGridColumns = 2;
      } else if (width < 1100) {
        this.maxGridColumns = 3;
      } else {
        this.maxGridColumns = 4;
      }
    },
    pullData() {
      if (this.token) {
        if (this.output) {
          //this.disabled = true;
          this.$store.dispatch("pull", {
            filters: this.output.filters,
            sorting: this.sorting,
            limit: this.limit ? this.limit : null,
            source: {
              file: "Output",
              entry: this.entry
            }
          });

          // this.checkForChangedEntries();
        }
      }
    },
    applyInputForAll(input) {
      this.entries.forEach(entry => {
        this.applyInput(this.inputSchema(input, entry), entry, true);
      });
    },
    transitionOnSectionEnter(el, done) {
      if (!this.$Cypress && this.transitionSection && this.transitionSection.enter) {
        el.style.position = "absolute";
        el.style.width = "100%";
        this.$anime({
          ...{
            targets: el,
            complete: () => {
              done();
              el.style.position = ""; // Reset the position property after the animation
              el.style.width = ""; // Reset the width property after the animation
            }
          },
          ...this.transitionSection.enter
        });
      } else {
        done();
      }
    },
    // eslint-disable-next-line no-unused-vars
    transitionOnSectionLeave(_el, _done) {
      // if (
      //   !this.$Cypress &&
      //   this.transitionSection &&
      //   this.transitionSection.leave
      // ) {
      //   el.style.position = "absolute";
      //   el.style.width = "100%";
      //   this.$anime({
      //     ...{
      //       targets: el,
      //       complete: () => {
      //         done();
      //         el.style.position = ""; // Reset the position property after the animation
      //         el.style.width = ""; // Reset the width property after the animation
      //       },
      //     },
      //     ...this.transitionSection.leave,
      //   });
      // } else {
      //   done();
      // }
    },
    capturePosition(el) {
      const rect = el.getBoundingClientRect();
      const wrapperRect = this.$refs.boundaries.getBoundingClientRect();
      const computedStyle = window.getComputedStyle(el);
      const relativeTop = rect.top - wrapperRect.top;
      const relativeLeft = rect.left - wrapperRect.left;
      const marginLeft = parseInt(computedStyle.marginLeft) || 0;
      const marginTop = parseInt(computedStyle.marginTop) || 0;
      el.style.setProperty("--original-top", `${relativeTop - marginTop}px`);
      el.style.setProperty("--original-left", `${relativeLeft - marginLeft}px`);
      el.style.setProperty("--original-width", `${rect.width}px`);
      el.style.setProperty("--original-height", `${rect.height}px`);
    }
  }
};