import "core-js/modules/es.array.push.js";
import moment from "moment";
export default {
  // inheritAttrs: false,
  props: {
    modelValue: Number,
    basis: {
      type: String,
      default: () => moment().format("YYYY-MM-DD HH:mm:ss")
    }
  },
  data() {
    return {
      internalDate: "",
      internalTime: "",
      internalVisibleTime: ""
    };
  },
  watch: {
    duration(val) {
      this.internalDuration = val;
      this.calculateTime();
    },
    // eslint-disable-next-line no-unused-vars
    basis(val) {
      this.calculateTime();
    }
  },
  mounted() {
    this.internalDate = this.$moment.utc(this.basis).clone();
    this.internalDuration = this.time;
    this.calculateTime();
  },
  computed: {
    duration: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
        this.internalDate = this.$moment.utc(this.basis).clone();
      }
    },
    slots() {
      if (!this.basis) {
        return Array.from({
          length: 24
        }, (_, hour) => {
          return ["00", "15", "30", "45"].map(minute => {
            return `${String(hour).padStart(2, "0")}:${minute}`;
          });
        }).flat();
      }
      const slots = [];
      const basisMoment = this.$moment.utc(this.internalDate);
      const endMoment = this.$moment.utc(this.internalDate).add(8, "hours");
      let currentMoment = basisMoment.clone();
      // Round to next 15 minute interval
      const minutes = currentMoment.minutes();
      const remainder = minutes % 15;
      if (remainder > 0) {
        currentMoment.add(15 - remainder, "minutes");
      }
      while (currentMoment.isSameOrBefore(endMoment)) {
        slots.push({
          time: currentMoment.format("HH:mm"),
          date: currentMoment.format("YYYY-MM-DD")
        });
        currentMoment.add(15, "minutes");
      }
      return slots;
    }
  },
  methods: {
    renderDuration(slot) {
      const timeMoment = this.$moment.utc(slot.date + " " + slot.time);
      const duration = this.$moment.duration(this.$moment(timeMoment).diff(this.$moment.utc(this.basis)));
      const days = Math.floor(duration.asDays());
      const hours = duration.hours();
      const minutes = duration.minutes();
      return (days ? days + "d " : "") + (hours || days ? hours + "h " : "") + minutes + "m";
    },
    isValidTime(time) {
      return /^([01]\d|2[0-3])(:[0-5]\d(:[0-5]\d)?)?$/.test(time);
    },
    calculateTime() {
      if (this.basis === null) {
        this.internalVisibleTime = "";
        return;
      }
      this.internalVisibleTime = this.$moment.utc(this.basis).add(this.duration ? this.duration : 0, "seconds").local().format("HH:mm");
    },
    handleOnFocus() {
      if (window.matchMedia("(pointer: coarse)").matches) {
        if (this.$refs.mobile_input) {
          this.$refs.mobile_input.focus();
          this.$refs.mobile_input.setSelectionRange(0, this.$refs.mobile_input.value.length);
        }
      } else {
        if (this.$refs.input) {
          this.$refs.input.select();
          this.$refs.input.setSelectionRange(0, this.$refs.input.value.length);
        }
      }
    },
    handleOnBlur(close) {
      setTimeout(() => {
        close();
      }, 10);
      if (this.internalVisibleTime === "") {
        this.duration = null;
        return;
      }
      if (!this.isValidTime(this.internalVisibleTime)) {
        this.calculateTime();
        return;
      }
      if (this.isValidTime(this.internalVisibleTime)) {
        let endTimeMoment = this.$moment(this.internalDate.format("YYYY-MM-DD") + " " + this.internalVisibleTime).utc();

        // // If the end time is before the basis time, add a day
        // if (endTimeMoment.isBefore(this.$moment.utc(this.basis))) {
        //   endTimeMoment = endTimeMoment.add(1, "day");
        // }

        if (endTimeMoment.isValid()) {
          this.$refs.input.blur();
          this.duration = endTimeMoment.utc().diff(this.$moment.utc(this.basis), "seconds");
        }
      }
    }
    // handleOnEnter() {
    //   if (this.isValidTime(this.internalVisibleTime)) {
    //     const endTimeMoment = this.$moment(
    //       this.$moment.utc(this.basis).format("YYYY-MM-DD") +
    //         " " +
    //         this.internalVisibleTime,
    //     );
    //     if (endTimeMoment.isValid()) {
    //       this.$refs.input.blur();
    //       this.duration = endTimeMoment
    //         .utc()
    //         .diff(this.$moment.utc(this.basis), "seconds");
    //     }
    //   }
    // },
  }
};