import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.find.js";
import "core-js/modules/es.iterator.flat-map.js";
import "core-js/modules/es.iterator.map.js";
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, unref as _unref } from "vue";
const _hoisted_1 = {
  class: "flex w-96 max-w-[calc(100vw-32px)] flex-col gap-2 divide-y p-2 dark:divide-neutral-700"
};
const _hoisted_2 = {
  class: "relative flex items-center gap-2"
};
const _hoisted_3 = {
  key: 0,
  class: "absolute right-0 top-0 flex items-center gap-2"
};
const _hoisted_4 = {
  key: 1,
  class: "flex flex-col gap-2 px-2"
};
const _hoisted_5 = {
  class: "flex flex-wrap items-stretch justify-stretch gap-2"
};
const _hoisted_6 = {
  class: "flex flex-wrap items-stretch justify-stretch gap-2"
};
const _hoisted_7 = {
  key: 2,
  class: "flex flex-wrap items-center gap-2"
};
const _hoisted_8 = {
  key: 0,
  class: "mt-2 flex flex-col gap-2 rounded-md p-1 ring ring-pink-500"
};
const _hoisted_9 = {
  key: 3
};
const _hoisted_10 = {
  key: 0,
  class: "mt-2 flex flex-col gap-2 rounded-md p-1 ring ring-pink-500"
};
const _hoisted_11 = {
  key: 6
};
const _hoisted_12 = {
  key: 7
};
const _hoisted_13 = {
  class: "flex flex-col flex-wrap gap-2"
};
const _hoisted_14 = {
  key: 0
};
const _hoisted_15 = ["checked", "onChange"];
const _hoisted_16 = {
  key: 8,
  class: "flex flex-wrap items-center gap-2"
};
const _hoisted_17 = {
  key: 0
};
const _hoisted_18 = {
  key: 1
};
const _hoisted_19 = {
  class: "flex flex-col items-start gap-2 pt-2 first:pt-0"
};
const _hoisted_20 = {
  class: "flex flex-wrap items-center gap-2 pt-2"
};
const _hoisted_21 = {
  key: 0,
  href: "https://help.pensive.io/inputs",
  target: "_blank",
  class: "text-sm text-neutral-500 hover:text-neutral-700 hover:underline"
};
const _hoisted_22 = {
  key: 1
};
import { addonBlueprint } from "@/addonBlueprint";
import { Statusing } from "../mixins/Statusing";
import { Addon } from "../mixins/Addon";
import { Scheduling } from "../mixins/Scheduling";
const __default__ = {
  props: {
    position: String,
    color: {
      type: String,
      default: "white"
    }
  },
  mixins: [Statusing, Scheduling, Addon],
  watch: {
    "input.schema.schedule.date": function (val) {
      if (this.input) {
        if (val && val.op && val.op.includes("X")) {
          if (!this.input.schema.schedule.date.x) {
            this.input.schema.schedule.date.x = 1;
          }
        } else if (this.input.schema?.schedule?.date) {
          delete this.input.schema.schedule.date.x;
        }
      }
    },
    "input.schema.schedule.time": function (val) {
      if (this.input) {
        if (val && val.op && val.op.includes("X")) {
          if (!this.input.schema.schedule.time.x) {
            this.input.schema.schedule.time.x = 1;
          }
        } else if (this.input.schema?.schedule?.time) {
          delete this.input.schema.schedule.time.x;
        }
      }
    }
  },
  data() {
    return {
      //completion: null,
      schedule: null,
      // anchor: null,
      links: null,
      newInput: false,
      showLinkSearch: false,
      aiOptions: [
      // {
      //   title: "Add a description",
      //   prompt: "Add a description",
      // },
      // {
      //   title: "Add a priority",
      //   prompt: "Add a priority",
      // },
      // {
      //   title: "Auto schedule",
      //   prompt: "Auto schedule",
      // },
      {
        title: "Fill custom fields",
        prompt: "fill_custom_fields"
      }, {
        title: "Add relevant context",
        prompt: "add_context"
      }, {
        title: "Write a Post",
        prompt: "write_post"
      }, {
        title: "Create Bullet Points",
        prompt: "create_bullet_points"
      }],
      aiTones: [{
        title: "Persuasive",
        value: "persuasive",
        description: "Convincing and influential tone that motivates action"
      }, {
        title: "Assertive",
        value: "assertive",
        description: "Clear, confident and direct communication style"
      }, {
        title: "Confident",
        value: "confident",
        description: "Shows certainty and authority in the message"
      }, {
        title: "Constructive",
        value: "constructive",
        description: "Positive and supportive tone that encourages growth"
      }, {
        title: "Diplomatic",
        value: "diplomatic",
        description: "Tactful and considerate approach that maintains relationships"
      }, {
        title: "Empathetic",
        value: "empathetic",
        description: "Shows understanding and compassion for others' perspectives"
      }, {
        title: "Friendly",
        value: "friendly",
        description: "Warm and approachable communication style"
      }, {
        title: "Inspirational",
        value: "inspirational",
        description: "Uplifting and motivating tone that encourages positive action"
      }, {
        title: "More Descriptive",
        value: "more_descriptive",
        description: "Adds rich detail and vivid explanations to the content"
      }, {
        title: "More Detailed",
        value: "more_detailed",
        description: "Includes comprehensive information and specific examples"
      }, {
        title: "Formal",
        value: "formal",
        description: "Professional and structured communication style"
      }, {
        title: "Simple",
        value: "simple",
        description: "Clear and straightforward language without complexity"
      }, {
        title: "Direct",
        value: "direct",
        description: "Straightforward and to-the-point communication"
      }],
      aiSupportedFields: ["description", "links"]
    };
  },
  methods: {
    component(addon) {
      switch (addon) {
        // case "completion":
        //   return [
        //     "OptionPicker",
        //     {
        //       options: [[0], [1]],
        //       render: (v) => (v[0] ? "completed" : "uncompleted"),
        //     },
        //   ];
        case "schedule":
          return ["DatePicker"];
      }
    },
    addFieldToInput(addon) {
      switch (addon) {
        case "status":
          this.input.schema.status = this.statuses ? this.statuses[0].id : null;
          break;
        case "schedule":
          this.input.schema.schedule = {
            date: {
              op: "today"
            },
            time: null
          };
          break;
        // case "anchor":
        //   this.input.schema.anchor = {
        //     position: { op: "append" },
        //   };
        //   break;
        case "links":
          this.input.schema.links = {
            op: "set",
            val: []
          };
          break;
        case "description":
          this.input.schema.description = {
            content: ""
          };
          break;
        case "priority":
          this.input.schema.priority = {
            level: 2
          };
          break;
        case "procrastination":
          this.input.schema.procrastination = {
            count: 1
          };
          break;
        case "time_tracking":
          this.input.schema.time_tracking = {
            start_at: {
              op: "now"
            }
          };
          break;
        case "custom_fields":
          this.input.schema.custom_values = [];
          break;
        case "ai":
          this.input.schema.ai = {
            prompts: [],
            tones: []
          };
          break;
      }
      this.input = {
        ...this.input,
        ...{
          schema: this.input.schema
        }
      };
      this.newInput = false;
    },
    removeField(addon) {
      delete this.input.schema[addon];
      this.input = {
        ...this.input,
        ...{
          schema: this.input.schema
        }
      };
    },
    setAiSettingsFor(addon, value) {
      var data = {};
      switch (addon) {
        case "links":
          data = {
            links: []
          };
          break;
        case "description":
          data = {
            prompt: ""
          };
          break;
      }
      if (!this.input.settings) {
        this.input.settings = {};
      }
      if (value) {
        if (this.input.settings.ai == null) {
          this.input.settings.ai = {};
        }
        this.input.settings.ai[addon] = data;
      } else {
        delete this.input.settings.ai[addon];
        if (Object.keys(this.input.settings.ai).length == 0) {
          delete this.input.settings.ai;
        }
      }
      this.input = {
        ...this.input,
        ...{
          settings: this.input.settings
        }
      };
    }
  },
  computed: {
    input: {
      get() {
        return this.modelValue;
      },
      set(input) {
        /**
         * Actually we can't do this,
         * because it might be that the
         * entry that is linked to not
         * even exist in our $store
         *  */

        // if (
        //   input.schema.links &&
        //   input.schema.links.val &&
        //   input.schema.links.val.length
        // ) {
        //   // this filters out entries that do not exist anymore
        //   input.schema.links.val = input.schema.links.val.filter((id) =>
        //     this.$store.getters.entries.find((e) => e.id == id),
        //   );
        // }

        // console.log("setting input", input);
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.input = input;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: {
            entry: this.entry
          },
          entry: this.entry
        });
      }
    },
    validatedValue() {
      var value = {};
      // if (this.value.completion) value.completion = this.value.completion;
      if (this.value.status) value.status = this.value.status;
      if (this.value.schedule) value.schedule = this.value.schedule;
      // if (this.value.anchor) value.anchor = this.value.anchor;
      if (this.value.links) value.links = this.value.links;
      if (this.value.description) value.description = this.value.description;
      if (this.value.priority) value.priority = this.value.priority;
      if (this.value.procrastination) value.procrastination = this.value.procrastination;
      return value;
    },
    entries() {
      return this.$store.getters.entries.filter(e => e.deleted_at === null);
    },
    statuses() {
      return this.$merge(this.getOwnAvailableStatuses(this.entry), JSON.stringify(this.getOwnAvailableStatuses(this.entry)) != JSON.stringify(this.getAvailableStatuses(this.entry)) ? this.getAvailableStatuses(this.entry) : []);
    },
    status: {
      get() {
        return this.getStatusById(this.input.schema.status);
      },
      set(status) {
        this.input = {
          ...this.input,
          ...{
            schema: {
              ...this.input.schema,
              ...{
                status: status?.id
              }
            }
          }
        };
      }
    },
    customFields() {
      return this.input.schema.links?.val?.map(id => this.$store.getters.entries.find(e => e.id == id)).filter(e => e?.custom_fields?.length).flatMap(e => e.custom_fields);
    },
    availableAddons() {
      const baseAddons = ["links", "status", "schedule",
      // "anchor",
      "description", "priority", "procrastination", "time_tracking", "custom_fields"];
      if (this.$store.getters.space?.ai_enabled) {
        baseAddons.push("ai");
      }
      return baseAddons;
    }
  }
};
export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'InputSettings',
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_Settings2Icon = _resolveComponent("Settings2Icon");
      const _component_PSButton = _resolveComponent("PSButton");
      const _component_LabelHelper = _resolveComponent("LabelHelper");
      const _component_XIcon = _resolveComponent("XIcon");
      const _component_ButtonComponent = _resolveComponent("ButtonComponent");
      const _component_ToggleComponent = _resolveComponent("ToggleComponent");
      const _component_StatusSettings = _resolveComponent("StatusSettings");
      const _component_SelectMenu = _resolveComponent("SelectMenu");
      const _component_TextInput = _resolveComponent("TextInput");
      const _component_LinksBuilder = _resolveComponent("LinksBuilder");
      const _component_SparklesIcon = _resolveComponent("SparklesIcon");
      const _component_CustomFieldsDisplay = _resolveComponent("CustomFieldsDisplay");
      const _component_PlusIcon = _resolveComponent("PlusIcon");
      const _component_CircleHelpIcon = _resolveComponent("CircleHelpIcon");
      const _component_TrashIcon = _resolveComponent("TrashIcon");
      const _component_PopoverHelper = _resolveComponent("PopoverHelper");
      return _ctx.input ? (_openBlock(), _createBlock(_component_PopoverHelper, {
        key: 0,
        teleport: true
      }, {
        button: _withCtx(() => [_renderSlot(_ctx.$slots, "button", {}, () => [_createVNode(_component_PSButton, {
          class: _normalizeClass([_ctx.$style.settingsButton, "input_settings_button"]),
          title: "Input Settings",
          "data-table-column-target": ""
        }, {
          default: _withCtx(() => [_createVNode(_component_Settings2Icon, {
            size: "16",
            strokeWidth: "1.5"
          }), _cache[36] || (_cache[36] = _createTextVNode(" Settings "))]),
          _: 1
        }, 8, ["class"])])]),
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_renderSlot(_ctx.$slots, "before"), _createVNode(_component_LabelHelper, null, {
          default: _withCtx(() => _cache[37] || (_cache[37] = [_createTextVNode(" Input Settings ")])),
          _: 1
        }), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(_ctx.input.schema).filter(v => v), ([key], index) => {
          return _openBlock(), _createElementBlock("fieldset", {
            key: index,
            class: "flex flex-col gap-y-1.5 pt-2 first:pt-0"
          }, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_ButtonComponent, {
            onClick: $event => _ctx.removeField(key),
            size: "xs",
            color: __props.color,
            title: 'Remove ' + key.charAt(0).toUpperCase() + key.slice(1) + ' from this Input'
          }, {
            default: _withCtx(() => [_createVNode(_component_XIcon, {
              size: "16"
            }), _createTextVNode(" " + _toDisplayString(key.charAt(0).toUpperCase() + key.slice(1).replace("_", " ")), 1)]),
            _: 2
          }, 1032, ["onClick", "color", "title"]), _ctx.$store.getters.space?.ai_enabled && _ctx.aiSupportedFields.includes(key) ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_ToggleComponent, {
            label: "AI",
            modelValue: _ctx.input.settings?.ai && typeof _ctx.input.settings?.ai[key] != 'undefined',
            "onUpdate:modelValue": value => _ctx.setAiSettingsFor(key, value),
            style: {
              "--ps-toggle-color": "var(--ps-color-pink-500)"
            }
          }, null, 8, ["modelValue", "onUpdate:modelValue"])])) : _createCommentVNode("", true)]), key == 'status' ? (_openBlock(), _createBlock(_component_StatusSettings, {
            key: 0,
            modelValue: _ctx.status,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.status = $event),
            statuses: _ctx.statuses,
            full: true,
            size: _ctx.size,
            variant: _ctx.variant,
            color: __props.color,
            class: "self-start"
          }, null, 8, ["modelValue", "statuses", "size", "variant", "color"])) : _createCommentVNode("", true), key == 'schedule' ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createVNode(_component_LabelHelper, {
            class: "flex w-10 items-center"
          }, {
            default: _withCtx(() => _cache[38] || (_cache[38] = [_createTextVNode("Date:")])),
            _: 1
          }), !_ctx.input.schema.schedule.date ? (_openBlock(), _createBlock(_component_SelectMenu, {
            key: 0,
            modelValue: null,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = val => {
              _ctx.input = _ctx.$merge(_ctx.input, {
                schema: {
                  schedule: {
                    date: val
                  }
                }
              });
            }),
            teleport: false,
            options: [{
              title: 'No Date',
              value: null
            }, {
              title: 'Today',
              value: {
                op: 'today'
              }
            }, {
              title: 'Tomorrow',
              value: {
                op: 'tomorrow'
              }
            }, {
              title: 'Yesterday',
              value: {
                op: 'yesterday'
              }
            }, {
              title: 'Next ...',
              value: {
                op: 'nextXDays'
              }
            }, {
              title: 'Last ...',
              value: {
                op: 'lastXDays'
              }
            }],
            class: "flex-1"
          })) : (_openBlock(), _createBlock(_component_SelectMenu, {
            key: 1,
            modelValue: _ctx.input.schema.schedule.date.op,
            "onUpdate:modelValue": [_cache[2] || (_cache[2] = $event => _ctx.input.schema.schedule.date.op = $event), _cache[3] || (_cache[3] = val => {
              if (val === null) {
                _ctx.input = _ctx.$merge(_ctx.input, {
                  schema: {
                    schedule: {
                      date: null
                    }
                  }
                });
              } else {
                _ctx.input = _ctx.$merge(_ctx.input, {
                  schema: {
                    schedule: {
                      date: {
                        op: val
                      }
                    }
                  }
                });
              }
            })],
            teleport: false,
            options: [{
              title: 'No Date',
              value: null
            }, {
              title: 'Today',
              value: 'today'
            }, {
              title: 'Tomorrow',
              value: 'tomorrow'
            }, {
              title: 'Yesterday',
              value: 'yesterday'
            }, {
              title: 'Next ...',
              value: 'nextXDays'
            }, {
              title: 'Next ...',
              value: 'nextXWeeks',
              hidden: true
            }, {
              title: 'Next ...',
              value: 'nextXMonths',
              hidden: true
            }, {
              title: 'Next ...',
              value: 'nextXYears',
              hidden: true
            }, {
              title: 'Last ...',
              value: 'lastXDays'
            }, {
              title: 'Last ...',
              value: 'lastXWeeks',
              hidden: true
            }, {
              title: 'Last ...',
              value: 'lastXMonths',
              hidden: true
            }, {
              title: 'Last ...',
              value: 'lastXYears',
              hidden: true
            }],
            class: "flex-1"
          }, null, 8, ["modelValue"])), _ctx.input.schema.schedule.date?.op?.includes('X') ? (_openBlock(), _createBlock(_component_TextInput, {
            key: 2,
            modelValue: _ctx.input.schema.schedule.date.x,
            "onUpdate:modelValue": [_cache[4] || (_cache[4] = $event => _ctx.input.schema.schedule.date.x = $event), _cache[5] || (_cache[5] = val => {
              _ctx.input = _ctx.$merge(_ctx.input, {
                schema: {
                  schedule: {
                    date: {
                      x: val
                    }
                  }
                }
              });
            })],
            validation: val => val.replace(/[^\d]/g, ''),
            class: _normalizeClass([_ctx.$style.textInput, "flex-1 text-sm"])
          }, null, 8, ["modelValue", "validation", "class"])) : _createCommentVNode("", true), _ctx.input.schema.schedule.date?.op?.includes('next') ? (_openBlock(), _createBlock(_component_SelectMenu, {
            key: 3,
            modelValue: _ctx.input.schema.schedule.date.op,
            "onUpdate:modelValue": [_cache[6] || (_cache[6] = $event => _ctx.input.schema.schedule.date.op = $event), _cache[7] || (_cache[7] = val => {
              _ctx.input = _ctx.$merge(_ctx.input, {
                schema: {
                  schedule: {
                    date: {
                      op: val
                    }
                  }
                }
              });
            })],
            teleport: false,
            options: [{
              title: 'Days',
              value: 'nextXDays'
            }, {
              title: 'Weeks',
              value: 'nextXWeeks'
            }, {
              title: 'Months',
              value: 'nextXMonths'
            }, {
              title: 'Years',
              value: 'nextXYears'
            }],
            class: "flex-1"
          }, null, 8, ["modelValue"])) : _createCommentVNode("", true), _ctx.input.schema.schedule.date?.op?.includes('last') ? (_openBlock(), _createBlock(_component_SelectMenu, {
            key: 4,
            modelValue: _ctx.input.schema.schedule.date.op,
            "onUpdate:modelValue": [_cache[8] || (_cache[8] = $event => _ctx.input.schema.schedule.date.op = $event), _cache[9] || (_cache[9] = val => {
              _ctx.input = _ctx.$merge(_ctx.input, {
                schema: {
                  schedule: {
                    date: {
                      op: val
                    }
                  }
                }
              });
            })],
            teleport: false,
            options: [{
              title: 'Days',
              value: 'lastXDays'
            }, {
              title: 'Weeks',
              value: 'lastXWeeks'
            }, {
              title: 'Months',
              value: 'lastXMonths'
            }, {
              title: 'Years',
              value: 'lastXYears'
            }],
            class: "flex-1"
          }, null, 8, ["modelValue"])) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_LabelHelper, {
            class: "flex w-10 items-center"
          }, {
            default: _withCtx(() => _cache[39] || (_cache[39] = [_createTextVNode("Time:")])),
            _: 1
          }), !_ctx.input.schema.schedule.time ? (_openBlock(), _createBlock(_component_SelectMenu, {
            key: 0,
            modelValue: null,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = val => {
              _ctx.input = _ctx.$merge(_ctx.input, {
                schema: {
                  schedule: {
                    time: val
                  }
                }
              });
            }),
            teleport: false,
            options: [{
              title: 'No Time',
              value: null
            }, {
              title: 'Now',
              value: {
                op: 'now'
              }
            }, {
              title: 'Next ...',
              value: {
                op: 'nextXHours'
              }
            }, {
              title: 'Last ...',
              value: {
                op: 'lastXHours'
              }
            }],
            class: "flex-1"
          })) : (_openBlock(), _createBlock(_component_SelectMenu, {
            key: 1,
            modelValue: _ctx.input.schema.schedule.time.op,
            "onUpdate:modelValue": [_cache[11] || (_cache[11] = $event => _ctx.input.schema.schedule.time.op = $event), _cache[12] || (_cache[12] = val => {
              if (val === null) {
                _ctx.input = _ctx.$merge(_ctx.input, {
                  schema: {
                    schedule: {
                      time: null
                    }
                  }
                });
              } else {
                _ctx.input = _ctx.$merge(_ctx.input, {
                  schema: {
                    schedule: {
                      time: {
                        op: val
                      }
                    }
                  }
                });
              }
            })],
            teleport: false,
            options: [{
              title: 'No Time',
              value: null
            }, {
              title: 'Now',
              value: 'now'
            }, {
              title: 'Next ...',
              value: 'nextXHours'
            }, {
              title: 'Next ...',
              value: 'nextXMinutes',
              hidden: true
            }, {
              title: 'Next ...',
              value: 'nextXSeconds',
              hidden: true
            }, {
              title: 'Last ...',
              value: 'lastXHours'
            }, {
              title: 'Last ...',
              value: 'lastXMinutes',
              hidden: true
            }, {
              title: 'Last ...',
              value: 'lastXSeconds',
              hidden: true
            }],
            class: "flex-1"
          }, null, 8, ["modelValue"])), _ctx.input.schema.schedule.time?.op?.includes('X') ? (_openBlock(), _createBlock(_component_TextInput, {
            key: 2,
            modelValue: _ctx.input.schema.schedule.time.x,
            "onUpdate:modelValue": [_cache[13] || (_cache[13] = $event => _ctx.input.schema.schedule.time.x = $event), _cache[14] || (_cache[14] = val => {
              _ctx.input = _ctx.$merge(_ctx.input, {
                schema: {
                  schedule: {
                    time: {
                      x: val
                    }
                  }
                }
              });
            })],
            validation: val => val.replace(/[^\d]/g, ''),
            class: "flex-1 text-sm"
          }, null, 8, ["modelValue", "validation"])) : _createCommentVNode("", true), _ctx.input.schema.schedule.time?.op?.includes('next') ? (_openBlock(), _createBlock(_component_SelectMenu, {
            key: 3,
            modelValue: _ctx.input.schema.schedule.time.op,
            "onUpdate:modelValue": [_cache[15] || (_cache[15] = $event => _ctx.input.schema.schedule.time.op = $event), _cache[16] || (_cache[16] = val => {
              _ctx.input = _ctx.$merge(_ctx.input, {
                schema: {
                  schedule: {
                    time: {
                      op: val
                    }
                  }
                }
              });
            })],
            teleport: false,
            options: [{
              title: 'Hours',
              value: 'nextXHours'
            }, {
              title: 'Minutes',
              value: 'nextXMinutes'
            }, {
              title: 'Seconds',
              value: 'nextXSeconds'
            }],
            class: "flex-1"
          }, null, 8, ["modelValue"])) : _createCommentVNode("", true), _ctx.input.schema.schedule.time?.op?.includes('last') ? (_openBlock(), _createBlock(_component_SelectMenu, {
            key: 4,
            modelValue: _ctx.input.schema.schedule.time.op,
            "onUpdate:modelValue": [_cache[17] || (_cache[17] = $event => _ctx.input.schema.schedule.time.op = $event), _cache[18] || (_cache[18] = val => {
              _ctx.input = _ctx.$merge(_ctx.input, {
                schema: {
                  schedule: {
                    time: {
                      op: val
                    }
                  }
                }
              });
            })],
            teleport: false,
            options: [{
              title: 'Hours',
              value: 'lastXHours'
            }, {
              title: 'Minutes',
              value: 'lastXMinutes'
            }, {
              title: 'Seconds',
              value: 'lastXSeconds'
            }],
            class: "flex-1"
          }, null, 8, ["modelValue"])) : _createCommentVNode("", true)])])) : _createCommentVNode("", true), key == 'links' ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_component_SelectMenu, {
            modelValue: _ctx.input.schema.links.op,
            "onUpdate:modelValue": [_cache[19] || (_cache[19] = $event => _ctx.input.schema.links.op = $event), _cache[20] || (_cache[20] = op => {
              _ctx.input.schema.links.op = op;
              _ctx.input = {
                ..._ctx.input,
                ...{
                  schema: _ctx.input.schema
                }
              };
            })],
            options: [{
              title: 'Set',
              value: 'set'
            }, {
              title: 'Add',
              value: 'add'
            }]
          }, null, 8, ["modelValue"]), _createVNode(_component_LinksBuilder, {
            modelValue: _ctx.input.schema.links.val,
            "onUpdate:modelValue": [_cache[21] || (_cache[21] = $event => _ctx.input.schema.links.val = $event), _cache[22] || (_cache[22] = val => {
              _ctx.input.schema.links.val = val;
              _ctx.input = {
                ..._ctx.input,
                ...{
                  schema: _ctx.input.schema
                }
              };
            })],
            size: _ctx.size,
            variant: _ctx.variant,
            color: __props.color
          }, null, 8, ["modelValue", "size", "variant", "color"]), _ctx.$store.getters.space?.ai_enabled && typeof _ctx.input.settings?.ai?.links != 'undefined' ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(_component_LabelHelper, null, {
            default: _withCtx(() => [_createVNode(_component_SparklesIcon, {
              size: "16"
            }), _cache[40] || (_cache[40] = _createTextVNode(" Let AI choose between these: "))]),
            _: 1
          }), _createVNode(_component_LinksBuilder, {
            modelValue: _ctx.input.settings.ai.links.links,
            "onUpdate:modelValue": [_cache[23] || (_cache[23] = $event => _ctx.input.settings.ai.links.links = $event), _cache[24] || (_cache[24] = val => {
              _ctx.input.settings.ai.links.links = val;
              _ctx.input = {
                ..._ctx.input,
                ...{
                  settings: _ctx.input.settings
                }
              };
            })],
            size: _ctx.size,
            variant: _ctx.variant,
            color: __props.color
          }, null, 8, ["modelValue", "size", "variant", "color"])])) : _createCommentVNode("", true)])) : _createCommentVNode("", true), key == 'description' ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode(_component_TextInput, {
            modelValue: _ctx.input.schema.description.content,
            "onUpdate:modelValue": [_cache[25] || (_cache[25] = $event => _ctx.input.schema.description.content = $event), _cache[26] || (_cache[26] = val => {
              _ctx.input.schema.description.content = val;
              _ctx.input = {
                ..._ctx.input,
                ...{
                  schema: _ctx.input.schema
                }
              };
            })],
            placeholder: "Write a description...",
            class: "w-full rounded-md p-2 text-sm ring-1 ring-inset ring-neutral-200 dark:ring-neutral-800"
          }, null, 8, ["modelValue"]), _ctx.$store.getters.space?.ai_enabled && typeof _ctx.input.settings?.ai?.description != 'undefined' ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_createVNode(_component_LabelHelper, null, {
            default: _withCtx(() => [_createVNode(_component_SparklesIcon, {
              size: "16"
            }), _cache[41] || (_cache[41] = _createTextVNode(" AI Prompt:"))]),
            _: 1
          }), _createVNode(_component_TextInput, {
            modelValue: _ctx.input.settings.ai.description.prompt,
            "onUpdate:modelValue": [_cache[27] || (_cache[27] = $event => _ctx.input.settings.ai.description.prompt = $event), _cache[28] || (_cache[28] = val => {
              _ctx.input.settings.ai.description.prompt = val;
              _ctx.input = {
                ..._ctx.input,
                ...{
                  settings: _ctx.input.settings
                }
              };
            })],
            placeholder: "Write a prompt to let AI generate a description...",
            class: "rounded-md p-2 text-sm ring-1 ring-inset ring-neutral-200 dark:ring-neutral-800"
          }, null, 8, ["modelValue"])])) : _createCommentVNode("", true)])) : _createCommentVNode("", true), key == 'priority' ? (_openBlock(), _createBlock(_component_TextInput, {
            key: 4,
            modelValue: _ctx.input.schema.priority.level,
            "onUpdate:modelValue": [_cache[29] || (_cache[29] = $event => _ctx.input.schema.priority.level = $event), _cache[30] || (_cache[30] = val => {
              _ctx.input.schema.priority.level = val;
              _ctx.input = {
                ..._ctx.input,
                ...{
                  schema: _ctx.input.schema
                }
              };
            })],
            validation: val => val.replace(/[^1234]/g, '')
          }, null, 8, ["modelValue", "validation"])) : _createCommentVNode("", true), key == 'procrastination' ? (_openBlock(), _createBlock(_component_TextInput, {
            key: 5,
            modelValue: _ctx.input.schema.procrastination.count,
            "onUpdate:modelValue": [_cache[31] || (_cache[31] = $event => _ctx.input.schema.procrastination.count = $event), _cache[32] || (_cache[32] = val => {
              _ctx.input.schema.procrastination.count = val;
              _ctx.input = {
                ..._ctx.input,
                ...{
                  schema: _ctx.input.schema
                }
              };
            })],
            validation: val => val.replace(/[^\d]/g, '')
          }, null, 8, ["modelValue", "validation"])) : _createCommentVNode("", true), key == 'time_tracking' ? (_openBlock(), _createElementBlock("div", _hoisted_11, _cache[42] || (_cache[42] = [_createElementVNode("span", {
            class: "mx-2 text-xs text-neutral-500 dark:text-neutral-600"
          }, " Start tracking immediately ", -1)]))) : _createCommentVNode("", true), key == 'custom_values' ? (_openBlock(), _createElementBlock("div", _hoisted_12, [_createElementVNode("div", _hoisted_13, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customFields, (customField, index) => {
            return _openBlock(), _createElementBlock("div", {
              key: index,
              class: "flex items-center gap-2"
            }, [(_openBlock(), _createBlock(_component_CustomFieldsDisplay, {
              key: customField.id,
              customFields: [customField],
              entry: {
                temp: true,
                custom_values: _ctx.input.schema.custom_values
              },
              "onUpdate:customValues": _cache[33] || (_cache[33] = val => {
                _ctx.input.schema.custom_values = val.map(cv => {
                  return {
                    custom_field_id: cv.custom_field_id,
                    value: cv.value,
                    source: cv.source
                  };
                });
                _ctx.input = {
                  ..._ctx.input,
                  ...{
                    schema: _ctx.input.schema
                  }
                };
              })
            }, null, 8, ["customFields", "entry"])), _ctx.$store.getters.space?.ai_enabled ? (_openBlock(), _createElementBlock("div", _hoisted_14, [_createElementVNode("input", {
              type: "checkbox",
              checked: _ctx.input.settings?.ai && _ctx.input.settings?.ai.custom_values && _ctx.input.settings?.ai.custom_values[customField.id],
              onChange: event => {
                if (event.target.checked) {
                  if (!_ctx.input.settings.ai.custom_values) {
                    _ctx.input.settings.ai.custom_values = {};
                  }
                  _ctx.input.settings.ai.custom_values[customField.id] = '';
                } else {
                  delete _ctx.input.settings.ai.custom_values[customField.id];
                  if (Object.keys(_ctx.input.settings.ai.custom_values).length === 0) {
                    delete _ctx.input.settings.ai.custom_values;
                  }
                }
                _ctx.input = {
                  ..._ctx.input,
                  ...{
                    settings: _ctx.input.settings
                  }
                };
              }
            }, null, 40, _hoisted_15), _cache[43] || (_cache[43] = _createTextVNode(" Ai ")), _ctx.input.settings?.ai && _ctx.input.settings?.ai.custom_values && typeof _ctx.input.settings?.ai.custom_values[customField.id] != 'undefined' ? (_openBlock(), _createBlock(_component_TextInput, {
              key: 0,
              modelValue: _ctx.input.settings.ai.custom_values[customField.id],
              "onUpdate:modelValue": [$event => _ctx.input.settings.ai.custom_values[customField.id] = $event, val => {
                _ctx.input.settings.ai.custom_values[customField.id] = val;
                _ctx.input = {
                  ..._ctx.input,
                  ...{
                    settings: _ctx.input.settings
                  }
                };
              }]
            }, null, 8, ["modelValue", "onUpdate:modelValue"])) : _createCommentVNode("", true)])) : _createCommentVNode("", true)]);
          }), 128))])])) : _createCommentVNode("", true), key == 'ai' && _ctx.$store.getters.space?.ai_enabled ? (_openBlock(), _createElementBlock("div", _hoisted_16, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.aiOptions, option => {
            return _openBlock(), _createBlock(_component_PSButton, {
              key: option.id,
              title: option.prompt,
              onClick: () => {
                if (!_ctx.input.schema.ai.prompts.includes(option.prompt)) {
                  _ctx.input.schema.ai.prompts.push(option.prompt);
                } else {
                  _ctx.input.schema.ai.prompts = _ctx.input.schema.ai.prompts.filter(p => p !== option.prompt);
                }
                _ctx.input = {
                  ..._ctx.input,
                  ...{
                    schema: _ctx.input.schema
                  }
                };
              }
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(option.title) + " ", 1), _ctx.input.schema.ai.prompts.includes(option.prompt) ? (_openBlock(), _createElementBlock("span", _hoisted_17, " Y ")) : (_openBlock(), _createElementBlock("span", _hoisted_18, "N"))]),
              _: 2
            }, 1032, ["title", "onClick"]);
          }), 128))])) : _createCommentVNode("", true)]);
        }), 128)), _createElementVNode("div", _hoisted_19, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableAddons.filter(a => !_ctx.input.schema[a]), (addon, key) => {
          return _openBlock(), _createBlock(_component_ButtonComponent, {
            id: 'input_builder_add_' + addon,
            key: key,
            onClick: $event => _ctx.addFieldToInput(addon),
            size: "xs",
            color: __props.color,
            title: 'Add a ' + addon.charAt(0).toUpperCase() + addon.slice(1) + ' to this Input'
          }, {
            default: _withCtx(() => [_createVNode(_component_PlusIcon, {
              size: "16"
            }), _createTextVNode(" " + _toDisplayString(addon.charAt(0).toUpperCase() + addon.slice(1).replace("_", " ")), 1)]),
            _: 2
          }, 1032, ["id", "onClick", "color", "title"]);
        }), 128))]), _createElementVNode("div", _hoisted_20, [__props.position != 'dialog' ? (_openBlock(), _createElementBlock("a", _hoisted_21, [_createVNode(_component_CircleHelpIcon, {
          size: "16",
          class: "mr-1 inline"
        }), _cache[44] || (_cache[44] = _createElementVNode("span", null, "Learn more about inputs", -1))])) : _createCommentVNode("", true), _createVNode(_component_ButtonComponent, {
          onClick: _cache[34] || (_cache[34] = $event => _ctx.input = null),
          size: _ctx.size,
          class: "ml-auto hover:text-red-500",
          color: "secondary",
          title: "Delete Input"
        }, {
          default: _withCtx(() => [_createVNode(_component_TrashIcon, {
            class: "h-4 w-4"
          }), _cache[45] || (_cache[45] = _createTextVNode()), _cache[46] || (_cache[46] = _createElementVNode("span", null, " Delete ", -1))]),
          _: 1
        }, 8, ["size"])])])]),
        _: 3
      })) : (_openBlock(), _createElementBlock("div", _hoisted_22, [_createVNode(_component_PSButton, {
        onClick: _cache[35] || (_cache[35] = $event => _ctx.input = _unref(addonBlueprint)('input', _ctx.entry, this)),
        title: "Add Input",
        shortcode: "i",
        class: _normalizeClass(_ctx.$style.createButton),
        "data-table-column-target": ""
      }, {
        default: _withCtx(() => _cache[47] || (_cache[47] = [_createElementVNode("span", null, " Add Input ", -1)])),
        _: 1
      }, 8, ["class"])]));
    };
  }
});