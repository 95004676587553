import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.flat-map.js";
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "flex flex-col items-center"
};
const _hoisted_2 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PSButton = _resolveComponent("PSButton");
  return $options.page ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_PSButton, {
    onClick: _cache[0] || (_cache[0] = $event => $data.showDebugInfo = !$data.showDebugInfo),
    class: "text-xs text-neutral-300 dark:text-neutral-600"
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString($data.showDebugInfo ? "Hide" : "Show") + " Debug Info ", 1)]),
    _: 1
  }), $data.showDebugInfo ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass(_ctx.$style.debugBar),
    onClick: _cache[2] || (_cache[2] = (...args) => $options.closeDebugInfo && $options.closeDebugInfo(...args))
  }, [_createTextVNode(_toDisplayString($options.entries.length) + " Entries. (" + _toDisplayString($options.formattedSize) + ") ", 1), _cache[3] || (_cache[3] = _createElementVNode("hr", null, null, -1)), _createTextVNode(" " + _toDisplayString(_ctx.$store.getters.time_trackings.length) + " tt / " + _toDisplayString(_ctx.$store.getters.routine_logs.length) + " rl / " + _toDisplayString($options.entries.flatMap(e => e.senses).length) + " s ", 1), _cache[4] || (_cache[4] = _createElementVNode("hr", null, null, -1)), _createVNode(_component_PSButton, {
    onClick: _cache[1] || (_cache[1] = _withModifiers($event => $data.showPage = !$data.showPage, ["stop"])),
    class: _normalizeClass(_ctx.$style.Button)
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString($data.showPage ? "Hide" : "Show") + " " + _toDisplayString($options.page.name), 1)]),
    _: 1
  }, 8, ["class"]), $data.showPage ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("pre", null, [_createElementVNode("code", null, _toDisplayString($options.page), 1)])])) : _createCommentVNode("", true)], 2)) : _createCommentVNode("", true)])) : _createCommentVNode("", true);
}