import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = {
  class: "flex h-full flex-col justify-between gap-4"
};
const _hoisted_2 = {
  class: "flex flex-col gap-2"
};
const _hoisted_3 = {
  class: "flex items-center justify-between pb-2"
};
const _hoisted_4 = {
  class: "font-semibold text-neutral-900 dark:text-neutral-400"
};
const _hoisted_5 = {
  class: "text-sm text-neutral-500"
};
const _hoisted_6 = {
  key: 0,
  class: "relative flex max-h-96 flex-col gap-4 sm:col-span-3 sm:pl-4"
};
const _hoisted_7 = {
  key: "templateEntry"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_IconHelper = _resolveComponent("IconHelper");
  const _component_ButtonComponent = _resolveComponent("ButtonComponent");
  const _component_EntryDisplay = _resolveComponent("EntryDisplay");
  const _component_OutputDisplay = _resolveComponent("OutputDisplay");
  const _component_InputDisplay = _resolveComponent("InputDisplay");
  const _component_TransitionGroupHelper = _resolveComponent("TransitionGroupHelper");
  return _ctx.size == 'xs' ? (_openBlock(), _createBlock(_component_ButtonComponent, {
    key: 0,
    size: "sm",
    color: "secondary",
    title: $options.template.description
  }, {
    default: _withCtx(() => [_createElementVNode("div", null, [_createVNode(_component_IconHelper, {
      name: $options.template.icon,
      size: "16",
      class: _normalizeClass([`text-${$options.template.data.color}-500`, "inline"])
    }, null, 8, ["name", "class"]), _createTextVNode(" " + _toDisplayString($options.template.name), 1)])]),
    _: 1
  }, 8, ["title"])) : (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: _normalizeClass([{
      'sm:grid sm:grid-cols-4 sm:divide-x dark:divide-neutral-900': $props.showPreview
    }, "group relative flex h-full flex-col gap-4"]),
    onMouseenter: _cache[0] || (_cache[0] = (...args) => $options.onMouseEnter && $options.onMouseEnter(...args)),
    onMouseleave: _cache[1] || (_cache[1] = (...args) => $options.onMouseLeave && $options.onMouseLeave(...args)),
    style: _normalizeStyle({
      '--ps-template-color': _ctx.$colors[$options.template.data.color || 'neutral'][500],
      '--ps-template-bg-color': _ctx.$colors[$options.template.data.color || 'neutral'][50],
      '--ps-template-bg-color-dark': _ctx.$colors[$options.template.data.color || 'neutral'][950]
    })
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [$options.template.icon ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass([_ctx.$style.templateIcon, "self-start rounded-lg p-2"])
  }, [_createVNode(_component_IconHelper, {
    name: $options.template.icon,
    size: "24"
  }, null, 8, ["name"])], 2)) : _createCommentVNode("", true), _renderSlot(_ctx.$slots, "top")]), _createElementVNode("a", _hoisted_4, _toDisplayString($options.template.name), 1), _createElementVNode("p", _hoisted_5, _toDisplayString($options.template.description), 1)]), _createElementVNode("div", {
    class: _normalizeClass([{
      'sm:justify-end': $props.showPreview
    }, "flex"])
  }, [_createElementVNode("ul", {
    class: _normalizeClass(["flex flex-wrap gap-1.5 font-mono text-xs", _ctx.$style.templateTags])
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.template.tags?.filter(tag => !tag.includes('__')), tag => {
    return _openBlock(), _createElementBlock("li", {
      key: tag
    }, " #" + _toDisplayString(tag), 1);
  }), 128))], 2)], 2)]), $props.showPreview ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_cache[2] || (_cache[2] = _createElementVNode("span", {
    class: "absolute right-0 top-0.5 z-10 rounded bg-neutral-100 px-2 py-1 text-xs dark:bg-neutral-900"
  }, " Preview ", -1)), _createVNode(_component_TransitionGroupHelper, {
    enter: {
      translateY: [20, 0]
    }
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_7, [_createVNode(_component_EntryDisplay, {
      ref: "entry_display",
      modelValue: $options.templateEntry,
      display: {
        output: false
      },
      toggleRenderOutput: () => $data.showChildren = !$data.showChildren,
      "data-delay": "0",
      disableViewportChecking: true,
      disableInitialization: true,
      editable: false,
      disabled: true,
      position: "template"
    }, null, 8, ["modelValue", "toggleRenderOutput"])]), $data.showChildren && $options.templateChildren.length ? (_openBlock(), _createBlock(_component_OutputDisplay, {
      key: 0,
      modelValue: $options.resolvedTemplate.data.output,
      entry: $options.templateEntry,
      editable: false,
      disabled: true,
      entriesDisabled: true,
      position: "template"
    }, null, 8, ["modelValue", "entry"])) : _createCommentVNode("", true), $data.showChildren && !$options.templateChildren.length ? (_openBlock(), _createBlock(_component_InputDisplay, {
      key: 1,
      modelValue: $options.resolvedTemplate.data.input,
      entry: $options.templateEntry,
      editable: false,
      disabled: true,
      position: "template"
    }, null, 8, ["modelValue", "entry"])) : _createCommentVNode("", true)]),
    _: 1
  })])) : _createCommentVNode("", true)], 38));
}