import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.$style.VerticalListItem, $props.active ? _ctx.$style.Active : ''])
  }, [_ctx.$slots.before ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass(_ctx.$style.Before)
  }, [_renderSlot(_ctx.$slots, "before")], 2)) : _createCommentVNode("", true), _createElementVNode("div", {
    class: _normalizeClass(_ctx.$style.Content)
  }, [_renderSlot(_ctx.$slots, "default")], 2), _ctx.$slots.after ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: _normalizeClass(_ctx.$style.After)
  }, [_renderSlot(_ctx.$slots, "after")], 2)) : _createCommentVNode("", true)], 2);
}