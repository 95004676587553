import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, Transition as _Transition } from "vue";
const _hoisted_1 = {
  class: "flex items-center"
};
const _hoisted_2 = {
  class: "ml-3 block truncate capitalize"
};
const _hoisted_3 = {
  class: "pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
};
const _hoisted_4 = {
  class: "flex items-center"
};
const _hoisted_5 = {
  class: "flex items-center"
};
const __default__ = {
  props: {
    modelValue: String,
    hideLabel: Boolean,
    label: {
      type: String,
      default: "Color"
    }
  },
  computed: {
    color: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update", value);
      }
    }
  }
};
export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'ColorPicker',
  setup(__props) {
    const colors = ["neutral", "red", "orange", "amber", "yellow", "lime", "green", "emerald", "teal", "cyan", "sky", "blue", "indigo", "violet", "purple", "fuchsia", "pink", "rose"];
    return (_ctx, _cache) => {
      const _component_ListboxLabel = _resolveComponent("ListboxLabel");
      const _component_ChevronsUpDownIcon = _resolveComponent("ChevronsUpDownIcon");
      const _component_ListboxButton = _resolveComponent("ListboxButton");
      const _component_ListboxOption = _resolveComponent("ListboxOption");
      const _component_CheckIcon = _resolveComponent("CheckIcon");
      const _component_ListboxOptions = _resolveComponent("ListboxOptions");
      const _component_Listbox = _resolveComponent("Listbox");
      return _openBlock(), _createBlock(_component_Listbox, {
        as: "div",
        modelValue: _ctx.color,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.color = $event)
      }, {
        default: _withCtx(() => [!__props.hideLabel ? (_openBlock(), _createBlock(_component_ListboxLabel, {
          key: 0,
          class: "block text-sm font-medium leading-6 text-neutral-900 dark:text-neutral-100"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(__props.label), 1)]),
          _: 1
        })) : _createCommentVNode("", true), _createElementVNode("div", {
          class: _normalizeClass(["relative", {
            'mt-2': !__props.hideLabel
          }])
        }, [_createVNode(_component_ListboxButton, {
          class: "relative w-full cursor-default rounded-md bg-white py-0.5 pl-3 pr-10 text-left text-neutral-900 shadow-sm ring-1 ring-inset ring-neutral-300 focus:outline-none focus:ring-2 focus:ring-neutral-600 sm:text-sm sm:leading-6 dark:bg-black dark:text-neutral-100 dark:ring-neutral-700 dark:hover:bg-neutral-900",
          style: _normalizeStyle({
            '--ps-color-picker-bg-color': _ctx.color ? _ctx.$colors[_ctx.color][500] : null
          })
        }, {
          default: _withCtx(() => [_createElementVNode("span", _hoisted_1, [_createElementVNode("span", {
            class: _normalizeClass(_ctx.$style.colorDot),
            "aria-hidden": "true"
          }, null, 2), _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.color || "No Color"), 1)]), _createElementVNode("span", _hoisted_3, [_createVNode(_component_ChevronsUpDownIcon, {
            size: "20",
            class: "text-neutral-400",
            "aria-hidden": "true"
          })])]),
          _: 1
        }, 8, ["style"]), _createVNode(_Transition, {
          "leave-active-class": "transition ease-in duration-100",
          "leave-from-class": "opacity-100",
          "leave-to-class": "opacity-0"
        }, {
          default: _withCtx(() => [_createVNode(_component_ListboxOptions, {
            class: "absolute z-10 mt-1 max-h-60 w-full min-w-[10rem] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm dark:bg-black"
          }, {
            default: _withCtx(() => [_ctx.color ? (_openBlock(), _createBlock(_component_ListboxOption, {
              key: 0,
              as: "template",
              value: null
            }, {
              default: _withCtx(({
                active,
                selected
              }) => [_createElementVNode("li", {
                class: _normalizeClass([active ? 'bg-neutral-600 text-white dark:text-neutral-100 ' : 'text-neutral-900 dark:text-neutral-100', 'relative cursor-default select-none py-2 pl-3 pr-9'])
              }, [_createElementVNode("div", _hoisted_4, [_cache[1] || (_cache[1] = _createElementVNode("span", {
                class: _normalizeClass(['bg-neutral-200 dark:bg-neutral-800', 'inline-block h-2 w-2 flex-shrink-0 rounded-full']),
                "aria-hidden": "true"
              }, null, -1)), _createElementVNode("span", {
                class: _normalizeClass([selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate capitalize'])
              }, " No color ", 2)])], 2)]),
              _: 1
            })) : _createCommentVNode("", true), (_openBlock(), _createElementBlock(_Fragment, null, _renderList(colors, color => {
              return _createVNode(_component_ListboxOption, {
                as: "template",
                key: color,
                value: color
              }, {
                default: _withCtx(({
                  active,
                  selected
                }) => [_createElementVNode("li", {
                  class: _normalizeClass([active ? 'bg-neutral-600 text-white dark:text-neutral-100' : 'text-neutral-900 dark:text-neutral-100', 'relative cursor-default select-none py-2 pl-3 pr-9']),
                  style: _normalizeStyle({
                    '--ps-color-picker-bg-color': _ctx.$colors[color][500]
                  })
                }, [_createElementVNode("div", _hoisted_5, [_createElementVNode("span", {
                  class: _normalizeClass(_ctx.$style.colorDot),
                  "aria-hidden": "true"
                }, null, 2), _createElementVNode("span", {
                  class: _normalizeClass([selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate capitalize'])
                }, _toDisplayString(color), 3)]), selected ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: _normalizeClass([active ? 'text-white' : 'text-neutral-600', 'absolute inset-y-0 right-0 flex items-center pr-4'])
                }, [_createVNode(_component_CheckIcon, {
                  class: "h-5 w-5",
                  "aria-hidden": "true"
                })], 2)) : _createCommentVNode("", true)], 6)]),
                _: 2
              }, 1032, ["value"]);
            }), 64))]),
            _: 1
          })]),
          _: 1
        })], 2)]),
        _: 1
      }, 8, ["modelValue"]);
    };
  }
});