import { defineAsyncComponent } from "vue";
import { Filtering } from "../mixins/Filtering";
import { Searching } from "../mixins/Searching";
import { Sorting } from "../mixins/Sorting";
import { Statusing } from "../mixins/Statusing";
export default {
  // provide: {
  //   intersectionListTransitionsEnabled: false, // Disable transitions for search results
  // },
  props: {
    entry: Object,
    query: String,
    filtersOnEmpty: Array,
    filterSearchResults: {
      type: Function,
      default: () => true
    },
    displayFields: {
      type: Object,
      default: () => ({
        procrastination: false,
        name: true,
        status: true,
        links: false,
        description: false,
        schedule: false,
        output: false,
        input: false,
        senses: false,
        settings: false,
        routine: false,
        time_trackings: false,
        custom_fields: false
      })
    }
  },
  components: {
    OutputDisplay: defineAsyncComponent(() => import("@/components/output/OutputDisplay.vue"))
  },
  mixins: [Filtering, Sorting, Searching, Statusing],
  watch: {
    query: function () {
      this.searchEntriesByName(this.query);
    },
    "searchResults.length": function () {
      this.searchFocusIndex = 0;
    },
    searchResults: {
      handler: function () {
        this.$emit("onSearchResultsChange", this.searchResults);
      },
      deep: true
    },
    searchFocusIndex: function (n, o) {
      /**
       * We want to bring the new focused
       */
      this.$nextTick(() => {
        const element = this.$refs.output?.$refs["entry_" + n];
        if (element && element[0]) {
          element[0].scrollIntoView({
            /**
             * If the difference between the old and new index is 1, scroll smoothly.
             * Otherwise, we move fast fro top to bottom or vice versa and just jump to the element.
             */
            behavior: Math.abs(n - o) == 1 ? "smooth" : "auto",
            block: "center",
            inline: "center"
          });
        }
      });
    }
  },
  mounted() {
    document.addEventListener("keydown", this.onKeyDown);
    document.addEventListener("keyup", this.onKeyUp);
    // console.log("SearchResults::mounted", this.searchEntriesByName(this.query));
    if (this.query.length) this.searchEntriesByName(this.query);
  },
  unmounted() {
    document.removeEventListener("keydown", this.onKeyDown);
    document.removeEventListener("keyup", this.onKeyUp);
  },
  data() {
    return {
      searchFocusIndex: 0,
      keyInterval: null,
      keyIsDown: false
    };
  },
  computed: {
    outputComputed() {
      if (!this.query && this.filtersOnEmpty) {
        return {
          id: this.$nanoid(),
          filters: this.filtersOnEmpty,
          sorting: [{
            field: "updated_at",
            dir: -1
          }]
        };
      }
      return {
        id: this.$nanoid(),
        filters: this.searchOutput,
        sorting: [{
          field: "updated_at",
          dir: -1
        }]
      };
    }
  },
  methods: {
    onKeyDown(e) {
      if (e.key === "Tab") {
        e.preventDefault();
        e.stopPropagation();
      }
      if (e.key === "Enter") {
        if (this.searchFocusIndex >= 0) {
          e.preventDefault();
          e.stopPropagation();
          this.$emit("onEntrySelect", this.$refs.output.entries[this.searchFocusIndex]);
        }
      }
      if (e.key === "ArrowDown" || e.key === "ArrowUp") {
        e.preventDefault();
        e.stopPropagation();
        if (!this.keyIsDown) {
          this.keyIsDown = true;
          // Clear any existing interval
          if (this.keyInterval) {
            clearInterval(this.keyInterval);
          }
          // Set a new interval
          this.keyInterval = setInterval(() => {
            if (e.key === "ArrowDown") {
              this.incrementFocusIndex();
            } else if (e.key === "ArrowUp") {
              this.decrementFocusIndex();
            }
          }, 200); // Change the focus index every 200ms
        }
      }
    },
    incrementFocusIndex() {
      // If the search focus index is less than the length of the search results
      if (this.searchFocusIndex < this.$refs.output.entries.length - 1) {
        // Increment the search focus index
        this.searchFocusIndex++;
      } else {
        // Otherwise set the search focus index to 0
        this.searchFocusIndex = 0;
      }
    },
    decrementFocusIndex() {
      // If the search focus index is greater than 0
      if (this.searchFocusIndex > 0 ? -1 : 0) {
        // Decrement the search focus index
        this.searchFocusIndex--;
      } else {
        // Otherwise set the search focus index to the length of the search results
        this.searchFocusIndex = this.$refs.output.entries.length - 1;
      }
    },
    onKeyUp(e) {
      switch (e.key) {
        case "Tab":
        case "ArrowDown":
          e.preventDefault();
          e.stopPropagation();
          this.keyIsDown = false;
          clearInterval(this.keyInterval);
          this.keyInterval = null;
          this.incrementFocusIndex();
          break;
        case "ArrowUp":
          e.preventDefault();
          e.stopPropagation();
          this.keyIsDown = false;
          clearInterval(this.keyInterval);
          this.keyInterval = null;
          this.decrementFocusIndex();
          break;
      }
    }
  }
};