import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.find.js";
import { Filtering } from "../mixins/Filtering";
import { Schema } from "../mixins/Schema";
export default {
  props: {
    modelValue: Object
  },
  data() {
    return {
      breadcrumbs: []
    };
  },
  watch: {
    modelValue(newVal) {
      this.breadcrumbs = [];
      this.buildBreadcrumbs(newVal);
    }
    // "$store.getters.entries": {
    //   handler() {
    //     this.$onIdle(() => {
    //       this.breadcrumbs = [];
    //       this.buildBreadcrumbs(this.modelValue);
    //     });
    //   },
    //   deep: true,
    // },
  },
  mounted() {
    // this.$onIdle(() => {
    this.buildBreadcrumbs(this.modelValue);
    // });
  },
  methods: {
    buildBreadcrumbs(entry) {
      if (!this.entry || this.breadcrumbs.findIndex(e => e.id == entry.id) != -1) {
        return;
      }
      this.breadcrumbs.unshift(entry);
      if (entry.links.length /*&& entry.links.length === 1*/) {
        const next = this.$store.getters.entries.find(e => e.id == entry.links[0].id);
        if (next) {
          this.buildBreadcrumbs(next);
        }
      }
    }
  },
  mixins: [Schema, Filtering],
  components: {},
  computed: {
    entry() {
      return this.modelValue;
    }
  }
};