import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  class: "flex items-center gap-1"
};
const _hoisted_2 = {
  class: "flex items-center gap-1"
};
const _hoisted_3 = ["data-procrastination-count"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TitleHelper = _resolveComponent("TitleHelper");
  const _component_CalendarX2Icon = _resolveComponent("CalendarX2Icon");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.procrastinationDisplay)
  }, [3 > 4 && $props.internalSettings?.display?.settings ? (_openBlock(), _createBlock(_component_TitleHelper, {
    key: 0
  }, {
    title: _withCtx(() => [_createTextVNode(" Procrastinated " + _toDisplayString($options.procrastination?.count) + " times ", 1)]),
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(32, i => {
      return _createElementVNode("div", {
        key: i,
        class: _normalizeClass({
          [_ctx.$style.procrastinationDot]: true,
          [_ctx.$style.procrastinationDotRed]: i <= $options.procrastination?.count
        })
      }, null, 2);
    }), 64))])]),
    _: 1
  })) : $options.procrastination ? (_openBlock(), _createBlock(_component_TitleHelper, {
    key: 1,
    class: _normalizeClass([{
      '!text-xs': $props.position == 'calendar'
    }, "ps_procrastination_display flex max-w-fit cursor-default items-center rounded bg-red-50 px-1 py-0.5 text-xs text-red-500 dark:bg-red-950 dark:text-red-500"])
  }, {
    title: _withCtx(() => [_createTextVNode(" Procrastinated " + _toDisplayString($options.procrastination?.count) + " times ", 1)]),
    default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_CalendarX2Icon, {
      size: "16",
      strokeWidth: "1.5"
    }), _createElementVNode("span", {
      "data-procrastination-count": $options.procrastination?.count
    }, _toDisplayString($options.procrastination?.count), 9, _hoisted_3)])]),
    _: 1
  }, 8, ["class"])) : _createCommentVNode("", true)], 2);
}