import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.find.js";
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "container mx-auto flex items-center justify-between gap-x-1 px-4 py-1"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BreadcrumbsDisplay = _resolveComponent("BreadcrumbsDisplay");
  const _component_TourHelper = _resolveComponent("TourHelper");
  const _component_AnchorizedOutputDisplay = _resolveComponent("AnchorizedOutputDisplay");
  const _component_PageSettings = _resolveComponent("PageSettings");
  const _component_HeaderTimeTrackingComponent = _resolveComponent("HeaderTimeTrackingComponent");
  return _openBlock(), _createElementBlock("div", {
    id: "application-header",
    class: _normalizeClass(_ctx.$style.applicationHeader)
  }, [_createElementVNode("div", _hoisted_1, [_ctx.$route.meta.settings?.display?.header?.breadcrumbs ? (_openBlock(), _createBlock(_component_BreadcrumbsDisplay, {
    key: 0,
    modelValue: $options.entry,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.entry = $event)
  }, null, 8, ["modelValue"])) : _createCommentVNode("", true), _cache[1] || (_cache[1] = _createElementVNode("div", {
    class: "flex-1"
  }, null, -1)), _ctx.$store.getters.tours?.find(t => t.hook == 'application_header') ? (_openBlock(), _createBlock(_component_TourHelper, {
    key: 1,
    hook: "application_header"
  })) : _createCommentVNode("", true), _createElementVNode("div", {
    class: _normalizeClass(_ctx.$style.xScroll)
  }, [_createVNode(_component_AnchorizedOutputDisplay, {
    anchor: "main",
    class: _normalizeClass([_ctx.$style.anchorizedOutputDisplay, "hidden sm:block"])
  }, null, 8, ["class"])], 2), $options.entry ? (_openBlock(), _createBlock(_component_PageSettings, {
    key: 2,
    entry: $options.entry
  }, null, 8, ["entry"])) : _createCommentVNode("", true), $options.user && $options.isShowTimeTrackings ? (_openBlock(), _createBlock(_component_HeaderTimeTrackingComponent, {
    key: 3
  })) : _createCommentVNode("", true)])], 2);
}