import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.for-each.js";
import "core-js/modules/es.iterator.map.js";
export default {
  props: {
    output: Object,
    entry: Object,
    computedDisplay: Object
  },
  computed: {
    isRenderLeftColumn() {
      return this.computedDisplay.output && /*!["gallery"].includes(this.renderType) && */
      this.entry.output || this.computedDisplay.status && this.entry.status /*this.renderType != "tiles" &&*/ || this.computedDisplay.senses && this.entry.senses?.length;
    }
  },
  methods: {
    restoreEntry(entry) {
      entry.deleted_at = null;
      this.$store.dispatch("push", {
        event: "entry_update",
        params: {
          entry: entry
        },
        entry: entry
      });
      this.$store.getters.entries.filter(e => e.links.map(l => l.id).includes(entry.id)).forEach(e => {
        e.links[e.links.map(l => l.id).findIndex(l => l == entry.id)].deleted_at = null;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: {
            entry: e
          },
          entry: e,
          hide_in_undo: true
        });
      });
    }
  }
};