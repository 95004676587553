import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.find.js";
import "core-js/modules/es.iterator.for-each.js";
import { Filtering } from "../mixins/Filtering";
import { Sorting } from "../mixins/Sorting";
import { Statusing } from "../mixins/Statusing";
export default {
  mixins: [Filtering, Sorting, Statusing],
  props: {
    entry: Object
  },
  data() {
    return {};
  },
  computed: {
    statuses() {
      return this.$store.getters.statuses;
    },
    entries() {
      return this.filterEntries(this.output.filters);
      // .sort(this.sortEntries(this.output.sorting))
      // .sort((a, b) => {
      //   if (this.output.custom_sorting) {
      //     const indexA = this.output.custom_sorting.indexOf(a.id);
      //     const indexB = this.output.custom_sorting.indexOf(b.id);

      //     // If neither item is in custom_sorting, maintain their relative order
      //     if (indexA === -1 && indexB === -1) return 0;

      //     // If only A is not in custom_sorting, move it to the end
      //     if (indexA === -1) return 1;

      //     // If only B is not in custom_sorting, move it to the end
      //     if (indexB === -1) return -1;

      //     // Both items are in custom_sorting, sort by their position
      //     return indexA - indexB;
      //   }
      //   return 0;
      // });
    },
    input() {
      return this.entry.input;
    },
    output() {
      return this.entry.output;
    }
  },
  methods: {
    apply(transform) {
      if (transform === "todo-list") {
        const input = this.entry.input;
        if (input) {
          const status = this.statuses.find(s => s.type === "open");
          if (status) {
            this.input.schema = {
              ...this.input.schema,
              status: status.id
            };
            this.$store.dispatch("push", {
              event: "entry_update",
              params: {
                entry: this.entry
              },
              entry: this.entry
            });
          }
        }
        const status = this.statuses.find(s => s.type === "open");
        if (status) {
          this.entries.forEach(entry => {
            this.setStatus(entry, status);
            this.$store.dispatch("push", {
              event: "entry_update",
              params: {
                entry: entry
              },
              entry: entry
            });
          });
        }
      }
    }
  }
};