import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = ["onClick"];
const _hoisted_2 = {
  key: 0,
  class: "grayscale"
};
const _hoisted_3 = {
  key: 0,
  class: "flex h-6 w-6 items-center justify-center text-xs font-bold"
};
const _hoisted_4 = {
  key: 1,
  class: "grayscale",
  "data-state": "unknown"
};
const _hoisted_5 = {
  key: 0,
  class: "flex h-6 w-6 items-center justify-center text-xs font-bold"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CircleHelpIcon = _resolveComponent("CircleHelpIcon");
  const _component_CircleCheckIcon = _resolveComponent("CircleCheckIcon");
  const _component_XCircleIcon = _resolveComponent("XCircleIcon");
  return _openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.slots, (slot, index) => {
    return _openBlock(), _createElementBlock("button", {
      key: slot.log,
      onClick: () => {
        _ctx.logASpan({
          entry: $props.entry,
          span_at: slot.date.format('YYYY-MM-DD'),
          frequency: $props.entry.routine.frequency
        });
        if (!$props.entry.temp) _ctx.$store.dispatch('push', {
          event: 'routine_update',
          params: {
            routine: _ctx.routine
          },
          entry: $props.entry
        });
      },
      class: _normalizeClass({
        [_ctx.$style.slot]: true,
        [_ctx.$style.slotCurrent]: $options.isCurrentSlot(slot.date),
        [_ctx.$style.slotInFuture]: slot.date.isAfter(_ctx.$moment())
      }),
      style: _normalizeStyle({
        '--ps-column-span': slot.gridSpan,
        '--ps-routine-display-positive-color': _ctx.$colors[$props.entry.routine.settings?.colors?.positive || 'neutral'][500],
        '--ps-routine-display-positive-color-hover': _ctx.$colors[$props.entry.routine.settings?.colors?.positive || 'neutral'][600],
        '--ps-routine-display-negative-color': _ctx.$colors[$props.entry.routine.settings?.colors?.negative || 'neutral'][500],
        '--ps-routine-display-negative-color-hover': _ctx.$colors[$props.entry.routine.settings?.colors?.negative || 'neutral'][600]
      })
    }, [typeof slot.log == 'undefined' ? (_openBlock(), _createElementBlock("span", _hoisted_2, [index == $options.slots.length - 1 && _ctx.routine.value > 0 ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.routine.value), 1)) : (_openBlock(), _createBlock(_component_CircleHelpIcon, {
      key: 1,
      size: "24"
    }))])) : slot.log.completed === null ? (_openBlock(), _createElementBlock("span", _hoisted_4, [$options.isCurrentSlot(slot.date) && _ctx.routine.value > 0 ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.routine.value), 1)) : (_openBlock(), _createBlock(_component_CircleHelpIcon, {
      key: 1,
      size: "24"
    }))])) : slot?.log?.completed ? (_openBlock(), _createElementBlock("span", {
      key: 2,
      class: _normalizeClass(`${typeof slot.log == 'undefined' || slot.log.completed === null ? 'grayscale' : ''}`),
      "data-state": "completed"
    }, [_createVNode(_component_CircleCheckIcon, {
      size: "24"
    })], 2)) : (_openBlock(), _createElementBlock("span", {
      key: 3,
      class: _normalizeClass(`${typeof slot.log == 'undefined' || slot.log.completed === null ? 'grayscale' : ''}`),
      "data-state": "uncompleted"
    }, [_createVNode(_component_XCircleIcon, {
      size: "24"
    })], 2))], 14, _hoisted_1);
  }), 128);
}