export const Tracking = {
    computed: {

        entries() {
            return this.$store.getters.entries;
        },
        activelyTimeTrackedEntry() {
            return this.$store.getters.time_trackings.find(
                (tt) =>
                    tt.user_id == this.user.id &&
                    tt.end_at == null
            );
        },
        time_trackings() {
            return this.$store.getters.time_trackings;
        },
        activeTimeTrackingByUser() {
            return this.time_trackings.find((tt) => tt.user_id == this.user.id && tt.end_at == null)
        }
    },
    methods: {
        getTotalDurationOfEntries(entries, date) {
            if (!date) date = this.$moment.utc().format("YYYY-MM-DD");
            return this.$moment
                .utc(
                    this.time_trackings
                        .filter(
                            (tt) =>
                                tt.end_at != null &&
                                this.$moment.utc(tt.start_at).format("YYYY-MM-DD") ==
                                date &&
                                tt.user_id == this.user.id,
                        )
                        .map((tt) => {
                            return (
                                this.$moment.utc(tt.end_at).unix() -
                                this.$moment.utc(tt.start_at).unix()
                            );
                        })
                        .reduce((a, b) => a + b, 0) * 1000,
                )
                .format("HH:mm:ss");
        },
        getTimeTrackingsInHour(time_trackings, hour, date = null) {
            if (date == null)
                date = this.$moment().format("YYYY-MM-DD");
            return time_trackings.filter(tt => {

                const hourStart = this.$moment(date).hour(hour).minute(0).second(0);
                const hourEnd = this.$moment(date).hour(hour).minute(59).second(59);

                const startHour = this.$moment.utc(tt.start_at).local();
                const endHour = this.$moment.utc(tt.end_at ? tt.end_at : this.$store.getters.clock).local();
                return startHour <= hourStart && endHour >= hourEnd ||
                    startHour >= hourStart && endHour <= hourEnd ||
                    startHour <= hourEnd && endHour >= hourEnd;
            });
        },
        getTimeTrackingsInBoundaries(time_trackings, from, to) {
            return time_trackings.filter(tt => {

                const boundaryStart = this.$moment(from);
                const boundaryEnd = this.$moment(to);

                const startTimeTracking = this.$moment.utc(tt.start_at).local();
                const endTimeTracking = this.$moment.utc(tt.end_at ? tt.end_at : this.$store.getters.clock).local();
                // return startTimeTracking <= boundaryStart && endTimeTracking >= boundaryEnd ||
                //     startTimeTracking >= boundaryStart && endTimeTracking <= boundaryEnd ||
                //     startTimeTracking >= endTimeTracking && endTimeTracking <= boundaryEnd ||
                //     startTimeTracking >= boundaryStart && endTimeTracking >= boundaryStart;

                return startTimeTracking <= boundaryEnd && endTimeTracking >= boundaryStart;
            });
        },
        getActiveHoursWithBoundaries(time_trackings) {
            if (time_trackings.length === 0) return { startHour: 6, endHour: 22 };
            const startHour = this.$moment.utc(time_trackings[0].start_at).local().hour();



            const endHour = this.$moment.utc(time_trackings[time_trackings.length - 1].end_at ? time_trackings[time_trackings.length - 1].end_at : this.$store.getters.clock).local().hour();
            return { startHour, endHour };
        },
        getStartHourWithBoundaries(time_trackings) {
            return this.getActiveHoursWithBoundaries(time_trackings).startHour;
        },
        getEndHourWithBoundaries(time_trackings) {
            return this.getActiveHoursWithBoundaries(time_trackings).endHour;
        },
        getDurationOfTimeTracking(time_tracking) {
            if (!time_tracking) return;
            const start = this.$moment.utc(time_tracking.start_at);
            const end = this.$moment.utc(time_tracking.end_at ? time_tracking.end_at : this.$store.getters.clock);
            return this.$moment.duration(Math.max(0, end.diff(start, "seconds")), "seconds");
        }
    },
};
