import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.find.js";
import "core-js/modules/es.iterator.for-each.js";
import "core-js/modules/es.iterator.map.js";
import { getDateSpan } from "@/datesHelper";
import moment from "moment";
export default {
  data() {
    return {
      date: moment().startOf("month")
    };
  },
  watch: {
    date() {
      this.pullDataBySpan(this.date);
      this.scrollDaysInMonthToRight();
    }
  },
  computed: {
    entries() {
      return this.$store.getters.entries.filter(entry => entry.routine);
    },
    daysInMonth() {
      return this.date.daysInMonth();
    }
  },
  mounted() {
    this.pullDataBySpan(this.date);
    this.scrollDaysInMonthToRight();
  },
  methods: {
    pullDataBySpan(date) {
      if (!date) date = this.$moment.utc().format("YYYY-MM-DD");
      const currentDate = this.$moment(date);
      const dates = [
      // Two months ago
      currentDate.clone().subtract(2, "months").startOf("month").format("YYYY-MM-DD"), currentDate.clone().endOf("month").format("YYYY-MM-DD")];

      // console.log("dates", dates);

      // this.dataReady = false;
      this.$store.dispatch("pull", {
        filters: [{
          key: ["routine", "id"],
          op: "any",
          val: this.entries.map(entry => entry.routine.id)
        }, {
          key: ["routine_logs", "span_at"],
          op: "ra",
          val: dates
        }],
        data: "routine_logs",
        cb: () => {
          this.dataReady = true;
        }
      });
    },
    getSpansOfEntry(entry) {
      const frequency = entry.routine.frequency;
      const target = entry.routine.target || 1; // Default to 1 if no target is set
      const spans = [];
      let currentDate = moment().startOf("month");
      const endDate = moment().endOf("month");
      while (currentDate.isBefore(endDate)) {
        const spanStart = getDateSpan(frequency, currentDate);
        spans.push(spanStart);
        switch (frequency) {
          case "daily":
            currentDate.add(target, "days");
            break;
          case "weekly":
            currentDate.add(target, "weeks");
            break;
          case "monthly":
            currentDate.add(target, "months");
            break;
          default:
            currentDate.add(1, "days");
          // Fallback to daily
        }
      }
      return spans.map(span => moment(span).daysInMonth());
    },
    scrollDaysInMonthToRight() {
      this.$nextTick(() => {
        const daysInMonthElement = this.$refs.daysInMonth;
        if (daysInMonthElement) {
          // Get the current day of the month
          const currentDay = moment().date();
          // Calculate cell width (including gap)
          const cellWidth = daysInMonthElement.scrollWidth / this.daysInMonth;
          // Calculate position to center current day
          const scrollPosition = (currentDay - 1) * cellWidth - (daysInMonthElement.clientWidth - cellWidth) / 2;
          daysInMonthElement.scrollLeft = Math.max(0, scrollPosition);
        }
      });
    },
    getCompletedDays(entry) {
      const logs = this.$store.getters.routine_logs.filter(log => log.routine_id === entry.routine.id) || [];
      const frequency = entry.routine.frequency;

      // Declare variables outside case blocks
      const completedLogs = logs.filter(log => log.completed && moment(log.span_at).isSame(this.date, "month"));
      const slots = frequency === "weekly" ? this.getWeeklySlots() : [];
      let completedCount = 0;
      let log;

      // Filter logs based on frequency
      switch (frequency) {
        case "weekly":
          // For weekly routines, we need to match the visual representation

          // Count completed slots
          slots.forEach(slot => {
            log = this.getLogBySpan({
              entry: entry,
              span_at: slot.date.format("YYYY-MM-DD")
            });
            if (log && log.completed) {
              completedCount++;
            }
          });

          // Debug logging
          // console.log("Entry:", entry.name);
          // console.log("Month:", this.date.format("YYYY-MM"));
          // console.log("Weekly slots:", slots.length);
          // console.log("Completed slots:", completedCount);

          return completedCount;
        case "monthly":
          // For monthly routines, check if there's a completed log for this month
          return logs.filter(log => log.completed && moment(log.span_at).format("YYYY-MM") === this.date.format("YYYY-MM")).length;
        case "daily":
        default:
          // For daily routines, count all completed logs in the current month
          return completedLogs.length;
      }
    },
    getDaysForFrequency(entry) {
      const slots = entry.routine.frequency === "weekly" ? this.getWeeklySlots() : [];
      switch (entry.routine.frequency) {
        case "weekly":
          // For weekly routines, count the number of slots shown in the UI
          return slots.length;
        case "monthly":
          // For monthly routines, it's just 1 per month
          return 1;
        // Each month counts as 1 completion
        case "daily":
        default:
          return this.daysInMonth;
        // Each day counts as 1 completion
      }
    },
    getCompletionPercentage(entry) {
      const completed = this.getCompletedDays(entry);
      const total = this.getDaysForFrequency(entry);

      // For monthly routines, we need to adjust the calculation
      if (entry.routine.frequency === "monthly") {
        // If there's at least one completion, it's 100%
        return completed > 0 ? 100 : 0;
      }

      // For weekly and daily routines, calculate percentage as normal
      return total > 0 ? Math.round(completed / total * 100) : 0;
    },
    // Helper method to get weekly slots that match the visual representation
    getWeeklySlots() {
      const slots = [];
      const firstDay = this.date.clone().startOf("month");
      const lastDay = this.date.clone().endOf("month");

      // Start from the first day of the first week that contains the first day of the month
      let cursor = firstDay.clone().startOf("week");

      // If cursor is before the first day of the month, we need to check if it's in the same week
      if (cursor.isBefore(firstDay) && !cursor.isSame(firstDay, "week")) {
        cursor = cursor.add(1, "week");
      }

      // Collect all weeks in the month
      while (cursor.isBefore(lastDay) || cursor.isSame(lastDay, "month")) {
        slots.push({
          date: cursor.clone(),
          gridSpan: Math.min(7, this.date.clone().endOf("month").diff(cursor, "days") + 1)
        });
        cursor.add(1, "week");
      }
      return slots;
    },
    // Helper method to get log by span date (copied from Routining mixin)
    getLogBySpan({
      entry,
      span_at
    }) {
      return (this.$store.getters.routine_logs.filter(log => log.routine_id === entry.routine.id) || []).find(log => moment(log.span_at).isSame(span_at, "day"));
    }
  }
};