import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, Teleport as _Teleport, createBlock as _createBlock, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_IconHelper = _resolveComponent("IconHelper");
  const _component_LeftSidebar = _resolveComponent("LeftSidebar");
  const _component_RightSidebar = _resolveComponent("RightSidebar");
  const _component_ApplicationHeader = _resolveComponent("ApplicationHeader");
  const _component_DebugInfo = _resolveComponent("DebugInfo");
  const _component_BulkSelectMenu = _resolveComponent("BulkSelectMenu");
  const _component_ApplicationFooter = _resolveComponent("ApplicationFooter");
  const _component_CommandPalette = _resolveComponent("CommandPalette");
  const _component_DraggableWrapper = _resolveComponent("DraggableWrapper");
  const _component_Toasts = _resolveComponent("Toasts");
  const _component_Undos = _resolveComponent("Undos");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{
      [_ctx.$style.applicationShell]: true,
      [_ctx.$style.inSpace]: $options.space
    }, "max-w-full dark:text-white"])
  }, [_createVNode(_component_IconHelper, {
    name: "star",
    size: "24",
    class: "hidden"
  }), (_openBlock(), _createBlock(_Teleport, {
    to: "body"
  }, [_createVNode(_Transition, {
    "enter-active-class": "transition-opacity duration-300 ease-linear",
    "enter-from-class": "opacity-0",
    "enter-to-class": "opacity-100",
    "leave-active-class": "transition-opacity duration-200 ease-linear",
    "leave-from-class": "opacity-100",
    "leave-to-class": "opacity-0"
  }, {
    default: _withCtx(() => [$data.leftSidebarOpen || $data.rightSidebarOpen ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      onClick: _cache[0] || (_cache[0] = $event => ($data.leftSidebarOpen = false, $data.rightSidebarOpen = false, $data.cursorIsOnLeftEdge = false, $data.cursorIsOnRightEdge = false)),
      class: "fixed inset-0 bg-black/10 md:hidden dark:bg-black/30"
    })) : _createCommentVNode("", true)]),
    _: 1
  }), $options.showSidebar ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    ref: "left-sidebar-wrapper",
    class: _normalizeClass([{
      '-ml-72': !$data.leftSidebarOpen && !$data.cursorIsOnLeftEdge && (!$data.isSwiping || $data.deltaX === 0 || $data.swipingDirection !== 'right' || $data.rightSidebarOpen),
      [_ctx.$style.sidebarOpen]: $data.leftSidebarOpen || $data.cursorIsOnLeftEdge || $data.isSwiping && $data.deltaX !== 0 && $data.swipingDirection === 'right' && !$data.rightSidebarOpen
    }, "fixed inset-y-0 flex max-w-[80%] flex-col"]),
    style: _normalizeStyle({
      width: $options.isDesktop && $data.leftSidebarOpen ? $data.leftSidebarWidth + 'px' : '288px',
      transform: $data.isSwiping && $data.swipingDirection === 'right' ? `translateX(${-288 * (1 - $data.leftSidebarProgress)}px)` : $data.leftSidebarOpen && $data.isSwiping && $data.swipingDirection === 'left' ? `translateX(${-288 * (1 - $data.leftSidebarProgress)}px)` : undefined,
      transition: $data.isResizingLeft || $data.temporarilyDisableSidebarTransitionsOnCapacitor || $data.isSwiping ? 'none' : 'all var(--ps-transition-duration-1) ease-out'
    })
  }, [$data.leftSidebarOpen ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "absolute right-0 top-0 h-full w-0.5 cursor-col-resize hover:bg-neutral-300 dark:hover:bg-neutral-600",
    onMousedown: _cache[1] || (_cache[1] = (...args) => $options.startResizingLeft && $options.startResizingLeft(...args))
  }, null, 32)) : _createCommentVNode("", true), _createVNode(_component_LeftSidebar, {
    modelValue: $data.leftSidebarOpen,
    "onUpdate:modelValue": [_cache[2] || (_cache[2] = $event => $data.leftSidebarOpen = $event), _cache[3] || (_cache[3] = value => {
      $data.leftSidebarOpen = value;
      $options.user.settings = {
        ...$options.user.settings,
        display: {
          ...$options.user.settings?.display,
          left_sidebar: value,
          right_sidebar: $data.rightSidebarOpen
        }
      };
      if (!value) {
        delete $options.user.settings.display.left_sidebar_width;
        $data.leftSidebarWidth = 288;
      }
      _ctx.$store.dispatch('push', {
        event: 'user_update',
        params: {
          user: $options.user
        },
        user: $options.user
      });
    })],
    onToggleCommandPalette: _cache[4] || (_cache[4] = $event => $data.showCommandPalette = !$data.showCommandPalette)
  }, null, 8, ["modelValue"])], 6)) : _createCommentVNode("", true), $options.showSidebar ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    ref: "right-sidebar-wrapper",
    class: _normalizeClass([{
      '-mr-72': !$data.rightSidebarOpen && !$data.cursorIsOnRightEdge && (!$data.isSwiping || $data.deltaX === 0 || $data.swipingDirection !== 'left' || $data.leftSidebarOpen),
      [_ctx.$style.sidebarOpen]: $data.rightSidebarOpen || $data.cursorIsOnRightEdge || $data.isSwiping && $data.deltaX !== 0 && $data.swipingDirection === 'left' && !$data.leftSidebarOpen
    }, "fixed inset-y-0 right-0 flex max-w-[80%] flex-col"]),
    style: _normalizeStyle({
      width: $options.isDesktop && $data.rightSidebarOpen ? $data.rightSidebarWidth + 'px' : '288px',
      transform: $data.isSwiping && $data.swipingDirection === 'left' ? `translateX(${288 * (1 - $data.rightSidebarProgress)}px)` : $data.rightSidebarOpen && $data.isSwiping && $data.swipingDirection === 'right' ? `translateX(${288 * (1 - $data.rightSidebarProgress)}px)` : undefined,
      transition: $data.isResizingRight || $data.temporarilyDisableSidebarTransitionsOnCapacitor || $data.isSwiping ? 'none' : 'all var(--ps-transition-duration-1) ease-out'
    })
  }, [$data.rightSidebarOpen ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "absolute left-0 top-0 h-full w-0.5 cursor-col-resize hover:bg-neutral-300 dark:hover:bg-neutral-600",
    onMousedown: _cache[5] || (_cache[5] = (...args) => $options.startResizingRight && $options.startResizingRight(...args))
  }, null, 32)) : _createCommentVNode("", true), _createVNode(_component_RightSidebar, {
    modelValue: $data.rightSidebarOpen,
    "onUpdate:modelValue": [_cache[6] || (_cache[6] = $event => $data.rightSidebarOpen = $event), _cache[7] || (_cache[7] = value => {
      $data.rightSidebarOpen = value;
      $options.user.settings = {
        ...$options.user.settings,
        display: {
          ...$options.user.settings?.display,
          left_sidebar: $data.leftSidebarOpen,
          right_sidebar: value
        }
      };
      if (!value) {
        delete $options.user.settings.display.right_sidebar_width;
        $data.rightSidebarWidth = 288;
      }
      _ctx.$store.dispatch('push', {
        event: 'user_update',
        params: {
          user: $options.user
        },
        user: $options.user
      });
    })]
  }, null, 8, ["modelValue"])], 6)) : _createCommentVNode("", true)])), _createElementVNode("div", {
    style: _normalizeStyle({
      marginLeft: $options.showSidebar && $data.leftSidebarOpen && $options.isDesktop ? $data.leftSidebarWidth + 'px' : undefined,
      marginRight: $options.showSidebar && $data.rightSidebarOpen && $options.isDesktop ? $data.rightSidebarWidth + 'px' : undefined,
      transition: $options.isDesktop ? 'margin var(--ps-application-shell-main-sidebar-transition-duration) ease-out' : 'none'
    }),
    class: "relative flex h-screen max-w-full flex-col overflow-clip"
  }, [_ctx.$route.meta.settings?.display?.header ? (_openBlock(), _createBlock(_component_ApplicationHeader, {
    key: 0,
    ref: "header",
    sidebarDesktopOpen: $data.leftSidebarOpen
  }, null, 8, ["sidebarDesktopOpen"])) : _createCommentVNode("", true), _createElementVNode("main", {
    ref: "main",
    id: "main",
    class: _normalizeClass([_ctx.$style.mainContent, {
      [_ctx.$style.rightSidebarOpen]: $data.rightSidebarOpen
    }]),
    style: _normalizeStyle({
      paddingBottom: _ctx.$route.meta.settings?.display?.footer ? '30dvh' : '0'
      // marginTop: !$route.meta.settings?.display?.header ? '48px' : '0',
    })
  }, [_renderSlot(_ctx.$slots, "default"), $options.user?.settings.debug ? (_openBlock(), _createBlock(_component_DebugInfo, {
    key: 0,
    class: "mx-5"
  })) : _createCommentVNode("", true)], 6), _createVNode(_Transition, {
    "enter-active-class": "transition-all duration-300 ease-spring",
    "enter-from-class": "opacity-0 translate-y-5",
    "enter-to-class": "opacity-100 translate-y-0",
    "leave-active-class": "transition-all duration-300 ease-spring",
    "leave-from-class": "opacity-100 translate-y-0",
    "leave-to-class": "opacity-0 translate-y-5"
  }, {
    default: _withCtx(() => [_ctx.$store.getters.selected.length > 0 ? (_openBlock(), _createBlock(_Teleport, {
      key: 0,
      to: "body"
    }, [_createElementVNode("div", {
      class: _normalizeClass([_ctx.$style.bulkSelectMenuWrapper])
    }, [_createVNode(_component_BulkSelectMenu)], 2)])) : _createCommentVNode("", true)]),
    _: 1
  }), _ctx.$route.meta.settings?.display?.footer ? (_openBlock(), _createBlock(_component_ApplicationFooter, {
    key: 1,
    ref: "footer",
    onToggleCommandPalette: _cache[8] || (_cache[8] = $event => $data.showCommandPalette = !$data.showCommandPalette)
  }, null, 512)) : _createCommentVNode("", true)], 4), _createVNode(_component_CommandPalette, {
    isOpen: $data.showCommandPalette,
    onOnClose: _cache[9] || (_cache[9] = $event => $data.showCommandPalette = false)
  }, null, 8, ["isOpen"]), _createVNode(_component_DraggableWrapper), _createVNode(_component_Toasts), _createVNode(_component_Undos)], 2);
}