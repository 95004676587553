import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import { Schema } from "@/components/mixins/Schema";
import { Filtering } from "@/components/mixins/Filtering";
export default {
  props: {
    entry: Object,
    customFilter: {
      type: Function,
      default: e => e
    }
  },
  mixins: [Schema, Filtering],
  computed: {
    entries() {
      return this.filterEntries(this.outputSchema(this.entry)).filter(this.customFilter);
    }
  }
};