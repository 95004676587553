import { Routing } from "@/components/mixins/Routing";
export default {
  mixins: [Routing],
  props: {
    event: Object,
    entry: Object
  },
  data() {
    return {
      position: "calendar"
    };
  }
};