import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.find.js";
import { useModel as _useModel, mergeModels as _mergeModels } from 'vue';
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, unref as _unref, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, Transition as _Transition, Teleport as _Teleport } from "vue";
const _hoisted_1 = {
  class: "relative"
};
const _hoisted_2 = {
  class: "block truncate"
};
const _hoisted_3 = {
  class: "pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
};
import { ref, nextTick } from "vue";
import { useFloating, shift, flip, autoUpdate } from "@floating-ui/vue";
export default {
  __name: 'SelectMenu',
  props: /*@__PURE__*/_mergeModels({
    options: {
      type: Array,
      required: true
    },
    openWhenEmpty: Boolean,
    teleport: {
      type: Boolean,
      default: true
    }
  }, {
    "modelValue": {},
    "modelModifiers": {}
  }),
  emits: ["update:modelValue"],
  setup(__props) {
    const model = _useModel(__props, "modelValue");
    const props = __props;
    const selected = ref(model);
    const button = ref(null);
    if (props.openWhenEmpty) {
      nextTick(() => {
        if (selected.value == null) {
          button.value.$el.click();
        }
      });
    }
    const floating = ref(null);
    const {
      floatingStyles
    } = useFloating(button, floating, {
      //strategy: "fixed",
      placement: "bottom-start",
      whileElementsMounted: autoUpdate,
      middleware: [flip(), shift({
        crossAxis: true
      })]
    });
    return (_ctx, _cache) => {
      const _component_ListboxLabel = _resolveComponent("ListboxLabel");
      const _component_ChevronsUpDownIcon = _resolveComponent("ChevronsUpDownIcon");
      const _component_ListboxButton = _resolveComponent("ListboxButton");
      const _component_CheckIcon = _resolveComponent("CheckIcon");
      const _component_ListboxOption = _resolveComponent("ListboxOption");
      const _component_ListboxOptions = _resolveComponent("ListboxOptions");
      const _component_Listbox = _resolveComponent("Listbox");
      return _openBlock(), _createBlock(_component_Listbox, {
        as: "div",
        modelValue: selected.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => selected.value = $event)
      }, {
        default: _withCtx(() => [_ctx.$slots['label'] ? (_openBlock(), _createBlock(_component_ListboxLabel, {
          key: 0,
          class: "mb-2 block text-sm font-medium leading-6 text-neutral-900"
        }, {
          default: _withCtx(() => [_renderSlot(_ctx.$slots, "label")]),
          _: 3
        })) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_1, [_createVNode(_component_ListboxButton, {
          ref_key: "button",
          ref: button,
          class: _normalizeClass(_ctx.$style.button)
        }, {
          default: _withCtx(() => [_createElementVNode("span", _hoisted_2, _toDisplayString(props.options.find(o => JSON.stringify(o.value) == JSON.stringify(selected.value))?.title || "Select"), 1), _createElementVNode("span", _hoisted_3, [_createVNode(_component_ChevronsUpDownIcon, {
            size: "16",
            class: "text-neutral-400 dark:text-neutral-600",
            "aria-hidden": "true"
          })])]),
          _: 1
        }, 8, ["class"]), (_openBlock(), _createBlock(_Teleport, {
          disabled: !props.teleport,
          to: "body"
        }, [_createVNode(_Transition, {
          "leave-active-class": "transition ease-in duration-100",
          "leave-from-class": "opacity-100",
          "leave-to-class": "opacity-0"
        }, {
          default: _withCtx(() => [_createVNode(_component_ListboxOptions, {
            ref_key: "floating",
            ref: floating,
            style: _normalizeStyle(_unref(floatingStyles)),
            class: "absolute z-[99999] mt-1 max-h-60 min-w-fit overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-neutral-200 focus:outline-none sm:text-sm dark:bg-black dark:ring-neutral-700"
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.options.filter(o => typeof o.hidden == 'undefined' || !o.hidden), option => {
              return _openBlock(), _createBlock(_component_ListboxOption, {
                as: "template",
                key: option.value,
                value: option.value,
                "data-option": typeof option == 'object' && option.value ? option.value : option
              }, {
                default: _withCtx(({
                  active,
                  selected
                }) => [_createElementVNode("li", {
                  class: _normalizeClass([active ? 'bg-neutral-900 text-white dark:bg-neutral-200 dark:text-black' : 'text-neutral-900 dark:bg-black dark:text-neutral-200', 'relative cursor-default select-none py-2 pl-3 pr-9'])
                }, [_createElementVNode("span", {
                  class: _normalizeClass([selected ? 'font-semibold' : 'font-normal', 'block truncate'])
                }, _toDisplayString(option.title), 3), selected ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: _normalizeClass([active ? 'text-white dark:text-black' : 'text-neutral-600 dark:text-neutral-400', 'absolute inset-y-0 right-0 flex items-center pr-4'])
                }, [_createVNode(_component_CheckIcon, {
                  class: "h-5 w-5",
                  "aria-hidden": "true"
                })], 2)) : _createCommentVNode("", true)], 2)]),
                _: 2
              }, 1032, ["value", "data-option"]);
            }), 128))]),
            _: 1
          }, 8, ["style"])]),
          _: 1
        })], 8, ["disabled"]))])]),
        _: 3
      }, 8, ["modelValue"]);
    };
  }
};