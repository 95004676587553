import "core-js/modules/es.array.push.js";
import { Auth } from "../mixins/Auth";
import { Analytics } from "../mixins/Analytics";
export default {
  mixins: [Auth, Analytics],
  data() {
    return {
      formData: {
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        password_confirmation: ""
      }
    };
  },
  watch: {
    "$route.name": function () {
      this.message = null;
    }
  },
  mounted() {
    // if (this.$enableAnalytics) {
    //   window._paq.push(["setDocumentTitle", this.$route.meta.title]);
    //   window._paq.push(["trackPageView"]);
    // }

    if (this.$route.name == "email-verification") {
      this.verifyEmail();
    }
  },
  methods: {
    handleButtonClick() {
      this.message = null;
      switch (this.$route.name) {
        case "login":
          this.login();
          break;
        case "register":
          this.register();
          break;
        case "forgot-password":
          this.forgot_password();
          break;
        case "reset-password":
          this.reset_password();
          break;
        case "email-verification":
          this.$router.push({
            name: "login"
          });
          break;
      }
    },
    verifyEmail() {
      const id = this.$route.params.verification_id;
      const hash = this.$route.params.verification_hash;
      if (id && hash) {
        this.$store.dispatch("verifyEmail", {
          id: id,
          hash: hash,
          expires: this.$route.query.expires,
          signature: this.$route.query.signature
        }).then(() => {
          this.$store.dispatch("retrieveUser");
          this.message = {
            type: "success",
            message: "Email successfully verified."
          };
          this.success = true;
        }).catch(data => {
          if (data.response?.data?.message == "Invalid signature.") {
            this.message = {
              type: "warning",
              message: "This link is expired, please send yourself a new one"
            };
          } else {
            this.message = {
              type: "error",
              message: "An error appeared"
            };
          }
          this.resend = true;
        });
      }
    }
  },
  computed: {
    subheading_text() {
      switch (this.$route.name) {
        case "login":
          return "Sign in to your account";
        case "register":
          return "Create your account";
        case "forgot-password":
          return "Reset your password";
        case "reset-password":
          return "Reset your password";
        case "email-verification":
          return "Email Verification";
        default:
          return "";
      }
    },
    buttonText() {
      switch (this.$route.name) {
        case "login":
          return "Sign in";
        case "register":
          return "Sign up";
        case "forgot-password":
          return "Reset password";
        case "reset-password":
          return "Reset password";
        case "email-verification":
          return "Go back";
        default:
          return "";
      }
    },
    formFields() {
      switch (this.$route.name) {
        case "login":
          return [{
            label: "Email",
            key: "email",
            type: "email",
            autocomplete: "email"
          }, {
            label: "Password",
            key: "password",
            type: "password",
            autocomplete: "current-password"
          }];
        case "register":
          return [{
            label: "First Name",
            key: "firstname",
            autocomplete: "given-name"
          }, {
            label: "Last Name",
            key: "lastname",
            autocomplete: "family-name"
          }, {
            label: "Email",
            key: "email",
            type: "email",
            autocomplete: "email"
          }, {
            label: "Password",
            key: "password",
            type: "password",
            autocomplete: "current-password"
          }, {
            label: "Confirm Password",
            key: "password_confirmation",
            type: "password",
            autocomplete: "current-password"
          }];
        case "forgot-password":
          return [{
            label: "Email",
            key: "email",
            type: "email",
            autocomplete: "email"
          }];
        case "reset-password":
          return [{
            label: "Password",
            key: "password",
            type: "password"
          }, {
            label: "Confirm Password",
            key: "password_confirmation",
            type: "password"
          }];
        default:
          return [];
      }
    }
  }
};