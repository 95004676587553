import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.find.js";
export default {
  data() {
    return {
      showPage: false,
      showDebugInfo: false
    };
  },
  computed: {
    page() {
      return this.entries.find(e => e.id == this.$route.params.id);
    },
    entries() {
      return this.$store.getters.entries;
    },
    jsonSize() {
      return new TextEncoder().encode(JSON.stringify(this.entries)).length;
    },
    formattedSize() {
      const bytes = this.jsonSize;
      if (bytes >= 1024 * 1024) {
        return `${(bytes / (1024 * 1024)).toFixed(1)} MB`;
      } else if (bytes >= 1024) {
        return `${(bytes / 1024).toFixed(1)} KB`;
      }
      return `${bytes} bytes`;
    }
  },
  methods: {
    closeDebugInfo() {
      this.showDebugInfo = false;
    }
  }
};