import moment from "moment";
import { Scheduling } from "@/components/mixins/Scheduling";
export default {
  mixins: [Scheduling /*, EditUtilities */],
  props: {
    modelValue: String,
    entry: Object,
    otherentries: Array,
    teleport: Boolean
  },
  data() {
    return {
      //   moment: moment,
      internalTime: null,
      internalDuration: null
      //   now: null,
    };
  },
  watch: {
    // "entry.schedule.time": function (n) {
    //   // this.value = n;
    //   // this.cursor = n;
    // },
  },
  mounted() {
    this.internalTime = this.time;
    this.internalDuration = this.duration;

    // this.interval = setInterval(() => {
    //   this.now = moment.utc();
    // }, 1000);
  },
  beforeUnmount() {
    // clearInterval(this.interval);
  },
  computed: {
    schedule: {
      get() {
        return this.entry.schedule;
      },
      set(value) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.schedule = value;
        if (!this.entry.temp) this.$store.dispatch("push", {
          event: "entry_update",
          params: {
            entry: this.entry
          },
          entry: this.entry
        });
      }
    },
    time: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.schedule = {
          ...this.schedule,
          time: value
        };
        this.$emit("update:time", value);
      }
    },
    duration: {
      get() {
        return this.entry?.schedule?.duration;
      },
      set(value) {
        this.schedule = {
          ...this.schedule,
          duration: value
        };
      }
    },
    displayText() {
      if (this.time && this.duration) return this.valToHtml(this.time) + " - " + this.valToHtml(this.time, "HH:mm", {
        key: "seconds",
        val: this.duration
      });
      if (!this.time && this.duration) return this.duration / 60 + " min.";
      if (this.time || !this.duration) return this.valToHtml(this.time);
      return "Set Time";
    },
    date() {
      return moment().format("YYYY-MM-DD");
    }
  },
  methods: {
    timePart(part, time) {
      if (time == null) return "--";
      switch (part) {
        case "H":
          return moment.utc(this.date + " " + time).local().format("HH");
        case "M":
          return moment.utc(this.date + " " + time).local().format("mm");
        case "S":
          return moment.utc(this.date + " " + time).local().format("ss");
      }
    },
    modifyRelativeDate(dir, time) {
      switch (dir) {
        case 1:
          switch (time.op) {
            case "now":
              time.op = "nextXHours";
              time.x = 1;
              break;
            default:
              time.x = typeof time.x != "undefined" ? time.op.slice(0, 4) == "next" ? time.x + 1 : time.x - 1 : 0;
              if (time.x < 0) {
                time.op = time.op.slice(0, 4) == "next" ? "last" + time.op.slice(4) : "next" + time.op.slice(4);
                time.x = 1;
              }
          }
          break;
        case -1:
          switch (time.op) {
            case "now":
              time.op = "lastXHours";
              time.x = 1;
              break;
            default:
              time.x = typeof time.x != "undefined" ? time.op.slice(0, 4) == "next" ? time.x - 1 : time.x + 1 : 0;
              if (time.x < 0) {
                time.op = time.op.slice(0, 4) == "next" ? "last" + time.op.slice(4) : "next" + time.op.slice(4);
                time.x = 1;
              }
          }
          break;
      }
      if ((time.op == "nextXHours" || time.op == "lastXHours") && time.x == 0) {
        delete time.x;
        time.op = "now";
      }
      if (time.op == "nextXHours" && time.x >= 24) {
        time.x = 0;
      }
      if (time.op == "lastXHours" && time.x >= 24) {
        time.x = 0;
      }
      if (time.op == "nextXMinutes" && time.x >= 60) {
        time.x = 0;
      }
      if (time.op == "lastXMinutes" && time.x >= 60) {
        time.x = 0;
      }
      if (time.op == "nextXSeconds" && time.x >= 60) {
        time.x = 0;
      }
      if (time.op == "lastXSeconds" && time.x >= 60) {
        time.x = 0;
      }
      //this.$emit("update", this.value);
    },
    modifyRelativeTimespan(dir, time) {
      if (time !== null) {
        switch (dir) {
          case 1:
            switch (time.op) {
              case "nextXSeconds":
                time.op = "nextXMinutes";
                if (typeof time.x == "undefined") time.x = 1;
                break;
              case "nextXMinutes":
                time.op = "nextXHours";
                if (typeof time.x == "undefined") time.x = 1;
                break;
              case "lastXSeconds":
                time.op = "lastXMinutes";
                if (typeof time.x == "undefined") time.x = 1;
                break;
              case "lastXMinutes":
                time.op = "lastXHours";
                if (typeof time.x == "undefined") time.x = 1;
                break;
            }
            break;
          case -1:
            switch (time.op) {
              case "now":
                time.op = "nextXMinutes";
                if (typeof time.x == "undefined") time.x = 0;
                break;
              case "nextXMinutes":
                time.op = "nextXSeconds";
                if (typeof time.x == "undefined") time.x = 1;
                break;
              case "nextXHours":
                time.op = "nextXMinutes";
                if (typeof time.x == "undefined") time.x = 1;
                break;
              case "lastXMinutes":
                time.op = "lastXSeconds";
                if (typeof time.x == "undefined") time.x = 1;
                break;
              case "lastXHours":
                time.op = "lastXMinutes";
                if (typeof time.x == "undefined") time.x = 1;
                break;
            }
            break;
        }
        if ((time.op == "nextXHours" || time.op == "lastXHours") && time.x == 0) {
          delete time.x;
          time.op = "now";
        }
      }
      //this.$emit("update", this.value);
    },
    relativeTimespan(time) {
      if (time === null) {
        return [0, "hours"];
      }
      switch (time.op) {
        case "now":
          return [0, "hours"];
        case "lastXHours":
        case "nextXHours":
          return [time.x, "hour" + (time.x != 1 ? "s" : "")];
        case "lastXMinutes":
        case "nextXMinutes":
          return [time.x, "minute" + (time.x != 1 ? "s" : "")];
        case "lastXSeconds":
        case "nextXSeconds":
          return [time.x, "second" + (time.x != 1 ? "s" : "")];
      }
    },
    valToHtml(val, format = "HH:mm", mod) {
      if (val) {
        if (val.op == "null") return "unset time";
        if (val.op == "now") {
          return "now";
        }
        if (val.op == "nextXHours") {
          if (val.x == 0) return "now";
          return "in " + val.x + " hour" + (val.x != 1 ? "s" : "");
        }
        if (val.op == "nextXMinutes") {
          if (val.x == 0) return "now";
          return "in " + val.x + " minute" + (val.x != 1 ? "s" : "");
        }
        if (val.op == "nextXSeconds") {
          if (val.x == 0) return "now";
          return "in " + val.x + " second" + (val.x != 1 ? "s" : "");
        }
        if (val.op == "lastXHours") {
          if (val.x == 0) return "now";
          return val.x + " hour" + (val.x != 1 ? "s" : "") + " ago";
        }
        if (val.op == "lastXMinutes") {
          if (val.x == 0) return "now";
          return val.x + " minute" + (val.x != 1 ? "s" : "") + " ago";
        }
        if (val.op == "lastXSeconds") {
          if (val.x == 0) return "now";
          return val.x + " second" + (val.x != 1 ? "s" : "") + " ago";
        }
        /*
                  @note this shows in completedAt Component: "TODAY now" - I don't like that.
                  if (format == "HH:mm" && val == moment().utc().format("HH:mm:ss")) {
                    return "now";
                  }*/
        var datetime = moment.utc(moment.utc().format("YYYY-MM-DD " + val));
        if (datetime.format("ss") != "00") format = format + ":ss";
        if (mod) {
          datetime = datetime.add(mod.val, mod.key);
        }
        return datetime.local().format(format);
      }
      return;
      // return "--:--";
    }
  }
};