import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import { Filtering } from "../mixins/Filtering";
import { Schema } from "../mixins/Schema";
export default {
  props: {
    modelValue: Object,
    inputs: Array,
    position: String
  },
  watch: {
    entry: {
      handler() {
        this.showOutput = !this.entry.output;
        this.skipLengthCheck = true;
      },
      deep: true
    },
    "entries.length": function (n, o) {
      if (n > o && o == 0 && !this.skipLengthCheck) {
        this.showOutput = true;
      }
      this.skipLengthCheck = false;
    }
  },
  data() {
    return {
      showOutput: true,
      skipLengthCheck: false,
      filter: e => !this.entry.output || this.outputComputed.findIndex(i => i.id == e.id) == -1
    };
  },
  mounted() {
    this.showOutput = !this.entry.output;
    this.pullData();
  },
  methods: {
    pullData() {
      this.$nextTick(() => {
        this.$store.dispatch("pull", {
          filters: this.defaultOutput.filters
        });
      });
    }
  },
  mixins: [Schema, Filtering],
  computed: {
    entry() {
      return this.modelValue;
    },
    outputComputed() {
      return this.filterEntries(this.outputSchema(this.entry));
    },
    entries() {
      return this.filterEntries(this.defaultOutput.filters).filter(this.filter);
    },
    defaultOutput() {
      return {
        filters: [{
          key: "links",
          op: "any",
          val: [this.entry.id]
        }]
      };
    }
  }
};