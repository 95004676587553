import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, withKeys as _withKeys, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  class: "relative bg-white p-5 dark:bg-black"
};
const _hoisted_3 = {
  class: "mt-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ImageIcon = _resolveComponent("ImageIcon");
  const _component_ButtonComponent = _resolveComponent("ButtonComponent");
  const _component_PSOverlay = _resolveComponent("PSOverlay");
  return _openBlock(), _createBlock(_component_PSOverlay, null, {
    button: _withCtx(() => [_renderSlot(_ctx.$slots, "button", {
      open: _ctx.open
    }, () => [_createVNode(_component_ButtonComponent, {
      size: _ctx.size,
      variant: _ctx.variant,
      color: _ctx.color
    }, {
      default: _withCtx(() => [_createVNode(_component_ImageIcon, {
        size: "20"
      }), _ctx.variant != 'round' ? (_openBlock(), _createElementBlock("span", _hoisted_1, " Cover ")) : _createCommentVNode("", true)]),
      _: 1
    }, 8, ["size", "variant", "color"])])]),
    default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_cache[3] || (_cache[3] = _createElementVNode("label", {
      for: "email",
      class: "block text-sm font-medium leading-6 text-gray-900 dark:text-neutral-300"
    }, "URL", -1)), _createElementVNode("div", _hoisted_3, [_createElementVNode("input", {
      type: "text",
      class: "block w-full rounded-md border-0 py-1.5 text-neutral-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6 dark:bg-black dark:text-neutral-300 dark:ring-neutral-700 dark:focus:ring-white",
      placeholder: "",
      onKeyup: _cache[0] || (_cache[0] = _withKeys(event => event.target.blur(), ["enter"])),
      onBlur: _cache[1] || (_cache[1] = event => {
        if (event.target.value.length) {
          // eslint-disable-next-line vue/no-mutating-props
          _ctx.entry.cover = {
            ...$data.cover,
            ...{
              url: event.target.value
            }
          };
          _ctx.$store.dispatch('push', {
            event: 'entry_update',
            params: {
              entry: _ctx.entry
            },
            entry: _ctx.entry
          });
        }
        event.target.value = '';
      })
    }, null, 32)]), _cache[4] || (_cache[4] = _createElementVNode("p", {
      class: "mt-2 text-sm text-neutral-500",
      id: "email-description"
    }, " Works with any image from the web. ", -1)), $data.cover?.url ? (_openBlock(), _createElementBlock("button", {
      key: 0,
      onClick: _cache[2] || (_cache[2] = $event => (
      // eslint-disable-next-line vue/no-mutating-props
      _ctx.entry.cover = null, _ctx.$store.dispatch('push', {
        event: 'entry_update',
        params: {
          entry: _ctx.entry
        },
        entry: _ctx.entry
      }))),
      type: "button",
      class: "rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:bg-black dark:text-neutral-300 dark:ring-neutral-700 dark:hover:bg-neutral-900"
    }, " Remove ")) : _createCommentVNode("", true)])]),
    _: 3
  });
}