import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.find.js";
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "flex-1 py-8"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PanelRightCloseIcon = _resolveComponent("PanelRightCloseIcon");
  const _component_PanelRightOpenIcon = _resolveComponent("PanelRightOpenIcon");
  const _component_PSButton = _resolveComponent("PSButton");
  const _component_SuggestionsDisplay = _resolveComponent("SuggestionsDisplay");
  const _component_OutputDisplay = _resolveComponent("OutputDisplay");
  const _component_VersionInfo = _resolveComponent("VersionInfo");
  return _openBlock(), _createElementBlock("div", {
    id: "right-sidebar",
    class: _normalizeClass(_ctx.$style.rightSidebar),
    style: _normalizeStyle($options.cssProps)
  }, [_createElementVNode("div", {
    class: _normalizeClass(_ctx.$style.topMenuWrapper)
  }, [_createVNode(_component_PSButton, {
    class: _normalizeClass(_ctx.$style.buttonToggleSidebar),
    title: "Toggle sidebar",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('update:modelValue', !$props.modelValue)),
    shortcode: "m"
  }, {
    default: _withCtx(() => [_cache[4] || (_cache[4] = _createElementVNode("span", {
      class: "sr-only"
    }, "Toggle sidebar", -1)), $props.modelValue ? (_openBlock(), _createBlock(_component_PanelRightCloseIcon, {
      key: 0,
      size: "20",
      strokeWidth: "1.5"
    })) : (_openBlock(), _createBlock(_component_PanelRightOpenIcon, {
      key: 1,
      size: "20",
      strokeWidth: "1.5"
    }))]),
    _: 1
  }, 8, ["class"])], 2), _createElementVNode("div", _hoisted_1, [(_openBlock(), _createBlock(_resolveDynamicComponent('style'), null, {
    default: _withCtx(() => [_createTextVNode(" body:not(:has(#draggable-wrapper)) ." + _toDisplayString(_ctx.$style.rightSidebar) + " [data-entry-id=\"" + _toDisplayString(_ctx.$route.params.id) + "\"] { --ps-output-display-entry-display-background-color: var(--ps-base-primary-color); --ps-output-display-entry-display-background-color-hover: var(--ps-base-primary-color); --ps-output-display-entry-display-text-color: var(--ps-base-background-color); --ps-output-display-entry-display-text-color-hover: var(--ps-base-background-color); --ps-entry-display-settings-button-bg-color: var(--ps-base-primary-color); --ps-entry-display-settings-button-bg-color-hover: var(--ps-base-secondary-color); .ps_sense_button { --ps-button-bg-color:transparent!important; --ps-button-bg-color-hover: transparent!important; } } body:not(:has(#draggable-wrapper)) ." + _toDisplayString(_ctx.$style.rightSidebar) + " [data-entry-id=\"" + _toDisplayString(_ctx.$route.params.id) + "\"] .name_display { --ps-name-display-color: var(--ps-base-background-color)!important; } ", 1)]),
    _: 1
  })), _createVNode(_component_OutputDisplay, {
    id: $props.outputId,
    modelValue: $data.originsEntry.output,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.originsEntry.output = $event),
    entry: $data.originsEntry,
    display: {
      status: false,
      procrastination: false,
      links: false,
      description: false,
      schedule: false,
      output: true,
      output_count: true,
      input: false,
      columns: false,
      leftover: false,
      settings: true,
      senses: true,
      routine: false,
      time_trackings: false,
      custom_fields: false
    },
    sorting: $data.originsEntry.output.sorting,
    position: "right-sidebar",
    disableViewportChecking: false,
    entriesDisabled: true,
    onOnEntryClick: _cache[2] || (_cache[2] = $event => _ctx.$emit('onEntryClick')),
    overrideEntryPermissions: {
      name: false,
      description: false,
      status: false
    },
    overrideEntryDisplayOutput: entry => entry.anchors.find(anchor => anchor.placement == 'right-sidebar')?.settings?.display?.output,
    overrideToggleEntryDisplayOutput: entry => {
      let anchor = entry.anchors.find(anchor => anchor.placement == 'right-sidebar');
      if (anchor) {
        if (!anchor.settings) anchor.settings = {};
        if (!anchor.settings.display) anchor.settings.display = {};
        anchor.settings.display = {
          ...anchor.settings.display,
          output: anchor.settings.display?.output ? !anchor.settings.display.output : true
        };
        if (!entry.temp) _ctx.$store.dispatch('push', {
          event: 'entry_update',
          params: {
            entry: entry
          },
          entry: entry,
          undo: true
        });
      }
    },
    editable: false,
    size: "sm",
    onCreated: _cache[3] || (_cache[3] = entry => {
      _ctx.$document.activeElement?.blur();
      _ctx.$nextTick(() => {
        this.$router.push({
          name: 'entry',
          params: {
            id: entry.id
          }
        });
      });
    }),
    overrideOnDrop: $options.handleAnchorSorting,
    class: _normalizeClass(_ctx.$style.outputDisplay)
  }, {
    "before-input": _withCtx(() => [_createVNode(_component_SuggestionsDisplay, {
      filterSuggestions: [
      // [
      //   {
      //     key: ['anchors', 'placement'],
      //     op: 'not any',
      //     val: ['left-sidebar', 'right-sidebar'],
      //   },
      // ],
      // [
      //   {
      //     key: 'output',
      //     op: 'is set',
      //     val: [],
      //   },
      // ],
      [{
        key: 'anchor',
        op: 'not any',
        val: []
      }, {
        key: 'output',
        op: 'is set',
        val: []
      }
      // {
      //   key: 'status',
      //   op: 'is not set',
      //   val: [],
      // },
      // {
      //   key: ['date', 'updated'],
      //   op: 'gt',
      //   val: [{ op: 'lastXWeeks', x: 2 }],
      // },
      ], [
      // {
      //   key: 'anchor',
      //   op: 'not any',
      //   val: [],
      // },
      // {
      //   key: 'output',
      //   op: 'is set',
      //   val: [],
      // },
      // {
      //   key: 'status',
      //   op: 'is not set',
      //   val: [],
      // },
      {
        key: ['date', 'updated'],
        op: 'gt',
        val: [{
          op: 'lastXWeeks',
          x: 2
        }]
      }]],
      onOnEntryClick: $options.handleAddSuggestion,
      class: _normalizeClass(_ctx.$style.suggestionsDisplay)
    }, null, 8, ["onOnEntryClick", "class"])]),
    _: 1
  }, 8, ["id", "modelValue", "entry", "sorting", "overrideEntryDisplayOutput", "overrideToggleEntryDisplayOutput", "overrideOnDrop", "class"])]), _createVNode(_component_VersionInfo)], 6);
}