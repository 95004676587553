import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_EntrySettings = _resolveComponent("EntrySettings");
  const _component_EntryModalPage = _resolveComponent("EntryModalPage");
  return $options.entry_by_route ? (_openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(1, i => {
    return _createVNode(_component_EntrySettings, {
      key: i,
      modelValue: $options.entry_by_route,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.entry_by_route = $event),
      position: "center",
      "data-tour": "entry_page"
    }, null, 8, ["modelValue"]);
  }), 64)), _createVNode(_component_EntryModalPage, {
    open: _ctx.$route.params.id2 ? true : false
  }, null, 8, ["open"])])) : _createCommentVNode("", true);
}