import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.find.js";
import "core-js/modules/es.iterator.for-each.js";
import "core-js/modules/es.iterator.map.js";
import "core-js/modules/es.set.difference.v2.js";
import "core-js/modules/es.set.intersection.v2.js";
import "core-js/modules/es.set.is-disjoint-from.v2.js";
import "core-js/modules/es.set.is-subset-of.v2.js";
import "core-js/modules/es.set.is-superset-of.v2.js";
import "core-js/modules/es.set.symmetric-difference.v2.js";
import "core-js/modules/es.set.union.v2.js";
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, TransitionGroup as _TransitionGroup, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  class: "mx-auto sm:w-full sm:max-w-3xl md:max-w-5xl"
};
const _hoisted_2 = {
  class: "mt-10 p-5"
};
const _hoisted_3 = {
  key: 0,
  class: "flex flex-col gap-4"
};
const _hoisted_4 = {
  class: "md:col-span-4"
};
const _hoisted_5 = {
  class: "flex flex-col gap-4 rounded-3xl bg-neutral-50 py-5 dark:bg-neutral-900"
};
const _hoisted_6 = {
  ref: "inner",
  role: "list",
  class: "grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5"
};
const _hoisted_7 = {
  class: "group-hover/selected:hidden"
};
const _hoisted_8 = {
  class: "hidden group-hover/selected:inline"
};
const _hoisted_9 = {
  class: "flex w-7 flex-col justify-between"
};
const _hoisted_10 = {
  class: "h-7"
};
const _hoisted_11 = {
  class: "h-7"
};
const _hoisted_12 = {
  class: "h-7"
};
const _hoisted_13 = {
  key: "recommendations",
  class: "relative col-span-2 sm:col-span-3 md:col-span-4 lg:col-span-5"
};
const _hoisted_14 = {
  class: "relative flex justify-center"
};
const _hoisted_15 = {
  class: "bg-white px-3 dark:bg-neutral-950"
};
const _hoisted_16 = {
  class: "rounded-full bg-neutral-50 p-1.5 text-neutral-300 dark:bg-neutral-950 dark:text-neutral-500"
};
const _hoisted_17 = ["onClick"];
const _hoisted_18 = {
  class: "flex justify-end"
};
const _hoisted_19 = {
  class: "origin-center rounded-full bg-neutral-50 p-1.5 text-neutral-300 transition-all duration-300 group-hover:-translate-y-1 group-hover:bg-neutral-100 group-hover:text-neutral-900 dark:bg-neutral-950 dark:text-neutral-500 dark:group-hover:bg-neutral-900 dark:group-hover:text-neutral-100"
};
const _hoisted_20 = {
  class: "group flex h-full cursor-pointer flex-col gap-2 rounded-xl border border-neutral-100 p-5 transition-all duration-300 hover:-translate-y-2 hover:shadow-lg dark:border-neutral-900"
};
const _hoisted_21 = {
  class: "flex h-full flex-col justify-between gap-2"
};
const _hoisted_22 = {
  class: "flex flex-col gap-2"
};
const _hoisted_23 = {
  class: "flex items-center justify-between pb-2"
};
const _hoisted_24 = {
  class: "self-start rounded-lg bg-neutral-50 p-2 dark:bg-neutral-900"
};
const _hoisted_25 = {
  class: "text-neutral-500"
};
const _hoisted_26 = {
  class: "flex justify-end"
};
const _hoisted_27 = {
  class: "origin-center rounded-full bg-neutral-50 p-1.5 text-neutral-300 transition-all duration-300 group-hover:-translate-y-1 group-hover:bg-neutral-100 group-hover:text-neutral-900 dark:bg-neutral-950 dark:text-neutral-500 dark:group-hover:bg-neutral-900 dark:group-hover:text-neutral-100"
};
const _hoisted_28 = {
  class: "flex items-center justify-end gap-4 px-5"
};
const _hoisted_29 = {
  class: "leading-normal text-neutral-600 dark:text-neutral-300"
};
const _hoisted_30 = {
  class: "font-semibold"
};
const _hoisted_31 = {
  class: "fixed inset-0 z-10 w-screen overflow-y-auto overflow-x-hidden",
  style: {
    "scrollbar-gutter": "stable"
  }
};
const _hoisted_32 = {
  class: "flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
};
const _hoisted_33 = {
  class: "group-hover/selected:hidden"
};
const _hoisted_34 = {
  class: "hidden group-hover/selected:inline"
};
const _hoisted_35 = {
  class: "mt-4 flex justify-end"
};
import TemplateBuilder from "../builders/TemplateBuilder.vue";
import { Templating } from "../mixins/Templating";
import { Schema } from "../mixins/Schema";
import { Scheduling } from "../mixins/Scheduling";
import { Applicators } from "../mixins/Applicators";
import { Statusing } from "../mixins/Statusing";
const __default__ = {
  mixins: [Templating, Statusing, Scheduling, Schema, Applicators],
  components: {
    TemplateBuilder
  },
  data() {
    return {
      showBuilder: false,
      resizeObserver: null,
      showTemplateBuilder: false,
      selectedTemplatesInTemplateBuilder: [],
      delayPushSelectedTemplatesInterval: null
    };
  },
  mounted() {
    this.$store.dispatch("retrieveTemplates").then(() => {
      //   if (this.templates.find((t) => t.name === "Inbox"))
      //     this.selected.push(this.templates.find((t) => t.name === "Inbox").id);
      // if (this.templates.find((t) => t.name === "Daily Tasks List"))
      //   this.selected.push(
      //     this.templates.find((t) => t.name === "Daily Tasks List").id,
      //   );
      //   if (this.templates.find((t) => t.name === "Task Board"))
      //     this.selectedTemplates.push(
      //       this.templates.find((t) => t.name === "Task Board").id,
      //     );
    });
    setTimeout(() => {
      this.showBuilder = true;
      this.$nextTick(() => {
        this.initResizeObserver();
      });
    }, 1500 * 2);
  },
  beforeUnmount() {
    this.cleanupResizeObserver();
  },
  computed: {
    space() {
      return this.$store.getters.space;
    },
    entries() {
      return this.$store.getters.entries.filter(e => e.deleted_at === null);
    },
    recommendations() {
      return this.templatesAvailable.slice(0, this.recommendationsLimit);
    },
    recommendationsLimit() {
      switch (this.$store.getters.widthSize) {
        case "lg":
        case "xl":
          return 4;
        case "sm":
          return 2;
        case "md":
          return 3;
        case "xs":
        default:
          return 3;
      }
    }
  },
  watch: {
    space() {
      if (this.entries !== null && this.entries.length > 0) {
        // console.log("Onboarding watch space: entries found, redirecting...");
        this.$router.push({
          name: "start"
        });
      }
    },
    "entries.length": function () {
      if (this.entries.length > 0) {
        // console.log(
        //   // "Onboarding watch entries length: entries found, redirecting...",
        // );
        // console.log(
        //   "Onboarding watch entries length: entries found, redirecting...",
        // );
        // this.showBuilder = false;
        // setTimeout(() => {
        this.$router.push({
          name: "entry",
          params: {
            id: this.entries[0].id
          }
        });
        // }, 500);
      }
    }
  },
  methods: {
    animationEnter(el, done) {
      this.$anime({
        delay: el => el.dataset.delay,
        targets: el,
        duration: 2000,
        easing: "easeOutExpo",
        translateX: [100, 0],
        opacity: [0, 1],
        // delay: 600,
        complete: done
      });
    },
    initResizeObserver() {
      this.resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          const newHeight = entry.contentRect.height;
          // console.log("Height changed:", newHeight);
          // this.$anime({
          //   targets: this.$refs.outer,
          //   height: newHeight,
          //   easing: "easeOutExpo",
          // });
          if (this.$refs.outer) this.$refs.outer.$el.style.height = `${newHeight}px`;
        }
      });
      if (this.$refs.inner) this.resizeObserver.observe(this.$refs.inner);
    },
    cleanupResizeObserver() {
      if (this.resizeObserver) {
        this.resizeObserver.disconnect();
      }
    },
    handleCloseTemplateBuilder() {
      this.delayPushSelectedTemplatesInterval = setInterval(() => {
        if (this.showTemplateBuilder === false) {
          if (this.selectedTemplatesInTemplateBuilder.length > 0) {
            this.selectedTemplates = [...new Set([...this.selectedTemplates, ...[this.selectedTemplatesInTemplateBuilder[0]]])];
            this.selectedTemplatesInTemplateBuilder = this.selectedTemplatesInTemplateBuilder.slice(1);
            if (this.selectedTemplatesInTemplateBuilder.length === 0) {
              clearInterval(this.delayPushSelectedTemplatesInterval);
            }
          }
        } else {
          clearInterval(this.delayPushSelectedTemplatesInterval);
        }
      }, 200);
      this.showTemplateBuilder = false;
    },
    handleTemplateBuilderClickTemplate(template) {
      this.$nextTick(() => {
        this.$refs.template_builder.selectedTemplate = null;
      });
      if (this.selectedTemplatesInTemplateBuilder.includes(template.id)) {
        this.selectedTemplatesInTemplateBuilder.splice(this.selectedTemplatesInTemplateBuilder.indexOf(template.id), 1);
      } else {
        this.selectedTemplatesInTemplateBuilder.push(template.id);
      }
    },
    animationEnterIsSelectedInTemplateBuilder(el, done) {
      this.$anime({
        targets: el,
        translateY: [10, 0],
        opacity: [0, 1],
        complete: done
      });
    },
    animationLeaveIsSelectedInTemplateBuilder(el, done) {
      this.$anime({
        targets: el,
        translateY: [0, 10],
        opacity: [1, -0.2],
        complete: done
      });
    }
  }
};
export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'OnboardingComponent',
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_CheckIcon = _resolveComponent("CheckIcon");
      const _component_TrashIcon = _resolveComponent("TrashIcon");
      const _component_ButtonComponent = _resolveComponent("ButtonComponent");
      const _component_TemplateDisplay = _resolveComponent("TemplateDisplay");
      const _component_ArrowUpIcon = _resolveComponent("ArrowUpIcon");
      const _component_ArrowDownIcon = _resolveComponent("ArrowDownIcon");
      const _component_PlusIcon = _resolveComponent("PlusIcon");
      const _component_LayoutTemplateIcon = _resolveComponent("LayoutTemplateIcon");
      const _component_ArrowRightIcon = _resolveComponent("ArrowRightIcon");
      const _component_TransitionGroupHelper = _resolveComponent("TransitionGroupHelper");
      const _component_PanelHelper = _resolveComponent("PanelHelper");
      const _component_XIcon = _resolveComponent("XIcon");
      const _component_DialogTitle = _resolveComponent("DialogTitle");
      const _component_DialogPanel = _resolveComponent("DialogPanel");
      const _component_Dialog = _resolveComponent("Dialog");
      return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_Transition, {
        appear: "",
        onEnter: _cache[0] || (_cache[0] = (el, done) => {
          _ctx.$anime({
            targets: el,
            delay: 500,
            duration: 2000,
            easing: 'easeOutExpo',
            translateY: [100, 0],
            opacity: [0, 1],
            complete: done
          });
        })
      }, {
        default: _withCtx(() => _cache[9] || (_cache[9] = [_createElementVNode("h1", {
          class: "mb-2 font-serif text-3xl font-bold leading-tight text-neutral-900 sm:text-5xl sm:leading-tight md:mb-10 md:text-7xl md:leading-tight dark:text-neutral-100"
        }, [_createTextVNode(" Welcome to "), _createElementVNode("span", {
          class: "rounded-lg bg-neutral-900 px-2 text-white dark:bg-neutral-100 dark:text-black"
        }, " Pensive ")], -1)])),
        _: 1
      }), _createVNode(_TransitionGroup, {
        appear: true,
        onEnter: _ctx.animationEnter
      }, {
        default: _withCtx(() => _cache[10] || (_cache[10] = [_createElementVNode("p", {
          key: "2",
          class: "italic text-neutral-500 dark:text-neutral-400",
          "data-delay": "2000"
        }, " Your Space is ready, but it's waiting for your touch. Let's bring it to life. ", -1)])),
        _: 1
      }, 8, ["onEnter"])]), _createVNode(_Transition, {
        onEnter: _cache[3] || (_cache[3] = el => {
          _ctx.$anime({
            targets: el,
            delay: 500,
            duration: 3000,
            easing: 'easeOutExpo',
            translateY: [100, 0],
            opacity: [0, 1],
            complete: _ctx.done
          });
        })
      }, {
        default: _withCtx(() => [_ctx.showBuilder ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_cache[18] || (_cache[18] = _createElementVNode("p", {
          class: "px-5 leading-normal text-neutral-600 dark:text-neutral-300"
        }, [_createElementVNode("b", {
          class: "font-semibold"
        }, "Select Templates"), _createTextVNode(" to get started and make it uniquely yours. ")], -1)), _createVNode(_component_PanelHelper, {
          ref: "outer",
          class: "box-content",
          style: {
            "transition": "height 1s cubic-bezier(0.16, 1, 0.3, 1)"
          }
        }, {
          default: _withCtx(() => [_createElementVNode("ul", _hoisted_6, [_createVNode(_component_TransitionGroupHelper, {
            enter: {
              // easing: 'easeOutExpo',
              translateY: [50, 0],
              opacity: [0, 1]
            }
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedTemplates.map(id => _ctx.templates.find(t => t.id == id)), (item, index) => {
              return _openBlock(), _createElementBlock("div", {
                key: item.id,
                class: "relative col-span-2 flex gap-4 rounded-xl border border-neutral-100 p-5 sm:col-span-3 md:col-span-4 lg:col-span-5 dark:border-neutral-900"
              }, [_createVNode(_component_TemplateDisplay, {
                modelValue: item,
                class: "grow rounded-xl"
              }, {
                top: _withCtx(() => [_createVNode(_component_ButtonComponent, {
                  onClick: _withModifiers($event => _ctx.selectedTemplates.includes(item.id) ? _ctx.selectedTemplates.splice(_ctx.selectedTemplates.indexOf(item.id), 1) : _ctx.selectedTemplates.push(item.id), ["stop"]),
                  variant: "minimal",
                  class: "group/selected w-[90px] cursor-pointer !justify-center rounded bg-neutral-900 px-2 py-1 text-center text-xs !text-neutral-100",
                  title: "Remove this template"
                }, {
                  default: _withCtx(() => [_createElementVNode("span", _hoisted_7, [_createVNode(_component_CheckIcon, {
                    size: "16",
                    class: "inline"
                  }), _cache[11] || (_cache[11] = _createTextVNode(" Selected "))]), _createElementVNode("span", _hoisted_8, [_createVNode(_component_TrashIcon, {
                    size: "16",
                    class: "inline"
                  }), _cache[12] || (_cache[12] = _createTextVNode(" Remove "))])]),
                  _: 2
                }, 1032, ["onClick"])]),
                _: 2
              }, 1032, ["modelValue"]), _createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [index > 0 ? (_openBlock(), _createBlock(_component_ButtonComponent, {
                key: 0,
                onClick: _withModifiers(() => {
                  const i = _ctx.selectedTemplates.indexOf(item.id);
                  if (i > 0) {
                    const _item = _ctx.selectedTemplates.splice(i, 1)[0];
                    _ctx.selectedTemplates.splice(i - 1, 0, _item);
                  }
                }, ["stop"]),
                variant: "round",
                color: "secondary",
                size: "sm",
                title: "Move up"
              }, {
                default: _withCtx(() => [_createVNode(_component_ArrowUpIcon, {
                  size: "20"
                })]),
                _: 2
              }, 1032, ["onClick"])) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_11, [_createVNode(_component_ButtonComponent, {
                onClick: _withModifiers($event =>
                // applyTemplateToEntry(item, entry), (showTemplates = false)
                _ctx.selectedTemplates.includes(item.id) ? _ctx.selectedTemplates.splice(_ctx.selectedTemplates.indexOf(item.id), 1) : _ctx.selectedTemplates.push(item.id), ["stop"]),
                variant: "round",
                color: "secondary",
                size: "sm",
                title: "Remove this template"
              }, {
                default: _withCtx(() => [_createVNode(_component_TrashIcon, {
                  size: "20"
                })]),
                _: 2
              }, 1032, ["onClick"])]), _createElementVNode("div", _hoisted_12, [index < _ctx.selectedTemplates.length - 1 ? (_openBlock(), _createBlock(_component_ButtonComponent, {
                key: 0,
                onClick: _withModifiers(() => {
                  const i = _ctx.selectedTemplates.indexOf(item.id);
                  if (i < _ctx.selectedTemplates.length - 1) {
                    const _item = _ctx.selectedTemplates.splice(i, 1)[0];
                    _ctx.selectedTemplates.splice(i + 1, 0, _item);
                  }
                }, ["stop"]),
                variant: "round",
                color: "secondary",
                size: "sm",
                title: "Move down"
              }, {
                default: _withCtx(() => [_createVNode(_component_ArrowDownIcon, {
                  size: "20"
                })]),
                _: 2
              }, 1032, ["onClick"])) : _createCommentVNode("", true)])])]);
            }), 128)), _createElementVNode("div", _hoisted_13, [_cache[13] || (_cache[13] = _createElementVNode("div", {
              class: "absolute inset-0 flex items-center",
              "aria-hidden": "true"
            }, [_createElementVNode("div", {
              class: "w-full border-t border-neutral-100 dark:border-neutral-900"
            })], -1)), _createElementVNode("div", _hoisted_14, [_createElementVNode("div", _hoisted_15, [_createElementVNode("div", _hoisted_16, [_createVNode(_component_PlusIcon, {
              size: "24",
              strokeWidth: "2"
            })])])])]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recommendations, item => {
              return _openBlock(), _createElementBlock("div", {
                key: item.id
              }, [_createElementVNode("div", {
                onClick: $event => _ctx.addTemplate(item),
                class: "group flex h-full cursor-pointer flex-col gap-2 rounded-xl border border-neutral-100 p-5 transition-all duration-300 hover:-translate-y-2 hover:shadow-lg dark:border-neutral-900"
              }, [_createVNode(_component_TemplateDisplay, {
                modelValue: item,
                showPreview: false,
                size: "sm"
              }, null, 8, ["modelValue"]), _createElementVNode("div", _hoisted_18, [_createElementVNode("div", _hoisted_19, [_createVNode(_component_PlusIcon, {
                size: "24"
              })])])], 8, _hoisted_17)]);
            }), 128)), _ctx.templatesAvailable.length > _ctx.recommendationsLimit ? (_openBlock(), _createElementBlock("div", {
              key: "more-button",
              onClick: _cache[1] || (_cache[1] = $event => _ctx.showTemplateBuilder = true)
            }, [_createElementVNode("div", _hoisted_20, [_createElementVNode("div", _hoisted_21, [_createElementVNode("div", _hoisted_22, [_createElementVNode("div", _hoisted_23, [_createElementVNode("div", _hoisted_24, [_createElementVNode("span", _hoisted_25, [_createVNode(_component_LayoutTemplateIcon, {
              size: "24"
            })])])]), _cache[14] || (_cache[14] = _createElementVNode("a", {
              class: "font-semibold text-neutral-900 dark:text-neutral-400"
            }, " More Templates ", -1)), _cache[15] || (_cache[15] = _createElementVNode("p", {
              class: "text-sm text-neutral-500"
            }, " Show more templates to help you get started. ", -1))])]), _createElementVNode("div", _hoisted_26, [_createElementVNode("div", _hoisted_27, [_createVNode(_component_ArrowRightIcon, {
              size: "24"
            })])])])])) : _createCommentVNode("", true)]),
            _: 1
          })], 512)]),
          _: 1
        }, 512), _createElementVNode("div", _hoisted_28, [_createElementVNode("span", _hoisted_29, [_createElementVNode("b", _hoisted_30, _toDisplayString(_ctx.selectedTemplates.length), 1), _cache[16] || (_cache[16] = _createTextVNode(" Template(s) selected "))]), _createVNode(_component_ButtonComponent, {
          ref: "continue",
          disabled: !_ctx.selectedTemplates.length,
          onClick: _cache[2] || (_cache[2] = $event => _ctx.selectedTemplates.length ? [_ctx.selectedTemplates.map(id => _ctx.templates.find(t => t.id === id)).forEach(t => _ctx.setTemplate(t)), _ctx.selected = []] : _ctx.addEmptyEntry()),
          color: "primary",
          title: _ctx.selectedTemplates.length ? null : 'Please select at least one template to continue',
          class: _normalizeClass(_ctx.selectedTemplates.length ? null : 'cursor-not-allowed !bg-neutral-500')
        }, {
          default: _withCtx(() => [_cache[17] || (_cache[17] = _createTextVNode(" Continue ")), _createVNode(_component_ArrowRightIcon, {
            class: "h-4 w-4"
          })]),
          _: 1
        }, 8, ["disabled", "title", "class"])])])])])) : _createCommentVNode("", true)]),
        _: 1
      }), _ctx.showTemplateBuilder ? (_openBlock(), _createBlock(_component_Dialog, {
        key: 0,
        as: "div",
        open: true,
        onClose: _ctx.handleCloseTemplateBuilder,
        class: "relative z-[999] h-px w-px"
      }, {
        default: _withCtx(() => [_createVNode(_Transition, {
          onEnter: _cache[4] || (_cache[4] = (el, done) => {
            _ctx.$anime({
              duration: 300,
              easing: 'linear',
              targets: el,
              opacity: [0, 1],
              complete: done
            });
          }),
          onLeave: _cache[5] || (_cache[5] = (el, done) => {
            _ctx.$anime({
              duration: 300,
              easing: 'linear',
              targets: el,
              opacity: [1, 0],
              complete: done
            });
          }),
          css: false,
          appear: ""
        }, {
          default: _withCtx(() => _cache[19] || (_cache[19] = [_createElementVNode("div", {
            class: "fixed inset-0 backdrop-blur"
          }, null, -1)])),
          _: 1
        }), _createVNode(_Transition, {
          onEnter: _cache[7] || (_cache[7] = (el, done) => {
            _ctx.$anime({
              targets: el,
              opacity: [0, 1],
              translateY: [200, 0],
              complete: done
            });
          }),
          onLeave: _cache[8] || (_cache[8] = (el, done) => {
            _ctx.$anime({
              targets: el,
              opacity: [1, 0],
              translateY: [0, 200],
              complete: done
            });
          }),
          css: true,
          appear: ""
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_31, [_createElementVNode("div", _hoisted_32, [_createVNode(_component_DialogPanel, {
            class: "relative max-w-[90vw] transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-2 text-left shadow-xl ring-1 ring-transparent transition-all sm:my-8 sm:w-full sm:px-6 sm:pb-6 md:max-w-3xl lg:max-w-4xl dark:bg-black dark:ring-neutral-700"
          }, {
            default: _withCtx(() => [_createVNode(_component_DialogTitle, {
              as: "h3",
              class: "sticky top-0 z-10 -mx-4 flex justify-between rounded-b bg-white px-4 py-4 dark:bg-black"
            }, {
              default: _withCtx(() => [_cache[20] || (_cache[20] = _createElementVNode("span", {
                class: "text-xl font-semibold"
              }, "Templates", -1)), _createVNode(_component_ButtonComponent, {
                onClick: _ctx.handleCloseTemplateBuilder,
                color: "secondary",
                size: "sm",
                class: "!px-1"
              }, {
                default: _withCtx(() => [_createVNode(_component_XIcon, {
                  size: "20"
                })]),
                _: 1
              }, 8, ["onClick"])]),
              _: 1
            }), _createVNode(TemplateBuilder, {
              ref: "template_builder",
              filterTemplates: t => !_ctx.selectedTemplates.includes(t.id),
              hideCustomTemplates: true,
              onClickTemplate: _cache[6] || (_cache[6] = template => _ctx.handleTemplateBuilderClickTemplate(template))
            }, {
              template_top: _withCtx(({
                template
              }) => [_createVNode(_Transition, {
                onEnter: _ctx.animationEnterIsSelectedInTemplateBuilder,
                onLeave: _ctx.animationLeaveIsSelectedInTemplateBuilder
              }, {
                default: _withCtx(() => [_ctx.selectedTemplatesInTemplateBuilder.includes(template.id) ? (_openBlock(), _createBlock(_component_ButtonComponent, {
                  key: 0,
                  variant: "minimal",
                  class: "group/selected w-[90px] cursor-pointer !justify-center rounded bg-neutral-900 px-2 py-1 text-center text-xs !text-neutral-100",
                  title: "Remove this template"
                }, {
                  default: _withCtx(() => [_createElementVNode("span", _hoisted_33, [_createVNode(_component_CheckIcon, {
                    size: "16",
                    class: "inline"
                  }), _cache[21] || (_cache[21] = _createTextVNode(" Selected "))]), _createElementVNode("span", _hoisted_34, [_createVNode(_component_TrashIcon, {
                    size: "16",
                    class: "inline"
                  }), _cache[22] || (_cache[22] = _createTextVNode(" Unselect "))])]),
                  _: 1
                })) : _createCommentVNode("", true)]),
                _: 2
              }, 1032, ["onEnter", "onLeave"])]),
              _: 1
            }, 8, ["filterTemplates"]), _createElementVNode("div", _hoisted_35, [_createVNode(_component_ButtonComponent, {
              color: "primary",
              onClick: _ctx.handleCloseTemplateBuilder
            }, {
              default: _withCtx(() => _cache[23] || (_cache[23] = [_createTextVNode(" Done ")])),
              _: 1
            }, 8, ["onClick"])])]),
            _: 1
          })])])]),
          _: 1
        })]),
        _: 1
      }, 8, ["onClose"])) : _createCommentVNode("", true)])]);
    };
  }
});