import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "sm:hidden"
};
const _hoisted_2 = {
  class: "sm:hidden"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_HomeIcon = _resolveComponent("HomeIcon");
  const _component_PSButton = _resolveComponent("PSButton");
  const _component_SearchIcon = _resolveComponent("SearchIcon");
  const _component_AnchorizedOutputDisplay = _resolveComponent("AnchorizedOutputDisplay");
  const _component_QuickAddEntryMenu = _resolveComponent("QuickAddEntryMenu");
  return _openBlock(), _createElementBlock("div", {
    id: "application-footer",
    class: _normalizeClass(_ctx.$style.applicationFooter)
  }, [_createElementVNode("div", {
    class: _normalizeClass(_ctx.$style.applicationFooterInner)
  }, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_PSButton, {
    onClick: _cache[0] || (_cache[0] = () => {
      _ctx.$router.push({
        name: 'entry',
        params: {
          id: $options.anchors[0].id
        }
      });
    }),
    class: _normalizeClass(_ctx.$style.footerButton)
  }, {
    default: _withCtx(() => [_cache[2] || (_cache[2] = _createElementVNode("span", {
      class: "sr-only"
    }, "Go home", -1)), _createVNode(_component_HomeIcon, {
      size: "24",
      "stroke-width": "2"
    })]),
    _: 1
  }, 8, ["class"])]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_PSButton, {
    onClick: _cache[1] || (_cache[1] = $event => _ctx.$emit('toggleCommandPalette')),
    class: _normalizeClass(_ctx.$style.footerButton)
  }, {
    default: _withCtx(() => [_createVNode(_component_SearchIcon, {
      size: "24",
      "stroke-width": "2"
    })]),
    _: 1
  }, 8, ["class"])]), _createElementVNode("div", {
    class: _normalizeClass([_ctx.$style.xScroll, 'sm:hidden'])
  }, [_createVNode(_component_AnchorizedOutputDisplay, {
    anchor: "main",
    class: _normalizeClass([_ctx.$style.anchorizedOutputDisplay, _ctx.$style.footerButton])
  }, null, 8, ["class"])], 2), _createElementVNode("div", {
    class: _normalizeClass(_ctx.$style.moveUpOnDesktop)
  }, [_createVNode(_component_QuickAddEntryMenu)], 2)], 2)], 2);
}