export const Applicators = {
  methods: {
    applyableInputs(entry) {
      if (entry.temp || !this.inputs) return [];

      var string = JSON.stringify(entry);
      return this.inputs.filter((input) => {
        var clone = JSON.parse(string);
        clone = this.applyInput(
          this.inputSchema(
            input, clone
          ),
          clone
        );
        return string != JSON.stringify(clone);
      });
    },
    inputApplyable(input, entry) {
      const before = JSON.stringify(entry);
      const after = JSON.stringify(this.applyInput(this.inputSchema(input, entry), JSON.parse(before)));
      // console.log("inputApplyable", before, after, before != after);
      return before != after;
    },
    applyInput(input, entry = null, save = false) {
      // console.log("APPLY INPUT", input, entry.name);
      const clone = JSON.stringify(entry);

      // eslint-disable-next-line no-unreachable
      if (input.schedule) {
        if (
          entry.schedule &&
          entry.schedule.id &&
          input.schedule &&
          input.schedule.id
        )
          input.schedule.id = entry.schedule.id;

        this.setEntrySchedule(entry, input.schedule);
      }
      // console.log("applyInput", JSON.stringify(input));
      if (input.status) {
        this.setStatus(entry, input.status.id);
      }

      for (const [key, value] of Object.entries(input)) {
        switch (key) {
          // case "completion":
          // case "status":
          // case "anchor":
          case "output":
          case "input":
          case "priority":
          case "routine":
          case "description":
            if (entry[key] && entry[key].id && value && value.id)
              value.id = entry[key].id;
            entry[key] = { ...entry[key], ...value };
            break;
          case "links":
            entry[key] = value.map(link => {
              if (typeof link === 'string' || typeof link === 'number') {
                return {
                  id: link,
                  settings: {},
                  created_at: this.$moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                  updated_at: this.$moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                  deleted_at: null
                };
              }
              return {
                ...link,
                settings: link.settings || {},
                created_at: this.$moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                updated_at: this.$moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                deleted_at: null
              };
            });
            break;
          case "anchors":
            if (!entry[key]) entry[key] = [];

            var existingAnchors = new Map();
            entry[key].forEach(anchor => {
              existingAnchors.set(anchor.placement, anchor);
            });

            // Merge or add new anchors
            entry[key] = value.map(newAnchor => {
              const existing = existingAnchors.get(newAnchor.placement);
              if (existing) {
                // Preserve existing anchor's ID and other properties while updating with new values
                return {
                  ...existing,
                  ...newAnchor
                };
              }
              return newAnchor;
            });

            // Add back any existing anchors that weren't in the new value
            entry[key].push(...Array.from(existingAnchors.values())
              .filter(anchor => !value.some(newAnchor => newAnchor.placement === anchor.placement)));
            break;
          case "custom_fields":
          case "statuses":
          case "senses":
          case "color":
            entry[key] = value;
            break;
          case "time_tracking":
            if (value && value.start_at && value.start_at?.op && value.start_at?.op == 'now') {
              this.startTimeTracking(entry);
            }
            break;
        }
      }
      // console.log(clone, JSON.stringify(entry));
      if (save && clone != JSON.stringify(entry)) {

        // we need to keep the input_id if it exists, because it's used for the schema
        if (input.input_id) {
          entry.input_id = input.input_id;
        }
        this.$store.dispatch("push", {
          event: "entry_update",
          params: { entry: entry },
          entry: entry,
          source: "Applicator"
        });
      }
      return entry;
    },
  },
};
