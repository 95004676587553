export default {
  provide() {
    return {
      position: "popover"
    };
  },
  mounted() {
    if (this.isOpen) {
      setTimeout(() => {
        this.$refs.reference.$el.click();
      }, 50);
    }
  }
};