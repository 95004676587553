import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, withModifiers as _withModifiers, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  ref: "reference",
  class: "pointer-events-none leading-[0]"
};
const _hoisted_2 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PlusIcon = _resolveComponent("PlusIcon");
  const _component_PSButton = _resolveComponent("PSButton");
  const _component_EntryDisplay = _resolveComponent("EntryDisplay");
  const _component_PSListItem = _resolveComponent("PSListItem");
  const _component_PSList = _resolveComponent("PSList");
  const _component_PSOverlay = _resolveComponent("PSOverlay");
  const _component_InputDisplay = _resolveComponent("InputDisplay");
  const _component_ModalHelper = _resolveComponent("ModalHelper");
  const _directive_touch = _resolveDirective("touch");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_PSOverlay, {
    placement: "top-center"
  }, {
    button: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_withDirectives((_openBlock(), _createBlock(_component_PSButton, {
      onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
      onMousedown: $options.onMouseDown,
      onMouseup: $options.onMouseUp,
      title: $options.entriesWithInput && $options.entriesWithInput.length ? `Add Entry to ${$options.entriesWithInput[0].name || 'Unnamed'}` : 'Add Entry',
      shortcode: "+",
      class: _normalizeClass(_ctx.$style.addButtonOuter)
    }, {
      default: _withCtx(() => [_createVNode(_component_PSButton, {
        class: _normalizeClass(_ctx.$style.addButton),
        style: _normalizeStyle({
          '--ps-color': $options.rootEntry?.color ? _ctx.$colors[$options.rootEntry.color][500] : _ctx.$colors.neutral[900]
        })
      }, {
        default: _withCtx(() => [_createVNode(_component_PlusIcon, {
          ref: "button",
          size: "24",
          "stroke-width": "2"
        }, null, 512)]),
        _: 1
      }, 8, ["class", "style"])]),
      _: 1
    }, 8, ["onMousedown", "onMouseup", "title", "class"])), [[_directive_touch, $options.onMouseDown, "press", {
      stop: true
    }], [_directive_touch, $options.onMouseUp, "release", {
      stop: true
    }]])], 512)]),
    title: _withCtx(() => _cache[5] || (_cache[5] = [_createTextVNode(" Create an entry to any of these options: ")])),
    default: _withCtx(({
      close
    }) => [_createVNode(_component_PSList, null, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.inputs.slice(0, $options.inputs.length), (input, key) => {
        return _openBlock(), _createBlock(_component_PSListItem, {
          key: key,
          onClick: $event => ($data.activeInput = input, close(), $data.showInputModal = true)
        }, {
          default: _withCtx(() => [_createVNode(_component_EntryDisplay, {
            modelValue: $options.inputSchemaWithId(input.schema, input.entry.name),
            disabled: true,
            display: {
              settings: false
            },
            class: "pointer-events-none w-full"
          }, null, 8, ["modelValue"])]),
          _: 2
        }, 1032, ["onClick"]);
      }), 128))]),
      _: 2
    }, 1024)]),
    _: 1
  }), _createVNode(_component_ModalHelper, {
    show: $data.showInputModal,
    onClose: _cache[4] || (_cache[4] = $event => $data.showInputModal = false)
  }, {
    title: _withCtx(() => [_cache[6] || (_cache[6] = _createTextVNode(" Create Entry ")), $data.activeInput.entry.name ? (_openBlock(), _createElementBlock("span", _hoisted_2, " in " + _toDisplayString(_ctx.$clearMarkdown($data.activeInput.entry.name)), 1)) : _createCommentVNode("", true)]),
    default: _withCtx(() => [_createVNode(_component_InputDisplay, {
      modelValue: $data.activeInput.entry.input,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.activeInput.entry.input = $event),
      entry: $data.activeInput.entry,
      schema: $data.activeInput.schema,
      color: $data.activeInput.entry.color,
      focusOnMount: true,
      onCreated: _cache[2] || (_cache[2] = $event => $data.showInputModal = false),
      onOnCancel: _cache[3] || (_cache[3] = $event => $data.showInputModal = false)
    }, {
      settings: _withCtx(() => _cache[7] || (_cache[7] = [_createTextVNode("  ")])),
      _: 1
    }, 8, ["modelValue", "entry", "schema", "color"])]),
    _: 1
  }, 8, ["show"])]);
}