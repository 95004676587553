export default {
  provide() {
    return {
      onChildOverlayOpen: this.expand
    };
  },
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpen: this.open,
      deltaY: 0,
      deltaYTimeout: null,
      deltaYDebounced: 0,
      isTouching: false,
      touchStartY: 0,
      initialScrollTop: 0,
      scrollTop: 0,
      initialHeight: 0,
      modalTransition: window.innerHeight * 0.4,
      // @note with 90dvh height and 40% transition, the modal will be half the screen
      isExpanded: false,
      temporarilyDisableDrag: false,
      hoverTimeout: null
    };
  },
  mounted() {
    if (this.isOpen) {
      this.$nextTick(() => {
        if (this.$refs.modal) {
          this.$refs.modal.addEventListener("touchstart", this.handleTouchStart);
          this.$refs.modal.addEventListener("touchmove", this.handleTouchMove);
          this.$refs.modal.addEventListener("touchend", this.handleTouchEnd);
        }
        this.$emit("onModalOpen");
      });
    }
  },
  watch: {
    open() {
      // console.log("open", this.open);
      this.isOpen = this.open;
    },
    isOpen() {
      if (this.hoverTimeout) clearTimeout(this.hoverTimeout);
      this.modalTransition = window.innerHeight * 0.4;
      this.isExpanded = false;
      if (this.isOpen) {
        // Only scroll up when open, otherwise it will jump in the closing transition
        this.scrollTop = 0;
        this.initialScrollTop = 0;
        if (this.$refs.content) {
          this.$refs.content.scrollTop = 0;
        }
        this.$nextTick(() => {
          if (this.$refs.modal) {
            this.$refs.modal.addEventListener("touchstart", this.handleTouchStart);
            this.$refs.modal.addEventListener("touchmove", this.handleTouchMove);
            this.$refs.modal.addEventListener("touchend", this.handleTouchEnd);
          }
          this.$emit("onModalOpen");
        });
      } else {
        if (this.$refs.modal) {
          this.$refs.modal.removeEventListener("touchstart", this.handleTouchStart);
          this.$refs.modal.removeEventListener("touchmove", this.handleTouchMove);
          this.$refs.modal.removeEventListener("touchend", this.handleTouchEnd);
        }
        this.$emit("onModalClose");
      }
    },
    scrollTop: function (newVal) {
      if (newVal > 0) {
        this.temporarilyDisableDrag = true;
      }
    },
    isTouching: function (newVal) {
      if (!newVal) {
        this.temporarilyDisableDrag = false;
        this.initialScrollTop = 0;
      }
    },
    deltaY: function (newVal) {
      this.deltaYDebounced = newVal;
      if (this.deltaYTimeout) clearTimeout(this.deltaYTimeout);
      this.deltaYTimeout = setTimeout(() => {
        this.deltaYDebounced = null;
      }, 20);
    }
  },
  computed: {
    isRenderBackdrop() {
      return true;
      // return (
      //   this.renderBackdrop || window.matchMedia("(pointer: coarse)").matches
      // );
    }
  },
  methods: {
    expand() {
      this.modalTransition = 0;
      this.isExpanded = true;
    },
    close() {
      this.isOpen = false;
    },
    handleTouchStart(e) {
      // console.log("handleTouchStart");
      this.isTouching = true;
      this.touchStartY = e.touches[0].clientY;
      this.initialScrollTop = this.$refs.content.scrollTop;
      this.scrollTop = this.initialScrollTop;
      this.initialTransition = this.modalTransition;
    },
    handleTouchMove(e) {
      if (!this.isTouching) return;

      // Clear any active element focus
      if (document.activeElement) {
        // document.activeElement.blur();
      }
      const currentY = e.touches[0].clientY;
      this.deltaY = currentY - this.touchStartY;
      this.scrollTop = this.$refs.content.scrollTop;

      // Check if target is inside content and content is scrollable
      const isTargetInContent = this.$refs.content?.contains(e.target);
      // const isContentScrollable =
      //   this.$refs.content?.scrollHeight > this.$refs.content?.clientHeight;

      // console.log("isTargetInContent", isTargetInContent);
      // console.log("isContentScrollable", isContentScrollable);
      // Only handle gestures if we're at the top or pulling up
      if (!this.isExpanded || !isTargetInContent || !this.temporarilyDisableDrag && this.initialScrollTop <= 0 && this.scrollTop <= 0) {
        this.modalTransition = Math.max(this.initialTransition + this.deltaY, 0);
        if (this.deltaY > 0) {
          e.preventDefault();
        }
      }
    },
    handleTouchEnd() {
      // console.log("handleTouchEnd");
      this.isTouching = false;
      const maxHeight = 0;
      const normalHeight = window.innerHeight * 0.4;
      const threshold = window.innerHeight * 0.25;
      if (this.deltaYDebounced !== null && Math.abs(this.deltaYDebounced) > 10 && this.scrollTop <= 0 && this.initialScrollTop <= 0) {
        if (this.deltaYDebounced <= 0) {
          if (!this.isExpanded) {
            this.modalTransition = maxHeight;
            this.isExpanded = true;
          }
        } else {
          if (this.modalTransition < normalHeight) {
            this.modalTransition = normalHeight;
            this.isExpanded = false;
          } else {
            // If pulled down far enough, close
            this.isOpen = false;
          }
        }
      } else {
        if (this.modalTransition > window.innerHeight * 0.7) {
          // If pulled down far enough, close
          this.isOpen = false;
        } else {
          if (this.modalTransition < threshold) {
            this.modalTransition = maxHeight;
            this.isExpanded = true;
          } else {
            this.modalTransition = normalHeight;
            this.isExpanded = false;
          }
        }
        if (!this.isExpanded) {
          // this.scrollTop = 0;
          // this.initialScrollTop = 0;
          // this.$refs.content.scrollTop = 0;
        }
      }
    }
  }
};