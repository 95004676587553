import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.reduce.js";
import TimeTrackingSettings from "@/components/timetrackings/TimeTrackingSettings.vue";
import TimeTrackingListUserDropdown from "./TimeTrackingListUserDropdown.vue";
export default {
  components: {
    TimeTrackingSettings,
    TimeTrackingListUserDropdown
  },
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    entry: Object,
    mode: {
      type: String,
      default: "entry"
    }
  },
  computed: {
    time_trackings: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      }
    },
    time_trackings_by_users() {
      return this.time_trackings.reduce((acc, time_tracking) => {
        if (!acc[time_tracking.user_id]) {
          acc[time_tracking.user_id] = [];
        }
        acc[time_tracking.user_id].push(time_tracking);
        return acc;
      }, {});
    },
    users() {
      return this.$store.getters.users;
    }
  },
  mounted() {
    // if (Object.keys(this.time_trackings_by_users).length == 1) {
    //   this.$refs.time_tracking_list_user_dropdown[0].open = true;
    // }
  }
};