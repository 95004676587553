import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, renderList as _renderList, createSlots as _createSlots } from "vue";
const _hoisted_1 = ["data-position", "data-active", "data-entry-id", "data-color", "tabindex"];
const _hoisted_2 = {
  class: "whitespace-nowrap text-xs text-neutral-500"
};
const _hoisted_3 = {
  key: 1
};
const _hoisted_4 = {
  key: 1
};
const _hoisted_5 = {
  key: 0,
  class: "whitespace-nowrap text-xs text-neutral-500"
};
const _hoisted_6 = {
  key: 0,
  class: "whitespace-nowrap text-xs text-neutral-500"
};
const _hoisted_7 = {
  key: 0,
  class: "whitespace-nowrap text-xs text-neutral-500"
};
const _hoisted_8 = {
  key: 0,
  class: "whitespace-nowrap text-xs text-neutral-500"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CoverDisplay = _resolveComponent("CoverDisplay");
  const _component_ChevronRightIcon = _resolveComponent("ChevronRightIcon");
  const _component_PSButton = _resolveComponent("PSButton");
  const _component_NameDisplay = _resolveComponent("NameDisplay");
  const _component_ProcrastinationDisplay = _resolveComponent("ProcrastinationDisplay");
  const _component_PriorityDisplay = _resolveComponent("PriorityDisplay");
  const _component_StatusDisplay = _resolveComponent("StatusDisplay");
  const _component_SensesDisplay = _resolveComponent("SensesDisplay");
  const _component_LinksDisplay = _resolveComponent("LinksDisplay");
  const _component_DescriptionDisplay = _resolveComponent("DescriptionDisplay");
  const _component_ScheduleDisplay = _resolveComponent("ScheduleDisplay");
  const _component_TimeTrackingsDisplay = _resolveComponent("TimeTrackingsDisplay");
  const _component_RoutineDisplay = _resolveComponent("RoutineDisplay");
  const _component_CustomFieldsDisplay = _resolveComponent("CustomFieldsDisplay");
  const _component_EllipsisIcon = _resolveComponent("EllipsisIcon");
  const _component_EntryContextmenu = _resolveComponent("EntryContextmenu");
  const _component_PSOverlay = _resolveComponent("PSOverlay");
  const _component_OutputSettings = _resolveComponent("OutputSettings");
  const _component_InputSettings = _resolveComponent("InputSettings");
  return _openBlock(), _createElementBlock("div", {
    "data-position": $props.position,
    "data-active": $options.isActive,
    ref: "wrapper",
    class: _normalizeClass(["ps_entry_display group relative", {
      [_ctx.$style.entryDisplay]: true,
      [_ctx.$style.entryDisplay_temp]: $options.entry.temp,
      [_ctx.$style.entryDisplay_deleted]: $options.entry.deleted_at,
      [_ctx.$style.entryDisplay_selected]: $props.position != 'draggable' && $data.isSelected && !$data.isDragged,
      [_ctx.$style.entryDisplay_muted]: _ctx.$store.getters.selected.length && !$options.entry.temp
    }]),
    "data-entry-id": $options.entry.temp ? null : $options.entry.id,
    "data-color": $options.entry.color,
    onClick: _cache[22] || (_cache[22] = (...args) => $options.onClick && $options.onClick(...args)),
    tabindex: $options.entry.temp || $options.entry.id == _ctx.$route.params.id || $options.entry.id == _ctx.$route.params.id2 ? -1 : 0,
    onContextmenu: _cache[23] || (_cache[23] = _withModifiers((...args) => $options.contextMenu && $options.contextMenu(...args), ["prevent"])),
    onMousedown: _cache[24] || (_cache[24] = (...args) => _ctx.onMouseDown && _ctx.onMouseDown(...args)),
    onMouseenter: _cache[25] || (_cache[25] = _withModifiers(e => !$options.entry.temp ? _ctx.onMouseEnter(e, _ctx.DndInfo) : null, ["stop"])),
    onMouseleave: _cache[26] || (_cache[26] = _withModifiers(e => !$options.entry.temp ? _ctx.onMouseLeave(e) : null, ["stop"])),
    onTouchstart: _cache[27] || (_cache[27] = e => !$options.entry.temp ? _ctx.onTouchStart(e) : null),
    style: _normalizeStyle($options.cssProps),
    "data-has-mouseenter": "true"
  }, [_ctx.renderDnDBeforeEdge ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    "data-dnd-edge": "before",
    class: _normalizeClass([_ctx.$style.dndEdge, _ctx.$style.dndEdge_Before])
  }, null, 2)) : _createCommentVNode("", true), _ctx.renderDnDAfterEdge ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    "data-dnd-edge": "after",
    class: _normalizeClass([_ctx.$style.dndEdge, _ctx.$style.dndEdge_After])
  }, null, 2)) : _createCommentVNode("", true), (_openBlock(), _createBlock(_resolveDynamicComponent($options.renderComponent), {
    output: $props.output,
    entry: $options.entry,
    computedDisplay: $options.computedDisplay,
    "data-entry-container": "",
    onMousedown: _ctx.onMouseDown
  }, _createSlots({
    cover: _withCtx(() => [$data.isLazyMounted && $props.renderType == 'gallery' && $options.entry.cover ? (_openBlock(), _createBlock(_component_CoverDisplay, {
      key: 0,
      modelValue: $options.entry.cover,
      entry: $options.entry,
      class: "mb-2 h-24 w-full overflow-hidden rounded-lg"
    }, null, 8, ["modelValue", "entry"])) : _createCommentVNode("", true)]),
    id: _withCtx(() => [_createElementVNode("div", _hoisted_2, _toDisplayString($options.entry.id), 1)]),
    toggleOutput: _withCtx(() => [_renderSlot(_ctx.$slots, "toggleOutput", {}, () => [_createVNode(_component_PSButton, {
      onClick: _cache[0] || (_cache[0] = _withModifiers($event => $options.toggleDisplayOutput ? $options.toggleDisplayOutput($options.entry) : null, ["stop"])),
      class: "toggle_output",
      style: _normalizeStyle({
        '--ps-button-color': _ctx.$colors[$options.entry.color || 'neutral'][500],
        '--ps-button-color-hover': _ctx.$colors[$options.entry.color || 'neutral'][600],
        '--ps-button-padding-x': '0',
        '--ps-button-padding-y': '0'
      })
    }, {
      default: _withCtx(() => [_createVNode(_component_ChevronRightIcon, {
        size: "16",
        "stroke-width": "1.5",
        class: _normalizeClass([{
          'rotate-90': $options.isDisplayOutput ? $options.isDisplayOutput($options.entry) : false
        }, "transition-transform duration-200"])
      }, null, 8, ["class"])]),
      _: 1
    }, 8, ["style"])])]),
    name: _withCtx(() => [$data.isLazyMounted ? (_openBlock(), _createBlock(_component_NameDisplay, {
      key: 0,
      ref: "name_display",
      modelValue: $options.entry.name,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $options.entry.name = $event),
      onMounted: _cache[2] || (_cache[2] = $event => _ctx.$emit('mounted:name')),
      "onLive:name": _cache[3] || (_cache[3] = value => {
        _ctx.$emit('live:name', value);
      }),
      entry: $options.entry,
      placeholder: $props.placeholder,
      disabled: $props.disabled || !$props.permissions.name,
      class: _normalizeClass(_ctx.$style.nameDisplay),
      onOnKeyDown: _cache[4] || (_cache[4] = (name, event) => {
        this.$emit('onNameKeyDown', name, event);
      }),
      onOnFocus: _cache[5] || (_cache[5] = () => _ctx.$emit('onFocus')),
      onOnBlur: _cache[6] || (_cache[6] = () => _ctx.$emit('onBlur')),
      "onNlp:date": _cache[7] || (_cache[7] = date => _ctx.$emit('nlp:date', date)),
      position: $props.position,
      "data-tour": $props.position == 'center' && $options.entry.id == _ctx.$route.params.id ? 'entry_name' : null,
      style: {
        '--ps-name-display-color': 'var(--ps-entry-display-color)'
      }
    }, null, 8, ["modelValue", "entry", "placeholder", "disabled", "class", "position", "data-tour"])) : (_openBlock(), _createElementBlock("div", {
      key: 1,
      class: _normalizeClass([_ctx.$style.skeletonLoader, _ctx.$style.skeletonLoader_name])
    }, null, 2))]),
    created_at: _withCtx(() => [!$options.entry.temp ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString($options.entry.created_at), 1)) : _createCommentVNode("", true)]),
    updated_at: _withCtx(() => [!$options.entry.temp ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString($options.entry.updated_at), 1)) : _createCommentVNode("", true)]),
    completed_at: _withCtx(() => [!$options.entry.temp ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString($options.entry.completed_at), 1)) : _createCommentVNode("", true)]),
    completed_count: _withCtx(() => [!$options.entry.temp ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString($options.entry.completed_count), 1)) : _createCommentVNode("", true)]),
    _: 2
  }, [_ctx.$slots.before ? {
    name: "before",
    fn: _withCtx(() => [_renderSlot(_ctx.$slots, "before")]),
    key: "0"
  } : undefined, $options.computedDisplay.procrastination && $options.entry.procrastination ? {
    name: "procrastination",
    fn: _withCtx(({
      internalSettings
    }) => [$data.isLazyMounted ? (_openBlock(), _createBlock(_component_ProcrastinationDisplay, {
      key: 0,
      modelValue: $options.entry.procrastination,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => $options.entry.procrastination = $event),
      entry: $options.entry,
      position: $props.position,
      class: _normalizeClass(_ctx.$style.procrastinationDisplay),
      internalSettings: internalSettings
    }, null, 8, ["modelValue", "entry", "position", "class", "internalSettings"])) : (_openBlock(), _createElementBlock("div", {
      key: 1,
      class: _normalizeClass([_ctx.$style.skeletonLoader, _ctx.$style.skeletonLoader_procrastination])
    }, null, 2))]),
    key: "1"
  } : undefined, $options.computedDisplay.priority && ($options.entry.priority || $options.isShowEmptyAttributes) ? {
    name: "priority",
    fn: _withCtx(() => [$data.isLazyMounted ? (_openBlock(), _createBlock(_component_PriorityDisplay, {
      key: 0,
      modelValue: $options.entry.priority,
      "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => $options.entry.priority = $event),
      entry: $options.entry,
      position: $props.position,
      class: _normalizeClass(_ctx.$style.priorityDisplay)
    }, null, 8, ["modelValue", "entry", "position", "class"])) : (_openBlock(), _createElementBlock("div", {
      key: 1,
      class: _normalizeClass([_ctx.$style.skeletonLoader, _ctx.$style.skeletonLoader_priority])
    }, null, 2))]),
    key: "2"
  } : undefined, $options.computedDisplay.status && ($options.entry.status || $options.isShowEmptyAttributes) ? {
    name: "status",
    fn: _withCtx(({
      internalSettings
    }) => [$data.isLazyMounted ? (_openBlock(), _createBlock(_component_StatusDisplay, {
      key: 0,
      modelValue: $options.status,
      entry: $options.entry,
      statuses: $options.availableStatuses,
      disabled: $props.disabled || !$props.permissions.status,
      position: $props.position,
      onClick: _cache[10] || (_cache[10] = _withModifiers(e => _ctx.$emit('click', e), ["stop"])),
      internalSettings: internalSettings,
      class: _normalizeClass(_ctx.$style.statusDisplay)
    }, null, 8, ["modelValue", "entry", "statuses", "disabled", "position", "internalSettings", "class"])) : (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", {
      class: _normalizeClass([_ctx.$style.skeletonLoader, _ctx.$style.skeletonLoader_status])
    }, null, 2)]))]),
    key: "3"
  } : undefined, $options.computedDisplay.senses && ($options.entry.senses?.length || $options.isShowEmptyAttributes) ? {
    name: "senses",
    fn: _withCtx(({
      internalSettings
    }) => [$data.isLazyMounted ? (_openBlock(), _createBlock(_component_SensesDisplay, {
      key: 0,
      class: _normalizeClass(_ctx.$style.sensesDisplay),
      modelValue: $options.entry.senses,
      "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => $options.entry.senses = $event),
      entry: $options.entry,
      position: $props.position,
      internalSettings: internalSettings,
      disabled: $props.disabled || !$props.permissions.senses
    }, null, 8, ["class", "modelValue", "entry", "position", "internalSettings", "disabled"])) : (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("div", {
      class: _normalizeClass([_ctx.$style.skeletonLoader, _ctx.$style.skeletonLoader_senses])
    }, null, 2)]))]),
    key: "4"
  } : undefined, $options.computedDisplay.links && ($options.entry.links || $options.isShowEmptyAttributes) ? {
    name: "links",
    fn: _withCtx(({
      internalSettings
    }) => [$data.isLazyMounted ? (_openBlock(), _createBlock(_component_LinksDisplay, {
      key: 0,
      modelValue: $options.entry,
      "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => $options.entry = $event),
      entry: $options.entry,
      position: $props.position,
      internalSettings: internalSettings,
      class: _normalizeClass({
        // '-mt-0.5': ['sm'].includes(size) && position != 'calendar',
        // 'mt-0.5': ['md'].includes(size),
        // 'pt-1': ['lg', 'xl', '2xl'].includes(size), // in case name is bigger
        // // 'pt-2': ['4xl'].includes(size), // in case name is bigger @deprecated: new EntryDisplayDetail makes this obsolete
        'order-last -mx-1 basis-full': $props.renderType == 'board'
      })
    }, null, 8, ["modelValue", "entry", "position", "internalSettings", "class"])) : (_openBlock(), _createElementBlock("div", {
      key: 1,
      class: _normalizeClass([_ctx.$style.skeletonLoader, _ctx.$style.skeletonLoader_links])
    }, null, 2))]),
    key: "5"
  } : undefined, $data.isLazyMounted && $options.computedDisplay.description && ($options.entry.description || $options.isShowEmptyAttributes) ? {
    name: "description",
    fn: _withCtx(() => [_createVNode(_component_DescriptionDisplay, {
      overrideEditable: $props.renderType == 'table',
      modelValue: $options.entry.description,
      "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => $options.entry.description = $event),
      entry: $options.entry,
      position: $props.position
    }, null, 8, ["overrideEditable", "modelValue", "entry", "position"])]),
    key: "6"
  } : undefined, $options.computedDisplay.schedule && ($options.entry.schedule || $options.isShowEmptyAttributes) ? {
    name: "schedule",
    fn: _withCtx(() => [$data.isLazyMounted ? (_openBlock(), _createBlock(_component_ScheduleDisplay, {
      key: 0,
      modelValue: $options.entry.schedule,
      "onUpdate:modelValue": _cache[14] || (_cache[14] = $event => $options.entry.schedule = $event),
      entry: $options.entry,
      onClick: _cache[15] || (_cache[15] = e => _ctx.$emit('click', e) // @note for input display to recognize the click event
      ),
      disabled: $props.disabled || !$props.permissions.schedule
    }, null, 8, ["modelValue", "entry", "disabled"])) : (_openBlock(), _createElementBlock("div", {
      key: 1,
      class: _normalizeClass([_ctx.$style.skeletonLoader, _ctx.$style.skeletonLoader_schedule])
    }, null, 2))]),
    key: "7"
  } : undefined, $options.computedDisplay.time_trackings && ($options.entry.time_trackings.count > 0 || $options.isShowEmptyAttributes) ? {
    name: "time_trackings",
    fn: _withCtx(() => [$data.isLazyMounted ? (_openBlock(), _createBlock(_component_TimeTrackingsDisplay, {
      key: 0,
      modelValue: _ctx.$store.getters.time_trackings.filter(tt => tt.entry_id == $options.entry.id),
      entry: $options.entry
    }, null, 8, ["modelValue", "entry"])) : (_openBlock(), _createElementBlock("div", {
      key: 1,
      class: _normalizeClass([_ctx.$style.skeletonLoader, _ctx.$style.skeletonLoader_timeTrackings])
    }, null, 2))]),
    key: "8"
  } : undefined, $options.computedDisplay.routine && ($options.entry.routine || $options.isShowEmptyAttributes) ? {
    name: "routine",
    fn: _withCtx(({
      internalSettings
    }) => [$data.isLazyMounted ? (_openBlock(), _createBlock(_component_RoutineDisplay, {
      key: 0,
      modelValue: $options.entry.routine,
      "onUpdate:modelValue": _cache[16] || (_cache[16] = $event => $options.entry.routine = $event),
      entry: $options.entry,
      disabled: $props.disabled || !$props.permissions.routine,
      internalSettings: internalSettings
    }, null, 8, ["modelValue", "entry", "disabled", "internalSettings"])) : (_openBlock(), _createElementBlock("div", {
      key: 1,
      class: _normalizeClass([_ctx.$style.skeletonLoader, _ctx.$style.skeletonLoader_routine])
    }, null, 2))]),
    key: "9"
  } : undefined, $data.isLazyMounted && $options.computedDisplay.custom_fields && $options.customFields?.length ? {
    name: "custom_fields",
    fn: _withCtx(() => [_createVNode(_component_CustomFieldsDisplay, {
      entry: $options.entry,
      customFields: $options.customFields,
      class: "mt-1"
    }, null, 8, ["entry", "customFields"])]),
    key: "10"
  } : undefined, _renderList($options.customFields, custom_field => {
    return {
      name: `custom_field_${custom_field.id}`,
      fn: _withCtx(() => [$data.isLazyMounted && $options.computedDisplay.custom_fields && $options.customFields?.length ? (_openBlock(), _createBlock(_component_CustomFieldsDisplay, {
        key: 0,
        entry: $options.entry,
        customFields: [custom_field],
        class: "mt-1"
      }, null, 8, ["entry", "customFields"])) : _createCommentVNode("", true)])
    };
  }), $options.computedDisplay.settings ? {
    name: "settings",
    fn: _withCtx(() => [$data.isLazyMounted ? _renderSlot(_ctx.$slots, "settings", {
      key: 0
    }, () => [!$options.entry.temp ? (_openBlock(), _createBlock(_component_PSOverlay, {
      key: 0,
      id: "contextmenu",
      ref: "settings",
      open: $data.showConfig,
      onClose: _cache[17] || (_cache[17] = $event => $data.showConfig = false)
    }, {
      button: _withCtx(() => [_createVNode(_component_PSButton, {
        class: _normalizeClass([_ctx.$style.settingsButton, "ps_settings_button"]),
        title: "Entry Settings",
        "data-table-column-target": ""
      }, {
        default: _withCtx(() => [_createVNode(_component_EllipsisIcon, {
          size: "20"
        })]),
        _: 1
      }, 8, ["class"])]),
      default: _withCtx(({
        close
      }) => [_createVNode(_component_EntryContextmenu, {
        ref: "configuration",
        modelValue: $options.entry,
        hide: ['timestamps'],
        position: "settings",
        onClose: () => {
          $data.showConfig = false;
          _ctx.$emit('close');
          close();
        }
      }, {
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "contextmenu")]),
        _: 2
      }, 1032, ["modelValue", "onClose"])]),
      _: 3
    }, 8, ["open"])) : _createCommentVNode("", true)]) : (_openBlock(), _createElementBlock("div", {
      key: 1,
      class: _normalizeClass([_ctx.$style.skeletonLoader, _ctx.$style.skeletonLoader_settings])
    }, null, 2))]),
    key: "11"
  } : undefined, $options.computedDisplay.output_settings && ($options.entry.output || $options.isShowEmptyAttributes) ? {
    name: "output_settings",
    fn: _withCtx(() => [!$props.disabled ? (_openBlock(), _createBlock(_component_OutputSettings, {
      key: 0,
      modelValue: $options.entry.output,
      "onUpdate:modelValue": _cache[18] || (_cache[18] = $event => $options.entry.output = $event),
      entry: $options.entry,
      showDelete: false,
      size: "xs",
      variant: "minimal",
      "onOn:popoverOpen": _cache[19] || (_cache[19] = $event => _ctx.groupHasActivePopover = _ctx.groupIndex),
      "onOn:popoverClose": _cache[20] || (_cache[20] = $event => _ctx.groupHasActivePopover = -1),
      class: _normalizeClass(_ctx.$style.outputSettings),
      "data-tour": "output_settings"
    }, null, 8, ["modelValue", "entry", "class"])) : _createCommentVNode("", true)]),
    key: "12"
  } : undefined, $options.computedDisplay.input_settings && ($options.entry.input || $options.isShowEmptyAttributes) ? {
    name: "input_settings",
    fn: _withCtx(() => [!$props.disabled ? (_openBlock(), _createBlock(_component_InputSettings, {
      key: 0,
      modelValue: $options.entry.input,
      "onUpdate:modelValue": _cache[21] || (_cache[21] = $event => $options.entry.input = $event),
      entry: $options.entry
    }, null, 8, ["modelValue", "entry"])) : _createCommentVNode("", true)]),
    key: "13"
  } : undefined]), 1064, ["output", "entry", "computedDisplay", "onMousedown"])),
  // $store.getters.dragged.findIndex((e) => e.id == entry.id) !== -1 &&
  $props.position != 'draggable' && $props.position != 'calendar' && $props.position != 'undo' ? (_openBlock(), _createElementBlock("div", {
    key: 2,
    class: _normalizeClass(_ctx.$style.dragPlaceholder),
    "data-draggable-placeholder": ""
  }, null, 2)) : _createCommentVNode("", true)], 46, _hoisted_1);
}