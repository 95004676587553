import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.map.js";
import { Scheduling } from "@/components//mixins/Scheduling";
import { Statusing } from "@/components//mixins/Statusing";
export default {
  // inject: ["position"],
  name: "DatePicker",
  mixins: [Scheduling, Statusing],
  props: {
    modelValue: null,
    entry: Object,
    showRecurrence: {
      type: Boolean,
      default: true
    },
    autoSetOnClick: {
      type: Boolean,
      default: true
    },
    teleport: Boolean
  },
  data() {
    return {
      cursor: this.modelValue || this.$moment().format("YYYY-MM-DD"),
      recurrence: this.entry?.schedule?.recurrence ? JSON.parse(JSON.stringify(this.entry.schedule.recurrence)) : {
        op: null,
        mode: "relative"
      },
      recurrenceOptions: [{
        title: "Every Day",
        value: {
          op: "nextXDays",
          x: 1,
          mode: "relative"
        }
      }, {
        title: "Every 2 Days",
        value: {
          op: "nextXDays",
          x: 2,
          mode: "relative"
        }
      }, {
        title: "2x / Week",
        value: {
          op: "nextXWeeks",
          x: 0.5,
          mode: "relative"
        }
      }, {
        title: "Every Week",
        value: {
          op: "nextXWeeks",
          x: 1,
          mode: "relative"
        }
      }, {
        title: "Every 2 Weeks",
        value: {
          op: "nextXWeeks",
          x: 2,
          mode: "relative"
        }
      }, {
        title: "Every Month",
        value: {
          op: "nextXMonths",
          x: 1,
          mode: "relative"
        }
      }, {
        title: "Every 2 Months",
        value: {
          op: "nextXMonths",
          x: 2,
          mode: "relative"
        }
      }, {
        title: "Every Quarter",
        value: {
          op: "nextXMonths",
          x: 3,
          mode: "relative"
        }
      }, {
        title: "2x / Year",
        value: {
          op: "nextXYears",
          x: 0.5,
          mode: "relative"
        }
      }, {
        title: "Every Year",
        value: {
          op: "nextXYears",
          x: 1,
          mode: "relative"
        }
      }]
    };
  },
  watch: {
    modelValue: {
      handler() {
        this.cursor = this.modelValue || this.$moment().format("YYYY-MM-DD");
      },
      deep: true
    },
    "entry.schedule.recurrence": function () {
      this.recurrence = JSON.parse(JSON.stringify(this.entry?.schedule?.recurrence)) || {
        op: null
      };
    },
    "recurrence.op": function () {
      if (this.recurrence.op == "onDays") {
        this.recurrence.x = [0];
      } else if (["nextXDays", "nextXWeeks", "nextXMonths", "nextXYears"].includes(this.recurrence.op)) {
        this.recurrence.x = 1;
      } else if (this.recurrence.op == "customSettings") {
        this.recurrence.baseX = 1;
        this.recurrence.base = "day";
      }
    },
    "recurrence.base": function () {
      if (this.recurrence.base == "week") {
        this.recurrence.x = [0];
      } else if (this.recurrence.base == "month") {
        this.recurrence.monthMode = "date";
      }
    },
    "recurrence.monthMode": function () {
      if (this.recurrence.monthMode == "week") {
        this.recurrence.weekInMonth = 1;
        this.recurrence.dayInWeek = 0;
      }
    }
  },
  computed: {
    date: {
      get() {
        return this.modelValue;
      },
      set(date) {
        // console.log("date", date);
        this.$emit("update", date);
        this.$emit("update:modelValue", date);
      }
    },
    displayText() {
      return this.date ? this.$formattedDateOp(this.date) : null;
    },
    dayOptions() {
      return Array.from({
        length: this.$moment(this.cursor).local().daysInMonth()
      }, (_, i) => i + 1).map(day => {
        const _date = this.$moment(this.$moment(this.cursor).set("date", day)).format("YYYY-MM-DD");
        return {
          title: this.$moment(_date).date().toString().padStart(2, "0") /* +
                                                                        (_date == date ? " · " + this.$moment(_date).format("ddd") : "")*/,
          date: _date,
          active: _date == this.modelValue
        };
      });
    }
  }
};