import { CalendarIcon, Grid2X2Icon, KanbanIcon, ListIcon, TableIcon, SquareStackIcon } from "lucide-vue-next";
export default {
  props: {
    modelValue: null,
    entry: Object,
    options: {
      type: Array,
      default: () => [{
        icon: ListIcon,
        label: "List",
        description: "Render entries as a list",
        value: "list"
      }, {
        icon: KanbanIcon,
        label: "Board",
        description: "Render entries as a kanban board",
        value: "board"
      }, {
        icon: Grid2X2Icon,
        label: "Gallery",
        description: "Render entries as a gallery",
        value: "gallery"
      }, {
        icon: TableIcon,
        label: "Table",
        description: "Render entries as a table",
        value: "table"
      }, {
        icon: CalendarIcon,
        label: "Calendar",
        description: "Render entries as a calendar",
        value: "calendar"
      }, {
        icon: SquareStackIcon,
        label: "Tiles",
        description: "Render entries as small tiles with initials",
        value: "tiles"
      }]
    }
  }
};