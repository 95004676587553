import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SquareArrowOutUpRightIcon = _resolveComponent("SquareArrowOutUpRightIcon");
  const _component_PSButton = _resolveComponent("PSButton");
  const _component_EntrySettings = _resolveComponent("EntrySettings");
  const _component_PSModal = _resolveComponent("PSModal");
  return _openBlock(), _createBlock(_component_PSModal, {
    onOnModalOpen: $options.onModalOpen,
    onOnModalClose: _cache[1] || (_cache[1] = $event => ($options.onModalClose(), _ctx._trackEvent('Navigation', 'Entry Modal', 'Close Modal'), _ctx.$router.replace({
      name: 'entry',
      params: {
        id: _ctx.$route.params.id
      }
    }))),
    style: _normalizeStyle($options.cssProps)
  }, {
    title: _withCtx(() => [_createVNode(_component_PSButton, {
      onClick: $options.openFullPage,
      shortcode: "f",
      title: "Open this Entry in Full Page",
      class: _normalizeClass(_ctx.$style.buttonOpenFullPage)
    }, {
      default: _withCtx(() => [_createVNode(_component_SquareArrowOutUpRightIcon, {
        size: "14",
        "aria-hidden": "true"
      }), _cache[2] || (_cache[2] = _createTextVNode(" Open Full Page "))]),
      _: 1
    }, 8, ["onClick", "class"])]),
    default: _withCtx(() => [_createElementVNode("div", {
      id: "entry-modal-page",
      class: _normalizeClass(_ctx.$style.entryModalPageWrapper)
    }, [_createVNode(_component_EntrySettings, {
      ref: "entrySettings",
      modelValue: $options.entry_by_route,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.entry_by_route = $event),
      position: "modal"
    }, null, 8, ["modelValue"])], 2)]),
    _: 1
  }, 8, ["onOnModalOpen", "style"]);
}