import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_EntryDisplay = _resolveComponent("EntryDisplay");
  const _component_EntryDisplayOnHover = _resolveComponent("EntryDisplayOnHover");
  return _openBlock(), _createBlock(_component_EntryDisplayOnHover, {
    entry: $props.entry
  }, {
    default: _withCtx(() => [_createVNode(_component_EntryDisplay, {
      modelValue: $props.entry,
      permissions: {
        name: false,
        status: true,
        links: false,
        description: false,
        schedule: false,
        output: false,
        settings: false,
        routine: false,
        time_trackings: false,
        custom_fields: false
      },
      display: {
        // status: view.type == 'timeGridDay',
        // procrastination: view.type == 'timeGridDay',
        // priority: view.type == 'timeGridDay',
        links: !$props.event.allDay,
        //view.type == 'timeGridDay',
        schedule: !$props.event.allDay,
        // view.type == 'timeGridDay' && getSlotSize(event) > 1,
        routine: !$props.event.allDay,
        output: false,
        settings: false
      },
      position: "calendar",
      size: "sm",
      disableViewportChecking: true,
      disableInitialization: true,
      disableEdgeDetection: true
    }, null, 8, ["modelValue", "display"])]),
    _: 1
  }, 8, ["entry"]);
}