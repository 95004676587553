import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "relative mx-4 my-2"
};
const _hoisted_2 = {
  class: "relative flex justify-center"
};
const _hoisted_3 = {
  class: "flex items-center gap-2 bg-white px-2 text-sm text-neutral-500 dark:bg-black dark:text-neutral-400"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[0] || (_cache[0] = _createElementVNode("div", {
    class: "absolute inset-0 flex items-center",
    "aria-hidden": "true"
  }, [_createElementVNode("div", {
    class: "w-full border-t border-neutral-300 dark:border-neutral-700"
  })], -1)), _createElementVNode("div", _hoisted_2, [_createElementVNode("span", _hoisted_3, [_renderSlot(_ctx.$slots, "default")])])]);
}