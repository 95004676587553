export default {
  data() {
    return {
      resizeObserver: null
    };
  },
  mounted() {
    this.initResizeObserver();
  },
  beforeUnmount() {
    this.cleanupResizeObserver();
  },
  methods: {
    initResizeObserver() {
      // Create a ResizeObserver to watch the inner div's size
      this.resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          // Update the outer div's height to match the inner content
          if (this.$refs.outer) {
            this.$refs.outer.style.height = `${entry.contentRect.height}px`;
          }
        }
      });

      // Start observing the inner div
      if (this.$refs.inner) {
        this.resizeObserver.observe(this.$refs.inner);
      }
    },
    cleanupResizeObserver() {
      // Clean up the observer when component is destroyed
      if (this.resizeObserver) {
        this.resizeObserver.disconnect();
      }
    }
  }
};