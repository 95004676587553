import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["onClick"];
const _hoisted_2 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CalendarIcon = _resolveComponent("CalendarIcon");
  const _component_CalendarComponent = _resolveComponent("CalendarComponent");
  const _component_TrashIcon = _resolveComponent("TrashIcon");
  const _component_PopoverHelper = _resolveComponent("PopoverHelper");
  return _openBlock(), _createBlock(_component_PopoverHelper, {
    teleport: true
  }, {
    button: _withCtx(() => [_createElementVNode("div", _mergeProps(_ctx.$attrs, {
      placeholder: "Set Date",
      class: _ctx.$style.button
    }), [_renderSlot(_ctx.$slots, "button", {}, () => [_createVNode(_component_CalendarIcon, {
      size: "16",
      class: "inline"
    }), _createTextVNode(" " + _toDisplayString($options.date && _ctx.$moment($options.date).isValid() ? _ctx.$moment($options.date).format("DD.MM.YYYY") : "Set date"), 1)])], 16)]),
    default: _withCtx(({
      close
    }) => [_createVNode(_component_CalendarComponent, {
      class: _normalizeClass(_ctx.$style.calendar)
    }, {
      default: _withCtx(({
        date: _date
      } = _ctx.slotProps) => [_createElementVNode("button", {
        onClick: _withModifiers($event => ($options.date = _date, close()), ["prevent"]),
        class: _normalizeClass({
          'font-bold text-black dark:text-white': _date == _ctx.$moment().format('YYYY-MM-DD'),
          'h-8 w-8 rounded-full bg-neutral-900 font-bold text-white': _date == $options.date
        })
      }, _toDisplayString(_ctx.$moment(_date).format("DD")), 11, _hoisted_1)]),
      _: 2
    }, 1032, ["class"]), _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.utilitiesRow)
    }, [_createElementVNode("button", {
      onClick: $event => ($options.date = null, close()),
      class: _normalizeClass(_ctx.$style.buttonDelete)
    }, [_createVNode(_component_TrashIcon, {
      size: "18"
    })], 10, _hoisted_2)], 2)]),
    _: 3
  });
}