import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, Transition as _Transition } from "vue";
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_LightbulbIcon = _resolveComponent("LightbulbIcon");
  const _component_ChevronDownIcon = _resolveComponent("ChevronDownIcon");
  const _component_ChevronRightIcon = _resolveComponent("ChevronRightIcon");
  const _component_PSButton = _resolveComponent("PSButton");
  const _component_OutputDisplay = _resolveComponent("OutputDisplay");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.suggestionsDisplay)
  }, [_createVNode(_component_PSButton, {
    class: _normalizeClass(_ctx.$style.toggleButton),
    onClick: _cache[0] || (_cache[0] = $event => $data.showSuggestions = !$data.showSuggestions)
  }, {
    default: _withCtx(() => [_createVNode(_component_LightbulbIcon, {
      size: "16",
      strokeWidth: "1.5"
    }), _cache[2] || (_cache[2] = _createTextVNode(" Suggestions ")), $data.showSuggestions ? (_openBlock(), _createElementBlock("span", _hoisted_1, [_createVNode(_component_ChevronDownIcon, {
      size: "16",
      strokeWidth: "1.5"
    })])) : (_openBlock(), _createElementBlock("span", _hoisted_2, [_createVNode(_component_ChevronRightIcon, {
      size: "16",
      strokeWidth: "1.5"
    })]))]),
    _: 1
  }, 8, ["class"]), _createVNode(_Transition, {
    "enter-active-class": "transition-all duration-300 ease-in-out",
    "enter-from-class": "opacity-0 translate-y-2",
    "enter-to-class": "opacity-100 translate-y-0",
    "leave-active-class": "transition-all duration-300 ease-in-out",
    "leave-from-class": "opacity-100 translate-y-0",
    "leave-to-class": "opacity-0 translate-y-2"
  }, {
    default: _withCtx(() => [$data.showSuggestions ? (_openBlock(), _createBlock(_component_OutputDisplay, {
      key: 0,
      showHeader: false,
      modelValue: {},
      editable: false,
      entriesDisabled: true,
      onOnEntryClick: _cache[1] || (_cache[1] = $event => _ctx.$emit('onEntryClick', $event)),
      display: {
        output: false,
        status: false,
        senses: false,
        links: false,
        procrastination: false,
        description: false,
        schedule: false,
        time_trackings: false,
        routine: false
      },
      position: "suggestions",
      class: _normalizeClass(_ctx.$style.outputDisplay)
    }, null, 8, ["class"])) : _createCommentVNode("", true)]),
    _: 1
  })], 2);
}