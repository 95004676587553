import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.for-each.js";
import "core-js/modules/es.iterator.map.js";
import "core-js/modules/es.iterator.some.js";
import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  class: "_hidden _sm: flex flex-wrap gap-2"
};
const _hoisted_2 = ["onClick"];
const _hoisted_3 = {
  class: "hidden items-center gap-1 rounded bg-neutral-50 px-1 py-0.5 text-xs text-neutral-500 first:flex hover:bg-neutral-100 hover:text-neutral-600 dark:bg-neutral-950 dark:text-neutral-400"
};
const _hoisted_4 = ["onClick", "active"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_LinksBuilder = _resolveComponent("LinksBuilder");
  const _component_MenuItem = _resolveComponent("MenuItem");
  const _component_MenuHelper = _resolveComponent("MenuHelper");
  const _component_LinksPopover = _resolveComponent("LinksPopover");
  return $props.internalSettings.display.settings ? (_openBlock(), _createBlock(_component_LinksBuilder, {
    key: 0,
    modelValue: $options.links.map(link => link.id),
    "onUpdate:modelValue": _cache[0] || (_cache[0] = val => {
      // add new links
      val.forEach(id => {
        if (!$options.links.some(link => link.id === id)) {
          $options.links.push({
            id: id,
            settings: {},
            created_at: _ctx.$moment.utc().format('YYYY-MM-DD HH:mm:ss'),
            updated_at: _ctx.$moment.utc().format('YYYY-MM-DD HH:mm:ss'),
            deleted_at: null
          });
        }
      });
      // remove links
      $options.links = $options.links.filter(link => val.includes(link.id));
      $options.links.forEach((link, index) => {
        link.position = index;
      });
    }),
    class: _normalizeClass(_ctx.$style.LinksBuilder)
  }, {
    button: _withCtx(() => _cache[2] || (_cache[2] = [])),
    _: 1
  }, 8, ["modelValue", "class"])) : (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: _normalizeClass(_ctx.$style.linksDisplay)
  }, [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.linksObjects, (link, key) => {
    return _openBlock(), _createElementBlock(_Fragment, null, [link.id != $options.output_entry_origin?.id ? (_openBlock(), _createElementBlock("a", {
      key: key,
      onClick: _withModifiers(e => $options.onClick(link, e), ["stop"]),
      class: _normalizeClass(["hidden items-center gap-1 text-xs first:flex", [` 
            ${_ctx.$style.link}
            ${['calendar', 'command-palette'].includes($props.position) ? '' : 'sm:flex'}
            `]]),
      style: _normalizeStyle({
        '--color': link.color ? 'var(--ps-color-' + link.color + '-500)' : 'var(--ps-base-primary-color)',
        '--bg-color': link.color ? 'var(--ps-color-' + link.color + '-50)' : 'transparent',
        '--color-hover': link.color ? 'var(--ps-color-' + link.color + '-600)' : 'var(--ps-base-primary-color)',
        '--bg-color-hover': link.color ? 'var(--ps-color-' + link.color + '-100)' : 'transparent',
        '--dark-color': link.color ? 'var(--ps-color-' + link.color + '-300)' : 'var(--ps-base-primary-color)',
        '--dark-bg-color': link.color ? 'var(--ps-color-' + link.color + '-950)' : 'transparent',
        '--dark-color-hover': link.color ? 'var(--ps-color-' + link.color + '-200)' : 'var(--ps-base-primary-color)',
        '--dark-bg-color-hover': link.color ? 'var(--ps-color-' + link.color + '-900)' : 'transparent'
      })
    }, _toDisplayString(link.name ? _ctx.clearMarkdown(link.name).length > 20 ? _ctx.clearMarkdown(link.name).slice(0, 17) + "..." : _ctx.clearMarkdown(link.name) : "Unnamed"), 15, _hoisted_2)) : _createCommentVNode("", true)], 64);
  }), 256)), $options.linksObjects.filter(link => link.id != $options.output_entry_origin?.id).length > 1 ? (_openBlock(), _createBlock(_component_MenuHelper, {
    key: 0,
    as: "div",
    class: _normalizeClass([` 
            ${['calendar', 'command-palette'].includes($props.position) ? '' : 'sm:hidden'}
          `])
  }, {
    button: _withCtx(() => [_createElementVNode("div", _hoisted_3, " + " + _toDisplayString($options.linksObjects.filter(link => link.id != $options.output_entry_origin?.id).length - 1), 1)]),
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.linksObjects, (link, key) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [link.id != $options.output_entry_origin?.id ? (_openBlock(), _createBlock(_component_MenuItem, {
        key: key,
        class: "first:hidden"
      }, {
        default: _withCtx(({
          active
        }) => [_createElementVNode("a", {
          onClick: _withModifiers(e => $options.onClick(link, e), ["stop"]),
          active: active,
          class: _normalizeClass(["block", [` 
                      text-${link.color}-500
                      hover:text-${link.color}-600
                      rounded  px-4 py-2 text-sm
                    `]])
        }, _toDisplayString(_ctx.clearMarkdown(link.name).length > 20 ? _ctx.clearMarkdown(link.name).slice(0, 17) + "..." : _ctx.clearMarkdown(link.name)), 11, _hoisted_4)]),
        _: 2
      }, 1024)) : _createCommentVNode("", true)], 64);
    }), 256))]),
    _: 1
  }, 8, ["class"])) : _createCommentVNode("", true)]), _createVNode(_component_LinksPopover, {
    modelValue: $options.links,
    "onAdd:linkId": _cache[1] || (_cache[1] = id => {
      $options.links = [...$options.links, {
        id,
        position: $options.links.length,
        settings: {},
        created_at: _ctx.$moment.utc().format('YYYY-MM-DD HH:mm:ss'),
        updated_at: _ctx.$moment.utc().format('YYYY-MM-DD HH:mm:ss'),
        deleted_at: null
      }];
    })
  }, {
    button: _withCtx(() => _cache[3] || (_cache[3] = [_createElementVNode("button", {
      "data-table-column-target": ""
    }, null, -1)])),
    _: 1
  }, 8, ["modelValue"])], 2));
}