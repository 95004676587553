import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["aria-label"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("span", {
    "aria-label": $props.status?.name || 'No status',
    class: _normalizeClass({
      [_ctx.$style.statusIndicator]: $props.status,
      [_ctx.$style.statusIndicatorDone]: $props.status?.type == 'done'
    }),
    style: _normalizeStyle({
      '--ps-status-color': _ctx.$colors[$props.status?.color || 'neutral'][500],
      '--ps-status-color-dark': _ctx.$colors[$props.status?.color || 'neutral'][400]
    })
  }, null, 14, _hoisted_1);
}