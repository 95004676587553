import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.find.js";
import { Crud } from "../mixins/Crud";
export default {
  provide() {
    return {
      position: "shell"
    };
  },
  props: {
    sidebarDesktopOpen: Boolean,
    scrollY: Number
  },
  mixins: [Crud],
  computed: {
    user() {
      return this.$store.getters.user;
    },
    entries() {
      return this.$store.getters.entries.filter(e => e.deleted_at === null);
    },
    entry() {
      return this.entries.find(e => e.id == this.$route.params.id);
    },
    space() {
      return this.$store.getters.space;
    },
    isShowTimeTrackings() {
      return this.$store.getters.time_trackings.filter(tt => tt.user_id == this.user.id && this.$moment.utc(tt.start_at).format("YYYY-MM-DD") == this.$moment().format("YYYY-MM-DD")).length;
    }
  },
  created() {},
  data() {
    return {
      navigator: navigator
    };
  },
  mounted() {
    this.pullData();
  },
  methods: {
    pullData() {
      this.$store.dispatch("pull", {
        filters: [{
          key: ["time_trackings", "start_at"],
          op: "eq",
          val: [this.$moment().format("YYYY-MM-DD")]
        }],
        data: "time_trackings"
      });
    }
    // animateEnter(el, done) {
    //   this.$anime({
    //     targets: el,
    //     translateY: [-100, 0],
    //     opacity: [0, 1],
    //     complete: done,
    //   });
    // },
    // animateLeave(el, done) {
    //   this.$anime({
    //     targets: el,
    //     translateY: [0, -100],
    //     opacity: [1, 0],
    //     complete: done,
    //   });
    // },
  }
};