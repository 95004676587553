import { vModelText as _vModelText, withModifiers as _withModifiers, withKeys as _withKeys, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  key: 1,
  class: "text-center"
};
const _hoisted_2 = {
  key: 1,
  class: "relative inline-block h-[30px] w-[30px]"
};
const _hoisted_3 = {
  key: 0,
  class: "justify-end"
};
import { ref } from "vue";
import { useFloating, shift, flip } from "@floating-ui/vue";
import { TimeTrackingsMixin } from "@/components/timetrackings/mixins/TimeTrackingsMixin";
import { Tracking } from "@/components/mixins/Tracking";
import { addonBlueprint } from "@/addonBlueprint";
const __default__ = {
  mixins: [TimeTrackingsMixin, Tracking],
  props: {
    modelValue: Object,
    entry: Object
  },
  mounted() {
    if (this.time_tracking) {
      this.internalTimeTracking = this.time_tracking;
      const startTime = this.$moment.utc(this.internalTimeTracking.start_at);
      const endTime = this.$moment.utc(this.internalTimeTracking.end_at);
      const d = this.$moment.duration(endTime.diff(startTime));
      this.durationAsText = d.format("H[h] m[m] s[s]", {
        stopTrim: "m"
      });
      this.interpretDuration(this.durationAsText + "s");
    } else {
      this.internalTimeTracking = addonBlueprint("time_track", this.entry, this);
      this.internalTimeTracking.start_at = this.$moment.utc().format("YYYY-MM-DD HH:mm:00");
      this.internalTimeTracking.end_at = this.$moment.utc().format("YYYY-MM-DD HH:mm:00");
    }
  },
  computed: {
    time_tracking: {
      get() {
        return this.modelValue;
      },
      set(value) {
        // console.log("set time_tracking", value, this.entry.name);

        const index = this.entry.time_trackings.findIndex(tt => tt.id === value.id);

        // eslint-disable-next-line vue/no-mutating-props
        this.entry.time_trackings[index] = value;
        if (!this.entry.temp) this.$store.dispatch("push", {
          event: "entry_update",
          params: {
            entry: this.entry
          },
          entry: this.entry,
          undo: 1
        });
      }
    },
    duration: {
      get() {
        if (this.internalTimeTracking) {
          const startTime = this.$moment.utc(this.internalTimeTracking.start_at);
          const endTime = this.$moment.utc(this.internalTimeTracking.end_at);
          return this.$moment.duration(endTime.diff(startTime)).asSeconds();
        }
        return 0;
      },
      set(value) {
        this.durationAsText = this.$moment.duration(value, "seconds").format("H[h] m[m] s[s]", {
          stopTrim: "m"
        });
        this.customDurationAsSeconds = value;

        // Calculate new end time while preserving the date
        const startMoment = this.$moment.utc(this.internalTimeTracking.start_at);
        const endMoment = startMoment.clone().add(value, "seconds");

        // If the duration crosses midnight, we need to adjust the date
        // if (endMoment.isBefore(startMoment)) {
        //   endMoment.add(1, "day");
        // }

        this.internalTimeTracking = {
          ...this.internalTimeTracking,
          ...{
            end_at: endMoment.format("YYYY-MM-DD HH:mm:ss")
          }
        };
      }
    },
    timeStart: {
      get() {
        if (this.internalTimeTracking) return this.$moment(this.internalTimeTracking.start_at).format("HH:mm:ss");
        return this.$moment.utc().format("HH:mm:ss");
      },
      set(value) {
        const timeStartMomentUTC = this.$moment(this.customDate + " " + value);
        if (timeStartMomentUTC.isValid()) {
          this.internalTimeTracking = {
            ...this.internalTimeTracking,
            ...{
              start_at: timeStartMomentUTC.format("YYYY-MM-DD HH:mm:ss"),
              end_at: timeStartMomentUTC.add(this.duration, "seconds").format("YYYY-MM-DD HH:mm:ss")
            }
          };
          this.duration = Math.abs(this.$moment(this.internalTimeTracking.end_at).diff(this.$moment(this.internalTimeTracking.start_at), "seconds"));
        }
      }
    },
    timeEnd: {
      get() {
        if (this.internalTimeTracking) return this.$moment.utc(this.internalTimeTracking.end_at).format("HH:mm");
        return this.$moment().utc().format("HH:mm");
      },
      set(value) {
        const timeEndMomentUTC = this.$moment(this.customDate + " " + value);
        if (timeEndMomentUTC.isValid()) {
          // If the end time is before the start time, add a day
          let adjustedTimeEndMomentUTC = timeEndMomentUTC;
          if (timeEndMomentUTC.isBefore(this.$moment(this.internalTimeTracking.start_at))) {
            adjustedTimeEndMomentUTC = timeEndMomentUTC.add(1, "day");
          }
          this.internalTimeTracking = {
            ...this.internalTimeTracking,
            ...{
              end_at: adjustedTimeEndMomentUTC.format("YYYY-MM-DD HH:mm:ss")
            }
          };
          this.customDurationAsSeconds = Math.abs(this.$moment(this.internalTimeTracking.end_at).diff(this.$moment(this.internalTimeTracking.start_at), "seconds"));
        }
      }
    }
  },
  watch: {},
  data() {
    return {
      showPopover: false,
      durationAsText: "",
      customDuration: "",
      customDurationAsSeconds: 0,
      humanReadableDuration: "",
      customDate: this.$moment().format("YYYY-MM-DD"),
      customTimeStart: this.$moment().format("HH:mm"),
      customTimeEnd: this.$moment().format("HH:mm"),
      internalTimeTracking: null
    };
  },
  methods: {
    delayShowPopover(show) {
      setTimeout(() => {
        this.showPopover = show;
      }, 100);
    },
    handleOnBlur() {
      if (this.duration != this.durationAsText) {
        const durationInSeconds = this.interpretDuration(this.durationAsText);
        this.customDurationAsSeconds = durationInSeconds;
        if (!this.time_tracking) {
          this.internalTimeTracking = {
            ...this.internalTimeTracking,
            ...{
              start_at: this.$moment.utc(this.internalTimeTracking.end_at).subtract(durationInSeconds, "seconds").format("YYYY-MM-DD HH:mm:ss")
            }
          };
        }
        this.duration = durationInSeconds;
      }
      if (this.showPopover) {
        this.delayShowPopover(false);
      }
    },
    handleCustomDurationInput(event) {
      const input = event.target.value.trim();
      if (input === "") {
        // this.customDurationAsSeconds = 0;
        this.humanReadableDuration = "";
        return;
      }
      this.interpretDuration(input);
    },
    interpretDuration(input) {
      let durationInSeconds = 0;
      let humanReadableDuration = "";
      let lastUnit = null;

      // Regular expressions for matching different variations
      const hourRegex = /^(h|ho|hour|hours)$/i;
      const minuteRegex = /^(m|min|minute|minutes)$/i;
      const secondRegex = /^(s|sec|second|seconds)$/i;

      // Split the input into parts that include both numbers and units
      const parts = input.match(/(\d+|\D+)/g);

      // Process each part
      for (let i = 0; i < parts.length; i++) {
        const part = parts[i].trim();
        if (!isNaN(part) && part !== "") {
          // If the part is a number, check the next part for the unit
          const nextPart = parts[i + 1] ? parts[i + 1].trim() : null;
          if (nextPart && hourRegex.test(nextPart)) {
            const hours = parseInt(part);
            durationInSeconds += hours * 3600;
            humanReadableDuration += `${hours} hour${hours > 1 ? "s" : ""} `;
            lastUnit = "hours";
            i++; // Skip the next part as it is already processed
          } else if (nextPart && minuteRegex.test(nextPart)) {
            const minutes = parseInt(part);
            durationInSeconds += minutes * 60;
            humanReadableDuration += `${minutes} minute${minutes > 1 ? "s" : ""} `;
            lastUnit = "minutes";
            i++; // Skip the next part as it is already processed
          } else if (nextPart && secondRegex.test(nextPart)) {
            const seconds = parseInt(part);
            durationInSeconds += seconds;
            humanReadableDuration += `${seconds} second${seconds > 1 ? "s" : ""} `;
            lastUnit = "seconds";
            i++; // Skip the next part as it is already processed
          } else {
            // Handle partial matches for units
            if (nextPart && nextPart.toLowerCase().startsWith("h")) {
              const hours = parseInt(part);
              if (!isNaN(hours)) {
                durationInSeconds += hours * 3600;
                humanReadableDuration += `${hours} hour${hours > 1 ? "s" : ""} `;
                lastUnit = "hours";
                i++; // Skip the next part as it is already processed
              }
            } else if (nextPart && nextPart.toLowerCase().startsWith("m")) {
              const minutes = parseInt(part);
              if (!isNaN(minutes)) {
                durationInSeconds += minutes * 60;
                humanReadableDuration += `${minutes} minute${minutes > 1 ? "s" : ""} `;
                lastUnit = "minutes";
                i++; // Skip the next part as it is already processed
              }
            } else if (nextPart && nextPart.toLowerCase().startsWith("s")) {
              const seconds = parseInt(part);
              if (!isNaN(seconds)) {
                durationInSeconds += seconds;
                humanReadableDuration += `${seconds} second${seconds > 1 ? "s" : ""} `;
                lastUnit = "seconds";
                i++; // Skip the next part as it is already processed
              }
            } else {
              // Default to the last unit if no unit is specified
              if (lastUnit === "hours") {
                const minutes = parseInt(part);
                if (!isNaN(minutes)) {
                  durationInSeconds += minutes * 60;
                  humanReadableDuration += `${minutes} minute${minutes > 1 ? "s" : ""} `;
                }
              } else if (lastUnit === "minutes") {
                const seconds = parseInt(part);
                if (!isNaN(seconds)) {
                  durationInSeconds += seconds;
                  humanReadableDuration += `${seconds} second${seconds > 1 ? "s" : ""} `;
                }
              } else {
                // Default to hours if no previous unit
                const hours = parseInt(part);
                if (!isNaN(hours)) {
                  durationInSeconds += hours * 3600;
                  humanReadableDuration += `${hours} hour${hours > 1 ? "s" : ""} `;
                }
              }
            }
          }
        } else if (hourRegex.test(part)) {
          const hours = parseInt(parts[i - 1]);
          if (!isNaN(hours)) {
            durationInSeconds += hours * 3600;
            humanReadableDuration += `${hours} hour${hours > 1 ? "s" : ""} `;
            lastUnit = "hours";
          }
        } else if (minuteRegex.test(part)) {
          const minutes = parseInt(parts[i - 1]);
          if (!isNaN(minutes)) {
            durationInSeconds += minutes * 60;
            humanReadableDuration += `${minutes} minute${minutes > 1 ? "s" : ""} `;
            lastUnit = "minutes";
          }
        } else if (secondRegex.test(part)) {
          const seconds = parseInt(parts[i - 1]);
          if (!isNaN(seconds)) {
            durationInSeconds += seconds;
            humanReadableDuration += `${seconds} second${seconds > 1 ? "s" : ""} `;
            lastUnit = "seconds";
          }
        }
      }
      this.humanReadableDuration = humanReadableDuration.trim();
      return durationInSeconds;
      // console.log("Duration in seconds", durationInSeconds);
      // console.log("Human readable duration", this.humanReadableDuration);
    },
    handleSaveTimeTracking() {
      this.saveTimeTracking(this.entry, this.internalTimeTracking);

      // reset internalTimeTracking if it is a new time tracking
      if (!this.time_tracking) {
        this.internalTimeTracking = addonBlueprint("time_track", this.entry, this);
        this.internalTimeTracking.start_at = this.$moment.utc().format("YYYY-MM-DD HH:mm:00");
        this.internalTimeTracking.end_at = this.$moment.utc().format("YYYY-MM-DD HH:mm:00");
      }
      this.customDuration = "";
      this.customDurationAsSeconds = 0;
      this.humanReadableDuration = "";
      this.durationAsText = "";
    }
  }
};
export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'TimeTrackingSettings',
  setup(__props) {
    const reference = ref(null);
    const floating = ref(null);
    const {
      floatingStyles
    } = useFloating(reference, floating, {
      transform: false,
      placement: "bottom-start",
      middleware: [flip(), shift({
        crossAxis: true
      })]
    });
    return (_ctx, _cache) => {
      const _component_TransitionGroupHelper = _resolveComponent("TransitionGroupHelper");
      const _component_SaveIcon = _resolveComponent("SaveIcon");
      const _component_PlayIcon = _resolveComponent("PlayIcon");
      const _component_PauseIcon = _resolveComponent("PauseIcon");
      const _component_DatePicker = _resolveComponent("DatePicker");
      const _component_TimePicker = _resolveComponent("TimePicker");
      const _component_DurationPicker = _resolveComponent("DurationPicker");
      const _component_TrashIcon = _resolveComponent("TrashIcon");
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(_ctx.$style.timeTrackingStarter)
      }, [_createElementVNode("div", {
        ref_key: "reference",
        ref: reference,
        class: _normalizeClass(_ctx.$style.firstRow)
      }, [_createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.currentDuration)
      }, [_createVNode(_component_TransitionGroupHelper, {
        enter: {
          translateX: [50, 0],
          opacity: [0, 1]
        },
        leave: {
          translateX: [0, -50],
          opacity: [1, 0]
        }
      }, {
        default: _withCtx(() => [!_ctx.userHasInEntryActiveTimeTracking ? _withDirectives((_openBlock(), _createElementBlock("input", {
          key: 0,
          ref: "input",
          type: "text",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.durationAsText = $event),
          placeholder: "Enter time or start the timer",
          onFocus: _cache[1] || (_cache[1] = () => {
            _ctx.$refs.input.select();
            _ctx.showPopover = true;
          }),
          onBlur: _cache[2] || (_cache[2] = (...args) => _ctx.handleOnBlur && _ctx.handleOnBlur(...args)),
          onInput: _cache[3] || (_cache[3] = (...args) => _ctx.handleCustomDurationInput && _ctx.handleCustomDurationInput(...args)),
          onKeydown: _cache[4] || (_cache[4] = _withKeys(_withModifiers(() => {
            _ctx.$refs.input.blur();
            _ctx.handleSaveTimeTracking();
            _ctx.showPopover = false;
            _ctx.$emit('close');
          }, ["stop", "prevent"]), ["enter"]))
        }, null, 544)), [[_vModelText, _ctx.durationAsText]]) : (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.getDurationOfTimeTracking(_ctx.activeTimeTrackingByUser).format("hh:mm:ss", {
          stopTrim: "m"
        })), 1))]),
        _: 1
      })], 2), _ctx.showPopover && !_ctx.userHasInEntryActiveTimeTracking && _ctx.humanReadableDuration ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        ref_key: "floating",
        ref: floating,
        onClick: _cache[5] || (_cache[5] = $event => _ctx.customDuration = _ctx.humanReadableDuration),
        class: _normalizeClass(_ctx.$style.popover),
        style: _normalizeStyle(_unref(floatingStyles))
      }, _toDisplayString(_ctx.humanReadableDuration), 7)) : _createCommentVNode("", true), !_ctx.time_tracking?.id ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_TransitionGroupHelper, {
        enter: {
          translateY: [50, 0],
          opacity: [0, 1]
        },
        leave: {
          translateY: [0, -50],
          opacity: [1, 0],
          begin: anim => {
            anim.animatables[0].target.style.position = 'absolute';
          }
        }
      }, {
        default: _withCtx(() => [_ctx.customDurationAsSeconds ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          onClick: _cache[6] || (_cache[6] = (...args) => _ctx.handleSaveTimeTracking && _ctx.handleSaveTimeTracking(...args)),
          class: _normalizeClass(_ctx.$style.startButton)
        }, [_createVNode(_component_SaveIcon, {
          size: "20",
          strokeWidth: 2.5
        })], 2)) : !_ctx.userHasInEntryActiveTimeTracking ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          onClick: _cache[7] || (_cache[7] = $event => _ctx.startTimeTracking(__props.entry)),
          class: _normalizeClass(_ctx.$style.startButton)
        }, [_createVNode(_component_PlayIcon, {
          size: "20",
          strokeWidth: 2.5
        })], 2)) : (_openBlock(), _createElementBlock("button", {
          key: 2,
          onClick: _cache[8] || (_cache[8] = $event => _ctx.stopTimeTracking(__props.entry)),
          class: _normalizeClass(_ctx.$style.startButton)
        }, [_createVNode(_component_PauseIcon, {
          size: "20",
          strokeWidth: 2.5
        })], 2))]),
        _: 1
      }, 8, ["leave"])])) : _createCommentVNode("", true)], 2), _ctx.internalTimeTracking && !_ctx.userHasInEntryActiveTimeTracking ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.$style.secondRow)
      }, [_createElementVNode("fieldset", null, [_createVNode(_component_DatePicker, {
        modelValue: _ctx.customDate,
        "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => _ctx.customDate = $event),
        class: _normalizeClass(_ctx.$style.datePicker)
      }, null, 8, ["modelValue", "class"]), _createVNode(_component_TimePicker, {
        modelValue: _ctx.timeStart,
        "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => _ctx.timeStart = $event),
        class: _normalizeClass(_ctx.$style.timePicker)
      }, null, 8, ["modelValue", "class"]), _createVNode(_component_DurationPicker, {
        modelValue: _ctx.duration,
        "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => _ctx.duration = $event),
        basis: _ctx.internalTimeTracking?.start_at,
        class: _normalizeClass(_ctx.$style.durationPicker)
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.internalTimeTracking?.start_at), 1)]),
        _: 1
      }, 8, ["modelValue", "basis", "class"])]), _ctx.time_tracking?.id ? (_openBlock(), _createElementBlock("fieldset", _hoisted_3, [_createElementVNode("button", {
        onClick: _cache[12] || (_cache[12] = () => {
          _ctx.deleteTimeTracking(_ctx.time_tracking);
          _ctx.$emit('close');
        }),
        class: _normalizeClass(_ctx.$style.buttonDelete)
      }, [_createVNode(_component_TrashIcon, {
        size: "16"
      })], 2), _createElementVNode("button", {
        onClick: _cache[13] || (_cache[13] = () => {
          _ctx.handleSaveTimeTracking();
          _ctx.$emit('close');
        }),
        class: _normalizeClass(_ctx.$style.buttonSave)
      }, [_createVNode(_component_SaveIcon, {
        size: "16"
      }), _cache[14] || (_cache[14] = _createTextVNode(" Save "))], 2)])) : _createCommentVNode("", true)], 2)) : _createCommentVNode("", true)], 2);
    };
  }
});