export default {
  props: {
    output: Object,
    entry: Object,
    computedDisplay: Object
  },
  mounted() {
    // console.log(this.$refs.tiltContainer);
    // this.$vanillaTilt.init(this.$refs.tiltContainer, {
    //   scale: 10.1,
    //   speed: 1000,
    //   max: 10,
    //   glare: true,
    //   "max-glare": 0.5,
    // });
  },
  beforeUnmount() {
    // this.$refs.tiltContainer.$vanillaTilt.destroy();
  }
};