import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  role: "button",
  class: "w-full"
};
const _hoisted_2 = {
  class: "flex items-center"
};
const _hoisted_3 = {
  key: 2,
  class: "ml-3 block truncate capitalize"
};
const _hoisted_4 = {
  key: 0,
  class: "pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
};
const _hoisted_5 = {
  class: "truncate capitalize"
};
import { Addon } from "../mixins/Addon";
const __default__ = {
  mixins: [Addon],
  props: {
    label: Boolean
  },
  computed: {
    entryColor: {
      get() {
        return this.modelValue;
      },
      set(color) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.color = color;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: {
            entry: this.entry
          },
          entry: this.entry
        });
        this.$emit("update:modelValue", color);
      }
    }
  }
};
export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'ColorSettings',
  setup(__props) {
    const colors = ["gray", "red", "orange", "amber", "yellow", "lime", "green", "emerald", "teal", "cyan", "sky", "blue", "indigo", "violet", "purple", "fuchsia", "pink", "rose"];
    return (_ctx, _cache) => {
      const _component_SwatchBookIcon = _resolveComponent("SwatchBookIcon");
      const _component_ChevronsUpDownIcon = _resolveComponent("ChevronsUpDownIcon");
      const _component_ButtonComponent = _resolveComponent("ButtonComponent");
      const _component_PSVerticalListItem = _resolveComponent("PSVerticalListItem");
      const _component_TrashIcon = _resolveComponent("TrashIcon");
      const _component_PSVerticalList = _resolveComponent("PSVerticalList");
      const _component_PSOverlay = _resolveComponent("PSOverlay");
      return _openBlock(), _createBlock(_component_PSOverlay, null, {
        button: _withCtx(({
          open
        }) => [_createElementVNode("button", _hoisted_1, [_renderSlot(_ctx.$slots, "button", {
          open: open
        }, () => [_createVNode(_component_ButtonComponent, {
          variant: _ctx.variant,
          size: _ctx.size,
          color: _ctx.color,
          colorWeight: _ctx.colorWeight,
          title: "Set Color",
          class: _normalizeClass([{
            'pr-8': _ctx.variant != 'round'
          }, "relative"]),
          style: _normalizeStyle({
            '--ps-color-settings-bg-color': _ctx.$colors[_ctx.entryColor || 'neutral'][500]
          })
        }, {
          default: _withCtx(() => [_createElementVNode("span", _hoisted_2, [_ctx.variant == 'round' ? (_openBlock(), _createBlock(_component_SwatchBookIcon, {
            key: 0,
            size: "20"
          })) : _createCommentVNode("", true), _ctx.variant != 'round' ? (_openBlock(), _createElementBlock("span", {
            key: 1,
            class: _normalizeClass(_ctx.$style.colorDot),
            "aria-hidden": "true"
          }, null, 2)) : _createCommentVNode("", true), _ctx.variant != 'round' ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.entryColor || "No Color"), 1)) : _createCommentVNode("", true)]), _ctx.variant != 'round' ? (_openBlock(), _createElementBlock("span", _hoisted_4, [_createVNode(_component_ChevronsUpDownIcon, {
            size: "20",
            class: "text-gray-400",
            "aria-hidden": "true"
          })])) : _createCommentVNode("", true)]),
          _: 1
        }, 8, ["variant", "size", "color", "colorWeight", "class", "style"])])])]),
        title: _withCtx(() => _cache[0] || (_cache[0] = [_createElementVNode("span", null, " Color ", -1)])),
        default: _withCtx(({
          close
        }) => [_createVNode(_component_PSVerticalList, {
          class: _normalizeClass(_ctx.$style.ColorSettingsVerticalList)
        }, {
          default: _withCtx(() => [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(colors, color => {
            return _createVNode(_component_PSVerticalListItem, {
              key: color,
              active: _ctx.entryColor == color,
              onClick: $event => (_ctx.entryColor = color, close()),
              style: _normalizeStyle({
                '--ps-color-settings-bg-color': _ctx.$colors[color][500]
              })
            }, {
              before: _withCtx(() => [_createElementVNode("span", {
                class: _normalizeClass(_ctx.$style.colorDot),
                "aria-hidden": "true"
              }, null, 2)]),
              default: _withCtx(() => [_createElementVNode("span", _hoisted_5, _toDisplayString(color), 1)]),
              _: 2
            }, 1032, ["active", "onClick", "style"]);
          }), 64)), _ctx.entryColor != null ? (_openBlock(), _createBlock(_component_PSVerticalListItem, {
            key: 0,
            onClick: $event => (_ctx.entryColor = null, close())
          }, {
            before: _withCtx(() => [_createVNode(_component_TrashIcon, {
              size: "12"
            })]),
            default: _withCtx(() => [_cache[1] || (_cache[1] = _createTextVNode(" Clear "))]),
            _: 2
          }, 1032, ["onClick"])) : _createCommentVNode("", true)]),
          _: 2
        }, 1032, ["class"])]),
        _: 3
      });
    };
  }
});