import { addonBlueprint } from "@/addonBlueprint";
import { Addon } from "../mixins/Addon";
export default {
  mixins: [Addon],
  watch: {
    modelValue: {
      immediate: true,
      handler() {
        if (this.modelValue) this.cover = this.modelValue;else this.cover = addonBlueprint("cover", this.entry);
      }
    },
    entry: function () {
      if (this.modelValue) this.cover = this.modelValue;else this.cover = addonBlueprint("cover", this.entry);
    }
  },
  data() {
    return {
      cover: null
    };
  },
  mounted() {
    if (this.modelValue) this.cover = this.modelValue;else this.cover = addonBlueprint("cover", this.entry);
  }
};