import { renderSlot as _renderSlot, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue";
import { ref, watchEffect } from "vue";
import { shift, flip, offset, autoUpdate, size, computePosition } from "@floating-ui/vue";
const __default__ = {
  provide() {
    return {
      onChildOverlayOpen: this.expand
    };
  },
  inject: {
    onChildOverlayOpen: {
      default: () => {
        return () => {};
      }
    }
  },
  data() {
    return {
      isOpen: this.open,
      deltaY: 0,
      deltaYTimeout: null,
      deltaYDebounced: 0,
      isTouching: false,
      touchStartY: 0,
      initialScrollTop: 0,
      scrollTop: 0,
      initialHeight: 0,
      modalTransition: window.innerHeight * 0.4,
      // @note with 90dvh height and 40% transition, the modal will be half the screen
      isExpanded: false,
      temporarilyDisableDrag: false,
      hoverTimeout: null
    };
  },
  watch: {
    open() {
      this.isOpen = this.open;
    },
    isOpen() {
      if (this.hoverTimeout) clearTimeout(this.hoverTimeout);
      this.modalTransition = window.innerHeight * 0.4;
      this.isExpanded = false;
      if (this.isOpen) {
        this.onChildOverlayOpen();
        // Only scroll up when open, otherwise it will jump in the closing transition
        this.scrollTop = 0;
        this.initialScrollTop = 0;
        if (this.$refs.content) {
          this.$refs.content.scrollTop = 0;
        }
        this.$nextTick(() => {
          if (this.$refs.overlay) {
            this.$refs.overlay.addEventListener("touchstart", this.handleTouchStart);
            this.$refs.overlay.addEventListener("touchmove", this.handleTouchMove);
            this.$refs.overlay.addEventListener("touchend", this.handleTouchEnd);
          }
          this.$emit("onOverlayOpen");
        });
      } else {
        if (this.$refs.overlay) {
          this.$refs.overlay.removeEventListener("touchstart", this.handleTouchStart);
          this.$refs.overlay.removeEventListener("touchmove", this.handleTouchMove);
          this.$refs.overlay.removeEventListener("touchend", this.handleTouchEnd);
        }
        this.$emit("close");
      }
    },
    // isExpanded: function (newVal) {
    //   // If the modal is collapsed, scroll to the top
    //   if (!newVal) {
    //     this.$nextTick(() => {
    //       // this.scrollTop = 0;
    //       // this.initialScrollTop = 0;
    //       // if (this.$refs.content) {
    //       //   this.$refs.content.scrollTop = 0;
    //       // }
    //     });
    //   }
    // },
    scrollTop: function (newVal) {
      if (newVal > 0) {
        this.temporarilyDisableDrag = true;
      }
    },
    isTouching: function (newVal) {
      if (!newVal) {
        this.temporarilyDisableDrag = false;
        this.initialScrollTop = 0;
      }
    },
    deltaY: function (newVal) {
      this.deltaYDebounced = newVal;
      if (this.deltaYTimeout) clearTimeout(this.deltaYTimeout);
      this.deltaYTimeout = setTimeout(() => {
        this.deltaYDebounced = null;
      }, 50);
    }
  },
  computed: {
    isOpenOnHoverComputed() {
      return this.openOnHover && window.matchMedia("(pointer: fine)").matches;
    },
    isRenderBackdrop() {
      return this.renderBackdrop || window.matchMedia("(pointer: coarse)").matches;
    }
  },
  methods: {
    expand() {
      this.modalTransition = 0;
      this.isExpanded = true;
    },
    close() {
      this.isOpen = false;
    },
    handleTouchStart(e) {
      // console.log("handleTouchStart");
      this.isTouching = true;
      this.touchStartY = e.touches[0].clientY;
      this.initialScrollTop = this.$refs.content.scrollTop;
      this.scrollTop = this.initialScrollTop;
      this.initialTransition = this.modalTransition;
      e.stopPropagation();
    },
    handleTouchMove(e) {
      // console.log("handleTouchMove");
      if (!this.isTouching) return;

      // Clear any active element focus
      if (document.activeElement) {
        // document.activeElement.blur();
      }
      const currentY = e.touches[0].clientY;
      this.deltaY = currentY - this.touchStartY;
      this.scrollTop = this.$refs.content.scrollTop;

      // Check if target is inside content and content is scrollable
      const isTargetInContent = this.$refs.content?.contains(e.target);
      // const isContentScrollable =
      //   this.$refs.content?.scrollHeight > this.$refs.content?.clientHeight;

      // console.log("isTargetInContent", isTargetInContent);
      // console.log("isContentScrollable", isContentScrollable);
      // Only handle gestures if we're at the top or pulling up
      if (!this.isExpanded || !isTargetInContent || !this.temporarilyDisableDrag && this.initialScrollTop <= 0 && this.scrollTop <= 0) {
        this.modalTransition = Math.max(this.initialTransition + this.deltaY, 0);
        if (this.deltaY > 0) {
          e.preventDefault();
        }
        e.stopPropagation();
      }
    },
    handleTouchEnd(e) {
      // console.log("handleTouchEnd");
      this.isTouching = false;
      const maxHeight = 0;
      const normalHeight = window.innerHeight * 0.4;
      const threshold = window.innerHeight * 0.25;
      if (this.deltaYDebounced !== null && Math.abs(this.deltaYDebounced) > 10 && this.scrollTop <= 0 && this.initialScrollTop <= 0) {
        if (this.deltaYDebounced <= 0) {
          if (!this.isExpanded) {
            this.modalTransition = maxHeight;
            this.isExpanded = true;
          }
        } else {
          if (this.modalTransition < normalHeight) {
            this.modalTransition = normalHeight;
            this.isExpanded = false;
          } else {
            // If pulled down far enough, close
            this.isOpen = false;
          }
        }
      } else {
        if (this.modalTransition > window.innerHeight * 0.7) {
          // If pulled down far enough, close
          this.isOpen = false;
        } else {
          if (this.modalTransition < threshold) {
            this.modalTransition = maxHeight;
            this.isExpanded = true;
          } else {
            this.modalTransition = normalHeight;
            this.isExpanded = false;
          }
        }
        if (!this.isExpanded) {
          // this.scrollTop = 0;
          // this.initialScrollTop = 0;
          // this.$refs.content.scrollTop = 0;
        }
      }
      e.stopPropagation();
    },
    onMouseEnter() {
      if (this.openOnHover && window.matchMedia("(pointer: fine)").matches) {
        clearTimeout(this.hoverTimeout);
        this.hoverTimeout = setTimeout(() => {
          this.isOpen = true;
        }, 200);
      }
    },
    onMouseLeave(event) {
      if (this.openOnHover && window.matchMedia("(pointer: fine)").matches) {
        clearTimeout(this.hoverTimeout);
        this.hoverTimeout = setTimeout(() => {
          if (!this.$refs.root?.contains(event.relatedTarget) && !this.$refs.reference?.contains(event.relatedTarget)) {
            this.isOpen = false;
          }
        }, 100);
      }
      if (this.$store.getters.dragged.length > 0) {
        this.isOpen = false;
      }
    }
  }
};
export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'PSOverlay',
  props: {
    id: String,
    open: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: "auto"
    },
    height: {
      type: String,
      default: "auto"
    },
    maxHeight: {
      type: String
    },
    teleport: {
      type: Boolean,
      default: false
    },
    buttonClass: String,
    placement: {
      type: String,
      default: "bottom"
    },
    offset: {
      type: Number,
      default: 0
    },
    canOverflow: {
      type: Boolean,
      default: false
    },
    openOnHover: {
      type: Boolean,
      default: false
    },
    renderBackdrop: {
      type: Boolean,
      default: true
    }
  },
  setup(__props) {
    const reference = ref(null);
    const overlay = ref(null);
    const props = __props;

    // Watch for element updates and apply computed position
    watchEffect(() => {
      if (!reference.value || !overlay.value) return;
      autoUpdate(reference.value, overlay.value, () => {
        if (!reference.value || !overlay.value) return;
        computePosition(reference.value, overlay.value, {
          placement: props.placement,
          middleware: [flip(), shift({
            crossAxis: true,
            padding: 8
          }), offset(props.offset), size({
            apply({
              availableWidth,
              availableHeight,
              elements
            }) {
              const {
                floating: overlay
              } = elements;

              // Store size as CSS variables
              overlay.style.setProperty("--floating-max-width", `${availableWidth}px`);
              overlay.style.setProperty("--floating-max-height", `${availableHeight}px`);
              overlay.style.setProperty("--floating-width", "max-content");
            },
            padding: 8
          })]
        }).then(({
          x,
          y
        }) => {
          overlay.value.style.setProperty("--floating-x", `${x}px`);
          overlay.value.style.setProperty("--floating-y", `${y}px`);
        });
      });
    });
    return (_ctx, _cache) => {
      const _component_PSOverlayBackdrop = _resolveComponent("PSOverlayBackdrop");
      const _component_PSOverlayDragHandle = _resolveComponent("PSOverlayDragHandle");
      const _component_XIcon = _resolveComponent("XIcon");
      const _component_PSButton = _resolveComponent("PSButton");
      const _component_PSOverlayTitle = _resolveComponent("PSOverlayTitle");
      const _component_PSOverlayTransition = _resolveComponent("PSOverlayTransition");
      return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", {
        ref_key: "reference",
        ref: reference,
        onClick: _cache[0] || (_cache[0] = _withModifiers($event => _ctx.isOpen = true, ["stop", "prevent"])),
        class: _normalizeClass(_ctx.$style.PopoverButtonSlot),
        onMouseenter: _cache[1] || (_cache[1] = (...args) => _ctx.onMouseEnter && _ctx.onMouseEnter(...args)),
        onMouseleave: _cache[2] || (_cache[2] = (...args) => _ctx.onMouseLeave && _ctx.onMouseLeave(...args))
      }, [_renderSlot(_ctx.$slots, "button", {
        open: _ctx.isOpen,
        close: _ctx.close,
        triggerOpen: () => _ctx.isOpen = true
      })], 34), _ctx.isRenderBackdrop && _ctx.isOpen && !_ctx.isOpenOnHoverComputed ? (_openBlock(), _createBlock(_Teleport, {
        key: 0,
        to: "body"
      }, [_createVNode(_component_PSOverlayBackdrop, {
        id: __props.id,
        onClick: _cache[3] || (_cache[3] = $event => _ctx.isOpen = false),
        class: _normalizeClass(_ctx.$style.OverlayBackdrop)
      }, null, 8, ["id", "class"])])) : _createCommentVNode("", true), _createVNode(_component_PSOverlayTransition, null, {
        default: _withCtx(() => [_ctx.isOpen ? (_openBlock(), _createBlock(_Teleport, {
          key: 0,
          to: "body"
        }, [_createElementVNode("div", {
          ref: "root",
          class: _normalizeClass(_ctx.$style.PopoverRoot),
          onMouseenter: _cache[5] || (_cache[5] = (...args) => _ctx.onMouseEnter && _ctx.onMouseEnter(...args)),
          onMouseleave: _cache[6] || (_cache[6] = (...args) => _ctx.onMouseLeave && _ctx.onMouseLeave(...args)),
          style: _normalizeStyle({
            '--ps-overlay-custom-max-height': __props.maxHeight
          })
        }, [_createElementVNode("div", {
          ref_key: "overlay",
          ref: overlay,
          class: _normalizeClass([[_ctx.$style.PopoverSlot, {
            'transition-transform duration-300 ease-out': !_ctx.isTouching,
            [_ctx.$style.isDragging]: _ctx.isTouching
          }], "ps_overlay"]),
          style: _normalizeStyle({
            '--ps-modal-transition-y': `${_ctx.modalTransition}px`,
            '--ps-modal-height': `${__props.height}px`
          })
        }, [_createVNode(_component_PSOverlayDragHandle), _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.Header)
        }, [_createVNode(_component_PSButton, {
          onClick: _cache[4] || (_cache[4] = _withModifiers($event => _ctx.isOpen = false, ["stop"])),
          class: _normalizeClass(_ctx.$style.CloseButton)
        }, {
          default: _withCtx(() => [_createVNode(_component_XIcon, {
            size: "20"
          })]),
          _: 1
        }, 8, ["class"]), _createVNode(_component_PSOverlayTitle, null, {
          default: _withCtx(() => [_renderSlot(_ctx.$slots, "title")]),
          _: 3
        })], 2), _createElementVNode("div", {
          ref: "content",
          class: _normalizeClass([_ctx.$style.PopoverContent, {
            [_ctx.$style.isExpanded]: _ctx.isExpanded,
            [_ctx.$style.isCollapsed]: !_ctx.isExpanded
          }])
        }, [_renderSlot(_ctx.$slots, "default", {
          close: _ctx.close,
          expand: _ctx.expand
        })], 2)], 6)], 38)])) : _createCommentVNode("", true)]),
        _: 3
      })]);
    };
  }
});