// import VanillaTilt from "vanilla-tilt";
export default {
  mounted() {
    // VanillaTilt.init(this.$refs.panelHelper.$el, {
    //   scale: 1.1,
    //   speed: 1000,
    //   max: 10,
    //   glare: true,
    //   "max-glare": 0.5,
    // });
  },
  beforeUnmount() {
    // this.$refs.panelHelper.$el.vanillaTilt.destroy();
  }
};