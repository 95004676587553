import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue";
const _hoisted_1 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TimePicker = _resolveComponent("TimePicker");
  const _component_MinusIcon = _resolveComponent("MinusIcon");
  const _component_DurationPicker = _resolveComponent("DurationPicker");
  const _component_DurationTextPicker = _resolveComponent("DurationTextPicker");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.scheduleTimeAndDurationSettings)
  }, [_createElementVNode("div", {
    class: _normalizeClass(_ctx.$style.timeAndDurationRow)
  }, [_createVNode(_component_TimePicker, {
    ref: "time_picker",
    modelValue: $data.internalTime,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = value => {
      $data.internalTime = value;
      _ctx.$emit('update:time', value);
    }),
    class: _normalizeClass(_ctx.$style.timePicker)
  }, null, 8, ["modelValue", "class"]), $data.internalTime ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_MinusIcon, {
    size: "16"
  })])) : _createCommentVNode("", true), $data.internalTime ? (_openBlock(), _createBlock(_component_DurationPicker, {
    key: 1,
    modelValue: $data.internalDuration,
    basis: $data.internalTime ? $props.entry.schedule.date ? $props.entry.schedule.date + ' ' + $data.internalTime : _ctx.$moment().format('YYYY-MM-DD') + ' ' + $data.internalTime : null,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = value => $data.internalDuration = value),
    class: _normalizeClass(_ctx.$style.durationPicker)
  }, null, 8, ["modelValue", "basis", "class"])) : _createCommentVNode("", true)], 2), _renderSlot(_ctx.$slots, "default"), _createElementVNode("div", {
    class: _normalizeClass(_ctx.$style.timeAndDurationRow)
  }, [_createVNode(_component_DurationTextPicker, {
    modelValue: $data.internalDuration,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = value => $data.internalDuration = value),
    class: _normalizeClass(_ctx.$style.durationTextPicker)
  }, null, 8, ["modelValue", "class"])], 2)], 2);
}