import { addonBlueprint } from "@/addonBlueprint";
import { getDateSpan } from "@/datesHelper";
import { Coloring } from "../mixins/Coloring";
import { Routining } from "../mixins/Routining";
import { Addon } from "../mixins/Addon";
export default {
  name: "RoutineSettings",
  mixins: [Coloring, Routining, Addon],
  props: {
    modelValue: {
      type: Object,
      default: null
    },
    entry: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      open: false,
      draftRoutine: null,
      options: [{
        title: "Daily",
        value: "daily"
      }, {
        title: "Weekly",
        value: "weekly"
      }, {
        title: "Monthly",
        value: "monthly"
      }],
      dataReady: false
    };
  },
  watch: {
    entry: {
      handler() {
        if (!this.modelValue) this.initializeDraftRoutine();
      }
    }
  },
  created() {
    if (!this.modelValue) this.initializeDraftRoutine();
  },
  computed: {
    formTarget: {
      get() {
        return this.modelValue || this.draftRoutine;
      },
      set(target) {
        if (this.modelValue) {
          this.updateExistingRoutine(target);
        } else {
          this.updateDraftRoutine(target);
        }
      }
    },
    routine: {
      get() {
        return this.modelValue;
      },
      set(routine) {
        if (routine === null) {
          this.$store.dispatch("push", {
            event: "routine_delete",
            params: {
              routine: this.entry.routine
            },
            entry: this.entry
          });
          this.deleteRoutine();
        } else {
          this.saveRoutine();
        }
      }
    },
    target: {
      get() {
        return [this.formTarget.target];
      },
      set(val) {
        this.formTarget = {
          ...this.formTarget,
          target: val[0]
        };
      }
    }
  },
  methods: {
    pullDataBySpan(date) {
      if (!date) date = this.$moment.utc().format("YYYY-MM-DD");
      const currentDate = this.$moment(date);
      const dates = [
      // Two months ago
      currentDate.clone().subtract(2, "months").startOf("month").format("YYYY-MM-DD"), currentDate.clone().endOf("month").format("YYYY-MM-DD")];

      // console.log("dates", dates);

      // this.dataReady = false;
      this.$store.dispatch("pull", {
        filters: [{
          key: ["routine", "id"],
          op: "eq",
          val: this.routine.id
        }, {
          key: ["routine_logs", "span_at"],
          op: "ra",
          val: dates
        }],
        data: "routine_logs",
        cb: () => {
          this.dataReady = true;
        }
      });
    },
    initializeDraftRoutine() {
      this.draftRoutine = {
        ...addonBlueprint("routine", this.entry, this),
        _temp: true
      };
    },
    updateExistingRoutine(target) {
      this.$emit("update:modelValue", target);
      this.updateEntryAndStore(target);
    },
    updateDraftRoutine(target) {
      this.draftRoutine = target;
    },
    deleteRoutine() {
      this.$emit("update:modelValue", null);
      this.updateEntryAndStore(null);
    },
    saveRoutine() {
      this.$emit("update:modelValue", this.draftRoutine);
      this.updateEntryAndStore(this.draftRoutine);
    },
    updateEntryAndStore(routine) {
      // eslint-disable-next-line vue/no-mutating-props
      this.entry.routine = routine;
      this.dispatchUpdate();
    },
    dispatchUpdate() {
      if (this.entry.routine) {
        if (this.entry.routine?._temp) {
          delete this.entry.routine._temp;
          this.$store.dispatch("push", {
            event: "routine_create",
            params: {
              routine: this.entry.routine
            },
            entry: this.entry
          });
        } else {
          this.$store.dispatch("push", {
            event: "routine_update",
            params: {
              routine: this.entry.routine
            },
            entry: this.entry
          });
        }
      }
    },
    getSpanForDate(frequency, date) {
      return getDateSpan(frequency, date);
    },
    handleSpanClick(date) {
      if (this.$moment(date).isSameOrBefore(this.$moment(), "day")) {
        this.logASpan({
          entry: this.entry,
          span_at: this.getSpanForDate(this.routine.frequency, date),
          frequency: this.routine.frequency
        });
        if (!this.entry.temp) {
          this.dispatchUpdate();
        }
      }
    }
  }
};