import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, Transition as _Transition, normalizeStyle as _normalizeStyle, renderList as _renderList, createBlock as _createBlock, Teleport as _Teleport } from "vue";
const _hoisted_1 = {
  class: "flex flex-none flex-wrap items-center justify-between gap-2 pb-4"
};
const _hoisted_2 = {
  class: "flex items-center gap-x-2 text-base font-semibold leading-6 text-neutral-900 dark:text-neutral-300"
};
const _hoisted_3 = ["datetime"];
const _hoisted_4 = ["datetime"];
const _hoisted_5 = ["datetime"];
const _hoisted_6 = {
  key: 3,
  class: "hidden text-sm text-neutral-500 sm:inline dark:text-neutral-400"
};
const _hoisted_7 = {
  class: "flex items-center gap-x-2"
};
const _hoisted_8 = {
  class: "relative flex items-center gap-1"
};
const _hoisted_9 = {
  class: "hidden md:flex md:items-center"
};
const _hoisted_10 = {
  class: "py-1"
};
const _hoisted_11 = {
  class: "py-1"
};
const _hoisted_12 = {
  class: "py-1"
};
const _hoisted_13 = {
  class: "flex items-center gap-x-0.5 text-xs font-medium"
};
const _hoisted_14 = {
  key: 0,
  class: "pr-0.5 text-xs text-neutral-700 dark:text-neutral-500"
};
const _hoisted_15 = {
  class: "text-xs text-neutral-700 dark:text-neutral-500"
};
const _hoisted_16 = {
  key: 0,
  class: "grid h-full grid-cols-7"
};
const _hoisted_17 = {
  key: 1,
  class: "grid h-full grid-cols-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ChevronLeftIcon = _resolveComponent("ChevronLeftIcon");
  const _component_PSButton = _resolveComponent("PSButton");
  const _component_ChevronRightIcon = _resolveComponent("ChevronRightIcon");
  const _component_ChevronDownIcon = _resolveComponent("ChevronDownIcon");
  const _component_ButtonComponent = _resolveComponent("ButtonComponent");
  const _component_MenuButton = _resolveComponent("MenuButton");
  const _component_MenuItem = _resolveComponent("MenuItem");
  const _component_MenuItems = _resolveComponent("MenuItems");
  const _component_Menu = _resolveComponent("Menu");
  const _component_EllipsisIcon = _resolveComponent("EllipsisIcon");
  const _component_CalendarEntryDisplay = _resolveComponent("CalendarEntryDisplay");
  const _component_DropZone = _resolveComponent("DropZone");
  const _component_FullCalendar = _resolveComponent("FullCalendar");
  const _component_InputDisplay = _resolveComponent("InputDisplay");
  const _component_PanelHelper = _resolveComponent("PanelHelper");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.calendarHelper)
  }, [_createElementVNode("header", _hoisted_1, [_createElementVNode("div", null, [_createElementVNode("h1", _hoisted_2, [$props.mode != 'day' ? (_openBlock(), _createElementBlock("time", {
    key: 0,
    datetime: $data.currentStart.format('YYYY-MM')
  }, _toDisplayString($data.currentStart.format("MMMM YYYY")), 9, _hoisted_3)) : _createCommentVNode("", true), $props.mode == 'day' ? (_openBlock(), _createElementBlock("time", {
    key: 1,
    datetime: $data.currentStart.format('YYYY-MM-DD'),
    class: "sm:hidden"
  }, _toDisplayString($data.currentStart.format("MMM DD, YYYY")), 9, _hoisted_4)) : _createCommentVNode("", true), $props.mode == 'day' ? (_openBlock(), _createElementBlock("time", {
    key: 2,
    datetime: $data.currentStart.format('YYYY-MM-DD'),
    class: "hidden sm:inline"
  }, _toDisplayString($data.currentStart.format("MMMM DD, YYYY")), 9, _hoisted_5)) : _createCommentVNode("", true), $props.mode == 'day' ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString($data.currentStart.format("dddd")), 1)) : _createCommentVNode("", true)])]), _createElementVNode("div", _hoisted_7, [_renderSlot(_ctx.$slots, "header"), _createElementVNode("div", _hoisted_8, [_createVNode(_component_PSButton, {
    onClick: _cache[0] || (_cache[0] = $event => (_ctx.$refs.fullCalendar.getApi().prev(), $options.retrieveDateSpanFromCalendar())),
    class: _normalizeClass(_ctx.$style.todayButton)
  }, {
    default: _withCtx(() => [_cache[16] || (_cache[16] = _createElementVNode("span", {
      class: "sr-only"
    }, "Previous", -1)), _createVNode(_component_ChevronLeftIcon, {
      class: "h-5 w-5",
      "aria-hidden": "true"
    })]),
    _: 1
  }, 8, ["class"]), _createVNode(_component_PSButton, {
    onClick: _cache[1] || (_cache[1] = $event => (_ctx.$refs.fullCalendar.getApi().today(), $options.retrieveDateSpanFromCalendar())),
    class: _normalizeClass([_ctx.$style.todayButton, "hidden focus:relative md:block"])
  }, {
    default: _withCtx(() => _cache[17] || (_cache[17] = [_createTextVNode(" Today ")])),
    _: 1
  }, 8, ["class"]), _createVNode(_component_PSButton, {
    onClick: _cache[2] || (_cache[2] = $event => (_ctx.$refs.fullCalendar.getApi().next(), $options.retrieveDateSpanFromCalendar())),
    class: _normalizeClass(_ctx.$style.todayButton)
  }, {
    default: _withCtx(() => [_cache[18] || (_cache[18] = _createElementVNode("span", {
      class: "sr-only"
    }, "Next", -1)), _createVNode(_component_ChevronRightIcon, {
      class: "h-5 w-5",
      "aria-hidden": "true"
    })]),
    _: 1
  }, 8, ["class"])]), _createElementVNode("div", _hoisted_9, [_createVNode(_component_Menu, {
    as: "div",
    class: "relative"
  }, {
    default: _withCtx(() => [_createVNode(_component_MenuButton, {
      type: "button",
      class: "focus-visible:outline-none"
    }, {
      default: _withCtx(() => [_createVNode(_component_ButtonComponent, {
        color: "primary"
      }, {
        default: _withCtx(() => [$props.mode == 'day' ? (_openBlock(), _createElementBlock(_Fragment, {
          key: 0
        }, [_createTextVNode("Day view")], 64)) : _createCommentVNode("", true), $props.mode == 'week' ? (_openBlock(), _createElementBlock(_Fragment, {
          key: 1
        }, [_createTextVNode("Week view")], 64)) : _createCommentVNode("", true), $props.mode == 'month' ? (_openBlock(), _createElementBlock(_Fragment, {
          key: 2
        }, [_createTextVNode("Month view")], 64)) : _createCommentVNode("", true), _createVNode(_component_ChevronDownIcon, {
          class: "-mr-1 h-5 w-5 text-gray-400",
          "aria-hidden": "true"
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_Transition, {
      "enter-active-class": "transition ease-out duration-100",
      "enter-from-class": "transform opacity-0 scale-95",
      "enter-to-class": "transform opacity-100 scale-100",
      "leave-active-class": "transition ease-in duration-75",
      "leave-from-class": "transform opacity-100 scale-100",
      "leave-to-class": "transform opacity-0 scale-95"
    }, {
      default: _withCtx(() => [_createVNode(_component_MenuItems, {
        class: "absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-black dark:ring-neutral-700"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_10, [_createVNode(_component_MenuItem, null, {
          default: _withCtx(({
            active
          }) => [_createElementVNode("a", {
            onClick: _cache[3] || (_cache[3] = $event => _ctx.$emit('mode', 'day')),
            class: _normalizeClass([active ? 'bg-neutral-100 text-neutral-900 dark:bg-neutral-900 dark:text-neutral-300' : 'text-neutral-700 dark:text-neutral-400', 'block px-4 py-2 text-sm'])
          }, " Day view ", 2)]),
          _: 1
        }), _createVNode(_component_MenuItem, null, {
          default: _withCtx(({
            active
          }) => [_createElementVNode("a", {
            onClick: _cache[4] || (_cache[4] = $event => _ctx.$emit('mode', 'week')),
            class: _normalizeClass([active ? 'bg-neutral-100 text-neutral-900 dark:bg-neutral-900 dark:text-neutral-300' : 'text-neutral-700 dark:text-neutral-400', 'block px-4 py-2 text-sm'])
          }, " Week view ", 2)]),
          _: 1
        }), _createVNode(_component_MenuItem, null, {
          default: _withCtx(({
            active
          }) => [_createElementVNode("a", {
            onClick: _cache[5] || (_cache[5] = $event => _ctx.$emit('mode', 'month')),
            class: _normalizeClass([active ? 'bg-neutral-100 text-neutral-900 dark:bg-neutral-900 dark:text-neutral-300' : 'text-neutral-700 dark:text-neutral-400', 'block px-4 py-2 text-sm'])
          }, " Month view ", 2)]),
          _: 1
        })])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })]), _createVNode(_component_Menu, {
    as: "div",
    class: "relative md:hidden"
  }, {
    default: _withCtx(() => [_createVNode(_component_MenuButton, {
      class: "-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500 focus-visible:outline-none"
    }, {
      default: _withCtx(() => [_cache[19] || (_cache[19] = _createElementVNode("span", {
        class: "sr-only"
      }, "Open menu", -1)), _createVNode(_component_EllipsisIcon, {
        size: "20",
        "aria-hidden": "true"
      })]),
      _: 1
    }), _createVNode(_Transition, {
      "enter-active-class": "transition ease-out duration-100",
      "enter-from-class": "transform opacity-0 scale-95",
      "enter-to-class": "transform opacity-100 scale-100",
      "leave-active-class": "transition ease-in duration-75",
      "leave-from-class": "transform opacity-100 scale-100",
      "leave-to-class": "transform opacity-0 scale-95"
    }, {
      default: _withCtx(() => [_createVNode(_component_MenuItems, {
        class: "absolute right-0 z-10 mt-3 w-36 origin-top-right divide-y divide-neutral-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:divide-neutral-800 dark:bg-black dark:ring-neutral-700"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_11, [_createVNode(_component_MenuItem, null, {
          default: _withCtx(({
            active
          }) => [_createElementVNode("a", {
            onClick: _cache[6] || (_cache[6] = $event => _ctx.$refs.fullCalendar.getApi().today()),
            class: _normalizeClass([active ? 'bg-neutral-100 text-neutral-900 dark:bg-neutral-900 dark:text-neutral-300' : 'text-neutral-700 dark:text-neutral-400', 'block px-4 py-2 text-sm'])
          }, "Go to today", 2)]),
          _: 1
        })]), _createElementVNode("div", _hoisted_12, [_createVNode(_component_MenuItem, null, {
          default: _withCtx(({
            active
          }) => [_createElementVNode("a", {
            onClick: _cache[7] || (_cache[7] = $event => _ctx.$emit('mode', 'day')),
            class: _normalizeClass([active ? 'bg-neutral-100 text-neutral-900 dark:bg-neutral-900 dark:text-neutral-300' : 'text-neutral-700 dark:text-neutral-400', 'block px-4 py-2 text-sm'])
          }, "Day view", 2)]),
          _: 1
        }), _createVNode(_component_MenuItem, null, {
          default: _withCtx(({
            active
          }) => [_createElementVNode("a", {
            onClick: _cache[8] || (_cache[8] = $event => _ctx.$emit('mode', 'week')),
            class: _normalizeClass([active ? 'bg-neutral-100 text-neutral-900 dark:bg-neutral-900 dark:text-neutral-300' : 'text-neutral-700 dark:text-neutral-400', 'block px-4 py-2 text-sm'])
          }, "Week view", 2)]),
          _: 1
        }), _createVNode(_component_MenuItem, null, {
          default: _withCtx(({
            active
          }) => [_createElementVNode("a", {
            onClick: _cache[9] || (_cache[9] = $event => _ctx.$emit('mode', 'month')),
            class: _normalizeClass([active ? 'bg-neutral-100 text-neutral-900 dark:bg-neutral-900 dark:text-neutral-300' : 'text-neutral-700 dark:text-neutral-400', 'block px-4 py-2 text-sm'])
          }, "Month view", 2)]),
          _: 1
        })])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })])]), $data.isLazyMounted ? (_openBlock(), _createBlock(_component_FullCalendar, {
    key: 0,
    ref: "fullCalendar",
    options: $options.calendarOptionsComputed
  }, {
    eventContent: _withCtx(arg => [_createVNode(_component_CalendarEntryDisplay, {
      entry: $options.getEntryByEvent(arg.event),
      event: arg.event,
      class: _normalizeClass([arg.view.type == 'dayGridMonth' ? 'h-[22px]' : '', _ctx.$style.eventContent]),
      style: _normalizeStyle({
        '--ps-calendar-bg-color': 'var(--ps-color-' + arg.event.backgroundColor + '-50)',
        '--ps-calendar-bg-color-hover': 'var(--ps-color-' + arg.event.backgroundColor + '-100)',
        '--ps-calendar-bg-color-dark': 'var(--ps-color-' + arg.event.backgroundColor + '-950)',
        '--ps-calendar-bg-color-dark-hover': 'var(--ps-color-' + arg.event.backgroundColor + '-900)'
      })
    }, null, 8, ["entry", "event", "class", "style"])]),
    dayHeaderContent: _withCtx(arg => [_createElementVNode("div", _hoisted_13, [_createElementVNode("span", {
      class: _normalizeClass(_ctx.$style.dayHeaderContent_dayName)
    }, _toDisplayString(_ctx.$moment(arg.date).format("ddd.")), 3), arg.view.type != 'dayGridMonth' ? (_openBlock(), _createElementBlock("span", {
      key: 0,
      class: _normalizeClass([{
        'bg-neutral-950 text-white': _ctx.$moment(arg.date).isSame(_ctx.$moment(), 'day')
      }, "flex h-5 w-5 items-center justify-center rounded-full text-center text-xs"])
    }, _toDisplayString(_ctx.$moment(arg.date).format("DD")), 3)) : _createCommentVNode("", true)])]),
    slotLabelContent: _withCtx(arg => [_ctx.$moment(arg.date).format('mm') == '00' && Math.abs($options.getTimeDifferenceInMinutes(arg.date)) > 5 ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.$moment(arg.date).format("HH:mm")), 1)) : _createCommentVNode("", true)]),
    allDayContent: _withCtx(arg => [_createElementVNode("span", _hoisted_15, _toDisplayString(arg.text), 1)]),
    nowIndicatorContent: _withCtx(arg => [arg.isAxis ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      class: _normalizeClass(_ctx.$style.nowIndicator)
    }, _toDisplayString(_ctx.$moment.utc(arg.date).local().format("HH:mm")), 3)) : _createCommentVNode("", true)]),
    dayCellContent: _withCtx(arg => [arg.view.type == 'dayGridMonth' ? (_openBlock(), _createElementBlock("span", {
      key: 0,
      class: _normalizeClass([_ctx.$style.slotDayCellContent_header, _ctx.$moment(arg.date).isSame(_ctx.$moment(), 'day') && _ctx.$style.slotDayCellContent_header_today])
    }, _toDisplayString(_ctx.$moment(arg.date).format("DD")), 3)) : _createCommentVNode("", true), _createVNode(_component_DropZone, {
      disableEdgeDetection: true,
      dropSchema: {
        ...$props.entry.input?.schema,
        ...{
          schedule: {
            date: _ctx.$moment(arg.date).format('YYYY-MM-DD')
          }
        }
      },
      class: "h-full w-full",
      "data-date": _ctx.$moment(arg.date).format('YYYY-MM-DD')
    }, null, 8, ["dropSchema", "data-date"])]),
    slotLaneContent: _withCtx(arg => [arg.view.type == 'timeGridWeek' ? (_openBlock(), _createElementBlock("div", _hoisted_16, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList([0, 1, 2, 3, 4, 5, 6], day => {
      return _createVNode(_component_DropZone, {
        key: day,
        disableEdgeDetection: true,
        onOnDrop_disabled_test: _cache[10] || (_cache[10] = schema => _ctx.onDrop(schema, _ctx.group)),
        dropSchema: {
          ...$props.entry.input?.schema,
          ...{
            schedule: {
              date: _ctx.$moment($data.calendarActiveStart).add(day, 'days').format('YYYY-MM-DD'),
              time: _ctx.$moment().set('hour', 0).set('minutes', 0).set('seconds', 0).set('milliseconds', arg.time.milliseconds).utc().format('HH:mm:ss')
            }
          }
        },
        class: "h-full",
        "data-date": _ctx.$moment($data.calendarActiveStart).add(day, 'days').format('YYYY-MM-DD'),
        "data-time": _ctx.$moment().set('hour', 0).set('minutes', 0).set('seconds', 0).set('milliseconds', arg.time.milliseconds).utc().format('HH:mm:ss')
      }, null, 8, ["dropSchema", "data-date", "data-time"]);
    }), 64))])) : arg.view.type == 'timeGridThreeDay' ? (_openBlock(), _createElementBlock("div", _hoisted_17, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList([0, 1, 2], day => {
      return _createVNode(_component_DropZone, {
        key: day,
        disableEdgeDetection: true,
        onOnDrop_disabled_test: _cache[11] || (_cache[11] = schema => _ctx.onDrop(schema, _ctx.group)),
        dropSchema: {
          ...$props.entry.input?.schema,
          ...{
            schedule: {
              date: _ctx.$moment($data.calendarActiveStart).add(day, 'days').format('YYYY-MM-DD'),
              time: _ctx.$moment().set('hour', 0).set('minutes', 0).set('seconds', 0).set('milliseconds', arg.time.milliseconds).utc().format('HH:mm:ss')
            }
          }
        },
        class: "h-full",
        "data-date": _ctx.$moment($data.calendarActiveStart).add(day, 'days').format('YYYY-MM-DD'),
        "data-time": _ctx.$moment().set('hour', 0).set('minutes', 0).set('seconds', 0).set('milliseconds', arg.time.milliseconds).utc().format('HH:mm:ss')
      }, null, 8, ["dropSchema", "data-date", "data-time"]);
    }), 64))])) : (_openBlock(), _createBlock(_component_DropZone, {
      key: 2,
      disableEdgeDetection: true,
      dropSchema: {
        ...$props.entry.input?.schema,
        ...{
          schedule: {
            date: _ctx.$moment($data.calendarActiveStart).format('YYYY-MM-DD'),
            time: _ctx.$moment().set('hour', 0).set('minutes', 0).set('seconds', 0).set('milliseconds', arg.time.milliseconds).utc().format('HH:mm:ss')
          }
        }
      },
      class: "h-full w-full",
      "data-time": _ctx.$moment().set('hour', 0).set('minutes', 0).set('seconds', 0).set('milliseconds', arg.time.milliseconds).utc().format('HH:mm:ss')
    }, null, 8, ["dropSchema", "data-time"]))]),
    _: 1
  }, 8, ["options"])) : _createCommentVNode("", true), $data.showInputOverlay ? (_openBlock(), _createBlock(_Teleport, {
    key: 1,
    to: "body"
  }, [_createElementVNode("div", {
    onClick: _cache[12] || (_cache[12] = $event => $data.showInput = false),
    class: "fixed inset-0 z-10"
  }), _createVNode(_Transition, {
    onEnter: $options.animationEnterInputPanel
  }, {
    default: _withCtx(() => [$data.showInput ? (_openBlock(), _createBlock(_component_PanelHelper, {
      key: 0,
      ref: "input",
      class: "fixed z-20 w-80",
      style: _normalizeStyle($data.inputPosition)
    }, {
      default: _withCtx(() => [_createVNode(_component_InputDisplay, {
        ref: "input_display",
        modelValue: $options.input,
        "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => $options.input = $event),
        focusOnMount: true,
        entry: $props.entry,
        schema: $data.inputSchemaDated,
        position: $props.position,
        color: $props.entry.color,
        editable: $options.input && $props.editable,
        onCreated: _cache[14] || (_cache[14] = $event => $data.showInput = false),
        onOnCancel: _cache[15] || (_cache[15] = $event => $data.showInput = false)
      }, null, 8, ["modelValue", "entry", "schema", "position", "color", "editable"])]),
      _: 1
    }, 8, ["style"])) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["onEnter"])])) : _createCommentVNode("", true)], 2);
}