import "core-js/modules/es.array.push.js";
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementVNode as _createElementVNode, withModifiers as _withModifiers, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, vModelRadio as _vModelRadio, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = ["data-date"];
const _hoisted_2 = {
  class: "min-w-fit"
};
const _hoisted_3 = {
  key: 0
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  key: 0
};
const _hoisted_6 = {
  key: 1
};
const _hoisted_7 = {
  class: "flex flex-col gap-2"
};
const _hoisted_8 = {
  class: "flex flex-wrap gap-2"
};
const _hoisted_9 = {
  key: 0,
  class: "grid grid-cols-7 gap-0.5"
};
const _hoisted_10 = {
  key: 1
};
const _hoisted_11 = {
  class: "flex flex-wrap items-center gap-2"
};
const _hoisted_12 = {
  key: 0,
  class: "grid grid-cols-7 gap-0.5"
};
const _hoisted_13 = {
  key: 1
};
const _hoisted_14 = {
  class: "flex flex-wrap gap-2"
};
const _hoisted_15 = {
  key: 0
};
const _hoisted_16 = {
  key: 1
};
const _hoisted_17 = {
  class: "grid grid-cols-7 gap-0.5"
};
const _hoisted_18 = {
  class: "flex items-center gap-2"
};
const _hoisted_19 = {
  style: {
    "font-weight": "medium"
  }
};
const _hoisted_20 = {
  style: {
    "color": "var(--color-medium-gray)",
    "font-size": "var(--font-size-smaller)",
    "display": "flex",
    "align-items": "center",
    "gap": "0.25rem"
  }
};
const _hoisted_21 = {
  key: 1,
  class: "p-3"
};
const _hoisted_22 = {
  style: {
    "display": "flex",
    "justify-content": "space-between",
    "gap": "1rem"
  }
};
const _hoisted_23 = {
  style: {
    "display": "flex",
    "gap": "0.5rem"
  }
};
const _hoisted_24 = {
  style: {
    "font-weight": "bold"
  }
};
const _hoisted_25 = {
  style: {
    "display": "flex",
    "gap": "0.5rem"
  }
};
const _hoisted_26 = {
  style: {
    "font-weight": "bold",
    "text-transform": "uppercase"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CalendarIcon = _resolveComponent("CalendarIcon");
  const _component_Repeat2Icon = _resolveComponent("Repeat2Icon");
  const _component_ButtonComponent = _resolveComponent("ButtonComponent");
  const _component_PSListItem = _resolveComponent("PSListItem");
  const _component_TrashIcon = _resolveComponent("TrashIcon");
  const _component_CalendarComponent = _resolveComponent("CalendarComponent");
  const _component_PSButton = _resolveComponent("PSButton");
  const _component_SelectMenu = _resolveComponent("SelectMenu");
  const _component_HelpCircleIcon = _resolveComponent("HelpCircleIcon");
  const _component_SaveIcon = _resolveComponent("SaveIcon");
  const _component_RotateCwIcon = _resolveComponent("RotateCwIcon");
  const _component_PSOverlay = _resolveComponent("PSOverlay");
  const _component_PopoverHelper = _resolveComponent("PopoverHelper");
  return _openBlock(), _createElementBlock("div", null, [_renderSlot(_ctx.$slots, "default", {
    date: $options.date
  }, () => [_createVNode(_component_PopoverHelper, {
    id: "date_picker",
    teleport: $props.teleport,
    class: "relative"
  }, {
    button: _withCtx(() => [_createVNode(_component_ButtonComponent, {
      onClick: _cache[0] || (_cache[0] = e => {
        $props.autoSetOnClick === true ? $props.entry ? typeof $options.date != 'string' && $options.date !== null ? $options.date = _ctx.$moment.utc().format('YYYY-MM-DD') : null : typeof $options.date == 'undefined' || $options.date === null || $options.date.length === 0 ? $options.date = {
          op: 'today'
        } : null : null;
        // $emit('click', e);
      }),
      variant: "none",
      ref: "reference",
      class: _normalizeClass([{
        [_ctx.$style.scheduleDatePickerButton]: true,
        [_ctx.$style.scheduleDatePickerButtonNeedsAction]: _ctx.$formattedDateOp($options.date) == 'today' || _ctx.$formattedDateOp($options.date) == 'yesterday' && !_ctx.statusIsDone($props.entry?.status),
        // '!text-neutral-700 hover:!text-black dark:!text-neutral-400 dark:hover:!text-neutral-300':
        //   $formattedDateOp(date) == 'today',
        [_ctx.$style.scheduleDatePickerButtonOverdue]: _ctx.$formattedDateOp($options.date) == 'yesterday' && $props.entry?.status && !_ctx.statusIsDone($props.entry?.status)
      }, "group"]),
      title: "Set schedule date",
      "data-table-column-target": ""
    }, {
      default: _withCtx(() => [_createVNode(_component_CalendarIcon, {
        size: "14"
      }), $options.date ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        "data-date": $options.date,
        class: "uppercase"
      }, _toDisplayString($options.displayText), 9, _hoisted_1)) : _createCommentVNode("", true), $props.entry?.schedule?.recurrence ? (_openBlock(), _createBlock(_component_Repeat2Icon, {
        key: 1,
        size: "16"
      })) : _createCommentVNode("", true)]),
      _: 1
    }, 8, ["class"])]),
    default: _withCtx(({
      close
    }) => [_createElementVNode("div", _hoisted_2, [typeof $options.date != 'object' || $options.date === null ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_PSListItem, {
      onClick: $event => ($options.date = _ctx.$moment().format('YYYY-MM-DD'), close()),
      class: "flex w-full cursor-pointer items-center justify-between gap-x-2 px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-950"
    }, {
      after: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(_ctx.$formattedDateOp(_ctx.$moment().format("YYYY-MM-DD"), false)), 1)]),
      default: _withCtx(() => [_cache[16] || (_cache[16] = _createElementVNode("span", {
        style: {
          "font-weight": "bold"
        }
      }, " Today ", -1))]),
      _: 2
    }, 1032, ["onClick"]), _createVNode(_component_PSListItem, {
      onClick: $event => ($options.date = _ctx.$moment().add(1, 'days').format('YYYY-MM-DD'), close()),
      class: "flex w-full cursor-pointer items-center justify-between gap-x-2 px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-950"
    }, {
      after: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(_ctx.$formattedDateOp(_ctx.$moment().add(1, "days").format("YYYY-MM-DD"), false)), 1)]),
      default: _withCtx(() => [_cache[17] || (_cache[17] = _createElementVNode("span", {
        style: {
          "font-weight": "bold"
        }
      }, " Tomorrow ", -1))]),
      _: 2
    }, 1032, ["onClick"]), _createVNode(_component_PSListItem, {
      onClick: $event => ($options.date = _ctx.$moment().add(-1, 'days').format('YYYY-MM-DD'), close()),
      class: "flex w-full cursor-pointer items-center justify-between gap-x-2 px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-950"
    }, {
      after: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(_ctx.$formattedDateOp(_ctx.$moment().add(-1, "days").format("YYYY-MM-DD"), false)), 1)]),
      default: _withCtx(() => [_cache[18] || (_cache[18] = _createElementVNode("span", {
        style: {
          "font-weight": "bold"
        }
      }, " Yesterday ", -1))]),
      _: 2
    }, 1032, ["onClick"]), $props.entry || $options.date ? (_openBlock(), _createBlock(_component_PSListItem, {
      key: 0,
      onClick: $event => ($options.date = {
        op: 'null'
      }, close()),
      class: "flex w-full cursor-pointer items-center justify-between gap-x-2 px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-950"
    }, {
      after: _withCtx(() => [_createVNode(_component_TrashIcon, {
        size: "16"
      })]),
      default: _withCtx(() => [_cache[19] || (_cache[19] = _createElementVNode("span", {
        style: {
          "font-weight": "bold"
        }
      }, " Unset ", -1))]),
      _: 2
    }, 1032, ["onClick"])) : _createCommentVNode("", true), _createVNode(_component_CalendarComponent, null, {
      default: _withCtx(({
        date: _date
      } = _ctx.slotProps) => [_createElementVNode("button", {
        onClick: _withModifiers($event => ($options.date = _date, close()), ["prevent"]),
        class: _normalizeClass({
          'font-bold text-black': _date == _ctx.$moment().format('YYYY-MM-DD'),
          'h-8 w-8 rounded-full bg-neutral-900 font-bold text-white': _date == $props.entry?.schedule?.date
        })
      }, _toDisplayString(_ctx.$moment(_date).format("DD")), 11, _hoisted_4)]),
      _: 2
    }, 1024), _cache[27] || (_cache[27] = _createElementVNode("hr", {
      class: "border-neutral-100 dark:border-neutral-800"
    }, null, -1)), _createVNode(_component_PSOverlay, {
      placement: "right-start",
      onClose: _cache[10] || (_cache[10] = () => {
        if ($data.recurrence.op !== null) {
          _ctx.$emit('update:recurrence', $data.recurrence);
        }
      })
    }, {
      button: _withCtx(() => [_createVNode(_component_PSButton, {
        class: _normalizeClass(_ctx.$style.PSListItem)
      }, {
        default: _withCtx(() => [!$props.entry?.schedule?.recurrence ? (_openBlock(), _createElementBlock("span", _hoisted_5, "Recurrence")) : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.getRecurrenceText($props.entry?.schedule?.recurrence)), 1)), _createVNode(_component_Repeat2Icon, {
          size: "20"
        })]),
        _: 1
      }, 8, ["class"])]),
      title: _withCtx(() => _cache[20] || (_cache[20] = [_createElementVNode("span", null, "Recurrence", -1)])),
      default: _withCtx(({
        close
      }) => [_createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_createVNode(_component_SelectMenu, {
        modelValue: $data.recurrence.op,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.recurrence.op = $event),
        options: [{
          title: 'Every X Days',
          value: 'nextXDays'
        }, {
          title: 'Every X Weeks',
          value: 'nextXWeeks'
        }, {
          title: 'Every X Months',
          value: 'nextXMonths'
        }, {
          title: 'Every X Years',
          value: 'nextXYears'
        }, {
          title: 'On the following days ... ',
          value: 'onDays'
        }, {
          title: 'Custom ... ',
          value: 'customSettings'
        }],
        class: "flex-1"
      }, null, 8, ["modelValue"]), $data.recurrence.op && ['nextXDays', 'nextXWeeks', 'nextXMonths', 'nextXYears'].includes($data.recurrence.op) ? _withDirectives((_openBlock(), _createElementBlock("input", {
        key: 0,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.recurrence.x = $event),
        class: _normalizeClass(_ctx.$style.recurrenceXInput)
      }, null, 2)), [[_vModelText, $data.recurrence.x]]) : _createCommentVNode("", true)]), $data.recurrence.op === 'onDays' ? (_openBlock(), _createElementBlock("div", _hoisted_9, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList([0, 1, 2, 3, 4, 5, 6], day => {
        return _createVNode(_component_PSButton, {
          onClick: () => {
            if ($data.recurrence.x.includes(day)) {
              if ($data.recurrence.x.length > 1) {
                $data.recurrence.x.splice($data.recurrence.x.indexOf(day), 1);
              }
            } else {
              $data.recurrence.x.push(day);
              $data.recurrence.x.sort((a, b) => a - b);
            }
          },
          key: day,
          class: _normalizeClass({
            [_ctx.$style.recurrenceDayButton]: true,
            [_ctx.$style.recurrenceDayButtonActive]: $data.recurrence.x.includes(day)
          })
        }, {
          default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(_ctx.$moment().day(day).format("ddd")), 1)]),
          _: 2
        }, 1032, ["onClick", "class"]);
      }), 64))])) : _createCommentVNode("", true), $data.recurrence.op === 'customSettings' ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, [_cache[21] || (_cache[21] = _createTextVNode(" every ")), _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $data.recurrence.baseX = $event),
        class: _normalizeClass(_ctx.$style.recurrenceXInput)
      }, null, 2), [[_vModelText, $data.recurrence.baseX]]), _createVNode(_component_SelectMenu, {
        modelValue: $data.recurrence.base,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => $data.recurrence.base = $event),
        options: [{
          title: 'Day',
          value: 'day'
        }, {
          title: 'Week',
          value: 'week'
        }, {
          title: 'Month',
          value: 'month'
        }, {
          title: 'Year',
          value: 'year'
        }],
        class: "flex-1"
      }, null, 8, ["modelValue"])]), $data.recurrence.base === 'week' ? (_openBlock(), _createElementBlock("div", _hoisted_12, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList([0, 1, 2, 3, 4, 5, 6], day => {
        return _createVNode(_component_PSButton, {
          onClick: () => {
            if ($data.recurrence.x.includes(day)) {
              if ($data.recurrence.x.length > 1) {
                $data.recurrence.x.splice($data.recurrence.x.indexOf(day), 1);
              }
            } else {
              $data.recurrence.x.push(day);
              $data.recurrence.x.sort((a, b) => a - b);
            }
          },
          key: day,
          class: _normalizeClass({
            [_ctx.$style.recurrenceDayButton]: true,
            [_ctx.$style.recurrenceDayButtonActive]: $data.recurrence.x.includes(day)
          })
        }, {
          default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(_ctx.$moment().day(day).format("ddd")), 1)]),
          _: 2
        }, 1032, ["onClick", "class"]);
      }), 64))])) : _createCommentVNode("", true), $data.recurrence.base === 'month' ? (_openBlock(), _createElementBlock("div", _hoisted_13, [_createElementVNode("div", _hoisted_14, [_createElementVNode("div", null, [_withDirectives(_createElementVNode("input", {
        type: "radio",
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => $data.recurrence.monthMode = $event),
        value: "date"
      }, null, 512), [[_vModelRadio, $data.recurrence.monthMode]]), _cache[22] || (_cache[22] = _createTextVNode(" Per Date "))]), _createElementVNode("div", null, [_withDirectives(_createElementVNode("input", {
        type: "radio",
        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => $data.recurrence.monthMode = $event),
        value: "week"
      }, null, 512), [[_vModelRadio, $data.recurrence.monthMode]]), _cache[23] || (_cache[23] = _createTextVNode(" Per Week "))])]), $data.recurrence.monthMode === 'date' ? (_openBlock(), _createElementBlock("div", _hoisted_15, [_createVNode(_component_SelectMenu, {
        modelValue: $data.recurrence.monthDay,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => $data.recurrence.monthDay = $event),
        options: Array.from({
          length: 28
        }, (_, i) => ({
          title: i + 1 + '.',
          value: i + 1
        }))
      }, null, 8, ["modelValue", "options"])])) : _createCommentVNode("", true), $data.recurrence.monthMode === 'week' ? (_openBlock(), _createElementBlock("div", _hoisted_16, [_createVNode(_component_SelectMenu, {
        modelValue: $data.recurrence.weekInMonth,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => $data.recurrence.weekInMonth = $event),
        options: Array.from({
          length: 4
        }, (_, i) => ({
          title: 'Week ' + (i + 1),
          value: i + 1
        }))
      }, null, 8, ["modelValue", "options"]), _createElementVNode("div", _hoisted_17, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList([0, 1, 2, 3, 4, 5, 6], day => {
        return _createVNode(_component_PSButton, {
          onClick: () => {
            $data.recurrence.dayInWeek = day;
          },
          key: day,
          class: _normalizeClass({
            [_ctx.$style.recurrenceDayButton]: true,
            [_ctx.$style.recurrenceDayButtonActive]: $data.recurrence.dayInWeek == day
          })
        }, {
          default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(_ctx.$moment().day(day).format("ddd")), 1)]),
          _: 2
        }, 1032, ["onClick", "class"]);
      }), 64))])])) : _createCommentVNode("", true)])) : _createCommentVNode("", true)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_18, [_createVNode(_component_SelectMenu, {
        modelValue: $data.recurrence.mode,
        "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => $data.recurrence.mode = $event),
        label: "Mode",
        options: [{
          title: 'Relative',
          value: 'relative'
        }, {
          title: 'Absolute',
          value: 'absolute'
        }],
        class: "flex-1"
      }, null, 8, ["modelValue"]), _createVNode(_component_PSButton, {
        title: "Relative: The recurrence is relative to the schedule date.<br/>Absolute: The recurrence is absolute to the completion date.",
        style: {
          "cursor": "default"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_HelpCircleIcon, {
          size: "16"
        })]),
        _: 1
      })]), _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.recurrenceActionsContainer)
      }, [$props.entry?.schedule?.recurrence ? (_openBlock(), _createBlock(_component_PSButton, {
        key: 0,
        onClick: $event => (_ctx.$emit('update:recurrence', null), close()),
        class: _normalizeClass(_ctx.$style.recurrenceUnsetButton)
      }, {
        default: _withCtx(() => [_createVNode(_component_TrashIcon, {
          size: "16"
        }), _cache[24] || (_cache[24] = _createElementVNode("span", null, " Unset ", -1))]),
        _: 2
      }, 1032, ["onClick", "class"])) : _createCommentVNode("", true), JSON.stringify($data.recurrence) !== JSON.stringify($props.entry?.schedule?.recurrence) && $data.recurrence.op ? (_openBlock(), _createBlock(_component_PSButton, {
        key: 1,
        onClick: () => {
          _ctx.$emit('update:recurrence', $data.recurrence);
          close();
        },
        class: _normalizeClass(_ctx.$style.recurrenceSaveButton)
      }, {
        default: _withCtx(() => [_createVNode(_component_SaveIcon, {
          size: "16"
        }), _cache[25] || (_cache[25] = _createElementVNode("span", null, "Save", -1))]),
        _: 2
      }, 1032, ["onClick", "class"])) : _createCommentVNode("", true)], 2)]), _cache[26] || (_cache[26] = _createElementVNode("hr", null, null, -1)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.recurrenceOptions, (option, index) => {
        return _openBlock(), _createBlock(_component_PSListItem, {
          key: index,
          onClick: $event => (_ctx.$emit('update:recurrence', option.value), close()),
          class: _normalizeClass([$data.recurrence?.op == option.value.op && $data.recurrence?.x == option.value.x ? 'font-bold' : null, "flex w-full justify-between px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-950"])
        }, {
          after: _withCtx(() => [_createElementVNode("span", _hoisted_20, [_createVNode(_component_RotateCwIcon, {
            size: "12",
            style: {
              "display": "inline-block"
            }
          }), _createTextVNode(" " + _toDisplayString(_ctx.interpretDate({
            date: option.value
          }, $data.recurrence.mode == "absolute" ? _ctx.$moment.utc() : _ctx.$moment.utc($props.entry?.schedule?.date))), 1)])]),
          default: _withCtx(() => [_createElementVNode("span", _hoisted_19, _toDisplayString(option.title), 1)]),
          _: 2
        }, 1032, ["onClick", "class"]);
      }), 128))]),
      _: 2
    }, 1024)])) : typeof $options.date == 'object' ? (_openBlock(), _createElementBlock("div", _hoisted_21, [_createElementVNode("div", _hoisted_22, [$options.date?.op ? (_openBlock(), _createElementBlock("span", {
      key: 0,
      style: _normalizeStyle([{
        color: $options.date.op == 'today' || $options.date.op == 'tomorrow' || $options.date.op.slice(0, 4) == 'next' && (typeof $options.date.x == 'undefined' || $options.date.x >= 0) || $options.date.op.slice(0, 4) == 'last' && (typeof $options.date.x == 'undefined' || $options.date.x <= 0) ? 'var(--color-dark-gray)' : 'var(--color-medium-gray)'
      }, {
        "font-size": "82%",
        "font-weight": "bold",
        "text-transform": "uppercase"
      }])
    }, " in ", 4)) : _createCommentVNode("", true), _createElementVNode("span", _hoisted_23, [_createElementVNode("button", {
      onClick: _cache[11] || (_cache[11] = _withModifiers($event => _ctx.modifyRelativeDate(-1, $options.date), ["prevent"]))
    }, " − "), _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.relativeTimespan($options.date) ? Math.abs(_ctx.relativeTimespan($options.date)[0]) : null), 1), _createElementVNode("button", {
      onClick: _cache[12] || (_cache[12] = _withModifiers($event => _ctx.modifyRelativeDate(1, $options.date), ["prevent"]))
    }, " + ")]), _createElementVNode("span", _hoisted_25, [_createElementVNode("button", {
      onClick: _cache[13] || (_cache[13] = _withModifiers($event => _ctx.modifyRelativeTimespan(-1, $options.date), ["prevent"]))
    }, " − "), _createElementVNode("button", _hoisted_26, _toDisplayString(_ctx.relativeTimespan($options.date) ? _ctx.relativeTimespan($options.date)[1] : null), 1), _createElementVNode("button", {
      onClick: _cache[14] || (_cache[14] = _withModifiers($event => _ctx.modifyRelativeTimespan(1, $options.date), ["prevent"]))
    }, " + ")]), $options.date?.op ? (_openBlock(), _createElementBlock("span", {
      key: 1,
      style: _normalizeStyle([{
        color: $options.date.op == 'yesterday' || $options.date.op.slice(0, 4) == 'next' && (typeof $options.date.x == 'undefined' || $options.date.x < 0) || $options.date.op.slice(0, 4) == 'last' && (typeof $options.date.x == 'undefined' || $options.date.x > 0) ? 'var(--color-dark-gray)' : 'var(--color-medium-gray)'
      }, {
        "font-size": "82%",
        "font-weight": "bold",
        "text-transform": "uppercase"
      }])
    }, " ago ", 4)) : _createCommentVNode("", true)]), $props.entry ? (_openBlock(), _createBlock(_component_ButtonComponent, {
      key: 0,
      onClick: _cache[15] || (_cache[15] = _withModifiers($event => [$options.date = $props.entry ? {
        op: 'null'
      } : {
        op: 'unset'
      }], ["prevent"])),
      title: "unset the schedule date"
    }, {
      default: _withCtx(() => [_createVNode(_component_TrashIcon, {
        class: "h-5 w-5"
      }), _cache[28] || (_cache[28] = _createElementVNode("span", null, " Unset ", -1))]),
      _: 1
    })) : _createCommentVNode("", true)])) : _createCommentVNode("", true)])]),
    _: 1
  }, 8, ["teleport"])])]);
}