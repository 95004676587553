export default {
  data() {
    return {
      helpItems: [{
        url: "https://help.pensive.io",
        title: "Help Section"
      },
      // {
      //   url: "https://help.pensive.io/best-practices",
      //   title: "Best Practices",
      // },
      // {
      //   url: "https://forms.gle/B5hi53AM9PZsR4jz5",
      //   title: "Report a Bug",
      // },
      // {
      //   url: "https://forms.gle/PQXmMKPwGQQhAin7A",
      //   title: "Request a Feature",
      // },
      {
        url: "https://pensive.io/whats-new",
        title: "Whats new in v." + this.$version
      }]
    };
  },
  computed: {
    token() {
      return this.$store.getters.token;
    }
  }
};