// import { addonBlueprint } from "@/addonBlueprint";
import moment from "moment";
export const Managing = {
    data() {
        return {
            showDeleteModal: false,
        };
    },
    methods: {
        duplicateEntry(baseEntry) {
            // console.log(this.entry);
            const newId = this.$nanoid();
            const entry = {
                ...this.entrySchema({ id: newId }),
                ...this.getCleanSchema(baseEntry, newId),
            };

            entry.name = entry.name + " (Copy)";

            // console.log(entry);
            this.$store.getters.entries.push(entry);

            this.$store.dispatch("push", {
                event: "entry_create",
                params: { entry: entry },
                entry: entry,
            });
        },
        getExclusiveLinkedEntries(entry) {
            const linkedEntries = this.$store.getters.entries.filter((e) => {
                return e.links.map((l) => l.id).includes(entry.id);
            });

            return linkedEntries.filter((e) => {
                const links = e.links.filter((l) => l.deleted_at == null);
                return links.length == 1 && links[0].id == entry.id && !e.output;
            });
        },
        attemptDeleteEntry(entry) {
            if (this.getExclusiveLinkedEntries(entry).length) {
                this.showDeleteModal = true;
            } else {
                this.deleteEntry(entry, false);
                this.showDeleteModal = false;
            }
        },
        deleteEntry(entry) {
            // console.log("deleteEntry", entry.name);
            entry.deleted_at = moment.utc().format("YYYY-MM-DD HH:mm:ss");

            this.$store.dispatch("push", {
                event: "entry_update",
                params: { entry: entry },
                entry: entry,
            });

            const linkedEntries = this.$store.getters.entries.filter((e) => {
                return e.links.map((l) => l.id).includes(entry.id);
            });
            // console.log("linkedEntries", linkedEntries.map((e) => e.name));
            linkedEntries.forEach((linkedEntry) => {

                linkedEntry.links[linkedEntry.links.map((l) => l.id)
                    .findIndex((l) => l == entry.id)]
                    .deleted_at = moment.utc().format("YYYY-MM-DD HH:mm:ss");

                this.$store.dispatch("push", {
                    event: "entry_update",
                    params: { entry: linkedEntry },
                    entry: linkedEntry,
                    hide_in_undo: true,
                });
            });

            // Get previous route from history
            const previousRoute = this.$router.options.history.state.back;

            if (this.$route.params.id == entry.id) {
                if (previousRoute && previousRoute.name == "entry") {
                    this.$router.replace(previousRoute.fullPath);
                } else {
                    this.$router.replace('/');
                }
            }
        },
        undeleteEntry(entry) {
            entry.deleted_at = null;
            if (!entry.temp)
                this.$store.dispatch("push", {
                    event: "entry_update",
                    params: { entry: entry },
                    entry: entry,
                });
        },
    }
};
