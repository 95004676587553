import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PSOverlay = _resolveComponent("PSOverlay");
  return _openBlock(), _createBlock(_component_PSOverlay, null, _createSlots({
    button: _withCtx(() => [_renderSlot(_ctx.$slots, "button")]),
    default: _withCtx(({
      close
    }) => [_renderSlot(_ctx.$slots, "default", {
      close: close
    })]),
    _: 2
  }, [_ctx.$slots.title ? {
    name: "title",
    fn: _withCtx(() => [_renderSlot(_ctx.$slots, "title")]),
    key: "0"
  } : undefined]), 1024);
}