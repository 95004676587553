import { renderSlot as _renderSlot, unref as _unref, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, Teleport as _Teleport, createElementBlock as _createElementBlock } from "vue";
import { ref } from "vue";
import { useFloating, shift, flip, autoUpdate } from "@floating-ui/vue";
import { Routing } from "@/components/mixins/Routing";
const __default__ = {
  mixins: [Routing],
  props: {
    entry: Object
  },
  data() {
    return {
      window: window,
      hover: false,
      showDetails: false,
      showDetailsTimeout: null
    };
  },
  watch: {
    hover: function (n) {
      if (n) {
        if (this.showDetailsTimeout) {
          clearTimeout(this.showDetailsTimeout);
        }
        this.showDetailsTimeout = setTimeout(() => {
          this.showDetails = n;
        }, 500);
      } else {
        this.showDetails = false;
      }
    }
  }
};
export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'EntryDisplayOnHover',
  setup(__props) {
    const reference = ref(null);
    const floating = ref(null);
    const {
      floatingStyles
    } = useFloating(reference, floating, {
      transform: false,
      placement: "bottom-start",
      whileElementsMounted: autoUpdate,
      middleware: [flip({
        padding: 8,
        fallbackPlacements: ["top-start", "bottom-start"]
      }), shift({
        crossAxis: true,
        mainAxis: true,
        padding: 8,
        limiter: {
          fn: ({
            x,
            y
          }) => {
            return {
              x: Math.min(x, window.innerWidth - 320),
              y
            };
          }
        }
      })]
    });
    return (_ctx, _cache) => {
      const _component_EntryDisplay = _resolveComponent("EntryDisplay");
      const _component_PanelHelper = _resolveComponent("PanelHelper");
      return _openBlock(), _createElementBlock("div", {
        ref_key: "reference",
        ref: reference,
        onMouseover: _cache[3] || (_cache[3] = $event => _ctx.window.matchMedia('(pointer: fine)').matches && (_ctx.hover = true)),
        onMouseleave: _cache[4] || (_cache[4] = $event => _ctx.hover = false)
      }, [_renderSlot(_ctx.$slots, "default", {
        close: () => (_ctx.hover = false, _ctx.showDetails = false)
      }), _ctx.hover ? (_openBlock(), _createBlock(_Teleport, {
        key: 0,
        to: "body"
      }, [_createVNode(_Transition, {
        "enter-active-class": "transition ease-out duration-100",
        "enter-from-class": "transform opacity-0 scale-95",
        "enter-to-class": "transform opacity-100 scale-100",
        "leave-active-class": "transition ease-in duration-75",
        "leave-from-class": "transform opacity-100 scale-100",
        "leave-to-class": "transform opacity-0 scale-95"
      }, {
        default: _withCtx(() => [_ctx.showDetails ? (_openBlock(), _createBlock(_component_PanelHelper, {
          key: 0,
          ref_key: "floating",
          ref: floating,
          class: _normalizeClass(_ctx.$style.popover),
          style: _normalizeStyle(_unref(floatingStyles)),
          onMouseover: _cache[0] || (_cache[0] = $event => _ctx.hover = true),
          onMouseleave: _cache[1] || (_cache[1] = $event => _ctx.hover = false),
          onClick: _cache[2] || (_cache[2] = $event => (_ctx.hover = false, _ctx.showDetails = false, _ctx.navigateTo(__props.entry)))
        }, {
          default: _withCtx(() => [_createVNode(_component_EntryDisplay, {
            modelValue: __props.entry,
            disabled: true,
            disableViewportChecking: true,
            disableInitialization: true,
            disableEdgeDetection: true,
            display: {
              output: false,
              settings: false
            },
            class: _normalizeClass(_ctx.$style.entryDisplay)
          }, null, 8, ["modelValue", "class"])]),
          _: 1
        }, 8, ["class", "style"])) : _createCommentVNode("", true)]),
        _: 1
      })])) : _createCommentVNode("", true)], 544);
    };
  }
});