import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  class: "text-sm font-medium text-gray-900 dark:text-neutral-300"
};
const _hoisted_2 = {
  class: "ps_output_type_picker_button_description text-sm text-gray-500 dark:text-neutral-400"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PSListItem = _resolveComponent("PSListItem");
  const _component_PSList = _resolveComponent("PSList");
  return _openBlock(), _createBlock(_component_PSList, null, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, option => {
      return _openBlock(), _createBlock(_component_PSListItem, {
        key: option.value,
        onClick: $event => _ctx.$emit('update:modelValue', option.value),
        style: {
          "align-items": "flex-start"
        }
      }, {
        before: _withCtx(() => [(_openBlock(), _createBlock(_resolveDynamicComponent(option.icon), {
          size: "16"
        }))]),
        default: _withCtx(() => [_createElementVNode("div", null, [_createElementVNode("p", _hoisted_1, _toDisplayString(option.label), 1), _createElementVNode("p", _hoisted_2, _toDisplayString(option.description), 1)])]),
        _: 2
      }, 1032, ["onClick"]);
    }), 128))]),
    _: 1
  });
}