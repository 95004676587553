import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.map.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SenseIndicatorComponent = _resolveComponent("SenseIndicatorComponent");
  const _component_EntryDisplay = _resolveComponent("EntryDisplay");
  const _component_OutputDisplay = _resolveComponent("OutputDisplay");
  const _component_InputDisplay = _resolveComponent("InputDisplay");
  const _component_PopoverHelper = _resolveComponent("PopoverHelper");
  const _component_EntryDisplayOnHover = _resolveComponent("EntryDisplayOnHover");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_EntryDisplayOnHover, {
    entry: $props.entry
  }, {
    default: _withCtx(({
      close
    }) => [_createElementVNode("div", null, [_createVNode(_component_PopoverHelper, {
      teleport: true,
      class: _normalizeClass(_ctx.$style.entryDisplayTiles),
      buttonClass: "flex justify-center items-center",
      onMousedown: _cache[2] || (_cache[2] = $event => _ctx.$emit('mousedown', $event))
    }, {
      button: _withCtx(() => [$props.entry.senses ? (_openBlock(true), _createElementBlock(_Fragment, {
        key: 0
      }, _renderList($props.entry.senses, sense => {
        return _openBlock(), _createBlock(_component_SenseIndicatorComponent, {
          hideOnOk: true,
          key: sense,
          modelValue: sense,
          entry: $props.entry,
          class: _normalizeClass(_ctx.$style.senseIndicator)
        }, null, 8, ["modelValue", "entry", "class"]);
      }), 128)) : _createCommentVNode("", true), _createElementVNode("div", {
        ref: "tiltContainer",
        onClick: close,
        class: _normalizeClass(_ctx.$style.nameWrapper)
      }, [_createElementVNode("span", {
        class: _normalizeClass(_ctx.$style.name)
      }, _toDisplayString($props.entry.name?.slice(0, 2).match(/\p{Extended_Pictographic}/u)?.[0] || $props.entry.name?.slice(0, 1)), 3)], 10, _hoisted_1)]),
      default: _withCtx(({
        close
      }) => [_createElementVNode("div", {
        class: _normalizeClass([_ctx.$style.entryDisplayTiles_content]),
        onMouseleave: _cache[1] || (_cache[1] = $event => _ctx.$store.getters.dragged.length ? /*close() */null : null)
      }, [_createVNode(_component_EntryDisplay, {
        onClick: close,
        modelValue: $props.entry,
        display: {
          output: false
        },
        renderType: "list",
        class: _normalizeClass({
          [_ctx.$style.entryDisplay]: true,
          [_ctx.$style.entryDisplay_withInput]: $props.entry.input && !_ctx.$store.getters.dragged.map(e => e.id).includes($props.entry.id)
        })
      }, null, 8, ["onClick", "modelValue", "class"]), $props.entry.output ? (_openBlock(), _createBlock(_component_OutputDisplay, {
        key: 0,
        modelValue: $props.entry.output,
        entry: $props.entry,
        output: $props.entry.output,
        schema: $props.entry.output?.schema,
        display: {
          output: false
        },
        color: $props.entry.color,
        editable: false,
        showHeader: false,
        class: "px-2",
        onOnEntryClick: close
      }, null, 8, ["modelValue", "entry", "output", "schema", "color", "onOnEntryClick"])) : _createCommentVNode("", true), !$props.entry.output && $props.entry.input ? (_openBlock(), _createBlock(_component_InputDisplay, {
        key: 1,
        modelValue: $props.entry.input,
        entry: $props.entry,
        output: $props.entry.output,
        schema: $props.entry.input?.schema,
        display: $props.computedDisplay,
        renderType: $props.entry.output?.type || 'list',
        color: $props.entry.color,
        editable: true,
        onCreated: _cache[0] || (_cache[0] = entry => _ctx.$emit('created', entry))
      }, null, 8, ["modelValue", "entry", "output", "schema", "display", "renderType", "color"])) : _createCommentVNode("", true)], 34)]),
      _: 2
    }, 1032, ["class"])])]),
    _: 1
  }, 8, ["entry"])]);
}