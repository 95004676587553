import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, vModelDynamic as _vModelDynamic, withKeys as _withKeys, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
  class: "custom_fields_display flex max-w-full flex-wrap justify-stretch gap-2"
};
const _hoisted_2 = {
  class: "select-none whitespace-nowrap text-gray-500 sm:text-sm"
};
const _hoisted_3 = ["data-rating"];
const _hoisted_4 = ["innerHTML"];
const _hoisted_5 = ["data-date"];
const _hoisted_6 = {
  key: 2
};
const _hoisted_7 = ["type", "step", "onUpdate:modelValue", "onInput", "onBlur", "placeholder"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SparklesIcon = _resolveComponent("SparklesIcon");
  const _component_ButtonComponent = _resolveComponent("ButtonComponent");
  const _component_DatePicker = _resolveComponent("DatePicker");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.customFields, (customField, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      onClick: _cache[1] || (_cache[1] = e => $props.entry && !$props.entry.temp ? e.stopPropagation() : null),
      class: _normalizeClass(_ctx.$style.customField)
    }, [_createElementVNode("label", _hoisted_2, [$options.customValues[index].source == 'ai' ? (_openBlock(), _createBlock(_component_SparklesIcon, {
      key: 0,
      size: "18",
      "stroke-width": "1.25",
      class: "inline dark:text-neutral-500"
    })) : _createCommentVNode("", true), _createTextVNode(" " + _toDisplayString(customField.name), 1)]), customField.type == 'rating' ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      class: "flex flex-wrap gap-y-1",
      "data-rating": $options.customValues[index].value || null
    }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(parseInt(customField.config?.count ? customField.config.count : 5), i => {
      return _openBlock(), _createBlock(_component_ButtonComponent, {
        key: i,
        onClick: $event => $options.updateCustomValue(index, i != $options.customValues[index].value ? i : null),
        onMouseover: $event => $data.hoveredRating[customField.id] = i,
        onMouseleave: $event => $data.hoveredRating[customField.id] = null,
        variant: "minimal",
        class: _normalizeClass([{
          'opacity-50 grayscale hover:opacity-100 hover:grayscale-0': $data.hoveredRating[customField.id] == null && i > $options.customValues[index].value || $data.hoveredRating[customField.id] != null && $data.hoveredRating[customField.id] < i
        }, "px-1"]),
        title: i.toString(),
        "data-current": i == $options.customValues[index].value || null
      }, {
        default: _withCtx(() => [_createElementVNode("span", {
          innerHTML: customField.config?.char ? customField.config.char : '⭐️'
        }, null, 8, _hoisted_4)]),
        _: 2
      }, 1032, ["onClick", "onMouseover", "onMouseleave", "class", "title", "data-current"]);
    }), 128))], 8, _hoisted_3)) : customField.type == 'date' ? (_openBlock(), _createElementBlock("div", {
      key: 1,
      "data-date": $options.customValues[index].value || null
    }, [_createVNode(_component_DatePicker, {
      modelValue: $options.customValues[index].value,
      "onUpdate:modelValue": value => $options.updateCustomValue(index, value),
      class: _normalizeClass(_ctx.$style.input),
      "data-table-column-target": ""
    }, null, 8, ["modelValue", "onUpdate:modelValue", "class"])], 8, _hoisted_5)) : (_openBlock(), _createElementBlock("div", _hoisted_6, [_withDirectives(_createElementVNode("input", {
      type: customField.type,
      step: customField.type == 'number' ? 'any' : null,
      "onUpdate:modelValue": $event => $options.customValues[index].value = $event,
      onInput: event => {
        $options.customValues[index].value = event.target.value;
      },
      onKeyup: _cache[0] || (_cache[0] = _withKeys(event => event.target.blur(), ["enter"])),
      onBlur: $event => $options.updateCustomValue(index, $options.customValues[index].value),
      class: _normalizeClass([_ctx.$style.input, _ctx.$style[customField.type]]),
      placeholder: customField.type.charAt(0).toUpperCase() + customField.type.slice(1)
    }, null, 42, _hoisted_7), [[_vModelDynamic, $options.customValues[index].value]])]))], 2);
  }), 128))]);
}