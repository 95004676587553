import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue";
const _hoisted_1 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_MenuItem = _resolveComponent("MenuItem");
  return _openBlock(), _createElementBlock(_Fragment, null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.priorities, (priority, index) => {
    return _openBlock(), _createBlock(_component_MenuItem, {
      key: index
    }, {
      default: _withCtx(({
        active
      }) => [_createElementVNode("a", {
        onClick: $event => _ctx.$emit('update:modelValue', {
          ...$props.modelValue,
          ...{
            level: priority
          }
        }),
        class: _normalizeClass([active ? 'bg-neutral-100 text-neutral-900 dark:bg-neutral-950 dark:text-neutral-100' : 'text-neutral-700 dark:text-neutral-300', 'block cursor-pointer px-4 py-2 text-sm'])
      }, " Priority " + _toDisplayString(priority), 11, _hoisted_1)]),
      _: 2
    }, 1024);
  }), 128)), _createVNode(_component_MenuItem, null, {
    default: _withCtx(({
      active
    }) => [_createElementVNode("a", {
      onClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('update:modelValue', null)),
      class: _normalizeClass([active ? 'bg-neutral-100 text-neutral-900 dark:bg-neutral-950 dark:text-neutral-100' : 'text-neutral-700 dark:text-neutral-300', 'block cursor-pointer px-4 py-2 text-sm'])
    }, " No Priority ", 2)]),
    _: 1
  })], 64);
}