import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "truncate"
};
const _hoisted_2 = ["onClick"];
const _hoisted_3 = ["onClick"];
const _hoisted_4 = ["onClick"];
const _hoisted_5 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_UserIcon = _resolveComponent("UserIcon");
  const _component_ChevronDownIcon = _resolveComponent("ChevronDownIcon");
  const _component_SettingsIcon = _resolveComponent("SettingsIcon");
  const _component_PSButton = _resolveComponent("PSButton");
  const _component_PSListItem = _resolveComponent("PSListItem");
  const _component_router_link = _resolveComponent("router-link");
  const _component_PSList = _resolveComponent("PSList");
  const _component_PSOverlay = _resolveComponent("PSOverlay");
  return _ctx.$store.getters.space ? (_openBlock(), _createBlock(_component_PSOverlay, {
    key: 0,
    placement: "bottom-start"
  }, {
    button: _withCtx(() => [_createVNode(_component_PSButton, {
      ref: "userMenuButton",
      class: _normalizeClass(_ctx.$style.userMenuButton)
    }, {
      default: _withCtx(() => [_createVNode(_component_UserIcon, {
        size: "28",
        "stroke-width": "2",
        class: _normalizeClass(_ctx.$style.userIcon)
      }, null, 8, ["class"]), _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$store.getters.space.name), 1), _createVNode(_component_ChevronDownIcon, {
        size: "16",
        "stroke-width": "1.5",
        class: _normalizeClass(_ctx.$style.chevronDownIcon)
      }, null, 8, ["class"]), _createVNode(_component_PSButton, {
        onClick: _cache[0] || (_cache[0] = _withModifiers($event => _ctx.$router.push({
          name: 'space'
        }), ["stop"])),
        class: _normalizeClass(_ctx.$style.buttonSpaceSettings),
        title: "Space settings"
      }, {
        default: _withCtx(() => [_cache[1] || (_cache[1] = _createElementVNode("span", {
          class: "sr-only"
        }, "Open options", -1)), _createVNode(_component_SettingsIcon, {
          size: "16",
          "stroke-width": "1.5",
          "aria-hidden": "true"
        })]),
        _: 1
      }, 8, ["class"])]),
      _: 1
    }, 8, ["class"])]),
    title: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode(" Profile ")])),
    default: _withCtx(({
      close
    }) => [_createVNode(_component_PSList, null, {
      default: _withCtx(() => [_createVNode(_component_PSListItem, null, {
        default: _withCtx(({
          active
        }) => [_createElementVNode("a", {
          onClick: $event => (_ctx.$router.push({
            name: 'profile'
          }), close()),
          class: _normalizeClass([active ? 'bg-gray-50 dark:bg-neutral-900' : '', 'block w-full cursor-pointer px-3 py-1 text-sm leading-6 text-gray-900 dark:text-neutral-300'])
        }, " Profile ", 10, _hoisted_2)]),
        _: 2
      }, 1024), _ctx.$store.getters.entries.filter(e => e.routine).length ? (_openBlock(), _createBlock(_component_PSListItem, {
        key: 0
      }, {
        default: _withCtx(({
          active
        }) => [_createElementVNode("a", {
          onClick: $event => (_ctx.$router.push({
            name: 'routines'
          }), close()),
          class: _normalizeClass([active ? 'bg-gray-50 dark:bg-neutral-900' : '', 'block w-full cursor-pointer px-3 py-1 text-sm leading-6 text-gray-900 dark:text-neutral-300'])
        }, " Routines ", 10, _hoisted_3)]),
        _: 2
      }, 1024)) : _createCommentVNode("", true), _createVNode(_component_PSListItem, null, {
        default: _withCtx(({
          active
        }) => [_createElementVNode("a", {
          onClick: $event => (_ctx.$router.push({
            name: 'space'
          }), close()),
          class: _normalizeClass([active ? 'bg-gray-50 dark:bg-neutral-900' : '', 'block w-full cursor-pointer px-3 py-1 text-sm leading-6 text-gray-900 dark:text-neutral-300'])
        }, " Space Settings ", 10, _hoisted_4)]),
        _: 2
      }, 1024), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.helpItems, (item, index) => {
        return _openBlock(), _createBlock(_component_PSListItem, {
          key: index,
          as: "div"
        }, {
          default: _withCtx(({
            active
          }) => [_createElementVNode("a", {
            href: item.url,
            target: "_blank",
            class: _normalizeClass([active ? 'bg-gray-50 dark:bg-neutral-900' : '', 'block w-full px-3 py-1 text-sm leading-6 text-gray-900 dark:text-neutral-300'])
          }, _toDisplayString(item.title), 11, _hoisted_5)]),
          _: 2
        }, 1024);
      }), 128)), _createVNode(_component_PSListItem, null, {
        default: _withCtx(({
          active
        }) => [$options.token ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          to: {
            name: 'logout'
          },
          class: _normalizeClass([active ? 'bg-gray-50 dark:bg-neutral-900' : '', 'block w-full px-3 py-1 text-sm leading-6 text-gray-900 dark:text-neutral-300'])
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode(" Logout ")])),
          _: 2
        }, 1032, ["class"])) : _createCommentVNode("", true)]),
        _: 1
      })]),
      _: 2
    }, 1024)]),
    _: 1
  })) : _createCommentVNode("", true);
}