import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import { Schema } from "../mixins/Schema";

// import templates from "@/templates";

import { Templating } from "../mixins/Templating";
import { Scheduling } from "../mixins/Scheduling";
// import { nanoid } from "nanoid";
import { Applicators } from "../mixins/Applicators";
import { Statusing } from "../mixins/Statusing";
export default {
  props: {
    modelValue: Object,
    hideCustomTemplates: Boolean
  },
  mixins: [Schema, Statusing, Scheduling, Templating, Applicators],
  data() {
    return {
      selectedTemplate: null
    };
  },
  computed: {
    token() {
      return this.$store.getters.token;
    },
    user() {
      return this.$store.getters.user;
    },
    space() {
      return this.$store.getters.space;
    },
    entries() {
      return this.$store.getters.entries.filter(e => e.deleted_at === null);
    },
    entry() {
      return this.modelValue;
    }
  },
  watch: {
    "selected.length": function () {
      if (this.entry && this.selected.length == 1) {
        this.setTemplate(this.templates[this.selected[0]]);
        this.selected = [];
      }
      if (!this.entry) {
        if (this.tagSelected && !this.tagsWithTemplates.includes(this.tagSelected)) {
          this.tagSelected = null;
        }
      }
    }
  },
  methods: {
    handleTemplateClick(template) {
      this.$emit("clickTemplate", template);
      this.selectedTemplate = template;
      // if (this.enableMultipleSelection) {
      //   if (this.selected.includes(template.id)) {
      //     this.selected.splice(this.selected.indexOf(template.id), 1);
      //   } else {
      //     this.selected.push(template.id);
      //   }
      // }
    },
    animationEnter(el, done) {
      this.$anime({
        targets: el,
        translateX: [50, 0],
        opacity: [0, 1],
        delay: 500,
        // easing: "easeOutExpo",
        duration: 1000,
        complete: done
      });
    },
    animationLeave(el, done) {
      this.$anime({
        targets: el,
        translateX: [0, -50],
        opacity: [1, -0.2],
        // easing: "easeOutExpo",
        duration: 1000,
        complete: done,
        begin: () => {
          el.style.width = "100%";
          el.style.position = "absolute";
        }
      });
    }
  }
};