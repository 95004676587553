import "core-js/modules/es.array.push.js";
import { Analytics } from "../mixins/Analytics";
export default {
  mixins: [Analytics],
  name: "LogoutComponent",
  created() {
    this._trackEvent("Authentication", "Logout", "Start");
    this.$store.dispatch("destroyToken").then(() => {
      this._trackEvent("Authentication", "Logout", "Success");
      this.$router.push({
        name: "login"
      });
      if (this.$isLocalhost) {
        // this.$router.push({ name: "home", query: { reset: "1" } });
      } else {
        // window.location.replace("https://pensive.io");
      }
    }).catch(() => {
      this._trackEvent("Authentication", "Logout", "Error");
    });
  }
};