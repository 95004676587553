import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.some.js";
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "flex flex-wrap gap-2"
};
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  key: 0
};
const _hoisted_4 = {
  key: 0
};
const _hoisted_5 = {
  key: 0
};
const _hoisted_6 = {
  key: 0
};
const _hoisted_7 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SidebarIcon = _resolveComponent("SidebarIcon");
  const _component_XIcon = _resolveComponent("XIcon");
  const _component_ButtonComponent = _resolveComponent("ButtonComponent");
  const _component_HeartIcon = _resolveComponent("HeartIcon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$options.anchors.some(a => a.placement === 'left-sidebar') ? (_openBlock(), _createElementBlock("button", {
    key: 0,
    role: "button",
    onClick: _cache[0] || (_cache[0] = $event => $options.toggleAnchor('left-sidebar'))
  }, [_renderSlot(_ctx.$slots, "button_delete_left", {}, () => [_createVNode(_component_ButtonComponent, {
    variant: _ctx.variant,
    size: _ctx.size,
    color: _ctx.color,
    colorWeight: _ctx.colorWeight,
    title: "Remove from Left Sidebar"
  }, {
    default: _withCtx(() => [_createVNode(_component_SidebarIcon, {
      size: "20"
    }), _ctx.variant != 'round' ? (_openBlock(), _createElementBlock("span", _hoisted_2, " Remove from Left Sidebar ")) : _createCommentVNode("", true), _ctx.variant == 'round' ? (_openBlock(), _createBlock(_component_ButtonComponent, {
      key: 1,
      variant: _ctx.variant,
      size: "xs",
      color: "red",
      colorWeight: "500",
      class: "!absolute right-0 top-0 -translate-y-1/3 translate-x-1/3 hover:bg-red-600"
    }, {
      default: _withCtx(() => [_createVNode(_component_XIcon, {
        size: "12"
      })]),
      _: 1
    }, 8, ["variant"])) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["variant", "size", "color", "colorWeight"])])])) : (_openBlock(), _createElementBlock("button", {
    key: 1,
    onClick: _cache[1] || (_cache[1] = $event => $options.toggleAnchor('left-sidebar'))
  }, [_renderSlot(_ctx.$slots, "button_create_left", {}, () => [_createVNode(_component_ButtonComponent, {
    variant: _ctx.variant,
    size: _ctx.size,
    color: _ctx.color,
    colorWeight: _ctx.colorWeight,
    title: "Add to Left Sidebar"
  }, {
    default: _withCtx(() => [_createVNode(_component_SidebarIcon, {
      size: "20"
    }), _ctx.variant != 'round' ? (_openBlock(), _createElementBlock("span", _hoisted_3, " Add to Left Sidebar ")) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["variant", "size", "color", "colorWeight"])])])), $options.anchors.some(a => a.placement === 'right-sidebar') ? (_openBlock(), _createElementBlock("button", {
    key: 2,
    role: "button",
    onClick: _cache[2] || (_cache[2] = $event => $options.toggleAnchor('right-sidebar'))
  }, [_renderSlot(_ctx.$slots, "button_delete_right", {}, () => [_createVNode(_component_ButtonComponent, {
    variant: _ctx.variant,
    size: _ctx.size,
    color: _ctx.color,
    colorWeight: _ctx.colorWeight,
    title: "Remove from Right Sidebar"
  }, {
    default: _withCtx(() => [_createVNode(_component_SidebarIcon, {
      size: "20"
    }), _ctx.variant != 'round' ? (_openBlock(), _createElementBlock("span", _hoisted_4, " Remove from Right Sidebar ")) : _createCommentVNode("", true), _ctx.variant == 'round' ? (_openBlock(), _createBlock(_component_ButtonComponent, {
      key: 1,
      variant: _ctx.variant,
      size: "xs",
      color: "red",
      colorWeight: "500",
      class: "!absolute right-0 top-0 -translate-y-1/3 translate-x-1/3 hover:bg-red-600"
    }, {
      default: _withCtx(() => [_createVNode(_component_XIcon, {
        size: "12"
      })]),
      _: 1
    }, 8, ["variant"])) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["variant", "size", "color", "colorWeight"])])])) : (_openBlock(), _createElementBlock("button", {
    key: 3,
    onClick: _cache[3] || (_cache[3] = $event => $options.toggleAnchor('right-sidebar'))
  }, [_renderSlot(_ctx.$slots, "button_create_right", {}, () => [_createVNode(_component_ButtonComponent, {
    variant: _ctx.variant,
    size: _ctx.size,
    color: _ctx.color,
    colorWeight: _ctx.colorWeight,
    title: "Add to Right Sidebar"
  }, {
    default: _withCtx(() => [_createVNode(_component_SidebarIcon, {
      size: "20",
      class: "scale-x-[-1] transform"
    }), _ctx.variant != 'round' ? (_openBlock(), _createElementBlock("span", _hoisted_5, " Add to Right Sidebar ")) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["variant", "size", "color", "colorWeight"])])])), $options.anchors.some(a => a.placement === 'main') ? (_openBlock(), _createElementBlock("button", {
    key: 4,
    role: "button",
    onClick: _cache[4] || (_cache[4] = $event => $options.toggleAnchor('main'))
  }, [_renderSlot(_ctx.$slots, "button_delete_main", {}, () => [_createVNode(_component_ButtonComponent, {
    variant: _ctx.variant,
    size: _ctx.size,
    color: _ctx.color,
    colorWeight: _ctx.colorWeight,
    title: "Remove from Favorites"
  }, {
    default: _withCtx(() => [_createVNode(_component_HeartIcon, {
      size: "20"
    }), _ctx.variant != 'round' ? (_openBlock(), _createElementBlock("span", _hoisted_6, " Remove from Favorites ")) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["variant", "size", "color", "colorWeight"])])])) : (_openBlock(), _createElementBlock("button", {
    key: 5,
    onClick: _cache[5] || (_cache[5] = $event => $options.toggleAnchor('main'))
  }, [_renderSlot(_ctx.$slots, "button_create_main", {}, () => [_createVNode(_component_ButtonComponent, {
    variant: _ctx.variant,
    size: _ctx.size,
    color: _ctx.color,
    colorWeight: _ctx.colorWeight,
    title: "Add to Favorites"
  }, {
    default: _withCtx(() => [_createVNode(_component_HeartIcon, {
      size: "20"
    }), _ctx.variant != 'round' ? (_openBlock(), _createElementBlock("span", _hoisted_7, " Add to Favorites ")) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["variant", "size", "color", "colorWeight"])])]))]);
}