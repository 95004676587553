import { createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "flex min-h-full items-end justify-center text-center sm:items-center"
};
const _hoisted_2 = {
  class: "absolute left-0 top-2 flex w-full justify-center sm:hidden"
};
const _hoisted_3 = {
  class: "absolute right-0 top-0 pr-4 pt-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_XIcon = _resolveComponent("XIcon");
  const _component_DialogTitle = _resolveComponent("DialogTitle");
  const _component_DialogPanel = _resolveComponent("DialogPanel");
  const _component_Dialog = _resolveComponent("Dialog");
  return $props.show ? (_openBlock(), _createBlock(_Teleport, {
    key: 0,
    to: "body"
  }, [_createVNode(_component_Dialog, {
    as: "div",
    open: true,
    class: "relative",
    onClose: _cache[1] || (_cache[1] = $event => _ctx.$emit('close'))
  }, {
    default: _withCtx(() => [_createVNode(_Transition, {
      "enter-active-class": "transition-opacity duration-200 ease-linear",
      "enter-from-class": "opacity-0",
      "enter-to-class": "opacity-100",
      "leave-active-class": "transition-opacity duration-100 ease-linear",
      "leave-from-class": "opacity-100",
      "leave-to-class": "opacity-0",
      appear: ""
    }, {
      default: _withCtx(() => _cache[2] || (_cache[2] = [_createElementVNode("div", {
        class: "fixed inset-0 bg-black/10"
      }, null, -1)])),
      _: 1
    }), _createVNode(_Transition, {
      "enter-active-class": "transition-translate duration-300 ease-out",
      "enter-from-class": "opacity-0 translate-y-full",
      "enter-to-class": "opacity-100 translate-y-0",
      "leave-active-class": "transition-translate duration-300 ease-out",
      "leave-from-class": "opacity-100 translate-y-0",
      "leave-to-class": "opacity-0 translate-y-full",
      appear: ""
    }, {
      default: _withCtx(() => [_createElementVNode("div", {
        class: _normalizeClass([{
          'overflow-y-auto overflow-x-hidden': $data.isExpanded,
          'overflow-x-hidden overflow-y-hidden sm:overflow-y-auto': !$data.isExpanded
        }, "fixed inset-0 w-screen"])
      }, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_DialogPanel, {
        ref: "wrapper",
        style: _normalizeStyle({
          '--ps-modal-transition-y': `${$data.modalTransition}px`
        }),
        class: _normalizeClass([{
          [_ctx.$style.dialogPanel]: true,
          'transition-transform duration-300 ease-out': !$data.isTouching,
          '!overflow-auto': $data.isExpanded
        }, "relative w-full rounded-t-lg bg-white px-4 pb-4 pt-6 text-left shadow-xl ring-1 ring-transparent will-change-transform sm:my-8 sm:px-6 sm:pb-6 md:max-w-3xl md:rounded-b-lg lg:max-w-5xl dark:bg-black dark:ring-neutral-700"]),
        onTouchstart: $options.handleTouchStart,
        onTouchmove: $options.handleTouchMove,
        onTouchend: $options.handleTouchEnd
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.dragHandle)
        }, null, 2)]), _createElementVNode("div", _hoisted_3, [_createElementVNode("button", {
          type: "button",
          class: "rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-neutral-500 focus:ring-offset-2 dark:bg-black dark:text-neutral-300 dark:ring-offset-black dark:hover:text-neutral-200 dark:focus:ring-neutral-500",
          onClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('close'))
        }, [_cache[3] || (_cache[3] = _createElementVNode("span", {
          class: "sr-only"
        }, "Close", -1)), _createVNode(_component_XIcon, {
          size: "20",
          "aria-hidden": "true"
        })])]), _createVNode(_component_DialogTitle, {
          as: "h3",
          class: "mb-2 text-base font-semibold leading-6 text-gray-900 dark:text-neutral-300"
        }, {
          default: _withCtx(() => [_renderSlot(_ctx.$slots, "title")]),
          _: 3
        }), _renderSlot(_ctx.$slots, "default")]),
        _: 3
      }, 8, ["style", "class", "onTouchstart", "onTouchmove", "onTouchend"])])], 2)]),
      _: 3
    })]),
    _: 3
  })])) : _createCommentVNode("", true);
}