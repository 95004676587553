import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.find.js";
import "core-js/modules/es.iterator.map.js";
import { Analytics } from "@/components/mixins/Analytics";
import { Lazying } from "./mixins/Lazying";
import { Markdowning } from "./mixins/Markdowning";
export default {
  mixins: [Analytics, Lazying, Markdowning],
  watch: {
    entry_by_route: function () {
      this.modalTransition = window.innerHeight * 0.4;
      this.isExpanded = false;
      if (this.entry_by_route) {
        this._trackEvent("Navigation", "Entry Modal", "Open Modal");
        document.title = `${this.clearMarkdown(this.entry_by_route.name) || "Unnamed"} · Pensive`;
      }
    },
    "entry_by_route.name": function () {
      if (this.entry_by_route) document.title = `${this.clearMarkdown(this.entry_by_route.name) || "Unnamed"} · Pensive`;
    },
    "$route.params.id": function () {
      this.pullData();
    },
    "$store.getters.timestamp": function () {
      this.$onIdle(() => {
        this.pullData();
      });
    }
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    entry_by_route_before() {
      return this.entries?.find(e => e.id == this.$route.params.id);
    },
    entry_by_route() {
      return this.entries?.find(e => e.id == this.$route.params.id2);
    },
    entries() {
      return this.$store.getters.entries?.filter(e => e.deleted_at === null);
    },
    defaultOutput() {
      return [{
        key: "links",
        op: "any",
        val: [this.entry_by_route.id]
      }];
    },
    outputIsModified() {
      return this.entry_by_route.output && JSON.stringify(this.defaultOutput) != JSON.stringify(this.entry_by_route.output.filters);
    },
    linkedEntries() {
      return this.entry_by_route.links.map(link => this.entries.find(e => e.id == link.id)).filter(e => e);
    },
    cssProps() {
      return {
        "--ps-output-display-entry-display-background-color-hover": this.$store.getters.dragged.length ? "transparent" : null
      };
    }
  },
  data() {
    return {
      window: window,
      schema: null,
      showSettings: false,
      showTemplates: false,
      showEntryModal: true
    };
  },
  created: function () {
    this.pullData();
    if (this.entry_by_route) {
      document.title = `${this.clearMarkdown(this.entry_by_route.name) || "Unnamed"} · Pensive`;
    }
  },
  methods: {
    onModalOpen() {
      // console.log("onModalOpen");
    },
    onModalClose() {
      // console.log("onModalClose");
    },
    openFullPage() {
      this._trackEvent("Navigation", "Entry Modal", "Open Full Page");
      this.$router.replace({
        name: "entry",
        params: {
          id: this.$route.params.id2
        }
      });
    },
    updateEntry() {
      this.$store.dispatch("push", {
        event: "entry_update",
        params: {
          entry: this.entry_by_route
        },
        entry: this.entry_by_route
      });
    },
    pullData() {
      if (this.$route.name == "entry") {
        if (this.entry_by_route?.deleted_at === null) {
          this.$store.dispatch("pull", {
            filters: [{
              key: "id",
              op: "eq",
              val: this.entry_by_route.id
            }]
          });
        } else {
          // this.$router.push({ name: "start" });
        }
      }
    },
    slugify: function (title) {
      var slug = title.toLowerCase();
      slug = slug.replace(/\s*$/g, "");
      slug = slug.replace(/\s+/g, "-");
      return slug;
    }
  }
};