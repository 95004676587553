import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PSButton = _resolveComponent("PSButton");
  const _component_HeartIcon = _resolveComponent("HeartIcon");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.versionInfo)
  }, [_ctx.$store.getters.version && $options.parseVersion(_ctx.$version) < $options.parseVersion(_ctx.$store.getters.version) ? (_openBlock(), _createBlock(_component_PSButton, {
    key: 0,
    onClick: $options.resetApp,
    class: _normalizeClass(["text-red-500", _ctx.$style.version])
  }, {
    default: _withCtx(() => [_createTextVNode(" v." + _toDisplayString(_ctx.$store.getters.version) + " available ", 1)]),
    _: 1
  }, 8, ["onClick", "class"])) : _ctx.$store.getters.version && $options.parseVersion(_ctx.$version) > $options.parseVersion(_ctx.$store.getters.version) ? (_openBlock(), _createBlock(_component_PSButton, {
    key: 1,
    onClick: $options.resetApp,
    class: _normalizeClass(["text-blue-500", _ctx.$style.version])
  }, {
    default: _withCtx(() => [_createTextVNode(" v." + _toDisplayString(_ctx.$store.getters.version) + " / v." + _toDisplayString(_ctx.$version), 1)]),
    _: 1
  }, 8, ["onClick", "class"])) : (_openBlock(), _createElementBlock("a", {
    key: 2,
    href: "https://pensive.io/whats-new",
    target: "_blank",
    class: _normalizeClass(_ctx.$style.version)
  }, " v." + _toDisplayString(_ctx.$version), 3)), _createElementVNode("span", {
    class: _normalizeClass(_ctx.$style.madeWith)
  }, [_cache[0] || (_cache[0] = _createTextVNode(" Made with ")), _createVNode(_component_HeartIcon, {
    size: "14",
    strokeWidth: "1.5",
    class: "inline-block"
  })], 2)], 2);
}