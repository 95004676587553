import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.find.js";
import { Statusing } from "../mixins/Statusing";
import { Scheduling } from "../mixins/Scheduling";
import { Routining } from "../mixins/Routining";
import { Addon } from "../mixins/Addon";
export default {
  // inject: ["position"],
  mixins: [Addon, Statusing, Scheduling, Routining],
  props: {
    modelValue: Object,
    entry: Object,
    full: Boolean,
    label: Boolean,
    statuses: {
      type: Array
    },
    teleport: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      window: window,
      skipQuickSwapTimeout: null,
      // search related
      showSearchInput: false,
      searchStatusQuery: ""
    };
  },
  computed: {
    status: {
      get() {
        return this.modelValue;
      },
      set(status) {
        if (this.entry) {
          this.setStatus(this.entry, status);
          if (!this.entry.temp) this.$store.dispatch("push", {
            event: "entry_update",
            params: {
              entry: this.entry
            },
            entry: this.entry
          });
        }
        this.$emit("update:modelValue", status);
      }
    },
    entries() {
      return this.$store.getters.entries.filter(e => e.deleted_at === null);
    },
    quickStatusSwapEnabled() {
      return typeof Cypress == "undefined" && this.status && this.statuses.length == 2;
    }
  },
  methods: {
    onMouseDown(e) {
      e.stopPropagation();
      e.preventDefault();
      if (e.button === 0 && this.quickStatusSwapEnabled) {
        this.mouseDown = true;
        this.skipQuickSwapTimeout = setTimeout(() => {
          if (this.mouseDown) {
            this.$refs.listbox_button.$el.click();
          }
        }, 500);
      }
    },
    onMouseUp(e) {
      e.stopPropagation();
      e.preventDefault();
      if (e.button === 0 && this.quickStatusSwapEnabled) {
        this.mouseDown = false;
        if (this.skipQuickSwapTimeout) {
          clearTimeout(this.skipQuickSwapTimeout);
          this.status = this.statuses.find(s => s.id != this.status.id);
        } else {
          clearTimeout(this.skipQuickSwapTimeout);
        }
      }
    },
    animationEnterDropdown(el, done) {
      this.$anime({
        targets: el,
        opacity: [0, 1],
        translateY: [20, 0],
        complete: done
      });
    },
    animationLeaveDropdown(el, done) {
      this.$anime({
        targets: el,
        opacity: [1, -0.2],
        translateY: [0, 20],
        complete: done
      });
    }
  }
};