import moment from "moment";
import { Statusing } from "../../mixins/Statusing";
import { Templating } from "../../mixins/Templating";
import { Schema } from "../../mixins/Schema";
import { Managing } from "../../mixins/Managing";
// import EntryComponent from "./EntryComponent.vue";

export default {
  inject: {
    overrideEntries: {
      default: null
    }
  },
  mixins: [Statusing, Templating, Schema /*, Rendering */, Managing],
  props: {
    modelValue: Object,
    position: String,
    hide: {
      type: Array,
      default() {
        return [];
      }
    },
    otherentries: [],
    // fixes console warning
    shareNode: null
  },
  data() {
    return {
      moment: moment,
      showSenseModal: false,
      showCustomFieldsModal: false,
      showCustomStatusesModal: false
    };
  },
  // watch: {
  //   modelValue: {
  //     handler(newVal) {
  //       this.$emit("update:modelValue", newVal);
  //       this.$emit("close");
  //     },
  //     deep: true,
  //   },
  // },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    entry: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      }
    },
    status: {
      get() {
        return this.getStatusById(this.modelValue.status?.id);
      },
      set() {
        //this.setStatus(this.entry, status);
        // if (!this.entry.temp)
        //   this.$store.dispatch("push", {
        //     event: "entry_update",
        //     params: { entry: this.entry },
        //     entry: this.entry,
        //   });
      }
    }
  }
};