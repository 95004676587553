import { Capacitor } from '@capacitor/core';
import { Haptics, ImpactStyle } from '@capacitor/haptics';

export const Routing = {
  methods: {
    navigateTo(entry) {
      if (Capacitor.isNativePlatform()) {
        const hapticsImpactLight = async () => {
          await Haptics.impact({ style: ImpactStyle.Medium });
        };

        // Actually call the haptics function
        hapticsImpactLight();
      }

      // console.log("navigateTo", entry.name, this.position)
      if (["center", "undo", "calendar", "modal"].includes(this.position)) {
        if (this.$route.params.id2 && this.$route.params.id2 != entry.id) {
          this.$router.replace({
            name: "entry",
            params: { id: entry.id },
          });
        } else if (this.$store.getters.focus) {
          // this.$router.go(-1);
          // this.$nextTick(() => {
          // console.log("navigateTo", entry.name)
          this.$router.replace({
            name: "entry",
            params: { id: entry.id },
          });
          // })
        } else {
          if (this.$route.params.id != entry.id) {
            if (!this.$Cypress) {
              this.$router.push({
                name: "entry",
                params: { id: this.$route.params.id, id2: entry.id },
              });
            } else {
              this.$router.push({
                name: "entry",
                params: { id: entry.id },
              });
            }
          }
        }
      } else if (!["search", "suggestions", "command-palette"].includes(this.position)) {
        this.$router.push({
          name: "entry",
          params: { id: entry.id },
        });
      }
    },
  },
};
