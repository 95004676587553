import { addonBlueprint } from "@/addonBlueprint";
import moment from "moment";
import { nanoid } from "nanoid";

export const Templating = {
    data() {
        return {
            selectedTemplates: [],
            search: "",
            tagSelected: null,
        }
    },
    props: {
        filterTemplates: {
            type: Function,
            default: (templates) => templates,
        }
    },
    computed: {
        user() {
            return this.$store.getters.user;
        },
        space() {
            return this.$store.getters.space;
        },
        customTemplates() {
            return this.$store.getters.templates
                ?.filter((t) => !t.global)
        },
        templates() {
            return this.$store.getters.templates.filter(
                (template) => template.tags === null ||
                    template.tags?.filter(
                        (tag) => tag.toLowerCase().indexOf("__onboarding__") !== -1,
                    ).length > 0,
            );
        },
        tags() {
            return [
                ...new Set(
                    this.templates
                        .flatMap((template) => template.tags)
                        .filter((tag) => tag && !tag.includes("__")),
                ),
            ];
        },
        tagsWithTemplates() {
            return this.tags.filter(
                (tag) =>
                    this.templates.filter(
                        (t) =>
                            // !this.selectedTemplates.includes(t.id) &&
                            t.tags?.includes(tag) &&
                            t.name.toLowerCase().includes(this.search.toLowerCase()),
                    ).length > 0,
            );
        },
        templatesAvailable() {
            return this.templates.filter(
                (t) => !this.selectedTemplates.includes(t.id) /*&&
                  (t.tags.includes(this.tagSelected) || !this.tagSelected) &&
                  t.name.toLowerCase().includes(this.search.toLowerCase()),*/,
            );
        },
        templatesFiltered() {
            return this.templates.filter(this.filterTemplates).filter(
                (t) =>
                    (t.tags?.includes(this.tagSelected) || !this.tagSelected) &&
                    t.name.toLowerCase().includes(this.search.toLowerCase()),
            );
        },


    },
    methods: {
        getCleanSchema(schema, replaceableId) {
            var cleanSchema = JSON.stringify(schema);

            cleanSchema = cleanSchema.replaceAll(schema.id, replaceableId);

            cleanSchema = JSON.parse(cleanSchema);
            if (cleanSchema.id)
                delete cleanSchema.id;


            if (cleanSchema.created_at)
                delete cleanSchema.created_at;
            if (cleanSchema.updated_at)
                delete cleanSchema.updated_at;
            if (cleanSchema.completed_at)
                delete cleanSchema.completed_at;
            if (cleanSchema.deleted_at)
                delete cleanSchema.deleted_at;

            for (const [key, value] of Object.entries(cleanSchema)) {
                if (value && typeof value == 'object' && !Array.isArray(value)) {
                    if (value.id)
                        delete cleanSchema[key].id;
                    if (value.entry_id)
                        delete cleanSchema[key].entry_id;

                    cleanSchema[key] = { ...addonBlueprint(key, { id: replaceableId }, this), ...cleanSchema[key] }
                }

                if (value && typeof value == 'object' && Array.isArray(value)) {
                    value.forEach((v, i) => {
                        if (v && typeof v == 'object') {
                            if (v.id && !['links'].includes(key))
                                delete cleanSchema[key][i].id;
                            if (v.entry_id)
                                delete cleanSchema[key][i].entry_id;


                            cleanSchema[key][i] = { ...addonBlueprint(key, { id: replaceableId }, this), ...cleanSchema[key][i] }
                        }
                    })
                }
            }

            // if (cleanSchema.time_trackings.length) {
            //     cleanSchema.time_trackings = [];
            // }

            return cleanSchema;
        },
        getOrCreateGlobalStatus(type) {

            var id = this.$store.getters.statuses.find(
                (s) => s.entry_id === null && s.type === type,
            )?.id;

            if (typeof id == "undefined") {
                id = nanoid();
                this.space.statuses.push({
                    id: id,
                    name: type.charAt(0).toUpperCase() + type.slice(1),
                    type: type,
                    color: (type == 'done' ? "green" : (type == 'open' ? "gray" : 'blue')),
                    entry_id: null,
                });
                this.$store.dispatch("push", {
                    event: "space_update",
                    params: { space: this.space },
                });
            }

            return id;
        },
        addTemplateAsEntry(data, overrideEntry = false, undo = true) {

            // console.log("addTemplateAsEntry", data);

            var entry = {};
            if (!entry.id) entry.id = nanoid();
            if (!entry.user_id) entry.user_id = this.user.id;
            if (!entry.created_at)
                entry.created_at = moment.utc().format("YYYY-MM-DD HH:mm:ss");
            if (!entry.updated_at)
                entry.updated_at = moment.utc().format("YYYY-MM-DD HH:mm:ss");
            if (!entry.deleted_at) entry.deleted_at = null;

            if (!entry.space_id) entry.space_id = this.space.id;

            entry.name = data.name;
            entry.links = [];

            entry = { ...entry, ...data };

            for (const [key, value] of Object.entries(entry)) {
                if (
                    typeof value == "object" &&
                    value !== null &&
                    !["links", "statuses", "anchors", "status"].includes(key)
                ) {
                    if (!entry[key].id) entry[key].id = nanoid();
                    if (!entry[key].entry_id) entry[key].entry_id = overrideEntry && this.entry ? this.entry.id : entry.id;
                }
            }
            if (entry.status_id) {
                if (this.$store.getters.statuses.find(s => s.id == entry.status_id)) {
                    entry.status = this.$store.getters.statuses.find(s => s.id == entry.status_id)
                }
                delete entry.status_id
            }
            if (entry.schedule) {
                if (entry.schedule.date && entry.schedule.date.op) {
                    entry.schedule.date = this.interpretDate(entry.schedule)
                }
                if (entry.schedule.time && entry.schedule.time.op) {
                    entry.schedule.time = this.interpretTime(entry.schedule)
                }
            }
            if (entry.anchor) {
                if (entry.anchor.position && entry.anchor.position.op) {
                    entry.anchor.position = this.$store.getters.entries.filter((e) => e.deleted_at === null).filter(e => e.anchor).length
                }
            }
            if (entry.statuses) {
                entry.statuses = entry.statuses.map(s => { s.entry_id = entry.id; s.space_id = entry.space_id; return s });
            }


            if (entry.custom_fields) {
                entry.custom_fields = entry.custom_fields.map(cf => {
                    if (!cf.id) cf.id = nanoid();
                    cf.entry_id = entry.id;
                    cf.space_id = entry.space_id;
                    return cf
                });
            }
            if (entry.custom_values) {
                entry.custom_values = entry.custom_values.map(cv => {
                    if (!cv.id) cv.id = nanoid();
                    cv.entry_id = entry.id;
                    return cv
                });
            }


            if (entry.links) {
                entry.links = entry.links.map(l => {
                    return {
                        id: l,
                        settings: {},
                        created_at: this.$moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                        updated_at: this.$moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                        deleted_at: null
                    }
                });
            }
            // if (entry.routine) {
            //     const routine = addonBlueprint("routine", entry);
            //     entry.routine = { ...routine, ...entry.routine };
            // }

            if (overrideEntry && this.entry) {

                delete entry.id;
                delete entry.name;
                for (const [key, value] of Object.entries(entry)) {
                    this.entry[key] = value;
                }

                this.$store.dispatch("push", {
                    event: "entry_update",
                    params: { entry: this.entry },
                    entry: this.entry,
                });
            } else {
                this.$store.getters.entries.push(entry);

                this.$store.dispatch("push", {
                    event: "entry_create",
                    params: { entry: entry },
                    entry: entry,
                    cb: () => this.$emit("created", entry),
                    undo: entry.name == null ? true : !undo,
                });

                return entry;
            }
            return entry;
        },
        addEmptyEntry() {
            const schema = this.entrySchema({
                name: null,
                anchor: { position: { op: "append" } },
            });
            this.addTemplateAsEntry(schema);
        },
        applyTemplateToEntry(template, entry) {

            template = this.resolveData(template);

            // console.log("applyTemplateToEntry", template);
            // return;

            // eslint-disable-next-line no-unreachable
            const schema = this.inputSchema(template.data,
                entry,
            )
            // console.log("schema", JSON.stringify(schema));
            this.applyInput(
                schema,
                entry,
                true,
            )
            this.$nextTick(() => {
                template.entries.forEach((e) => {
                    // console.log("addTemplateAsEntry", e);
                    this.addTemplateAsEntry(
                        this.entrySchema(e),
                    );
                });
            })
        },
        resolveData(data, vars = {}) {

            var string = JSON.stringify(data)
                .replaceAll("__ID__", vars.__ID__ ? vars.__ID__ : (this.entry?.id || nanoid()))
                .replaceAll("__STATUS_OPEN__", this.getOrCreateGlobalStatus("open"))
                .replaceAll("__STATUS_DONE__", this.getOrCreateGlobalStatus("done"));


            const regex = /__[A-Za-z0-9_]+__/g;
            const matches = [...new Set(string.match(regex))];
            matches.forEach((m) => {
                string = string.replaceAll(m, nanoid());
            });

            // console.log("resolveData", string, JSON.parse(string));

            return JSON.parse(string);
        },
        addTemplate(item) {
            this.selectedTemplates.push(item.id);
            // setTimeout(() => {
            //   this.$refs.available.scrollIntoView({
            //     behavior: "smooth",
            //     block: "end",
            //   });
            // }, 200);
        },

        setTemplate(template) {
            const id = this.$nanoid();
            template = this.resolveData(template, { __ID__: id });
            // console.log("Applying template", template);

            const schema = this.entrySchema(
                {
                    ...template.data,
                    ...{
                        id: id,
                        name: template.name,
                        anchors: [{ placement: "left-sidebar", position: { op: "append" } }],
                    },
                },
                id,
            );

            // console.log(schema);

            this.addTemplateAsEntry(schema, false, false);
            // console.log(entry);

            // this.$nextTick(() => {
            template.entries.forEach((e) => {
                // console.log(e.name);
                // console.log(e);
                this.addTemplateAsEntry(this.entrySchema(e), false, false);
            });
            // });

        },
    }
};
