import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString } from "vue";
const _hoisted_1 = {
  class: "w-48"
};
const _hoisted_2 = {
  class: "text-sm font-semibold capitalize"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_EntryDisplay = _resolveComponent("EntryDisplay");
  const _component_PopoverHelper = _resolveComponent("PopoverHelper");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.breadcrumbsDisplay),
    style: _normalizeStyle({
      '--ps-transition-base': $data.breadcrumbs.length
    })
  }, [$options.entry ? (_openBlock(true), _createElementBlock(_Fragment, {
    key: 0
  }, _renderList($data.breadcrumbs, (breadcrumb, index) => {
    return _openBlock(), _createElementBlock(_Fragment, {
      key: index
    }, [index == 1 || index > 1 && index >= $data.breadcrumbs.length - 2 ? (_openBlock(), _createElementBlock("span", {
      key: 0,
      class: _normalizeClass(_ctx.$style.breadcrumbSeparator),
      style: _normalizeStyle({
        '--ps-transition-index': $data.breadcrumbs.length - index
      })
    }, " / ", 6)) : _createCommentVNode("", true), index == 1 && $data.breadcrumbs.length > 3 && index < $data.breadcrumbs.length - 2 ? (_openBlock(), _createBlock(_component_PopoverHelper, {
      key: 1,
      teleport: true,
      style: _normalizeStyle({
        '--ps-transition-index': $data.breadcrumbs.length - index
      })
    }, {
      button: _withCtx(() => [_createElementVNode("span", {
        class: _normalizeClass(_ctx.$style.breadcrumbSeparator)
      }, " ... ", 2)]),
      default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.breadcrumbs.slice(1, $data.breadcrumbs.length - 2), breadcrumb => {
        return _openBlock(), _createBlock(_component_EntryDisplay, {
          key: breadcrumb.id,
          modelValue: breadcrumb,
          disabled: true,
          display: {
            status: false,
            procrastination: false,
            links: false,
            description: false,
            schedule: false,
            output: false,
            output_count: false,
            input: false,
            columns: false,
            leftover: false,
            settings: false,
            senses: false,
            routine: false,
            time_trackings: false,
            custom_fields: false
          },
          class: "cursor-pointer"
        }, null, 8, ["modelValue"]);
      }), 128))])]),
      _: 2
    }, 1032, ["style"])) : index == 0 || index >= $data.breadcrumbs.length - 2 ? (_openBlock(), _createElementBlock("div", {
      key: 2,
      class: _normalizeClass(_ctx.$style.entryWrapper),
      style: _normalizeStyle({
        '--ps-transition-index': $data.breadcrumbs.length - index
      })
    }, [_createVNode(_component_EntryDisplay, {
      modelValue: breadcrumb,
      disabled: true /*breadcrumb.id != entry.id*/,
      display: {
        status: false,
        procrastination: false,
        priority: false,
        links: false,
        description: false,
        schedule: false,
        output: false,
        output_count: false,
        input: false,
        columns: false,
        leftover: false,
        settings: false,
        senses: false,
        routine: false,
        time_trackings: false,
        custom_fields: false
      },
      class: _normalizeClass(_ctx.$style.entryDisplay)
    }, null, 8, ["modelValue", "class"])], 6)) : _createCommentVNode("", true)], 64);
  }), 128)) : (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: _normalizeClass(_ctx.$style.entryWrapper)
  }, [_createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$route.name), 1)], 2))], 6);
}