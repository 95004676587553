import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.find.js";
import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "flex flex-col"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TimeTrackingSettings = _resolveComponent("TimeTrackingSettings");
  const _component_TimeTrackingListUserDropdown = _resolveComponent("TimeTrackingListUserDropdown");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_TimeTrackingSettings, {
    entry: $props.entry,
    class: _normalizeClass(_ctx.$style.timeTrackingStarter)
  }, null, 8, ["entry", "class"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.time_trackings_by_users, (time_trackings, user_id) => {
    return _openBlock(), _createBlock(_component_TimeTrackingListUserDropdown, {
      ref_for: true,
      ref: "time_tracking_list_user_dropdown",
      key: user_id,
      modelValue: time_trackings,
      entry: $props.entry,
      user: $options.users.find(u => u.id == user_id)
    }, null, 8, ["modelValue", "entry", "user"]);
  }), 128))]);
}