export const Focusing = {

    // watch: {
    //     "$store.getters.registeredInputs.length": function (newVal) {
    //         if (newVal > 0) {
    //             this.inputQueryCheck();
    //         }
    //     },
    //     "$route.query.focusInput": function () {
    //         console.log("focusInput", this.$route.query.focusInput);
    //         this.inputQueryCheck();
    //     }
    // },

    // methods: {
    //     inputQueryCheck() {
    //         const to = this.$route;
    //         if (to.query.focusInput) {

    //             setTimeout(() => {
    //                 let inputs = this.$store.getters.registeredInputs.filter(
    //                     (input) => input.position == "center",
    //                 );
    //                 console.log("inputs length", inputs.length);
    //                 if (inputs.length > 0) {
    //                     this.focusInput(inputs[0]);

    //                     // Remove focusInput from URL without triggering route change
    //                     const query = { ...this.$route.query };
    //                     delete query.focusInput;
    //                     this.$router.replace({ query }, null, () => { });
    //                     // setTimeout(() => {
    //                     //     this.focusInput(inputs[0]);
    //                     // }, 1000);
    //                 }
    //             }, 1000);
    //         }
    //     },
    // }
};
