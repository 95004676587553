import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.map.js";
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
  class: "mx-auto w-full sm:max-w-xl md:max-w-2xl md:px-8 lg:max-w-3xl xl:max-w-5xl"
};
const _hoisted_2 = {
  class: "flex flex-col gap-4"
};
const _hoisted_3 = {
  class: "mt-6 border-t border-neutral-100 dark:border-neutral-500"
};
const _hoisted_4 = {
  class: "divide-y divide-neutral-100 dark:divide-neutral-500"
};
const _hoisted_5 = {
  class: "text-sm font-medium leading-6 text-neutral-900 dark:text-neutral-300"
};
const _hoisted_6 = {
  class: "mt-1 text-sm leading-6 text-neutral-700 sm:col-span-2 sm:mt-0 dark:text-neutral-400"
};
const _hoisted_7 = {
  key: 0,
  class: "px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
};
const _hoisted_8 = {
  class: "mt-1 text-sm leading-6 text-neutral-700 sm:col-span-2 sm:mt-0 dark:text-neutral-400"
};
const _hoisted_9 = {
  class: "space-y-12"
};
const _hoisted_10 = {
  class: "grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6"
};
const _hoisted_11 = {
  class: "sm:col-span-3"
};
const _hoisted_12 = {
  class: "mt-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Unverified = _resolveComponent("Unverified");
  const _component_PSButton = _resolveComponent("PSButton");
  const _component_AlertHelper = _resolveComponent("AlertHelper");
  const _component_PanelHelper = _resolveComponent("PanelHelper");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [!$options.user.verified ? (_openBlock(), _createBlock(_component_Unverified, {
    key: 0,
    class: "mb-4"
  })) : _createCommentVNode("", true), _createVNode(_component_PanelHelper, null, {
    default: _withCtx(() => [_cache[6] || (_cache[6] = _createElementVNode("div", {
      class: "px-4 sm:px-0"
    }, [_createElementVNode("h3", {
      class: "text-base font-semibold leading-7 text-neutral-900 dark:text-neutral-300"
    }, " User Information "), _createElementVNode("p", {
      class: "mt-1 max-w-2xl text-sm leading-6 text-neutral-500 dark:text-neutral-400"
    }, " Personal details. ")], -1)), _createElementVNode("div", _hoisted_3, [_createElementVNode("dl", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.details, (item, index) => {
      return _openBlock(), _createElementBlock("div", {
        key: index,
        class: "px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
      }, [_createElementVNode("dt", _hoisted_5, _toDisplayString(item.title), 1), _createElementVNode("dd", _hoisted_6, _toDisplayString(item.value), 1)]);
    }), 128)), 4 > 3 || $options.user?.verified ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_cache[5] || (_cache[5] = _createElementVNode("dt", {
      class: "text-sm font-medium leading-6 text-neutral-900 dark:text-neutral-300"
    }, " API Token ", -1)), _createElementVNode("dd", _hoisted_8, [_createElementVNode("div", null, [_createElementVNode("code", null, _toDisplayString($options.user.api_token.split("").map(c => $data.showApiToken ? c : "x").join("")), 1), _cache[3] || (_cache[3] = _createTextVNode("  ")), _createVNode(_component_PSButton, {
      onClick: _cache[0] || (_cache[0] = $event => $data.showApiToken = !$data.showApiToken),
      class: _normalizeClass(_ctx.$style.button)
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString($data.showApiToken ? "Hide" : "Show"), 1)]),
      _: 1
    }, 8, ["class"])]), _createElementVNode("div", null, [_createVNode(_component_AlertHelper, {
      variant: "warning"
    }, {
      default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode(" Your API token gives you full read and modify access to your Pensive data. "), _createElementVNode("br", null, null, -1), _createTextVNode("Please treat it like a password and be careful when you share it. "), _createElementVNode("br", null, null, -1), _createElementVNode("br", null, null, -1), _createTextVNode(" Learn more about the API at "), _createElementVNode("a", {
        href: "https://developer.pensive.io",
        target: "_blank",
        rel: "noopener noreferrer",
        class: "text-yellow-600 hover:text-yellow-500 dark:text-yellow-400 dark:hover:text-yellow-300"
      }, " developer.pensive.io ", -1)])),
      _: 1
    })])])])) : _createCommentVNode("", true)])])]),
    _: 1
  }), _createVNode(_component_PanelHelper, {
    class: "mt-2"
  }, {
    default: _withCtx(() => [_createElementVNode("form", null, [_createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, [_cache[8] || (_cache[8] = _createElementVNode("label", {
      for: "mode",
      class: "block text-sm font-medium leading-6 text-neutral-900 dark:text-neutral-300"
    }, "Mode", -1)), _createElementVNode("div", _hoisted_12, [_withDirectives(_createElementVNode("select", {
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $options.user.settings.prefers_color_scheme = $event),
      onChange: _cache[2] || (_cache[2] = event => {
        $options.user.settings = {
          ...$options.user.settings,
          prefers_color_scheme: event.target.value
        }, _ctx.$store.dispatch('push', {
          event: 'user_update',
          params: {
            user: $options.user
          },
          user: $options.user
        });
      }),
      id: "mode",
      name: "mode",
      class: "block w-full rounded-md border-0 py-1.5 text-neutral-900 shadow-sm ring-1 ring-inset ring-neutral-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 dark:bg-black dark:text-neutral-300 dark:ring-neutral-700"
    }, _cache[7] || (_cache[7] = [_createElementVNode("option", {
      value: "system"
    }, "System preference", -1), _createElementVNode("option", {
      value: "light"
    }, "Always Light mode", -1), _createElementVNode("option", {
      value: "dark"
    }, "Always Dark mode", -1)]), 544), [[_vModelSelect, $options.user.settings.prefers_color_scheme]])])])])])])]),
    _: 1
  })])])]);
}