import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "w-64"
};
const _hoisted_2 = {
  key: 0,
  class: "px-3 py-1 text-sm leading-6 text-gray-700"
};
const _hoisted_3 = {
  key: 0,
  class: "block text-xs text-gray-500"
};
const _hoisted_4 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Grid2x2Icon = _resolveComponent("Grid2x2Icon");
  const _component_ButtonComponent = _resolveComponent("ButtonComponent");
  const _component_PuzzleIcon = _resolveComponent("PuzzleIcon");
  const _component_PopoverTitle = _resolveComponent("PopoverTitle");
  const _component_PSListItem = _resolveComponent("PSListItem");
  const _component_TrashIcon = _resolveComponent("TrashIcon");
  const _component_PopoverHelper = _resolveComponent("PopoverHelper");
  return _openBlock(), _createBlock(_component_PopoverHelper, {
    teleport: true
  }, {
    button: _withCtx(() => [_createVNode(_component_ButtonComponent, {
      class: "w-full whitespace-nowrap rounded-md hover:bg-neutral-100 dark:hover:bg-neutral-900",
      size: _ctx.size,
      variant: _ctx.variant,
      color: _ctx.color
    }, {
      default: _withCtx(() => [_createVNode(_component_Grid2x2Icon, {
        size: "16"
      }), _cache[0] || (_cache[0] = _createTextVNode(" Use Template "))]),
      _: 1
    }, 8, ["size", "variant", "color"])]),
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_PopoverTitle, null, {
      default: _withCtx(() => [_createVNode(_component_PuzzleIcon, {
        size: "16",
        class: "text-gray-500",
        "aria-hidden": "true"
      }), _cache[1] || (_cache[1] = _createTextVNode(" Templates "))]),
      _: 1
    }), _ctx.$store.getters.templates.filter(t => t.entry_id !== _ctx.entry.id && !t.global).length === 0 ? (_openBlock(), _createElementBlock("p", _hoisted_2, " No Templates available ")) : _createCommentVNode("", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.getters.templates.filter(t => t.entry_id !== _ctx.entry.id && !t.global), (template, index) => {
      return _openBlock(), _createElementBlock("div", {
        key: index,
        class: "flex cursor-pointer items-start gap-x-2 rounded-lg px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 hover:text-black"
      }, [_createVNode(_component_PSListItem, {
        onClick: $event => (_ctx.applyTemplateToEntry(template, _ctx.entry),
        // applyInput(
        //   inputSchema(
        //     JSON.parse(
        //       JSON.stringify(template.data).replaceAll(
        //         template.entry_id,
        //         entry.id,
        //       ),
        //     ),
        //     entry,
        //   ),
        //   entry,
        //   true,
        // ),
        _ctx.$emit('templateSelected', template)),
        title: JSON.stringify(template.data),
        class: "w-full flex-1 text-left"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(template.name) + " ", 1), template.description ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(template.description), 1)) : _createCommentVNode("", true)]),
        _: 2
      }, 1032, ["onClick", "title"]), _createElementVNode("button", {
        onClick: $event => {
          _ctx.$store.getters.templates.splice(_ctx.$store.getters.templates.findIndex(t => t.id == template.id), 1);
          _ctx.$store.dispatch('push', {
            event: 'template_delete',
            params: {
              id: template.id
            },
            entry: _ctx.entry,
            undo: true
          });
        },
        class: "mt-1 hover:text-red-500"
      }, [_createVNode(_component_TrashIcon, {
        class: "h-4 w-4"
      })], 8, _hoisted_4)]);
    }), 128))])]),
    _: 1
  });
}