import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, Transition as _Transition, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import { ref } from "vue";
import { useFloating, shift, flip } from "@floating-ui/vue";
import OverlayComponent from "../helpers/OverlayComponent.vue";
export default {
  __name: 'MenuHelper',
  props: {
    placement: {
      type: String,
      default: "bottom-start"
    }
  },
  setup(__props) {
    const reference = ref(null);
    const floating = ref(null);
    const props = __props;
    const {
      floatingStyles
    } = useFloating(reference, floating, {
      placement: props.placement,
      middleware: [flip(), shift({
        crossAxis: true,
        padding: 16
      })]
    });
    return (_ctx, _cache) => {
      const _component_MenuButton = _resolveComponent("MenuButton");
      const _component_MenuItems = _resolveComponent("MenuItems");
      const _component_Menu = _resolveComponent("Menu");
      return _openBlock(), _createBlock(_component_Menu, {
        as: "div",
        class: "relative"
      }, {
        default: _withCtx(({
          open
        }) => [_createVNode(_component_MenuButton, {
          ref_key: "reference",
          ref: reference,
          onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
          class: "flex w-full focus-visible:outline-none"
        }, {
          default: _withCtx(() => [_renderSlot(_ctx.$slots, "button")]),
          _: 3
        }, 512), open ? (_openBlock(), _createBlock(_Teleport, {
          key: 0,
          to: "body"
        }, [_createVNode(OverlayComponent, {
          onClick: $event => open = false
        }, null, 8, ["onClick"]), _createVNode(_Transition, {
          "enter-active-class": "transition ease-out duration-100",
          "enter-from-class": "transform opacity-0 scale-95",
          "enter-to-class": "transform opacity-100 scale-100",
          "leave-active-class": "transition ease-in duration-75",
          "leave-from-class": "transform opacity-100 scale-100",
          "leave-to-class": "transform opacity-0 scale-95"
        }, {
          default: _withCtx(() => [_createVNode(_component_MenuItems, {
            class: "absolute right-0 z-[999] mt-2"
          }, {
            default: _withCtx(() => [_createElementVNode("div", {
              ref_key: "floating",
              ref: floating,
              style: _normalizeStyle(_unref(floatingStyles)),
              class: "w-56 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-black dark:ring-neutral-700"
            }, [_renderSlot(_ctx.$slots, "default", {
              open: open
            })], 4)]),
            _: 2
          }, 1024)]),
          _: 2
        }, 1024)])) : _createCommentVNode("", true)]),
        _: 3
      });
    };
  }
};