import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.find.js";
import "core-js/modules/es.iterator.flat-map.js";
import "core-js/modules/es.iterator.for-each.js";
import "core-js/modules/es.iterator.map.js";
import "core-js/modules/es.set.difference.v2.js";
import "core-js/modules/es.set.intersection.v2.js";
import "core-js/modules/es.set.is-disjoint-from.v2.js";
import "core-js/modules/es.set.is-subset-of.v2.js";
import "core-js/modules/es.set.is-superset-of.v2.js";
import "core-js/modules/es.set.symmetric-difference.v2.js";
import "core-js/modules/es.set.union.v2.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, toDisplayString as _toDisplayString, withModifiers as _withModifiers, mergeProps as _mergeProps, unref as _unref, Transition as _Transition } from "vue";
const _hoisted_1 = {
  class: "absolute right-2 top-2 opacity-0 transition-opacity duration-200 ease-in-out group-hover/cover:opacity-100"
};
const _hoisted_2 = ["onClick", "onMousedown", "onMouseup"];
const _hoisted_3 = {
  key: 1,
  class: "inline-block"
};
const _hoisted_4 = {
  key: 2,
  class: "inline-block"
};
const _hoisted_5 = ["data-show-empty-attributes"];
const _hoisted_6 = {
  class: "inline-block whitespace-nowrap"
};
const _hoisted_7 = {
  class: "flex flex-1 items-center justify-between"
};
import { addonBlueprint } from "@/addonBlueprint";
import { Routining } from "../mixins/Routining";
import { Scheduling } from "../mixins/Scheduling";
import { Statusing } from "../mixins/Statusing";
import { Touring } from "../mixins/Touring";
import EntryDisplay from "@/components/entry/EntryDisplay.vue";
import StatusDisplay from "@/components/status/StatusDisplay.vue";
import ScheduleDisplay from "@/components/schedule/ScheduleDisplay.vue";
import SensesDisplay from "@/components/senses/SensesDisplay.vue";
import TimeTrackingsDisplay from "@/components/timetrackings/TimeTrackingsDisplay.vue";
import RoutineDisplay from "@/components/routine/RoutineDisplay.vue";
import PriorityDisplay from "@/components/priority/PriorityDisplay.vue";
import ProcrastinationDisplay from "@/components/procrastination/ProcrastinationDisplay.vue";
import OutputSettings from "@/components/output/OutputSettings.vue";
import InputSettings from "@/components/input/InputSettings.vue";
import LinksDisplay from "@/components/links/LinksDisplay.vue";
import EntryDisplayLinked from "@/components/entry/partials/EntryDisplayLinked.vue";
import { TargetIcon, CalendarClockIcon, RadioIcon, ClockIcon, FlagIcon, CalendarX2Icon, RepeatIcon, DiamondIcon, ListPlusIcon, Link2Icon, TextCursorInputIcon } from "lucide-vue-next";
const ATTRIBUTE_ICONS = {
  status: TargetIcon,
  schedule: CalendarClockIcon,
  senses: RadioIcon,
  time_trackings: ClockIcon,
  routine: RepeatIcon,
  priority: FlagIcon,
  procrastination: CalendarX2Icon,
  custom_field: DiamondIcon,
  output_settings: ListPlusIcon,
  input_settings: TextCursorInputIcon,
  links: Link2Icon
  // Add more icon mappings here as needed
};
const __default__ = {
  components: {
    EntryDisplay,
    StatusDisplay,
    ScheduleDisplay,
    SensesDisplay,
    TimeTrackingsDisplay,
    RoutineDisplay,
    PriorityDisplay,
    ProcrastinationDisplay,
    OutputSettings,
    InputSettings,
    LinksDisplay,
    EntryDisplayLinked
    // TargetIcon,
    // CalendarClockIcon,
  },
  emits: ["showSettings"],
  mixins: [Statusing, Scheduling, Routining, Touring],
  props: {
    modelValue: Object,
    position: String
  },
  data() {
    return {
      showEmptyAttributes: false,
      availableAttributes: {
        status: entry => entry.status !== null && typeof entry.status === "object",
        schedule: entry => entry.schedule !== null && typeof entry.schedule === "object",
        senses: entry => Array.isArray(entry.senses) && entry.senses.length > 0,
        time_trackings: entry => entry.time_trackings.count > 0,
        routine: entry => entry.routine !== null && entry.routine !== undefined,
        priority: entry => entry.priority !== null && entry.priority !== undefined,
        procrastination: entry => entry.procrastination !== null && entry.procrastination !== undefined,
        output_settings: entry => entry.output !== null && entry.output !== undefined,
        input_settings: entry => entry.input !== null && entry.input !== undefined,
        links: entry => entry.links?.length > 0
      },
      visibleAttributes: [],
      initiallyEmpty: false
      // showSenseModal: false,
    };
  },
  created() {
    // console.time("entrySettings");
  },
  mounted() {
    this.initiallyEmpty = this.existingAttributes.length <= 0;
    if (this.initiallyEmpty) {
      this.showEmptyAttributes = true;
    } else {
      if (!this.$Cypress) {
        this.showEmptyAttributes = false;
      }
    }
    this.setVisibleAttributes();
    this.initResizeObserver();
  },
  beforeUnmount() {
    this.cleanupResizeObserver();
  },
  watch: {
    entry: {
      handler(n, o) {
        if (n.id != o.id) {
          this.visibleAttributes = [];
          this.initiallyEmpty = this.existingAttributes.length <= 0;
          if (this.existingAttributes.length <= 0 || this.initiallyEmpty) {
            this.showEmptyAttributes = true;
          } else {
            if (!this.$Cypress) {
              this.showEmptyAttributes = false;
            }
          }
          this.setVisibleAttributes();
        } else {
          this.setVisibleAttributes(false);
        }
      },
      deep: true
    },
    showEmptyAttributes() {
      this.setVisibleAttributes();
    }
  },
  computed: {
    cssProps() {
      return {
        "--ps-name-display-color": this.entry.color ? "var(--ps-color-" + this.entry.color + "-500)" : null
      };
    },
    existingAttributes() {
      return [...Object.keys(this.availableAttributes).filter(attribute => this.availableAttributes[attribute](this.entry)),
      // Getting static attributes and then custom fields
      ...this.customFieldsComputed.flatMap(e => e.custom_fields.map(cf => `custom_field_${cf.id}`)).filter(attribute => {
        const id = attribute.split("_").slice(2).join("_");
        return this.entry.custom_values.find(cv => cv.custom_field_id == id);
      })
      // ...this.entry.links.map((link) => `link_${link.id}`),
      ];
    },
    emptyAttributes() {
      return Object.keys(this.availableAttributes).filter(attribute => !this.availableAttributes[attribute](this.entry) && attribute !== "procrastination");
    },
    customFieldsComputed() {
      return this.entry.links?.map(link => this.$store.getters.entries.find(e => e.id == link.id)).filter(e => e?.custom_fields?.length);
    },
    tempDescription: {
      get() {
        if (this.entry.description) {
          return this.entry.description;
        }
        return addonBlueprint("description", this.entry, this);
      }
    },
    // visibleAttributes() {
    //   return [
    //     {
    //       key: "cover",
    //     },
    //     {
    //       key: "entry",
    //       val: {
    //         display: {
    //           toggle_output: false,
    //           output: false,
    //           input: false,
    //           status: false,
    //           schedule: false,
    //           senses: false,
    //           time_trackings: false,
    //           links: false,
    //           procrastination: false,
    //           output_settings: false,
    //           input_settings: false,
    //           routine: false,
    //           settings: false,
    //         },
    //       },
    //     },
    //     {
    //       key: "status",
    //       val: {},
    //     },
    //   ];
    // },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    entry: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      }
    },
    entries() {
      return this.$store.getters.entries?.filter(e => e.deleted_at === null);
    },
    linkedEntries() {
      if (this.position == "modal") {
        return [];
      } else {
        return this.entry.links.map(link => this.entries.find(e => e.id == link.id)).filter(e => e);
      }
    },
    statuses() {
      return this.getAvailableStatuses(this.entry);
    },
    status: {
      get() {
        return this.getStatusById(this.entry.status?.id);
      },
      set(status) {
        this.setStatus(this.entry, status);
      }
    },
    links: {
      get() {
        return this.entry.links;
      },
      set(links) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.links = links;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: {
            entry: this.entry
          },
          entry: this.entry
        });
      }
    },
    hasPanelContent() {
      return (
        // this.showTemplates ||
        this.entry.output || this.entry.input || this.linkedEntries.length > 0
      );
    },
    output: {
      get() {
        return this.entry.output;
      },
      set(output) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.output = output;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: {
            entry: this.entry
          },
          entry: this.entry
        });
      }
    },
    input: {
      get() {
        return this.entry.input;
      },
      set(input) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.input = input;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: {
            entry: this.entry
          },
          entry: this.entry
        });
      }
    }
  },
  methods: {
    handleMouseEvent(e, attribute, eventType) {
      // Only handle mousedown/mouseup for status attribute
      if (eventType !== "click" && attribute !== "status") return;
      const target = this.$refs[attribute]?.[0]?.querySelector("[data-table-column-target]");
      if (target) {
        e.stopPropagation();
        if (eventType !== "click") {
          const mouseEvent = new window.MouseEvent(eventType, {
            bubbles: e.bubbles,
            cancelable: e.cancelable,
            view: window,
            buttons: e.buttons,
            button: e.button,
            clientX: e.clientX,
            clientY: e.clientY,
            screenX: e.screenX,
            screenY: e.screenY
          });
          target.dispatchEvent(mouseEvent);
        } else {
          target.click();
        }
        target.focus();
      }
    },
    internalAttributeProperties(attribute) {
      switch (attribute) {
        case "status":
          return {
            component: "StatusDisplay",
            entry: this.entry,
            value: this.entry.status,
            bind: {
              statuses: this.statuses,
              internalSettings: {
                display: {
                  label: true,
                  settings: true
                }
              }
            }
          };
        case "time_trackings":
          return {
            component: "TimeTrackingsDisplay",
            entry: this.entry,
            value: this.entry[attribute],
            bind: {
              internalSettings: {
                display: {
                  label: true,
                  settings: true
                }
              }
            }
          };
        case "output_settings":
          return {
            component: "OutputSettings",
            entry: this.entry,
            value: this.entry.output,
            bind: {
              internalSettings: {
                display: {
                  label: true,
                  settings: true
                }
              }
            }
          };
        case "input_settings":
          return {
            component: "InputSettings",
            entry: this.entry,
            value: this.entry.input,
            bind: {
              internalSettings: {
                display: {
                  label: true,
                  settings: true
                }
              }
            }
          };
        case "links":
          return {
            component: "LinksDisplay",
            entry: this.entry,
            value: this.entry,
            bind: {
              internalSettings: {
                display: {
                  label: true,
                  settings: true
                }
              }
            }
          };
        default:
          return {
            component: attribute.charAt(0).toUpperCase() + attribute.slice(1) + "Display",
            entry: this.entry,
            value: this.entry[attribute],
            bind: {
              internalSettings: {
                display: {
                  label: true,
                  settings: true
                }
              }
            }
          };
      }
    },
    toggleEntrySettings(key, value) {
      // console.log("toggleSettings", key, value);

      // eslint-disable-next-line vue/no-mutating-props
      if (!this.entry.settings) this.entry.settings = {};

      // Handle dot notation by splitting the key
      const keyParts = key.split(".");
      let current = this.entry.settings;

      // Create nested objects if they don't exist
      for (let i = 0; i < keyParts.length - 1; i++) {
        if (!current[keyParts[i]]) current[keyParts[i]] = {};
        current = current[keyParts[i]];
      }

      // Set the final value
      current[keyParts[keyParts.length - 1]] = value;

      // eslint-disable-next-line vue/no-mutating-props
      this.entry.settings = {
        ...this.entry.settings
      };
      if (!this.entry.temp) this.$store.dispatch("push", {
        event: "entry_update",
        params: {
          entry: this.entry
        },
        entry: this.entry,
        undo: true
      });
    },
    toggleAttribute(attribute) {
      const value = this.entry.settings?.display?.[attribute];
      if (typeof value !== "undefined") {
        this.toggleEntrySettings("display." + attribute, !value);
      } else {
        this.toggleEntrySettings("display." + attribute, false);
      }
      if (this.entry.settings?.display?.[attribute] == false) {
        this.visibleAttributes = this.visibleAttributes.filter(attr => attr !== attribute);
      }
    },
    setVisibleAttributes(removeEmptyAttributes = true) {
      const currentAttributes = new Set(this.visibleAttributes);
      if (this.showEmptyAttributes) {
        // Add any new existing attributes that aren't already present
        this.existingAttributes.forEach(attr => {
          if (!currentAttributes.has(attr)) {
            this.visibleAttributes.push(attr);
            currentAttributes.add(attr);
          }
        });

        // Add any empty attributes that aren't already present
        this.emptyAttributes.forEach(attr => {
          if (!currentAttributes.has(attr)) {
            this.visibleAttributes.push(attr);
            currentAttributes.add(attr);
          }
        });
        this.customFieldsComputed.forEach(e => {
          e.custom_fields.forEach(cf => {
            if (!currentAttributes.has(`custom_field_${cf.id}`)) {
              this.visibleAttributes.push(`custom_field_${cf.id}`);
              currentAttributes.add(`custom_field_${cf.id}`);
            }
          });
        });
      } else {
        if (removeEmptyAttributes) {
          // Filter out empty attributes
          this.visibleAttributes = this.visibleAttributes.filter(attr => {
            this.entry.settings?.display?.[attr] && !this.emptyAttributes.includes(attr);
          });
        }

        // Add any missing existing attributes
        this.existingAttributes.forEach(attr => {
          if (!this.visibleAttributes.includes(attr) && (this.entry.settings?.display?.[attr] || typeof this.entry.settings?.display?.[attr] === "undefined")) {
            this.visibleAttributes.push(attr);
          }
        });
      }
    },
    checkGetParameters() {
      // console.log(this.$route.query);
      if (this.$route.query.sensesSettings == 1) {
        this.showSenseModal = true;
        this.$router.replace({
          query: {
            ...this.$route.query,
            sensesSettings: undefined
          }
        });
      }
    },
    initResizeObserver() {
      // this.resizeObserver = new ResizeObserver((entries) => {
      //   window.requestAnimationFrame(() => {
      //     for (let entry of entries) {
      //       const newHeight = entry.contentRect.height;
      //       if (this.$refs.outer_tinted_wrapper) {
      //         this.$refs.outer_tinted_wrapper.style.height = `${newHeight}px`;
      //       }
      //     }
      //   });
      // });
      // if (this.$refs.inner_tinted_wrapper)
      //   this.resizeObserver.observe(this.$refs.inner_tinted_wrapper);
      // this.resizeObserver2 = new ResizeObserver((entries) => {
      //   window.requestAnimationFrame(() => {
      //     for (let entry of entries) {
      //       const newHeight = entry.contentRect.height;
      //       if (this.$refs.outer) {
      //         this.$refs.outer.$el.style.height = `${newHeight}px`;
      //       }
      //     }
      //   });
      // });
      // if (this.$refs.inner) this.resizeObserver2.observe(this.$refs.inner);
    },
    cleanupResizeObserver() {
      // if (this.resizeObserver) {
      //   this.resizeObserver.disconnect();
      // }
      // if (this.resizeObserver2) {
      //   this.resizeObserver2.disconnect();
      // }
    },
    updatePositions(event) {
      if (event.moved) {
        const newLinks = [...this.entry.links];
        const {
          oldIndex,
          newIndex
        } = event.moved;
        const [movedItem] = newLinks.splice(oldIndex, 1);
        newLinks.splice(newIndex, 0, movedItem);
        newLinks.forEach((link, index) => {
          link.position = index;
        });
        this.entry.links = newLinks;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: {
            entry: this.entry
          },
          entry: this.entry
        });
      }
    },
    getLinkSettings(linkedEntryId) {
      const link = this.entry.links.find(l => l.id === linkedEntryId);
      return link?.settings || {};
    },
    toggleCollapse(linkedEntry) {
      const i = this.entry.links.findIndex(link => link.id === linkedEntry.id);
      if (i !== -1) {
        const updatedLinks = [...this.entry.links];
        updatedLinks[i] = {
          ...updatedLinks[i],
          settings: {
            ...updatedLinks[i].settings,
            collapsed: !updatedLinks[i].settings?.collapsed
          }
        };
        this.entry.links = updatedLinks;
        this.entry.links.forEach((link, index) => {
          link.position = index;
        });
        this.$store.dispatch("push", {
          event: "entry_update",
          params: {
            entry: this.entry
          },
          entry: this.entry,
          undo: true
        });
      }
    },
    getAttributeIcon(attribute) {
      if (attribute.startsWith("custom_field_")) {
        return ATTRIBUTE_ICONS["custom_field"];
      }
      return ATTRIBUTE_ICONS[attribute];
    }
  }
};
export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'EntrySettings',
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_TourHelper = _resolveComponent("TourHelper");
      const _component_CoverSettings = _resolveComponent("CoverSettings");
      const _component_CoverDisplay = _resolveComponent("CoverDisplay");
      const _component_ImageIcon = _resolveComponent("ImageIcon");
      const _component_PSButton = _resolveComponent("PSButton");
      const _component_NameDisplay = _resolveComponent("NameDisplay");
      const _component_DescriptionDisplay = _resolveComponent("DescriptionDisplay");
      const _component_EyeIcon = _resolveComponent("EyeIcon");
      const _component_EyeClosedIcon = _resolveComponent("EyeClosedIcon");
      const _component_ChevronUpIcon = _resolveComponent("ChevronUpIcon");
      const _component_ChevronDownIcon = _resolveComponent("ChevronDownIcon");
      const _component_NativeTransitionGroupHelper = _resolveComponent("NativeTransitionGroupHelper");
      const _component_PSHeightResizer = _resolveComponent("PSHeightResizer");
      const _component_OutputDisplay = _resolveComponent("OutputDisplay");
      const _component_InputDisplay = _resolveComponent("InputDisplay");
      const _component_QuickTransformInputOutput = _resolveComponent("QuickTransformInputOutput");
      const _component_ArrowUpIcon = _resolveComponent("ArrowUpIcon");
      const _component_ButtonComponent = _resolveComponent("ButtonComponent");
      const _component_MenuItem = _resolveComponent("MenuItem");
      const _component_Link2OffIcon = _resolveComponent("Link2OffIcon");
      const _component_ArrowDownIcon = _resolveComponent("ArrowDownIcon");
      const _component_LinksPopover = _resolveComponent("LinksPopover");
      const _component_RestDisplay = _resolveComponent("RestDisplay");
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(_ctx.$style.entrySettings)
      }, [_createVNode(_component_TourHelper, {
        hook: "entry_settings",
        class: "absolute right-0 top-0 mr-2 mt-2",
        "auto-start": ""
      }), _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.entrySettingsFlexCol),
        "data-tour": "main_entry_settings"
      }, [__props.position != 'modal' ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.$style.coverWrapper)
      }, [_ctx.entry.cover ? (_openBlock(), _createBlock(_component_CoverDisplay, {
        key: 0,
        modelValue: _ctx.entry.cover,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.entry.cover = $event),
        entry: _ctx.entry,
        class: "group/cover overflow-hidden"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_CoverSettings, {
          modelValue: _ctx.entry.cover,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.entry.cover = $event),
          key: "cover",
          color: "secondary",
          entry: _ctx.entry
        }, null, 8, ["modelValue", "entry"])])]),
        _: 1
      }, 8, ["modelValue", "entry"])) : _createCommentVNode("", true)], 2)) : _createCommentVNode("", true), _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.centerContent)
      }, [_createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.headerWrapper)
      }, [_createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.topMenuWrapper)
      }, [!_ctx.entry.cover ? (_openBlock(), _createBlock(_component_CoverSettings, {
        key: 0,
        modelValue: _ctx.entry.cover,
        entry: _ctx.entry
      }, {
        button: _withCtx(() => [_createVNode(_component_PSButton, {
          class: _normalizeClass(_ctx.$style.topMenuButton)
        }, {
          default: _withCtx(() => [_createVNode(_component_ImageIcon, {
            size: "16",
            strokeWidth: "1.5"
          }), _cache[11] || (_cache[11] = _createTextVNode(" Add cover "))]),
          _: 1
        }, 8, ["class"])]),
        _: 1
      }, 8, ["modelValue", "entry"])) : _createCommentVNode("", true)], 2), _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.nameWrapper),
        style: _normalizeStyle(_ctx.cssProps)
      }, [_createVNode(_component_NameDisplay, {
        modelValue: _ctx.entry.name,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.entry.name = $event),
        entry: _ctx.entry
      }, null, 8, ["modelValue", "entry"])], 6)], 2), _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.descriptionWrapper)
      }, [_createVNode(_component_DescriptionDisplay, {
        modelValue: _ctx.tempDescription,
        entry: _ctx.entry,
        position: "center",
        overrideEditable: true
      }, null, 8, ["modelValue", "entry"])], 2), _createVNode(_component_PSHeightResizer, null, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.entryAttributes),
          "data-tour": "main_entry_toolbar"
        }, [_createVNode(_component_NativeTransitionGroupHelper, {
          "enter-active-class": _ctx.$style.enterActive,
          "enter-from-class": _ctx.$style.enterFrom,
          "enter-to-class": _ctx.$style.enterTo,
          "leave-active-class": _ctx.$style.leaveActive,
          "leave-from-class": _ctx.$style.leaveFrom,
          "leave-to-class": _ctx.$style.leaveTo
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visibleAttributes, (attribute, index) => {
            return _openBlock(), _createElementBlock("div", {
              ref_for: true,
              ref: attribute,
              key: attribute,
              class: _normalizeClass([_ctx.$style.entryAttribute, _ctx.$style[attribute]]),
              role: "button",
              onClick: e => _ctx.handleMouseEvent(e, attribute, 'click'),
              onMousedown: _withModifiers(e => _ctx.handleMouseEvent(e, attribute, 'mousedown'), ["stop"]),
              onMouseup: _withModifiers(e => _ctx.handleMouseEvent(e, attribute, 'mouseup'), ["stop"]),
              style: _normalizeStyle({
                '--ps-transition-delay': `${index * 50}ms`
              })
            }, [_createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.attributeNameWrapper)
            }, [_createElementVNode("span", {
              class: _normalizeClass(_ctx.$style.attributeName)
            }, [_ctx.getAttributeIcon(attribute) ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getAttributeIcon(attribute)), {
              key: 0,
              size: "14",
              strokeWidth: "1.5",
              class: "inline-block h-[14px] w-[14px]"
            })) : _createCommentVNode("", true), !attribute.startsWith('custom_field_') ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(attribute.replace("_settings", "").split("_").join(" ")), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.customFieldsComputed.flatMap(e => e.custom_fields).find(e => e.id == attribute.split("_").slice(2).join("_"))?.name), 1))], 2), _ctx.existingAttributes.includes(attribute) ? (_openBlock(), _createBlock(_component_PSButton, {
              key: 0,
              onClick: _withModifiers($event => _ctx.toggleAttribute(attribute), ["stop"]),
              onMousedown: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"])),
              onMouseup: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"])),
              class: _normalizeClass(_ctx.$style.toggleAttributeButton),
              title: _ctx.entry.settings?.display?.[attribute] || typeof _ctx.entry.settings?.display?.[attribute] === 'undefined' ? 'Hide attribute' : 'Show attribute'
            }, {
              default: _withCtx(() => [_ctx.entry.settings?.display?.[attribute] || typeof _ctx.entry.settings?.display?.[attribute] === 'undefined' ? (_openBlock(), _createBlock(_component_EyeIcon, {
                key: 0,
                size: "14",
                strokeWidth: "1.5",
                class: "inline-block h-[14px] w-[14px]"
              })) : (_openBlock(), _createBlock(_component_EyeClosedIcon, {
                key: 1,
                size: "14",
                strokeWidth: "1.5",
                class: "inline-block h-[14px] w-[14px]"
              }))]),
              _: 2
            }, 1032, ["onClick", "class", "title"])) : _createCommentVNode("", true)], 2), _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.attributeValueWrapper)
            }, [_createElementVNode("div", {
              class: _normalizeClass({
                [_ctx.$style.attributeValue]: true,
                [_ctx.$style.attributeValue_Empty]: _ctx.availableAttributes[attribute] && !_ctx.availableAttributes[attribute](_ctx.entry)
              })
            }, [(_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.internalAttributeProperties(attribute).component), _mergeProps({
              ref_for: true
            }, _ctx.internalAttributeProperties(attribute).bind, {
              modelValue: _ctx.internalAttributeProperties(attribute).value,
              entry: _ctx.internalAttributeProperties(attribute).entry
            }), null, 16, ["modelValue", "entry"]))], 2)], 2)], 46, _hoisted_2);
          }), 128)), Object.keys(_ctx.availableAttributes).length > _ctx.visibleAttributes.length || _ctx.showEmptyAttributes ? (_openBlock(), _createElementBlock("div", {
            key: "showMore",
            class: _normalizeClass([_ctx.$style.entryAttribute, _ctx.$style.toggleHiddenAttributes])
          }, [_createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.attributeNameWrapper)
          }, [_createElementVNode("span", {
            class: _normalizeClass(_ctx.$style.attributeName)
          }, [_createElementVNode("div", {
            ref: "showMore",
            role: "button",
            onClick: _cache[5] || (_cache[5] = $event => _ctx.showEmptyAttributes = !_ctx.showEmptyAttributes),
            "data-tour": "entry_display_detail_show_more_button",
            "data-show-empty-attributes": _ctx.showEmptyAttributes,
            class: _normalizeClass(_ctx.$style.showMoreButton)
          }, [_ctx.showEmptyAttributes ? (_openBlock(), _createBlock(_component_ChevronUpIcon, {
            key: 0,
            size: "14",
            strokeWidth: "1.5",
            class: "inline-block h-[14px] w-[14px]"
          })) : (_openBlock(), _createBlock(_component_ChevronDownIcon, {
            key: 1,
            size: "14",
            strokeWidth: "1.5",
            class: "inline-block h-[14px] w-[14px]"
          })), _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.showEmptyAttributes ? "Hide empty / hidden attributes" : "Show empty / hidden attributes"), 1)], 10, _hoisted_5)], 2)], 2)], 2)) : _createCommentVNode("", true)]),
          _: 1
        }, 8, ["enter-active-class", "enter-from-class", "enter-to-class", "leave-active-class", "leave-from-class", "leave-to-class"])], 2)]),
        _: 1
      }), _ctx.entry.output || _ctx.entry.input ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_ctx.entry.output ? (_openBlock(), _createBlock(_component_OutputDisplay, {
        id: "output",
        key: _ctx.entry.output.id,
        modelValue: _ctx.entry.output,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _ctx.entry.output = $event),
        entry: _ctx.entry,
        display: {
          links: _ctx.entry.output.type != 'gallery',
          output: _ctx.entry.output.type != 'gallery'
        },
        filter: e => e.id != _ctx.entry.id,
        inputs: _ctx.linkedEntries,
        sorting: _ctx.entry.output.sorting,
        editable: true,
        position: __props.position,
        "data-tour": "main_entry_output"
      }, null, 8, ["modelValue", "entry", "display", "filter", "inputs", "sorting", "position"])) : _createCommentVNode("", true), !_ctx.entry.output && _ctx.entry.input ? (_openBlock(), _createBlock(_component_InputDisplay, {
        id: "input",
        key: "input",
        modelValue: _ctx.entry.input,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => _ctx.entry.input = $event),
        entry: _ctx.entry,
        schema: _ctx.entry.input.schema,
        color: _ctx.entry.color
      }, null, 8, ["modelValue", "entry", "schema", "color"])) : _createCommentVNode("", true), _ctx.entry.output && _ctx.entry.input && _ctx.entry.output.filters.length == 1 && Object.keys(_ctx.entry.input.schema).length == 1 ? (_openBlock(), _createBlock(_component_QuickTransformInputOutput, {
        key: 2,
        entry: _ctx.entry,
        class: _normalizeClass(_ctx.$style.quickTransformInputOutput)
      }, null, 8, ["entry", "class"])) : _createCommentVNode("", true)], 64)) : _createCommentVNode("", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.linkedEntries, (linkedEntry, index) => {
        return _openBlock(), _createElementBlock("div", {
          key: linkedEntry.id,
          class: _normalizeClass(_ctx.$style.linkedEntry)
        }, [_createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.stickySubHeader)
        }, [_createVNode(EntryDisplay, {
          modelValue: linkedEntry,
          display: {
            links: false,
            description: false,
            output: false,
            input: false
          },
          disabled: true
        }, {
          contextmenu: _withCtx(() => [index > 0 ? (_openBlock(), _createBlock(_component_MenuItem, {
            key: 0
          }, {
            default: _withCtx(({
              active
            }) => [_createVNode(_component_ButtonComponent, {
              onClick: _withModifiers(() => {
                const i = _ctx.entry.links.findIndex(link => link.id === linkedEntry.id);
                if (i > 0) {
                  const item = _ctx.entry.links.splice(i, 1)[0];
                  _ctx.entry.links.splice(i - 1, 0, item);
                }
                _ctx.entry.links.forEach((link, index) => {
                  link.position = index;
                });
                _ctx.$store.dispatch('push', {
                  event: 'entry_update',
                  params: {
                    entry: _ctx.entry
                  },
                  entry: _ctx.entry
                });
              }, ["stop"]),
              variant: "menu",
              color: "white",
              size: "sm",
              active: active,
              class: "w-full rounded-md",
              title: "Move this entry up"
            }, {
              default: _withCtx(() => [_createVNode(_component_ArrowUpIcon, {
                size: "16",
                class: _normalizeClass({
                  'text-neutral-500': active
                })
              }, null, 8, ["class"]), _cache[12] || (_cache[12] = _createElementVNode("div", {
                class: "flex flex-1 items-center justify-between"
              }, [_createElementVNode("span", null, " Move up ")], -1))]),
              _: 2
            }, 1032, ["onClick", "active"])]),
            _: 2
          }, 1024)) : _createCommentVNode("", true), _createVNode(_component_MenuItem, null, {
            default: _withCtx(({
              active
            }) => [_createVNode(_component_ButtonComponent, {
              onClick: _withModifiers(() => {
                const i = _ctx.entry.links.findIndex(link => link.id === linkedEntry.id);
                if (i > -1) {
                  _ctx.entry.links.splice(i, 1);
                }
                _ctx.entry.links.forEach((link, index) => {
                  link.position = index;
                });
                _ctx.$store.dispatch('push', {
                  event: 'entry_update',
                  params: {
                    entry: _ctx.entry
                  },
                  entry: _ctx.entry
                });
              }, ["stop"]),
              variant: "menu",
              color: "white",
              size: "sm",
              active: active,
              class: "w-full rounded-md",
              title: "Unlink"
            }, {
              default: _withCtx(() => [_createVNode(_component_Link2OffIcon, {
                size: "16",
                class: _normalizeClass({
                  'text-neutral-500': active
                })
              }, null, 8, ["class"]), _cache[13] || (_cache[13] = _createElementVNode("div", {
                class: "flex flex-1 items-center justify-between"
              }, [_createElementVNode("span", null, " Unlink ")], -1))]),
              _: 2
            }, 1032, ["onClick", "active"])]),
            _: 2
          }, 1024), index < _ctx.entry.links.length - 1 ? (_openBlock(), _createBlock(_component_MenuItem, {
            key: 1
          }, {
            default: _withCtx(({
              active
            }) => [_createVNode(_component_ButtonComponent, {
              onClick: _withModifiers(() => {
                const i = _ctx.entry.links.findIndex(link => link.id === linkedEntry.id);
                if (i < _ctx.entry.links.length - 1) {
                  const item = _ctx.entry.links.splice(i, 1)[0];
                  _ctx.entry.links.splice(i + 1, 0, item);
                }
                _ctx.entry.links.forEach((link, index) => {
                  link.position = index;
                });
                _ctx.$store.dispatch('push', {
                  event: 'entry_update',
                  params: {
                    entry: _ctx.entry
                  },
                  entry: _ctx.entry
                });
              }, ["stop"]),
              variant: "menu",
              color: "white",
              size: "sm",
              active: active,
              class: "w-full rounded-md",
              title: "Move this entry down"
            }, {
              default: _withCtx(() => [_createVNode(_component_ArrowDownIcon, {
                size: "16",
                class: _normalizeClass({
                  'text-neutral-500': active
                })
              }, null, 8, ["class"]), _cache[14] || (_cache[14] = _createElementVNode("div", {
                class: "flex flex-1 items-center justify-between"
              }, [_createElementVNode("span", null, " Move down ")], -1))]),
              _: 2
            }, 1032, ["onClick", "active"])]),
            _: 2
          }, 1024)) : _createCommentVNode("", true), _createVNode(_component_MenuItem, null, {
            default: _withCtx(({
              active
            }) => [_createVNode(_component_ButtonComponent, {
              onClick: _withModifiers($event => _ctx.toggleCollapse(linkedEntry), ["stop"]),
              variant: "menu",
              color: "white",
              size: "sm",
              active: active,
              class: "w-full rounded-md"
            }, {
              default: _withCtx(() => [_ctx.entry.links.find(l => l.id === linkedEntry.id)?.settings?.collapsed ? (_openBlock(), _createBlock(_component_ChevronDownIcon, {
                key: 0,
                size: "16",
                class: _normalizeClass({
                  'text-neutral-500': active
                })
              }, null, 8, ["class"])) : (_openBlock(), _createBlock(_component_ChevronUpIcon, {
                key: 1,
                size: "16",
                class: _normalizeClass({
                  'text-neutral-500': active
                })
              }, null, 8, ["class"])), _createElementVNode("div", _hoisted_7, [_createElementVNode("span", null, _toDisplayString(_ctx.entry.links.find(l => l.id === linkedEntry.id)?.settings?.collapsed ? "Expand" : "Collapse"), 1)])]),
              _: 2
            }, 1032, ["onClick", "active"])]),
            _: 2
          }, 1024), _cache[15] || (_cache[15] = _createElementVNode("hr", null, null, -1))]),
          _: 2
        }, 1032, ["modelValue"])], 2), linkedEntry.output ? (_openBlock(), _createBlock(_component_OutputDisplay, {
          id: "output",
          key: linkedEntry.output.id,
          modelValue: linkedEntry.output,
          "onUpdate:modelValue": $event => linkedEntry.output = $event,
          entry: linkedEntry,
          display: {
            links: linkedEntry.output.type != 'gallery',
            output: linkedEntry.output.type != 'gallery'
          },
          filter: e => e.id != _ctx.entry.id,
          inputs: _ctx.linkedEntries,
          sorting: linkedEntry.output.sorting,
          editable: true,
          position: __props.position
        }, null, 8, ["modelValue", "onUpdate:modelValue", "entry", "display", "filter", "inputs", "sorting", "position"])) : _createCommentVNode("", true), !linkedEntry.output && linkedEntry.input ? (_openBlock(), _createBlock(_component_InputDisplay, {
          id: "input",
          key: "input",
          modelValue: linkedEntry.input,
          "onUpdate:modelValue": $event => linkedEntry.input = $event,
          entry: linkedEntry,
          schema: linkedEntry.input.schema
        }, null, 8, ["modelValue", "onUpdate:modelValue", "entry", "schema"])) : _createCommentVNode("", true)], 2);
      }), 128)), _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.postActionButtons)
      }, [!_ctx.hasPanelContent ? (_openBlock(), _createBlock(_component_PSButton, {
        key: 0,
        onClick: _cache[8] || (_cache[8] = $event => {
          _ctx.output = _unref(addonBlueprint)('output', _ctx.entry, this);
          _ctx.input = _unref(addonBlueprint)('input', _ctx.entry, this);
        }),
        title: "Add Input & Output",
        shortcode: "+",
        class: _normalizeClass(_ctx.$style.postActionButton)
      }, {
        default: _withCtx(() => [_createVNode(_unref(ListPlusIcon), {
          size: "16"
        }), _cache[16] || (_cache[16] = _createTextVNode(" Add Input & Output "))]),
        _: 1
      }, 8, ["class"])) : _createCommentVNode("", true), _createVNode(_component_LinksPopover, {
        modelValue: _ctx.links.map(link => link.id),
        "onAdd:linkId": _cache[9] || (_cache[9] = id => {
          _ctx.links = [..._ctx.links, {
            id,
            position: _ctx.links.length,
            settings: {},
            created_at: _ctx.$moment.utc().format('YYYY-MM-DD HH:mm:ss'),
            updated_at: _ctx.$moment.utc().format('YYYY-MM-DD HH:mm:ss'),
            deleted_at: null
          }];
        }),
        "data-tour": "main_entry_links"
      }, {
        button: _withCtx(() => [_createVNode(_component_PSButton, {
          title: "Add Links",
          shortcode: "b",
          class: _normalizeClass(_ctx.$style.postActionButton)
        }, {
          default: _withCtx(() => [_createVNode(_unref(Link2Icon), {
            size: "16"
          }), _cache[17] || (_cache[17] = _createTextVNode(" Add Link "))]),
          _: 1
        }, 8, ["class"])]),
        _: 1
      }, 8, ["modelValue"])], 2), _createVNode(_Transition, {
        "enter-active-class": "transition-all duration-[800ms] delay-[400ms]",
        "enter-from-class": "opacity-0 translate-y-5",
        "enter-to-class": "opacity-100 translate-y-0",
        "leave-active-class": "transition-all duration-[800ms]",
        "leave-to-class": "opacity-0 translate-y-5",
        "leave-from-class": "opacity-100 translate-y-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_RestDisplay, {
          modelValue: _ctx.entry,
          "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => _ctx.entry = $event),
          class: "mt-2 py-5",
          position: __props.position
        }, null, 8, ["modelValue", "position"])]),
        _: 1
      })], 2)], 2)], 2);
    };
  }
});