import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.find.js";
import { Tracking } from "../mixins/Tracking";
export default {
  provide() {
    return {
      time_tracking_selected_date: this.date
    };
  },
  mixins: [Tracking],
  data() {
    return {
      date: this.$moment.utc().format("YYYY-MM-DD"),
      current: null,
      interval: null
    };
  },
  mounted() {
    this.current = this.$moment.utc().format("YYYY-MM-DD HH:mm:ss");
    this.interval = setInterval(() => {
      this.current = this.$moment.utc().format("YYYY-MM-DD HH:mm:ss");
    }, 1000);
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  computed: {
    outputComputed() {
      return {
        filters: [{
          key: ["time_trackings", "range"],
          op: "ra",
          val: [this.date + " 00:00:00", this.date + " 23:59:59"]
        }]
      };
    },
    user() {
      return this.$store.state.user;
    },
    hasActiveTimeTracking() {
      return this.$store.getters.time_trackings.find(tt => tt.end_at == null && tt.user_id == this.user.id);
    },
    time_trackings() {
      return this.$store.getters.time_trackings;
    },
    outputFilter() {
      return e => {
        return this.time_trackings.filter(tt => tt.entry_id == e.id && tt.user_id == this.user.id && this.$moment.utc(tt.start_at).format("YYYY-MM-DD") == this.date).length;
      };
    }
  }
};