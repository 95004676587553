export default {
  data() {
    return {
      showTemplates: false
    };
  },
  props: {
    entry: {
      type: Object
    }
  }
};