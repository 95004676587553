import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "mx-auto w-full md:px-8"
};
const _hoisted_2 = {
  class: "flex flex-col gap-4"
};
const _hoisted_3 = {
  class: "mt-6 border-t border-neutral-100 pt-6 dark:border-neutral-500"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_RoutineChart = _resolveComponent("RoutineChart");
  const _component_PanelHelper = _resolveComponent("PanelHelper");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_PanelHelper, {
    ref: "panelHelper"
  }, {
    default: _withCtx(() => [_cache[0] || (_cache[0] = _createElementVNode("div", {
      class: "px-4 sm:px-0"
    }, [_createElementVNode("h3", {
      class: "text-base font-semibold leading-7 text-neutral-900 dark:text-neutral-300"
    }, " Routines "), _createElementVNode("p", {
      class: "mt-1 max-w-2xl text-sm leading-6 text-neutral-500 dark:text-neutral-400"
    }, " Your personal habit tracker based on entries with a routine. ")], -1)), _createElementVNode("div", _hoisted_3, [_createVNode(_component_RoutineChart)])]),
    _: 1
  }, 512)])])]);
}