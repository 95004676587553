import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_EntryDisplay = _resolveComponent("EntryDisplay");
  const _component_ChevronDownIcon = _resolveComponent("ChevronDownIcon");
  const _component_ChevronRightIcon = _resolveComponent("ChevronRightIcon");
  const _component_PSButton = _resolveComponent("PSButton");
  const _component_OutputDisplay = _resolveComponent("OutputDisplay");
  const _component_InputDisplay = _resolveComponent("InputDisplay");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", {
    class: _normalizeClass(_ctx.$style.linkedEntrySticky)
  }, [_createElementVNode("div", {
    class: _normalizeClass([_ctx.$style.linkedEntryOuterWrapper])
  }, [_createElementVNode("div", {
    class: _normalizeClass(_ctx.$style.linkedEntryWrapper)
  }, [_createVNode(_component_EntryDisplay, {
    modelValue: $props.entry,
    permissions: {
      name: true,
      description: true,
      status: false,
      priority: false,
      time_trackings: false
    },
    display: {
      links: false,
      output: false,
      routine: false,
      custom_fields: false
    },
    class: _normalizeClass(_ctx.$style.linkedEntryDisplay)
  }, {
    contextmenu: _withCtx(() => [_renderSlot(_ctx.$slots, "contextmenu")]),
    _: 3
  }, 8, ["modelValue", "class"])], 2), _createElementVNode("div", {
    class: _normalizeClass(_ctx.$style.linkedEntrySettings)
  }, [_createVNode(_component_PSButton, {
    onClick: _cache[0] || (_cache[0] = _withModifiers($event => _ctx.$emit('toggleCollapse'), ["stop"])),
    class: _normalizeClass([_ctx.$style.interactableElements, 'text-neutral-500 hover:text-neutral-700 dark:hover:text-neutral-300'])
  }, {
    default: _withCtx(() => [!$props.settings?.collapsed ? (_openBlock(), _createBlock(_component_ChevronDownIcon, {
      key: 0,
      size: "16"
    })) : (_openBlock(), _createBlock(_component_ChevronRightIcon, {
      key: 1,
      size: "16"
    }))]),
    _: 1
  }, 8, ["class"])], 2)], 2)], 2), !$props.settings?.collapsed ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass(_ctx.$style.centerized)
  }, [$props.entry.output ? (_openBlock(), _createBlock(_component_OutputDisplay, {
    key: 0,
    modelValue: $props.entry.output,
    display: {
      links: $props.entry.output.type != 'gallery',
      output: $props.entry.output.type != 'gallery'
    },
    entry: $props.entry,
    filter: $props.outputFilter,
    inputs: $props.linkedEntries,
    editable: true,
    sorting: $props.entry.output.sorting,
    position: $props.position,
    class: _normalizeClass(_ctx.$style.linkedOutputDisplay)
  }, null, 8, ["modelValue", "display", "entry", "filter", "inputs", "sorting", "position", "class"])) : _createCommentVNode("", true), !$props.entry.output && $props.entry.input ? (_openBlock(), _createBlock(_component_InputDisplay, {
    key: 1,
    modelValue: $props.entry.input,
    entry: $props.entry,
    schema: $props.entry.input.schema,
    position: $props.position
  }, null, 8, ["modelValue", "entry", "schema", "position"])) : _createCommentVNode("", true)], 2)) : _createCommentVNode("", true)]);
}