import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, renderSlot as _renderSlot } from "vue";
const _hoisted_1 = {
  class: "flex justify-between"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SelectMenu = _resolveComponent("SelectMenu");
  const _component_LinksBuilder = _resolveComponent("LinksBuilder");
  const _component_TrashIcon = _resolveComponent("TrashIcon");
  const _component_PSButton = _resolveComponent("PSButton");
  const _component_PlusIcon = _resolveComponent("PlusIcon");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.filterBuilder)
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.filters, (o, k) => {
    return _openBlock(), _createElementBlock("fieldset", {
      key: k,
      class: _normalizeClass(_ctx.$style.filterRow)
    }, [_createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.filterRow_left)
    }, [_createVNode(_component_SelectMenu, {
      modelValue: o.key,
      "onUpdate:modelValue": [$event => o.key = $event, key => {
        o.key = key;
        _ctx.$emit('update:modelValue', $options.filters);
      }],
      options: _ctx.keys,
      ref_for: true,
      ref: 'key_select_' + k,
      openWhenEmpty: true,
      color: "secondary",
      size: "sm"
    }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]), o.key || o.op ? (_openBlock(), _createBlock(_component_SelectMenu, {
      key: 0,
      modelValue: o.op,
      "onUpdate:modelValue": [$event => o.op = $event, op => {
        o.op = op;
        _ctx.$emit('update:modelValue', $options.filters);
      }],
      options: _ctx.ops(o.key),
      render: val => _ctx.opToSign(val),
      openWhenEmpty: true,
      color: "secondary",
      size: "sm"
    }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "render"])) : _createCommentVNode("", true), _ctx.vals(o.key, o.op)[0] == 'LinksBuilder' ? (_openBlock(), _createBlock(_component_LinksBuilder, _mergeProps({
      key: 1,
      ref_for: true
    }, _ctx.vals(o.key, o.op)[1], {
      modelValue: o.val,
      "onUpdate:modelValue": [$event => o.val = $event, val => {
        o.val = val;
        _ctx.$emit('update:modelValue', $options.filters);
      }],
      color: "secondary",
      size: "sm",
      style: {
        '--ps-list-flex-direction': 'row'
      }
    }), null, 16, ["modelValue", "onUpdate:modelValue"])) : _ctx.vals(o.key, o.op).length && ['DatePickerBuilder', 'TimePickerBuilder'].includes(_ctx.vals(o.key, o.op)[0]) ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.vals(o.key, o.op)[0]), _mergeProps({
      key: 2,
      ref_for: true
    }, _ctx.vals(o.key, o.op)[1], {
      modelValue: o.val[0],
      "onUpdate:modelValue": [$event => o.val[0] = $event, val => {
        o.val = [val];
        _ctx.$emit('update:modelValue', $options.filters);
      }],
      color: "secondary",
      size: "sm"
    }), null, 16, ["modelValue", "onUpdate:modelValue"])) : _createCommentVNode("", true), _ctx.vals(o.key, o.op).length && !['DatePickerBuilder', 'TimePickerBuilder'].includes(_ctx.vals(o.key, o.op)[0]) ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.vals(o.key, o.op)[0]), _mergeProps({
      key: 3,
      ref_for: true
    }, _ctx.vals(o.key, o.op)[1], {
      modelValue: o.val,
      "onUpdate:modelValue": [$event => o.val = $event, val => {
        o.val = val;
        _ctx.$emit('update:modelValue', $options.filters);
      }],
      color: "secondary",
      size: "sm"
    }), null, 16, ["modelValue", "onUpdate:modelValue"])) : _createCommentVNode("", true)], 2), _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.filterRow_right)
    }, [_createVNode(_component_PSButton, {
      onClick: $event => ($options.filters.splice(k, 1), _ctx.$emit('update:modelValue', $options.filters)),
      class: _normalizeClass(_ctx.$style.buttonRemove)
    }, {
      default: _withCtx(() => [_createVNode(_component_TrashIcon, {
        size: "16",
        "stroke-width": "1.5"
      })]),
      _: 2
    }, 1032, ["onClick", "class"])], 2)], 2);
  }), 128)), _createElementVNode("fieldset", _hoisted_1, [_createVNode(_component_PSButton, {
    onClick: _withModifiers($options.handleRowAdd, ["stop"]),
    class: _normalizeClass(_ctx.$style.buttonAdd)
  }, {
    default: _withCtx(() => [_createVNode(_component_PlusIcon, {
      size: "16",
      "stroke-width": "1.5"
    }), _cache[0] || (_cache[0] = _createTextVNode(" Add "))]),
    _: 1
  }, 8, ["onClick", "class"]), _renderSlot(_ctx.$slots, "after")])], 2);
}