import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = ["innerHTML"];
const _hoisted_2 = {
  key: 1
};
const _hoisted_3 = {
  class: "flex max-h-48 flex-col gap-0 overflow-y-auto"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ButtonComponent = _resolveComponent("ButtonComponent");
  const _component_PSListItem = _resolveComponent("PSListItem");
  const _component_PopoverHelper = _resolveComponent("PopoverHelper");
  return _openBlock(), _createBlock(_component_PopoverHelper, {
    closeOnClick: true,
    isOpen: typeof $props.render(_ctx.modelValue) == 'undefined' || $props.render(_ctx.modelValue) == null
  }, {
    button: _withCtx(() => [_createVNode(_component_ButtonComponent, {
      variant: _ctx.variant,
      size: _ctx.size,
      color: _ctx.color,
      class: "whitespace-nowrap uppercase"
    }, {
      default: _withCtx(() => [typeof $props.render(_ctx.modelValue) != 'undefined' && $props.render(_ctx.modelValue) != null ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        innerHTML: $props.render(_ctx.modelValue)
      }, null, 8, _hoisted_1)) : (_openBlock(), _createElementBlock("span", _hoisted_2, " select "))]),
      _: 1
    }, 8, ["variant", "size", "color"])]),
    default: _withCtx(({
      close
    }) => [_createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options?.filter(o => JSON.stringify(o) != JSON.stringify(_ctx.modelValue)), (option, index) => {
      return _openBlock(), _createBlock(_component_PSListItem, {
        as: "button",
        key: index,
        onClick: $event => (_ctx.$emit('update:modelValue', typeof option == 'object' && option.value ? option.value : option), close()),
        innerHTML: $props.render(typeof option == 'object' && option.title ? option.title : option),
        class: "flex w-full cursor-pointer items-center justify-between gap-x-2 whitespace-nowrap px-3 py-1 text-xs uppercase leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-900",
        "data-option": typeof option == 'object' && option.value ? option.value : option
      }, null, 8, ["onClick", "innerHTML", "data-option"]);
    }), 128))])]),
    _: 1
  }, 8, ["isOpen"]);
}