import { withModifiers as _withModifiers, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = ["value", "onBlur"];
const _hoisted_2 = ["value"];
const _hoisted_3 = {
  class: "text-neutral-500"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_DatePicker = _resolveComponent("DatePicker");
  const _component_PSListItem = _resolveComponent("PSListItem");
  const _component_PSList = _resolveComponent("PSList");
  const _component_PSOverlay = _resolveComponent("PSOverlay");
  return _openBlock(), _createBlock(_component_PSOverlay, {
    maxHeight: "200px",
    renderBackdrop: false
  }, {
    button: _withCtx(({
      close
    }) => [_createElementVNode("input", {
      ref: "input",
      value: $data.internalVisibleTime,
      onInput: _cache[0] || (_cache[0] = $event => $data.internalVisibleTime = $event.target.value),
      type: "text",
      onFocus: _cache[1] || (_cache[1] = (...args) => $options.handleOnFocus && $options.handleOnFocus(...args)),
      onBlur: $event => $options.handleOnBlur(close),
      onKeydown: _cache[2] || (_cache[2] = _withKeys(_withModifiers($event => _ctx.$refs.input.blur(), ["stop", "prevent"]), ["enter"])),
      class: _normalizeClass(_ctx.$style.input),
      style: {
        "width": "100%"
      }
    }, null, 42, _hoisted_1)]),
    default: _withCtx(({
      close
    }) => [_createVNode(_component_PSList, {
      class: "ps_overlay_mobile_only"
    }, {
      default: _withCtx(() => [_createVNode(_component_PSListItem, {
        class: _normalizeClass([_ctx.$style.mobileInputWrapper, "ps_overlay_mobile_only"])
      }, {
        default: _withCtx(() => [_createVNode(_component_PSList, {
          style: {
            "--ps-list-flex-direction": "row",
            "width": "100%"
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_PSListItem, null, {
            default: _withCtx(() => [_createVNode(_component_DatePicker, {
              modelValue: $data.internalDate.format('YYYY-MM-DD'),
              "onUpdate:modelValue": $event => ($data.internalDate = _ctx.$moment.utc($data.internalDate).set({
                year: _ctx.$moment($event).year(),
                month: _ctx.$moment($event).month(),
                date: _ctx.$moment($event).date()
              }), close())
            }, null, 8, ["modelValue", "onUpdate:modelValue"])]),
            _: 2
          }, 1024), _createVNode(_component_PSListItem, {
            style: {
              "flex": "1"
            }
          }, {
            default: _withCtx(() => [_createElementVNode("input", {
              ref: "mobile_input",
              value: $data.internalVisibleTime,
              onInput: _cache[3] || (_cache[3] = $event => $data.internalVisibleTime = $event.target.value),
              type: "text",
              onFocus: _cache[4] || (_cache[4] = (...args) => $options.handleOnFocus && $options.handleOnFocus(...args)),
              onKeydown: _cache[5] || (_cache[5] = _withKeys(_withModifiers($event => _ctx.$refs.mobile_input.blur(), ["stop", "prevent"]), ["enter"])),
              placeholder: "Set Duration",
              class: _normalizeClass(_ctx.$style.mobileInput),
              style: {
                "width": "100%"
              }
            }, null, 42, _hoisted_2)]),
            _: 1
          })]),
          _: 2
        }, 1024)]),
        _: 2
      }, 1032, ["class"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.slots, (slot, key) => {
        return _withDirectives((_openBlock(), _createBlock(_component_PSListItem, {
          key: key,
          onClick: _withModifiers(() => {
            let endTimeMoment = _ctx.$moment.utc(slot.date + ' ' + slot.time);
            $data.internalVisibleTime = endTimeMoment.local().format('HH:mm');
            $options.duration = endTimeMoment.diff(_ctx.$moment.utc($props.basis), 'seconds');
            close();
          }, ["prevent"])
        }, {
          after: _withCtx(() => [_createElementVNode("span", _hoisted_3, _toDisplayString($options.renderDuration(slot)), 1)]),
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$moment.utc(slot.date + " " + slot.time).local().format("HH:mm")) + " ", 1)]),
          _: 2
        }, 1032, ["onClick"])), [[_vShow, $props.basis || _ctx.$moment.utc(_ctx.$moment.utc($props.basis).format('YYYY-MM-DD') + ' ' + slot.time).isAfter($props.basis ? _ctx.$moment.utc($props.basis) : _ctx.$moment.utc())]]);
      }), 128))]),
      _: 2
    }, 1024)]),
    _: 1
  });
}