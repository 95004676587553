import { addonBlueprint } from "@/addonBlueprint";
import { Addon } from "../mixins/Addon";
export default {
  mixins: [Addon],
  data() {
    return {
      level: this.modelValue?.level,
      priorities: [1, 2, 3, 4]
    };
  },
  watch: {
    modelValue(priority) {
      this.level = priority?.level;
    },
    level(value) {
      if (value) {
        if (this.priority) this.priority = {
          ...this.priority,
          level: value
        };else {
          this.priority = {
            ...addonBlueprint("priority", this.entry, this),
            ...{
              level: value
            }
          };
        }
      } else {
        this.priority = null;
      }
    }
  },
  computed: {
    priority: {
      get() {
        return this.modelValue;
      },
      set(value) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.priority = value;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: {
            entry: this.entry
          },
          entry: this.entry
        });
        this.$emit("update:modelValue", value);
      }
    },
    prioColor() {
      switch (this.priority?.level) {
        case 1:
          return "red";
        case 2:
          return "orange";
        case 3:
          return "yellow";
        default:
          return this.color;
      }
    }
  }
};