import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.find.js";
import "core-js/modules/es.iterator.map.js";
import { Addon } from "../mixins/Addon";
import LinksBuilder from "./partials/LinksBuilder.vue";
export default {
  components: {
    LinksBuilder
  },
  mixins: [Addon],
  computed: {
    links: {
      get() {
        return this.entry.links;
      },
      set(links) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.links = links;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: {
            entry: this.entry
          },
          entry: this.entry
        });
      }
    },
    linksObjects() {
      return this.entry.links.map(link => {
        const entry = this.$store.getters.entries.find(e => e.id == link.id);
        return entry ? {
          ...entry,
          linkSettings: link.settings
        } : null;
      }).filter(e => e);
    }
  },
  methods: {
    updateLinkSettings(linkId, settings) {
      const linkIndex = this.links.findIndex(link => (typeof link === "object" ? link.id : link) === linkId);
      if (linkIndex !== -1) {
        const updatedLinks = [...this.links];
        updatedLinks[linkIndex] = {
          id: linkId,
          settings: {
            ...settings
          }
        };
        this.links = updatedLinks;
      }
    }
  }
};