import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.find.js";
import "core-js/modules/es.iterator.flat-map.js";
import "core-js/modules/es.iterator.map.js";
import { Applicators } from "../mixins/Applicators";
import { DragAndDrop } from "../mixins/DragAndDrop";
import { Filtering } from "../mixins/Filtering";
import { Grouping } from "../mixins/Grouping";
import { Routining } from "../mixins/Routining";
import { Scheduling } from "../mixins/Scheduling";
import { Schema } from "../mixins/Schema";
import { Sorting } from "../mixins/Sorting";
import { Statusing } from "../mixins/Statusing";
import { Routing } from "../mixins/Routing";

// import { Lazying } from "../mixins/Lazying";
// import { Viewporting } from "../mixins/Viewporting";

import EntryDisplayDetail from "@/components/entry/partials/EntryDisplayDetail.vue";
import EntryDisplayList from "@/components/entry/partials/EntryDisplayList.vue";
import EntryDisplayTable from "@/components/entry/partials/EntryDisplayTable.vue";
import EntryDisplayTiles from "@/components/entry/partials/EntryDisplayTiles.vue";
import EntryDisplayGallery from "@/components/entry/partials/EntryDisplayGallery.vue";
export default {
  inject: {
    overrideEntries: {
      default: null
    },
    output_entry_origin: {
      default: null
    },
    toggleDisplayOutput: {
      default: () => {}
    },
    isDisplayOutput: {
      default: () => {}
    }
  },
  // eslint-disable-next-line vue/no-unused-components
  components: {
    EntryDisplayDetail,
    EntryDisplayList,
    EntryDisplayTable,
    EntryDisplayTiles,
    EntryDisplayGallery
  },
  mixins: [Schema, Filtering, Grouping, Sorting, Statusing, DragAndDrop, Scheduling, Routining, Applicators, Routing
  // Lazying,
  // Viewporting,
  ],
  props: {
    modelValue: Object,
    output: Object,
    placeholder: String,
    position: String,
    display: Object,
    disabled: Boolean,
    permissions: {
      type: Object,
      default: () => ({
        name: true,
        description: true,
        status: true,
        schedule: true,
        routine: true,
        senses: true
      })
    },
    renderType: {
      type: String,
      default: "list"
    },
    disableRouting: Boolean,
    containingOutputGroup: String,
    entryIndex: Number,
    isEntryDisplayOutput: {
      type: Function,
      default: entry => entry.settings?.display?.output
    },
    toggleEntryDisplayOutput: {
      type: Function,
      default: entry => {
        if (!entry.settings) entry.settings = {};
        if (!entry.settings.display) entry.settings.display = {};
        entry.settings.display = {
          ...entry.settings.display,
          output: entry.settings.display?.output ? !entry.settings.display.output : true
        };
        if (!entry.temp) this.$store.dispatch("push", {
          event: "entry_update",
          params: {
            entry: entry
          },
          entry: entry,
          undo: true
        });
      }
    }
  },
  data() {
    return {
      isInitialized: true,
      isVisibleInViewport: false,
      defaultDisplay: {
        name: true,
        status: true,
        procrastination: true,
        description: true,
        time_trackings: true,
        priority: true,
        routine: true,
        senses: true,
        schedule: true,
        output: true,
        input: true,
        links: true,
        columns: true,
        leftover: true,
        settings: true,
        custom_fields: true
      },
      navigator: navigator,
      showOutput: false,
      showConfig: false,
      isSelected: false,
      isDragged: false,
      isLazyMounted: false
    };
  },
  mounted() {
    // requestAnimationFrame(() => {
    this.pullData();
    this.isLazyMounted = true;
    // });
  },
  computed: {
    isShowEmptyAttributes() {
      return ["table", "detail"].includes(this.renderType);
    },
    renderComponent() {
      switch (this.renderType) {
        case "detail":
          return "EntryDisplayDetail";
        case "table":
          return "EntryDisplayTable";
        case "tiles":
          return "EntryDisplayTiles";
        case "gallery":
          return "EntryDisplayGallery";
        case "list":
        default:
          return "EntryDisplayList";
      }
    },
    computedDisplay() {
      return {
        ...this.defaultDisplay,
        ...this.display
      };
    },
    entry: {
      get() {
        return this.modelValue;
      }
    },
    space() {
      return this.$store.getters.space;
    },
    status: {
      get() {
        return this.getStatusById(this.entry.status?.id);
      }
      // set(status) {
      // this.setStatus(this.entry, status);
      // if (!this.entry.temp)
      //   this.$store.dispatch("push", {
      //     event: "entry_update",
      //     params: { entry: this.entry },
      //     entry: this.entry,
      //   });
      // },
    },
    customFields() {
      return this.entry.links?.map(link => this.$store.getters.entries.find(e => e.id == link.id)).filter(e => e?.custom_fields?.length).flatMap(e => e.custom_fields);
    },
    availableStatuses() {
      if (this.overrideStatuses) return this.overrideStatuses;
      const baseStatuses = this.getAvailableStatuses(this.entry);
      const originStatuses = this.output_entry_origin && JSON.stringify(this.getAvailableStatuses(this.entry)) != JSON.stringify(this.getAvailableStatuses(this.output_entry_origin)) ? this.getAvailableStatuses(this.output_entry_origin) : [];

      // Custom merge that removes duplicates based on id
      const mergedStatuses = this.$merge(baseStatuses, originStatuses, {
        arrayMerge: (target, source) => {
          const merged = [...target, ...source];
          return merged.filter((status, index, self) => index === self.findIndex(s => s.id === status.id));
        }
      });
      return mergedStatuses;
    },
    isActive() {
      return this.entry.id == this.$route.params.id;
    },
    cssProps() {
      return {
        "--ps-entry-display-color": this.entry.color ? "var(--ps-color-" + this.entry.color + "-500)" : null,
        "--ps-entry-display-color-light": this.entry.color ? `var(--ps-color-${this.entry.color}-100)` : null,
        "--ps-entry-display-color-light-hover": this.entry.color ? `var(--ps-color-${this.entry.color}-200)` : null,
        "--ps-entry-display-color-dark": this.entry.color ? `var(--ps-color-${this.entry.color}-900)` : null,
        "--ps-entry-display-color-dark-hover": this.entry.color ? `var(--ps-color-${this.entry.color}-800)` : null
      };
    }
  },
  methods: {
    pullData() {
      if (this.entry.deleted_at === null && this.entry.output) {
        this.$store.dispatch("pull", {
          filters: this.entry.output.filters,
          source: {
            file: "EntryDisplay",
            entry: this.entry.name
          }
        });
      }
    },
    onClick(e) {
      if (process.env.NODE_ENV === "development" && e.altKey) {
        this.isLazyMounted = !this.isLazyMounted;
        return;
      }
      if (this.disableClick) return;
      if (!this.entry.temp) {
        if (
        // this.$route.params.id != this.entry.id &&
        // this.$route.params.id2 != this.entry.id &&
        e.shiftKey || e.ctrlKey || navigator.userAgent.includes("Mobile") && this.$store.getters.selected.length > 0) {
          // Bulk Selection
          this.$store.dispatch("selected", this.entry);
          return;
        }
        if (e.altKey) {
          // Open in new tab
          window.open(window.location.origin + "/" + this.entry.id, "_blank");
          return;
        }
        if (!this.disableRouting && !window.getSelection().toString().length) {
          this.navigateTo(this.entry);
        }
      }
    },
    contextMenu(e, settingsClicked = false) {
      if (this.entry.temp) {
        return;
      }
      if (this.$route.params.id == this.entry.id && this.position == "center") {
        return;
      }
      /**
       * pointerType is undefined in cypress e2e tests
       */
      if (e.pointerType == "mouse" || typeof e.pointerType == "undefined") {
        if (e.type == "contextmenu" || settingsClicked) {
          /**
           * stopPropagation is needed to
           * prevent the contextmenu event
           * from potential parent entry
           */
          e.stopPropagation();
          e.preventDefault();
          if (this.computedDisplay.settings) {
            this.mousePositionOnDragStart.x = null;
            this.mousePositionOnDragStart.y = null;
            this.showConfig = !this.showConfig;
          }
        }
      } else {
        // no contextmenu on touch devices
        // if (this.showConfigOnPress == this.showConfig)
        //   this.showConfig = !this.showConfig;
        // e.stopPropagation();
      }
    },
    updateEntry() {
      this.$store.dispatch("push", {
        event: "entry_update",
        params: {
          entry: this.entry
        },
        entry: this.entry
      });
    }
  },
  watch: {
    "$store.getters.selected": function (n) {
      const isSelected = n.findIndex(id => id == this.entry.id) !== -1;
      if (isSelected != this.isSelected) this.isSelected = isSelected;
    },
    "$store.getters.dragged": function (n) {
      const isDragged = n.findIndex(e => e.id == this.entry.id) !== -1;
      if (isDragged != this.isDragged) this.isDragged = isDragged;
    },
    "$store.getters.timestamp": function (n, o) {
      if (n - o > 2) {
        this.$onIdle(() => {
          this.pullData();
        });
      }
    },
    showConfig() {
      if (this.showConfig) {
        this.$nextTick(() => {
          let configX = this.$cursorPosition.x;
          let configY = this.$cursorPosition.y;
          let overlay = document.querySelector(".ps_overlay");
          if (overlay) {
            /**
             * This is used to prevent the config
             * form from overlapping on the right side
             */
            if (window.innerWidth < configX + overlay.offsetWidth) {
              configX = window.innerWidth - overlay.offsetWidth - 20;
            }
            /**
             * This is used to prevent the config
             * form from overlapping on the bottom side
             */
            if (window.innerHeight < configY + overlay.offsetHeight) {
              configY = window.innerHeight - overlay.offsetHeight - 20;
            }
            configX = Math.max(5, configX);
            configY = Math.max(5, configY);
            overlay.style.setProperty("--floating-x-override", `${configX}px`);
            overlay.style.setProperty("--floating-y-override", `${configY}px`);
          }
        });
      }
    }
  }
};