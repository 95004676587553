import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");
  const _component_ApplicationShell = _resolveComponent("ApplicationShell");
  return $options.token ? (_openBlock(), _createBlock(_component_ApplicationShell, {
    key: 0,
    style: _normalizeStyle($options.cssProps)
  }, {
    default: _withCtx(() => [_createVNode(_component_router_view, null, {
      default: _withCtx(({
        Component
      }) => [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))]),
      _: 1
    })]),
    _: 1
  }, 8, ["style"])) : (_openBlock(), _createBlock(_component_router_view, {
    key: 1
  }, {
    default: _withCtx(({
      Component
    }) => [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))]),
    _: 1
  }));
}