import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.find.js";
import "core-js/modules/es.iterator.map.js";
import { customValueBlueprint } from "@/addonBlueprint";
export default {
  props: {
    customFields: Array,
    entry: Object
  },
  data() {
    return {
      hoveredRating: []
    };
  },
  computed: {
    customValues: {
      get() {
        return this.customFields?.map(cf => {
          return {
            ...customValueBlueprint(this.entry, cf),
            ...this.entry?.custom_values.find(cv => cv.custom_field_id == cf.id)
          };
        });
      },
      set(customValues) {
        this.$emit("update:modelValue", customValues);
      }
    }
  },
  methods: {
    updateCustomValue(index, value) {
      // if (this.customValues[index].value == value) return;
      const entrySerialized = JSON.stringify(this.entry);
      this.customValues[index].value = value;
      if (value || this.customValues[index].source == "ai") {
        const customValue = this.customValues[index];
        if (this.entry) {
          const existingCustomValue = this.entry.custom_values.find(cv => cv.custom_field_id == customValue.custom_field_id);
          if (existingCustomValue) {
            existingCustomValue.value = customValue.value;
          } else {
            // eslint-disable-next-line vue/no-mutating-props
            this.entry.custom_values.push(customValue);
          }
        }
      } else {
        if (this.entry) {
          // eslint-disable-next-line vue/no-mutating-props
          this.entry.custom_values = this.entry.custom_values.filter(cv => cv.custom_field_id != this.customValues[index].custom_field_id);
        }
      }
      const isEntryChanged = entrySerialized != JSON.stringify(this.entry);
      if (this.entry && !this.entry.temp && isEntryChanged) this.$store.dispatch("push", {
        event: "entry_update",
        params: {
          entry: this.entry
        },
        entry: this.entry
      });
      if (isEntryChanged) this.$emit("update:customValues", this.entry.custom_values);
    }
  }
};