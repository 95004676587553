import AuthenticationPage from "./components/auth/AuthenticationPage.vue";
import LoginProvider from "./components/auth/LoginProvider.vue";
// import Register from "./components/auth/Register.vue";
// import ForgotPassword from "./components/auth/ForgotPassword.vue";
// import ResetPassword from "./components/auth/ResetPassword.vue";
import Logout from "./components/auth/Logout.vue";
import ProfilePage from "./components/ProfilePage.vue";
import RoutinesPage from "./components/RoutinesPage.vue";
// import EmailVerification from "./components/EmailVerification.vue";

import SpacePage from "./components/SpacePage.vue";
import SubscriptionPage from "./components/SubscriptionPage.vue";
import OnboardingPage from "./components/OnboardingPage.vue";

import Start from "./components/Start.vue";
import EntryPage from "./components/EntryPage.vue";
// import DebugPage from "./components/DebugPage.vue";
// import FocusPage from "./components/FocusPage.vue";
// import EntryPageWithModal from "./components/EntryPageWithModal.vue";

const routes = [
  {
    path: "/",
    component: Start,
    name: "start",
    meta: {
      requiresAuth: true,
      title: "Start",
      settings: {
        display: {
          header: {

          }
        },
      },
    },
  },
  {
    path: "/index.html",
    redirect: "/",
  },
  {
    path: "/auth/:provider/callback",
    component: LoginProvider,
    name: "login_provider",
    meta: {
      requiresVisitor: true,
      title: "Login",
      settings: {
        display: {
          header: false,
          sidebar: false,
          footer: false,
        },
      },
    },
  },
  {
    path: "/login",
    component: AuthenticationPage,
    name: "login",
    meta: {
      requiresVisitor: true,
      title: "Login",
      settings: {
        display: {
          header: false,
          sidebar: false,
          footer: false,
        },
      },
    },
  },
  {
    path: "/register",
    component: AuthenticationPage,
    name: "register",
    meta: {
      requiresVisitor: true,
      title: "Register",
      settings: {
        display: {
          header: false,
          sidebar: false,
          footer: false,
        },
      },
    },
  },
  {
    path: "/forgot-password",
    component: AuthenticationPage,
    name: "forgot-password",
    meta: {
      requiresVisitor: true,
      title: "Forgot Password",
      settings: {
        display: {
          header: false,
          sidebar: false,
          footer: false,
        },
      },
    },
  },
  {
    path: "/reset-password/:token",
    component: AuthenticationPage,
    name: "reset-password",
    meta: {
      requiresVisitor: true,
      title: "Reset Password",
      settings: {
        display: {
          header: false,
          sidebar: false,
          footer: false,
        },
      },
    },
  },
  {
    path: "/logout",
    component: Logout,
    name: "logout",
    meta: {
      requiresAuth: true,
      title: "Logout",
      settings: {
        display: {
          header: {
            breadcrumbs: false,
            favorites: false,
          },
          sidebar: false,
          footer: false,
        },
      },
    },
  },
  {
    path: "/profile",
    component: ProfilePage,
    name: "profile",
    meta: {
      requiresAuth: true,
      title: "Profile",
      settings: {
        display: {
          header: {
            breadcrumbs: true,
            favorites: true,
          },
          sidebar: true,
          footer: {},
        },
      },
    },
  },
  {
    path: "/routines",
    component: RoutinesPage,
    name: "routines",
    meta: {
      requiresAuth: true,
      title: "Routines",
      settings: {
        display: {
          header: {
            breadcrumbs: true,
            favorites: true,
          },
          sidebar: {},
          footer: {},
        },
      },
    },
  },
  {
    path: "/email/verify/:verification_id/:verification_hash",
    component: AuthenticationPage,
    name: "email-verification",
    meta: {
      title: "Verify Email",
      settings: {
        display: {
          header: false,
          sidebar: false,
          footer: false,
        },
      },
    },
  },
  {
    path: "/space/:page?",
    component: SpacePage,
    name: "space",
    meta: {
      requiresAuth: true,
      title: "Space Settings",
      settings: {
        display: {
          header: {
            breadcrumbs: true,
            favorites: true,
          },
          sidebar: true,
          footer: {},
        },
      },
    },
  },
  {
    path: "/space/subscription",
    component: SubscriptionPage,
    name: "subscription",
    meta: {
      requiresAuth: true,
      title: "Subscription",
      settings: {
        display: {
          header: {
            breadcrumbs: true,
            favorites: true,
          },
          sidebar: {},
          footer: {},
        },
      },
    },
  },
  {
    path: "/onboarding",
    component: OnboardingPage,
    name: "onboarding",
    meta: {
      title: "Onboarding",
      requiresAuth: true,
      settings: {
        display: {
          header: false,
          sidebar: false,
          footer: false,
        },
      },
    },
  },
  {
    path: "/:id/:id2?",
    component: EntryPage,
    name: "entry",
    meta: {
      requiresAuth: true,
      settings: {
        display: {
          header: {
            breadcrumbs: true,
            favorites: true,
          },
          sidebar: {},
          footer: {}
        },
      },
    },
  },
];

export default routes;
