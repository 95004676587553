import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.for-each.js";
import { nanoid } from "nanoid";
export default {
  name: "ToastsComponent",
  watch: {
    "toasts.length": function () {
      this.toasts.filter(t => !t.id).forEach(t => {
        const id = nanoid();
        t.id = id;
        setTimeout(() => {
          this.toasts.splice(this.toasts.findIndex(t => t.id == id), 1);
        }, 5000);
      });
    }
  },
  computed: {
    toasts() {
      return this.$store.getters.toasts;
    }
  }
};