import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.find.js";
import { Statusing } from "../mixins/Statusing";
import { Scheduling } from "../mixins/Scheduling";
import { Routining } from "../mixins/Routining";
import { TimeTrackingsMixin } from "../timetrackings/mixins/TimeTrackingsMixin";
export default {
  mixins: [Statusing, Scheduling, Routining, TimeTrackingsMixin],
  props: {
    modelValue: Object,
    entry: Object,
    full: Boolean,
    label: Boolean,
    statuses: {
      type: Array
    },
    teleport: {
      type: Boolean,
      default: false
    },
    disabled: Boolean,
    position: String,
    size: String,
    internalSettings: {
      type: Object,
      default: () => ({
        display: {
          label: false,
          settings: false
        }
      })
    }
  },
  data() {
    return {
      statusOverride: null,
      disableQuickSwap: false,
      skipQuickSwapTimeout: null,
      // search related
      showSearchInput: false,
      searchStatusQuery: ""
    };
  },
  computed: {
    status: {
      get() {
        return this.modelValue;
      },
      set(status) {
        if (this.entry) {
          this.setStatus(this.entry, status);
          if (!this.entry.temp) this.$store.dispatch("push", {
            event: "entry_update",
            params: {
              entry: this.entry
            },
            entry: this.entry
          });
        }
        this.$emit("update:modelValue", status);
      }
    },
    quickStatusSwapEnabled() {
      return typeof Cypress == "undefined" && this.status && this.statuses?.length == 2;
    }
  },
  methods: {
    onMouseDown(e, open) {
      this.mouseDown = true;
      e.stopPropagation();
      e.preventDefault();
      if (e.button === 0 && this.quickStatusSwapEnabled) {
        this.skipQuickSwapTimeout = setTimeout(() => {
          if (this.mouseDown) {
            this.disableQuickSwap = true;
            open();
          }
        }, 500);
      }
    },
    onMouseUp(e) {
      if (this.skipQuickSwapTimeout) {
        clearTimeout(this.skipQuickSwapTimeout);
      }
      this.mouseDown = false;
      e.stopPropagation();
      e.preventDefault();
      if (e.button === 0 && this.quickStatusSwapEnabled && !this.statusOverride) {
        if (this.skipQuickSwapTimeout) {
          if (!this.disableQuickSwap && !this.disabled) {
            this.statusOverride = this.statuses.find(s => s.id != this.status.id);
            setTimeout(() => {
              this.status = this.statuses.find(s => s.id != this.status.id);
              this.statusOverride = null;
            }, 500);
          }
          this.disableQuickSwap = false;
        }
      }
    },
    onMountSearch() {
      if (!this.$isCapacitor && !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        setTimeout(() => {
          this.$refs.search?.focus();
        }, 100);
      }
    }
  }
};