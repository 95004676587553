import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, unref as _unref, normalizeStyle as _normalizeStyle, Transition as _Transition, Teleport as _Teleport } from "vue";
const _hoisted_1 = {
  class: "ps_output_settings_button_label"
};
const _hoisted_2 = {
  class: "ml-4"
};
const _hoisted_3 = {
  class: "text-sm font-medium text-gray-900 dark:text-neutral-300"
};
const _hoisted_4 = {
  class: "text-sm text-gray-500 dark:text-neutral-400"
};
const _hoisted_5 = {
  class: "ps_output_settings_button_label"
};
const _hoisted_6 = {
  class: "flex flex-col gap-4 p-3 sm:w-[320px] md:w-[500px] lg:w-[700px]"
};
const _hoisted_7 = {
  class: "ps_output_settings_button_label"
};
const _hoisted_8 = {
  class: "ml-4"
};
const _hoisted_9 = {
  class: "text-sm font-medium text-gray-900 dark:text-neutral-300"
};
const _hoisted_10 = {
  class: "text-sm text-gray-500 dark:text-neutral-400"
};
const _hoisted_11 = {
  key: 0,
  class: "text-sm text-gray-500 dark:text-neutral-400"
};
const _hoisted_12 = {
  class: "ps_output_settings_button_label"
};
const _hoisted_13 = {
  class: "flex flex-col gap-4 p-3 sm:w-[320px] md:w-[500px] lg:w-[700px]"
};
const _hoisted_14 = {
  class: "flex flex-col gap-5"
};
const _hoisted_15 = {
  key: 0
};
const _hoisted_16 = {
  key: 1
};
import { addonBlueprint } from "@/addonBlueprint";
import { ref } from "vue";
import { useFloating, shift, flip } from "@floating-ui/vue";
import { Addon } from "../mixins/Addon";
const __default__ = {
  mixins: [Addon],
  inject: {
    renderType: {
      default: "list"
    }
  },
  props: {
    permissions: {
      type: Object,
      default: () => ({
        type: true,
        group: true,
        filter: true,
        sort: true
      })
    },
    render_menu: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    "output.id": {
      handler() {
        this.temporarySorting = null;
        this.temporaryFilters = null;
      }
    }
  },
  data() {
    return {
      temporaryFilters: null,
      temporarySorting: null,
      showModal: false
    };
  },
  computed: {
    output: {
      get() {
        return this.modelValue;
      },
      set(output) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.output = output;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: {
            entry: this.entry
          },
          entry: this.entry
        });
      }
    }
  }
};
export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'OutputSettings',
  setup(__props) {
    const reference = ref(null);
    const floating = ref(null);
    const {
      floatingStyles
    } = useFloating(reference, floating, {
      placement: "bottom-start",
      middleware: [flip(), shift({
        crossAxis: true
      })]
    });
    return (_ctx, _cache) => {
      const _component_ListIcon = _resolveComponent("ListIcon");
      const _component_KanbanIcon = _resolveComponent("KanbanIcon");
      const _component_Grid2X2Icon = _resolveComponent("Grid2X2Icon");
      const _component_TableIcon = _resolveComponent("TableIcon");
      const _component_CalendarIcon = _resolveComponent("CalendarIcon");
      const _component_SquareStackIcon = _resolveComponent("SquareStackIcon");
      const _component_PSButton = _resolveComponent("PSButton");
      const _component_OutputTypePicker = _resolveComponent("OutputTypePicker");
      const _component_PSOverlay = _resolveComponent("PSOverlay");
      const _component_FilterIcon = _resolveComponent("FilterIcon");
      const _component_SectionHeader = _resolveComponent("SectionHeader");
      const _component_OutputFilterBuilder = _resolveComponent("OutputFilterBuilder");
      const _component_PopoverHelper = _resolveComponent("PopoverHelper");
      const _component_LayersIcon = _resolveComponent("LayersIcon");
      const _component_ArrowDownAZIcon = _resolveComponent("ArrowDownAZIcon");
      const _component_ArrowUpAZIcon = _resolveComponent("ArrowUpAZIcon");
      const _component_OutputGroupingPicker = _resolveComponent("OutputGroupingPicker");
      const _component_ChevronsUpDownIcon = _resolveComponent("ChevronsUpDownIcon");
      const _component_OutputSortingBuilder = _resolveComponent("OutputSortingBuilder");
      const _component_SettingsIcon = _resolveComponent("SettingsIcon");
      const _component_MenuButton = _resolveComponent("MenuButton");
      const _component_OverlayComponent = _resolveComponent("OverlayComponent");
      const _component_MenuItem = _resolveComponent("MenuItem");
      const _component_MenuItems = _resolveComponent("MenuItems");
      const _component_Menu = _resolveComponent("Menu");
      const _component_ViewIcon = _resolveComponent("ViewIcon");
      const _component_ModalHelper = _resolveComponent("ModalHelper");
      return _ctx.output ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.$style.outputSettings)
      }, [__props.permissions.type ? (_openBlock(), _createBlock(_component_PSOverlay, {
        key: 0,
        teleport: true,
        onOpen: _cache[1] || (_cache[1] = $event => _ctx.$emit('on:popover-open')),
        onClose: _cache[2] || (_cache[2] = $event => _ctx.$emit('on:popover-close'))
      }, {
        button: _withCtx(() => [_createVNode(_component_PSButton, {
          class: _normalizeClass([_ctx.$style.settingsButton, "output_settings_button"]),
          title: "Change Output Type"
        }, {
          default: _withCtx(() => [_ctx.output.type == 'list' ? (_openBlock(), _createBlock(_component_ListIcon, {
            key: 0,
            size: "16",
            strokeWidth: "1.5"
          })) : _ctx.output.type == 'board' ? (_openBlock(), _createBlock(_component_KanbanIcon, {
            key: 1,
            size: "16",
            strokeWidth: "1.5"
          })) : _ctx.output.type == 'gallery' ? (_openBlock(), _createBlock(_component_Grid2X2Icon, {
            key: 2,
            size: "16",
            strokeWidth: "1.5"
          })) : _ctx.output.type == 'table' ? (_openBlock(), _createBlock(_component_TableIcon, {
            key: 3,
            size: "16",
            strokeWidth: "1.5"
          })) : ['calendar', 'calendar_day', 'calendar_week', 'calendar_month'].includes(_ctx.output.type) ? (_openBlock(), _createBlock(_component_CalendarIcon, {
            key: 4,
            size: "16",
            strokeWidth: "1.5"
          })) : _ctx.output.type == 'tiles' ? (_openBlock(), _createBlock(_component_SquareStackIcon, {
            key: 5,
            size: "16",
            strokeWidth: "1.5"
          })) : _createCommentVNode("", true), _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.output.type.replace("_", " ") || "Type"), 1)]),
          _: 1
        }, 8, ["class"])]),
        title: _withCtx(() => _cache[28] || (_cache[28] = [_createElementVNode("span", null, " Output Type ", -1)])),
        default: _withCtx(({
          close
        }) => [_createVNode(_component_OutputTypePicker, {
          modelValue: _ctx.output.type,
          "onUpdate:modelValue": [_cache[0] || (_cache[0] = $event => _ctx.output.type = $event), type => {
            close();
            _ctx.output = {
              ..._ctx.output,
              ...{
                type: type
              }
            };
            if (type == 'table') {
              _ctx.output = {
                ..._ctx.output,
                ...{
                  settings: {
                    table: {
                      columns: [{
                        field: 'name',
                        width: 120
                      }, {
                        field: 'status',
                        width: 80
                      }, {
                        field: 'description',
                        width: 150
                      }]
                    }
                  }
                }
              };
            }
          }]
        }, {
          default: _withCtx(({
            option,
            active
          }) => [_createVNode(_component_PSButton, {
            class: _normalizeClass({
              [_ctx.$style.typeButton]: true,
              [_ctx.$style.typeButton_Active]: active
            })
          }, {
            default: _withCtx(() => [_createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.icon)
            }, [(_openBlock(), _createBlock(_resolveDynamicComponent(option.icon), {
              size: "20"
            }))], 2), _createElementVNode("div", _hoisted_2, [_createElementVNode("p", _hoisted_3, _toDisplayString(option.label), 1), _createElementVNode("p", _hoisted_4, _toDisplayString(option.description), 1)])]),
            _: 2
          }, 1032, ["class"])]),
          _: 2
        }, 1032, ["modelValue", "onUpdate:modelValue"])]),
        _: 1
      })) : _createCommentVNode("", true), __props.permissions.filter ? (_openBlock(), _createBlock(_component_PopoverHelper, {
        key: 1,
        id: "output_builder",
        teleport: true,
        canOverflow: true,
        onOpen: _cache[5] || (_cache[5] = $event => _ctx.$emit('on:popover-open')),
        onClose: _cache[6] || (_cache[6] = $event => (_ctx.temporaryFilters !== null ? _ctx.output = {
          ..._ctx.output,
          ...{
            filters: _ctx.temporaryFilters
          }
        } : null, _ctx.$emit('on:popover-close')))
      }, {
        button: _withCtx(() => [_createVNode(_component_PSButton, {
          title: "Filter Output",
          class: _normalizeClass([_ctx.$style.settingsButton, "output_settings_button"]),
          "data-table-column-target": ""
        }, {
          default: _withCtx(() => [_createVNode(_component_FilterIcon, {
            size: "16",
            strokeWidth: "1.5"
          }), _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.output.filters.length || "Filters"), 1)]),
          _: 1
        }, 8, ["class"])]),
        default: _withCtx(({
          close
        }) => [_createElementVNode("div", _hoisted_6, [_createVNode(_component_SectionHeader, {
          url: "https://help.pensive.io/outputs"
        }, {
          title: _withCtx(() => [_createVNode(_component_FilterIcon, {
            size: "16"
          }), _cache[29] || (_cache[29] = _createTextVNode(" Filters "))]),
          _: 1
        }), _createVNode(_component_OutputFilterBuilder, {
          id: "output_builder_inner",
          modelValue: _ctx.output.filters,
          "onUpdate:modelValue": [_cache[3] || (_cache[3] = $event => _ctx.output.filters = $event), filters => {
            close();
            _ctx.output = {
              ..._ctx.output,
              ...{
                filters: filters
              }
            };
          }],
          entry: _ctx.entry,
          onOnChange: _cache[4] || (_cache[4] = data => {
            _ctx.temporaryFilters = data;
          }),
          "onCancel:modelValue": close
        }, null, 8, ["modelValue", "entry", "onCancel:modelValue", "onUpdate:modelValue"])])]),
        _: 1
      })) : _createCommentVNode("", true), __props.permissions.group ? (_openBlock(), _createBlock(_component_PopoverHelper, {
        key: 2,
        teleport: true,
        onOpen: _cache[8] || (_cache[8] = $event => _ctx.$emit('on:popover-open')),
        onClose: _cache[9] || (_cache[9] = $event => _ctx.$emit('on:popover-close'))
      }, {
        button: _withCtx(() => [_createVNode(_component_PSButton, {
          class: _normalizeClass([_ctx.$style.settingsButton, "output_settings_button"]),
          title: "Group Output"
        }, {
          default: _withCtx(() => [_createVNode(_component_LayersIcon, {
            size: "16",
            strokeWidth: "1.5"
          }), _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.output.grouping?.field || "Group"), 1)]),
          _: 1
        }, 8, ["class"])]),
        default: _withCtx(({
          close
        }) => [_createVNode(_component_OutputGroupingPicker, {
          modelValue: _ctx.output.grouping,
          "onUpdate:modelValue": [_cache[7] || (_cache[7] = $event => _ctx.output.grouping = $event), grouping => {
            close();
            _ctx.output = {
              ..._ctx.output,
              ...{
                grouping: grouping
              }
            };
          }],
          size: _ctx.size,
          variant: _ctx.variant,
          color: _ctx.color,
          class: "flex flex-col gap-4 p-3"
        }, {
          default: _withCtx(({
            option,
            active,
            direction
          }) => [_createVNode(_component_PSButton, {
            class: _normalizeClass({
              [_ctx.$style.groupButton]: true,
              [_ctx.$style.groupButton_Active]: active
            })
          }, {
            default: _withCtx(() => [_createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.icon)
            }, [(_openBlock(), _createBlock(_resolveDynamicComponent(option.icon), {
              size: "20"
            }))], 2), _createElementVNode("div", _hoisted_8, [_createElementVNode("p", _hoisted_9, _toDisplayString(option.label), 1), _createElementVNode("p", _hoisted_10, _toDisplayString(option.description), 1), direction ? (_openBlock(), _createElementBlock("p", _hoisted_11, [_createElementVNode("span", null, [_cache[30] || (_cache[30] = _createTextVNode(" Direction: ")), direction == 1 ? (_openBlock(), _createBlock(_component_ArrowDownAZIcon, {
              key: 0,
              size: "16",
              class: "inline"
            })) : (_openBlock(), _createBlock(_component_ArrowUpAZIcon, {
              key: 1,
              size: "16",
              class: "inline"
            }))])])) : _createCommentVNode("", true)])]),
            _: 2
          }, 1032, ["class"])]),
          _: 2
        }, 1032, ["modelValue", "size", "variant", "color", "onUpdate:modelValue"])]),
        _: 1
      })) : _createCommentVNode("", true), __props.permissions.sort ? (_openBlock(), _createBlock(_component_PopoverHelper, {
        key: 3,
        canOverflow: true,
        teleport: true,
        onOpen: _cache[12] || (_cache[12] = $event => _ctx.$emit('on:popover-open')),
        onClose: _cache[13] || (_cache[13] = $event => (_ctx.temporarySorting !== null ? _ctx.output = {
          ..._ctx.output,
          ...{
            sorting: _ctx.temporarySorting,
            custom_sorting: []
          }
        } : null, _ctx.$emit('on:popover-close')))
      }, {
        button: _withCtx(() => [_createVNode(_component_PSButton, {
          title: "Sort Output",
          class: _normalizeClass([_ctx.$style.settingsButton, "output_settings_button"])
        }, {
          default: _withCtx(() => [_createVNode(_component_ChevronsUpDownIcon, {
            size: "16",
            strokeWidth: "1.5"
          }), _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.output.sorting.length || "Sorting"), 1)]),
          _: 1
        }, 8, ["class"])]),
        default: _withCtx(({
          close
        }) => [_createElementVNode("div", _hoisted_13, [_createVNode(_component_SectionHeader, {
          url: "https://help.pensive.io/outputs"
        }, {
          title: _withCtx(() => [_createVNode(_component_ChevronsUpDownIcon, {
            size: "16",
            strokeWidth: "1.5"
          }), _cache[31] || (_cache[31] = _createTextVNode(" Sorting "))]),
          _: 1
        }), _createVNode(_component_OutputSortingBuilder, {
          modelValue: _ctx.output.sorting,
          "onUpdate:modelValue": [_cache[10] || (_cache[10] = $event => _ctx.output.sorting = $event), sorting => {
            close();
            _ctx.output = {
              ..._ctx.output,
              ...{
                sorting: sorting,
                custom_sorting: []
              }
            };
          }],
          onOnChange: _cache[11] || (_cache[11] = data => {
            _ctx.temporarySorting = data;
          }),
          "onCancel:modelValue": close
        }, null, 8, ["modelValue", "onCancel:modelValue", "onUpdate:modelValue"])])]),
        _: 1
      })) : _createCommentVNode("", true), !_ctx.disabled && __props.render_menu ? (_openBlock(), _createBlock(_component_Menu, {
        key: 4,
        as: "div"
      }, {
        default: _withCtx(({
          open
        }) => [_createElementVNode("div", null, [_createVNode(_component_MenuButton, {
          ref_key: "reference",
          ref: reference,
          onClick: _cache[14] || (_cache[14] = _withModifiers(() => {}, ["stop"]))
        }, {
          default: _withCtx(() => [_createVNode(_component_PSButton, {
            class: _normalizeClass([_ctx.$style.settingsButton, "output_settings_button"]),
            title: "Open Output Options"
          }, {
            default: _withCtx(() => [_cache[32] || (_cache[32] = _createElementVNode("span", {
              class: "sr-only"
            }, "Open options", -1)), _createVNode(_component_SettingsIcon, {
              size: "16",
              strokeWidth: "1.5",
              "aria-hidden": "true"
            })]),
            _: 1
          }, 8, ["class"])]),
          _: 1
        }, 512)]), (_openBlock(), _createBlock(_Teleport, {
          to: "body"
        }, [open ? (_openBlock(), _createBlock(_component_OverlayComponent, {
          key: 0,
          onClick: $event => open = false
        }, null, 8, ["onClick"])) : _createCommentVNode("", true), _createVNode(_Transition, {
          "enter-active-class": "transition ease-spring duration-100",
          "enter-from-class": "transform opacity-0 translate-y-1",
          "enter-to-class": "transform opacity-100 translate-y-0",
          "leave-active-class": "transition ease-spring duration-100",
          "leave-from-class": "transform opacity-100 translate-y-0",
          "leave-to-class": "transform opacity-0 translate-y-1"
        }, {
          default: _withCtx(() => [_createVNode(_component_MenuItems, {
            class: "absolute right-0 z-[999] mt-2"
          }, {
            default: _withCtx(() => [_createElementVNode("div", {
              ref_key: "floating",
              ref: floating,
              style: _normalizeStyle(_unref(floatingStyles)),
              class: "w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-black dark:ring-neutral-700"
            }, [!_ctx.disabled ? (_openBlock(), _createBlock(_component_MenuItem, {
              key: 0
            }, {
              default: _withCtx(({
                active
              }) => [_createElementVNode("a", {
                onClick: _cache[15] || (_cache[15] = $event => _ctx.showModal = true),
                class: _normalizeClass([active ? 'bg-gray-100 text-gray-900 dark:bg-neutral-900 dark:text-neutral-300' : 'text-gray-700 dark:text-neutral-400', 'block  cursor-pointer px-4 py-2 text-sm'])
              }, " Edit Output ", 2)]),
              _: 1
            })) : _createCommentVNode("", true), !_ctx.disabled ? (_openBlock(), _createBlock(_component_MenuItem, {
              key: 1
            }, {
              default: _withCtx(({
                active
              }) => [_createElementVNode("a", {
                onClick: _cache[16] || (_cache[16] = $event => _ctx.output = null),
                class: _normalizeClass([active ? 'bg-gray-100 text-gray-900 dark:bg-neutral-900 dark:text-neutral-300' : 'text-gray-700 dark:text-neutral-400', 'block cursor-pointer px-4 py-2 text-sm'])
              }, " Delete Output ", 2)]),
              _: 1
            })) : _createCommentVNode("", true)], 4)]),
            _: 1
          })]),
          _: 1
        })]))]),
        _: 1
      })) : _createCommentVNode("", true), _createVNode(_component_ModalHelper, {
        show: _ctx.showModal,
        onClose: _cache[26] || (_cache[26] = $event => _ctx.showModal = false)
      }, {
        title: _withCtx(() => _cache[33] || (_cache[33] = [_createTextVNode(" Edit Output ")])),
        default: _withCtx(() => [_createElementVNode("div", _hoisted_14, [_createVNode(_component_SectionHeader, {
          url: "https://help.pensive.io/outputs"
        }, {
          title: _withCtx(() => [_createVNode(_component_ViewIcon, {
            size: "16"
          }), _cache[34] || (_cache[34] = _createTextVNode(" Type "))]),
          _: 1
        }), _createVNode(_component_OutputTypePicker, {
          modelValue: _ctx.output.type,
          "onUpdate:modelValue": [_cache[17] || (_cache[17] = $event => _ctx.output.type = $event), _cache[18] || (_cache[18] = type => {
            _ctx.output = {
              ..._ctx.output,
              ...{
                type: type
              }
            };
            if (type == 'table') {
              _ctx.output = {
                ..._ctx.output,
                ...{
                  settings: {
                    table: {
                      columns: [{
                        field: 'name',
                        width: 120
                      }, {
                        field: 'status',
                        width: 80
                      }, {
                        field: 'description',
                        width: 150
                      }]
                    }
                  }
                }
              };
            }
          })],
          class: _normalizeClass(_ctx.$style.typePicker)
        }, {
          default: _withCtx(({
            option,
            active
          }) => [_createElementVNode("div", {
            class: _normalizeClass({
              [_ctx.$style.typePickerButton]: true,
              [_ctx.$style.typePickerButton_Active]: active
            })
          }, [_createElementVNode("div", null, [(_openBlock(), _createBlock(_resolveDynamicComponent(option.icon), {
            size: "26",
            "stroke-width": "1.5"
          }))]), _createElementVNode("div", null, _toDisplayString(option.label), 1)], 2)]),
          _: 1
        }, 8, ["modelValue", "class"]), _createVNode(_component_SectionHeader, {
          url: "https://help.pensive.io/outputs"
        }, {
          title: _withCtx(() => [_createVNode(_component_FilterIcon, {
            size: "16"
          }), _cache[35] || (_cache[35] = _createTextVNode(" Filters "))]),
          _: 1
        }), _createVNode(_component_OutputFilterBuilder, {
          id: "output_builder_inner",
          modelValue: _ctx.output.filters,
          "onUpdate:modelValue": [_cache[19] || (_cache[19] = $event => _ctx.output.filters = $event), _cache[21] || (_cache[21] = filters => {
            // showModal = false;
            _ctx.output = {
              ..._ctx.output,
              ...{
                filters: filters
              }
            };
          })],
          entry: _ctx.entry,
          onOnChange: _cache[20] || (_cache[20] = data => {
            _ctx.temporaryFilters = data;
          })
        }, null, 8, ["modelValue", "entry"]), _createVNode(_component_SectionHeader, {
          url: "https://help.pensive.io/outputs"
        }, {
          title: _withCtx(() => [_createVNode(_component_LayersIcon, {
            size: "16"
          }), _cache[36] || (_cache[36] = _createTextVNode(" Grouping "))]),
          _: 1
        }), _createVNode(_component_OutputGroupingPicker, {
          modelValue: _ctx.output.grouping,
          "onUpdate:modelValue": [_cache[22] || (_cache[22] = $event => _ctx.output.grouping = $event), _cache[23] || (_cache[23] = grouping => {
            _ctx.output = {
              ..._ctx.output,
              ...{
                grouping: grouping
              }
            };
          })],
          class: _normalizeClass(_ctx.$style.groupingPicker)
        }, {
          default: _withCtx(({
            option,
            active,
            direction
          }) => [_createElementVNode("div", {
            class: _normalizeClass({
              [_ctx.$style.groupingPickerButton]: true,
              [_ctx.$style.groupingPickerButton_Active]: active
            })
          }, [_createElementVNode("div", null, [(_openBlock(), _createBlock(_resolveDynamicComponent(option.icon), {
            size: "26",
            "stroke-width": "1.5"
          }))]), _createElementVNode("div", null, [_createElementVNode("span", null, _toDisplayString(option.label), 1)]), direction ? (_openBlock(), _createElementBlock("div", _hoisted_15, [_createElementVNode("span", null, [_cache[37] || (_cache[37] = _createTextVNode(" Direction: ")), direction == 1 ? (_openBlock(), _createBlock(_component_ArrowDownAZIcon, {
            key: 0,
            size: "16",
            class: "inline"
          })) : (_openBlock(), _createBlock(_component_ArrowUpAZIcon, {
            key: 1,
            size: "16",
            class: "inline"
          }))])])) : _createCommentVNode("", true)], 2)]),
          _: 1
        }, 8, ["modelValue", "class"]), _createVNode(_component_SectionHeader, {
          url: "https://help.pensive.io/outputs"
        }, {
          title: _withCtx(() => [_createVNode(_component_ChevronsUpDownIcon, {
            size: "16",
            strokeWidth: "1.5"
          }), _cache[38] || (_cache[38] = _createTextVNode(" Sorting "))]),
          _: 1
        }), _createVNode(_component_OutputSortingBuilder, {
          modelValue: _ctx.output.sorting,
          "onUpdate:modelValue": [_cache[24] || (_cache[24] = $event => _ctx.output.sorting = $event), _cache[25] || (_cache[25] = sorting => {
            _ctx.output = {
              ..._ctx.output,
              ...{
                sorting: sorting,
                custom_sorting: []
              }
            };
          })],
          entry: _ctx.entry
        }, null, 8, ["modelValue", "entry"])])]),
        _: 1
      }, 8, ["show"])], 2)) : (_openBlock(), _createElementBlock("div", _hoisted_16, [_createVNode(_component_PSButton, {
        onClick: _cache[27] || (_cache[27] = $event => _ctx.output = _unref(addonBlueprint)('output', _ctx.entry, this)),
        title: "Add Output",
        shortcode: "o",
        class: _normalizeClass(_ctx.$style.createButton),
        "data-table-column-target": ""
      }, {
        default: _withCtx(() => _cache[39] || (_cache[39] = [_createElementVNode("span", null, " Add Output ", -1)])),
        _: 1
      }, 8, ["class"])]));
    };
  }
});